import { Menu, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { faToggleOff, faToggleOn, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { AccessGroupDto, AccessGroupStatusDto } from '../../../api';
import accessGroupApi from '../../../data-access/access-group-api';
import DeleteButton from '../../../ui/action-menu/delete-button/delete-button';
import { BurgerMenuButton, BurgerMenuButtonProps } from '../../../ui/burger-menu-button/burger-menu-button';
import usePermission from '../../permission/use-permission';
import AccessGroupStatusToggleButton from '../access-group-status-toggle/access-group-status-toggle-button';

interface AccessGroupMenuProps extends BurgerMenuButtonProps {
  accessGroup: AccessGroupDto;
  afterDelete: () => void;
}

export default function AccessGroupMenu({ accessGroup, afterDelete, ...props }: AccessGroupMenuProps) {
  const { t } = useTranslation(['access_group']);
  const isActive = accessGroup.status === AccessGroupStatusDto.ACTIVE;
  const { hasPermission } = usePermission();

  const [isDeletable, setDeletable] = React.useState<boolean>(false);

  const canActivate = hasPermission('ACCESS_GROUP:CAN_ACTIVATE') && !isActive;
  const canDeactivate = hasPermission('ACCESS_GROUP:CAN_DEACTIVATE') && isActive;
  const canDelete = hasPermission('ACCESS_GROUP:CAN_DELETE');
  const showMenu = canActivate || canDeactivate || canDelete;

  const checkStatus = React.useCallback(async (accessGroup: AccessGroupDto) => {
    setDeletable(false);
    invariant(accessGroup.id != null, 'Missing access group id');
    const response = await accessGroupApi.accessGroupDeletable({ id: accessGroup.id });
    setDeletable(response);
  }, []);

  return (
    showMenu && (
      <Menu onOpen={() => checkStatus(accessGroup)}>
        <BurgerMenuButton {...props} />
        <Portal>
          <MenuList>
            {(canActivate || canDeactivate) && (
              <MenuItem
                as={AccessGroupStatusToggleButton}
                accessGroup={accessGroup}
                color={isActive ? 'text.error' : 'text'}
                icon={<FontAwesomeIcon icon={isActive ? faToggleOff : faToggleOn} fixedWidth />}
              >
                {isActive ? t('access_group:action.deactivate') : t('access_group:action.activate')}
              </MenuItem>
            )}
            {canDelete && (
              <DeleteMenuItem
                accessGroup={accessGroup}
                afterDelete={afterDelete}
                isDisabled={isActive || !isDeletable}
              />
            )}
          </MenuList>
        </Portal>
      </Menu>
    )
  );
}

const DeleteMenuItem = React.memo(
  ({
    accessGroup,
    afterDelete,
    isDisabled,
  }: {
    accessGroup: AccessGroupDto;
    afterDelete?: () => void;
    isDisabled: boolean;
  }) => {
    const { t } = useTranslation(['access_group']);
    return (
      <MenuItem
        color="text.error"
        icon={<FontAwesomeIcon icon={faTrashAlt} fixedWidth />}
        as={DeleteButton<AccessGroupDto>}
        businessObject={accessGroup}
        isDisabled={isDisabled}
        disableReason={t('access_group:invalidate.disabledReason')}
        objectName={accessGroup.title}
        deleteObject={({ id }: AccessGroupDto) => accessGroupApi.deleteAccessGroup({ id })}
        objectTypeToDelete={t('access_group:accessGroup')}
        afterDelete={afterDelete}
        objectTypeWithArticleNominative={t('access_group:accessGroup', { context: 'withArticleNominative' })}
        objectTypeWithArticleGenitive={t('access_group:accessGroup', { context: 'withArticleGenitive' })}
      >
        {t('access_group:action.delete')}
      </MenuItem>
    );
  },
);
