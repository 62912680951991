/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CancellationFeeDto } from './CancellationFeeDto';
import {
    CancellationFeeDtoFromJSON,
    CancellationFeeDtoFromJSONTyped,
    CancellationFeeDtoToJSON,
} from './CancellationFeeDto';
import type { DiscountDto } from './DiscountDto';
import {
    DiscountDtoFromJSON,
    DiscountDtoFromJSONTyped,
    DiscountDtoToJSON,
} from './DiscountDto';
import type { ProductReferenceDto } from './ProductReferenceDto';
import {
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoFromJSONTyped,
    ProductReferenceDtoToJSON,
} from './ProductReferenceDto';

/**
 * 
 * @export
 * @interface AccountingItemsViewerDto
 */
export interface AccountingItemsViewerDto {
    /**
     * 
     * @type {ProductReferenceDto}
     * @memberof AccountingItemsViewerDto
     */
    product: ProductReferenceDto;
    /**
     * 
     * @type {DiscountDto}
     * @memberof AccountingItemsViewerDto
     */
    discount?: DiscountDto;
    /**
     * 
     * @type {number}
     * @memberof AccountingItemsViewerDto
     */
    netPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountingItemsViewerDto
     */
    cancelled: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountingItemsViewerDto
     */
    cancellationReason?: string;
    /**
     * 
     * @type {CancellationFeeDto}
     * @memberof AccountingItemsViewerDto
     */
    cancellationFee?: CancellationFeeDto;
    /**
     * 
     * @type {boolean}
     * @memberof AccountingItemsViewerDto
     */
    newCancellation: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountingItemsViewerDto
     */
    grossPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountingItemsViewerDto
     */
    taxPrice?: number;
}

/**
 * Check if a given object implements the AccountingItemsViewerDto interface.
 */
export function instanceOfAccountingItemsViewerDto(value: object): boolean {
    if (!('product' in value)) return false;
    if (!('cancelled' in value)) return false;
    if (!('newCancellation' in value)) return false;
    return true;
}

export function AccountingItemsViewerDtoFromJSON(json: any): AccountingItemsViewerDto {
    return AccountingItemsViewerDtoFromJSONTyped(json, false);
}

export function AccountingItemsViewerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountingItemsViewerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'product': ProductReferenceDtoFromJSON(json['product']),
        'discount': json['discount'] == null ? undefined : DiscountDtoFromJSON(json['discount']),
        'netPrice': json['netPrice'] == null ? undefined : json['netPrice'],
        'cancelled': json['cancelled'],
        'cancellationReason': json['cancellationReason'] == null ? undefined : json['cancellationReason'],
        'cancellationFee': json['cancellationFee'] == null ? undefined : CancellationFeeDtoFromJSON(json['cancellationFee']),
        'newCancellation': json['newCancellation'],
        'grossPrice': json['grossPrice'] == null ? undefined : json['grossPrice'],
        'taxPrice': json['taxPrice'] == null ? undefined : json['taxPrice'],
    };
}

export function AccountingItemsViewerDtoToJSON(value?: AccountingItemsViewerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'product': ProductReferenceDtoToJSON(value['product']),
        'discount': DiscountDtoToJSON(value['discount']),
        'netPrice': value['netPrice'],
        'cancelled': value['cancelled'],
        'cancellationReason': value['cancellationReason'],
        'cancellationFee': CancellationFeeDtoToJSON(value['cancellationFee']),
        'newCancellation': value['newCancellation'],
        'grossPrice': value['grossPrice'],
        'taxPrice': value['taxPrice'],
    };
}

