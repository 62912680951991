import { chakra, ChakraProps, HStack, Link, StackProps, Text } from '@chakra-ui/react';
import React, { ForwardedRef, forwardRef, isValidElement } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import HasPermission from '../../feature/permission/has-permission';
import { Permission, UsePermissionReturn } from '../../feature/permission/use-permission';
import { BusinessObjectIconDefinition, renderBusinessObjectIcon } from '../icons/business-objects';

export interface ReferenceProps extends ChakraProps {
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  spacing?: StackProps['spacing'];
  icon: BusinessObjectIconDefinition;
  hideIcon?: boolean;
  to?: LinkProps['to'];
  id?: string;
  necessaryPermission?: Permission | ((hasPermission: UsePermissionReturn) => boolean);
  maxWidth?: string | number;
}

const Reference = forwardRef(
  (
    {
      children,
      size = 'md',
      to,
      spacing,
      icon,
      hideIcon = false,
      id,
      necessaryPermission,
      maxWidth,
      ...props
    }: ReferenceProps,
    ref: ForwardedRef<HTMLAnchorElement | HTMLDivElement>,
  ) => {
    const outerProps = {
      display: to == null ? 'inline-flex' : 'inline-block',
      id,
      ref,
      ...props,
    };

    let element = (
      // @ts-expect-error  ref type is invalid
      <HStack fontSize={size} spacing={spacing ?? (size === 'sm' ? 1 : 2)} {...(to == null ? outerProps : {})}>
        {!hideIcon && renderBusinessObjectIcon(icon, { fixedWidth: true })}
        {isValidElement(children) ? (
          children
        ) : (
          <Text as="span" fontWeight="medium" noOfLines={1} maxWidth={maxWidth ?? '240'}>
            {children}
          </Text>
        )}
      </HStack>
    );

    if (to != null) {
      const link = (
        // @ts-expect-error ref type is invalid
        <Link
          // Title creates a system tooltip, good for when long reference content is cut off by ellipsis.
          // Only works for string children because this software is coooomplicated.
          // We use the system tooltip here instead of the chakra tooltip due to performance reasons.
          title={typeof children === 'string' ? children : undefined}
          as={RouterLink}
          to={to}
          size={size}
          {...outerProps}
        >
          {element}
        </Link>
      );

      element =
        necessaryPermission != null ? (
          <HasPermission
            necessaryPermission={necessaryPermission}
            fallback={<chakra.div display="inline-block">{element}</chakra.div>}
          >
            {link}
          </HasPermission>
        ) : (
          link
        );
    }

    return element;
  },
);

export default Reference;
