import { chakra } from '@chakra-ui/react';
import { faBold } from '@fortawesome/pro-regular-svg-icons';
import React, { ComponentPropsWithoutRef } from 'react';
import { toggleMark } from '../mark';
import { Render } from '../render';
import { HotkeyAction, WithFunction } from '../slate-types';
import { useRichTextStyles } from '../styles-context';
import { MarkToolbarButton } from '../toolbar/toolbar-button';

const BOLD = 'bold';

const withBold: WithFunction = (editor) => {
  const renderBold: Render<'mark'> = {
    type: BOLD,
    render: (children) => <Bold>{children}</Bold>,
  };
  editor.renderers = [...editor.renderers, renderBold];

  editor.toolbarButtons.mark = [...editor.toolbarButtons.mark, <MarkToolbarButton format={BOLD} icon={faBold} />];

  const hotkey: HotkeyAction = { hotkey: 'mod+b', action: () => toggleMark(editor, BOLD) };
  editor.hotkeys = [...editor.hotkeys, hotkey];

  return editor;
};

export default withBold;

function Bold({ children, ...props }: ComponentPropsWithoutRef<typeof chakra.strong>) {
  const styles = useRichTextStyles();

  return (
    <chakra.strong __css={styles.bold} {...props}>
      {children}
    </chakra.strong>
  );
}
