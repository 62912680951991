/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateRangeDto } from './ClosedDateRangeDto';
import {
    ClosedDateRangeDtoFromJSON,
    ClosedDateRangeDtoFromJSONTyped,
    ClosedDateRangeDtoToJSON,
} from './ClosedDateRangeDto';

/**
 * Header of an Edition
 * @export
 * @interface EditionHeaderDto
 */
export interface EditionHeaderDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof EditionHeaderDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EditionHeaderDto
     */
    name: string;
    /**
     * There is only one active edition which is the default selection for users.
     * @type {boolean}
     * @memberof EditionHeaderDto
     */
    readonly active: boolean;
    /**
     * 
     * @type {ClosedDateRangeDto}
     * @memberof EditionHeaderDto
     */
    dateRange: ClosedDateRangeDto;
}

/**
 * Check if a given object implements the EditionHeaderDto interface.
 */
export function instanceOfEditionHeaderDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('active' in value)) return false;
    if (!('dateRange' in value)) return false;
    return true;
}

export function EditionHeaderDtoFromJSON(json: any): EditionHeaderDto {
    return EditionHeaderDtoFromJSONTyped(json, false);
}

export function EditionHeaderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditionHeaderDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'active': json['active'],
        'dateRange': ClosedDateRangeDtoFromJSON(json['dateRange']),
    };
}

export function EditionHeaderDtoToJSON(value?: EditionHeaderDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'dateRange': ClosedDateRangeDtoToJSON(value['dateRange']),
    };
}

