/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';

/**
 * 
 * @export
 * @interface LandingPageDesignSponsorLogoVariantDto
 */
export interface LandingPageDesignSponsorLogoVariantDto {
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof LandingPageDesignSponsorLogoVariantDto
     */
    metadata: FileMetadataDto;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof LandingPageDesignSponsorLogoVariantDto
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageDesignSponsorLogoVariantDto
     */
    linkTarget: LandingPageDesignSponsorLogoVariantDtoLinkTargetEnum;
    /**
     * 
     * @type {string}
     * @memberof LandingPageDesignSponsorLogoVariantDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageDesignSponsorLogoVariantDto
     */
    alt?: string;
}


/**
 * @export
 */
export const LandingPageDesignSponsorLogoVariantDtoLinkTargetEnum = {
    SELF: '_self',
    BLANK: '_blank'
} as const;
export type LandingPageDesignSponsorLogoVariantDtoLinkTargetEnum = typeof LandingPageDesignSponsorLogoVariantDtoLinkTargetEnum[keyof typeof LandingPageDesignSponsorLogoVariantDtoLinkTargetEnum];


/**
 * Check if a given object implements the LandingPageDesignSponsorLogoVariantDto interface.
 */
export function instanceOfLandingPageDesignSponsorLogoVariantDto(value: object): boolean {
    if (!('metadata' in value)) return false;
    if (!('link' in value)) return false;
    if (!('linkTarget' in value)) return false;
    return true;
}

export function LandingPageDesignSponsorLogoVariantDtoFromJSON(json: any): LandingPageDesignSponsorLogoVariantDto {
    return LandingPageDesignSponsorLogoVariantDtoFromJSONTyped(json, false);
}

export function LandingPageDesignSponsorLogoVariantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageDesignSponsorLogoVariantDto {
    if (json == null) {
        return json;
    }
    return {
        
        'metadata': FileMetadataDtoFromJSON(json['metadata']),
        'link': json['link'],
        'linkTarget': json['linkTarget'],
        'title': json['title'] == null ? undefined : json['title'],
        'alt': json['alt'] == null ? undefined : json['alt'],
    };
}

export function LandingPageDesignSponsorLogoVariantDtoToJSON(value?: LandingPageDesignSponsorLogoVariantDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'metadata': FileMetadataDtoToJSON(value['metadata']),
        'link': value['link'],
        'linkTarget': value['linkTarget'],
        'title': value['title'],
        'alt': value['alt'],
    };
}

