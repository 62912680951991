import {
  faList,
  faMailBulk,
  faPassport,
  faPaw,
  faShoppingCart,
  faSignOut,
  faUsersCrown,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import accessGroupMenuItems from './feature/access-group/access-group-menu-items';
import MenuItem from './feature/app/app-navigation/menu-item';
import SubMenuItem from './feature/app/app-navigation/sub-menu-item';
import awardMenuItems from './feature/award/award-menu-items';
import codeMenuItems from './feature/code/code-menu-items';
import companyMenuItems from './feature/company/company-menu-items';
import countryMenuItems from './feature/country/country-menu-items';
import editionMenuItems from './feature/edition/edition-menu-items';
import eventMenuItems from './feature/event/event-menu-items';
import exportMenuItems from './feature/export/export-menu-items';
import filmMenuItems from './feature/film/film-menu-items';
import formatMenuItems from './feature/format/format-menu-items';
import invoiceMenuItems from './feature/invoice/invoice-menu-items';
import landingPageDesignMenuItems from './feature/landing-page-design/landing-page-design-menu-items';
import landingPageTemplateMenuItems from './feature/landing-page-template/landing-page-template-menu-items';
import landingPageMenuItems from './feature/landing-page/landing-page-menu-items';
import listMenuItems from './feature/list/list-menu-items';
import locationMenuItems from './feature/location/location-menu-items';
import mailingBlocklistMenuItems from './feature/mailing-blocklist/mailing-blocklist-menu-items';
import mailingDesignMenuItems from './feature/mailing-design/mailing-design-menu-items';
import mailingMenuItems from './feature/mailing/mailing-menu-items';
import myAccountMenuItem from './feature/my-account/my-account-menu-item';
import offerMenuItems from './feature/offer/offer-menu-items';
import orderConfirmationMenuItems from './feature/order-confirmation/order-confirmation-menu-items';
import orderMenuItems from './feature/order/order-menu-items';
import permissionClassGroupMenuItems from './feature/permission-class-group/permission-class-group-menu-items';
import anonymisationMenuItems from './feature/person/anonymisation/anonymisation-menu-items';
import personMenuItems from './feature/person/person-menu-items';
import personToDeleteMenuItems from './feature/person/person-to-delete-menu-items';
import productBundlesMenuItems from './feature/product-bundle/product-bundle-menu-items';
import productMenuItems from './feature/product/product-menu-items';
import reportingMenuItems from './feature/reporting/reporting-menu-items';
import sectionMenuItems from './feature/section/section-menu-items';
import staffMenuItems from './feature/staff/staff-menu-items';
import standMenuItems from './feature/stand/stand-menu-items';
import systemMailingMenuItems from './feature/system-mailing/system-mailing-menu-items';
import tagMenuItems from './feature/tag/tag-menu-items';
import teamMenuItems from './feature/team/team-menu-items';
import visaTemplateMenuItems from './feature/visa-template/visa-template-menu-items';
import visaMenuItems from './feature/visa/visa-menu-items';
import { companyIcon, eventIcon } from './ui/icons/business-objects';
import Translate from './util/translate/translate';

export const mainMenuItems: MenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.event" />,
    icon: eventIcon,
    menuItems: [...eventMenuItems, ...filmMenuItems],
  },
  {
    name: <Translate ns="app" i18nKey="main_navigation.person" />,
    icon: faUsersCrown,
    menuItems: personMenuItems,
  },
  {
    name: <Translate ns="app" i18nKey="main_navigation.company" />,
    icon: companyIcon,
    menuItems: [...companyMenuItems, ...formatMenuItems, ...standMenuItems],
  },
  {
    name: <Translate ns="app" i18nKey="main_navigation.mailing" />,
    icon: faMailBulk,
    menuItems: [
      ...mailingMenuItems,
      ...mailingDesignMenuItems,
      ...systemMailingMenuItems,
      ...landingPageMenuItems,
      ...landingPageTemplateMenuItems,
      ...landingPageDesignMenuItems,
    ],
  },
  {
    name: <Translate ns="app" i18nKey="main_navigation.order" />,
    icon: faShoppingCart,
    menuItems: [
      ...orderMenuItems,
      ...offerMenuItems,
      ...productMenuItems,
      ...productBundlesMenuItems,
      ...codeMenuItems,
      ...orderConfirmationMenuItems,
      ...invoiceMenuItems,
    ],
  },
  {
    name: <Translate ns="app" i18nKey="main_navigation.management" />,
    icon: faPaw,
    menuItems: [
      ...locationMenuItems,
      ...countryMenuItems,
      ...tagMenuItems,
      ...teamMenuItems,
      ...reportingMenuItems,
      ...sectionMenuItems,
      ...accessGroupMenuItems,
      ...staffMenuItems,
      ...awardMenuItems,
    ],
  },
  {
    name: <Translate ns="app" i18nKey="main_navigation.visa" />,
    icon: faPassport,
    menuItems: [...visaTemplateMenuItems, ...visaMenuItems],
  },
  {
    name: <Translate ns="app" i18nKey="main_navigation.admin" />,
    icon: faWrench,
    menuItems: [
      ...editionMenuItems,
      ...exportMenuItems,
      ...personToDeleteMenuItems,
      ...anonymisationMenuItems,
      ...mailingBlocklistMenuItems,
      myAccountMenuItem,
      ...permissionClassGroupMenuItems,
    ],
  },
  {
    name: <Translate ns="app" i18nKey="main_navigation.lists" />,
    icon: faList,
    menuItems: [...listMenuItems],
  },
];

export const logoutMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="account_navigation.logout" />,
    icon: faSignOut,
    path: '/logout',
  },
];
