import { Editor, Element, Location, Node, NodeEntry, Transforms } from 'slate';
import { isBlockActive } from './block';
import { FormatOption, InlineFormatOption } from './slate-types';

export function isInlineBlockActive(editor: Editor, format: InlineFormatOption) {
  return isBlockActive(editor, format);
}

export function selectionIsInline(editor: Editor) {
  const { selection } = editor;
  if (!selection) {
    return false;
  }

  const nodesAtSelection = Array.from(Editor.nodes(editor, { at: selection }));
  return nodesAtSelection.some(([node]) => {
    if (node.type === 'editor' || node.type === 'text') {
      return false;
    }
    return Editor.isInline(editor, node);
  });
}

export function removeInline(editor: Editor, format: InlineFormatOption) {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
  });
}

export function addInline(editor: Editor, format: InlineFormatOption, url: string) {
  // Unwrap other inline elements first.
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && Editor.isInline(editor, n),
  });

  Transforms.wrapNodes(editor, { type: format, url, children: [] }, { split: true });
}

export function updateInline(editor: Editor, at: Location, url: string) {
  Transforms.setNodes(editor, { url }, { at });
}

export function normalizeInline(editor: Editor) {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry: NodeEntry<Node>) => {
    const [node, path] = entry;

    if (Element.isElement(node) && Editor.isBlock(editor, node) && !editor.markOrInlineBlockAllowed(node)) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Element.isElement(child) && Editor.isInline(editor, child)) {
          Transforms.unwrapNodes(editor, { at: childPath });
          return;
        }
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };
}

export function normalizeChildLength(editor: Editor, maxLength: number, format: FormatOption) {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length > 0 && node.type === format) {
      const text = Editor.string(editor, path);

      // Overwrite current node to have max length
      if (text.length > maxLength) {
        Transforms.insertText(editor, text.substring(0, maxLength), { at: [...path, 0] });
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode([node, path]);
  };
}
