import { has } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { OrderConfirmationListItemDto, OrderConfirmationViewerDto } from '../../../api';
import orderConfirmationApi from '../../../data-access/order-confirmation-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import openDownload from '../../../ui/export/download-opener';
import { orderConfirmationFetcher } from '../order-confirmation-queries';

interface OrderConfirmationDownloadButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  orderConfirmation: OrderConfirmationViewerDto | OrderConfirmationListItemDto;
}

export const OrderConfirmationDownloadButton = React.forwardRef<
  HTMLButtonElement,
  OrderConfirmationDownloadButtonProps
>(({ orderConfirmation, children, ...props }, ref) => {
  const { t } = useTranslation('order_confirmation');
  const pdfWasCreated = isListItem(orderConfirmation)
    ? orderConfirmation.documentGenerated
    : orderConfirmation.document != null;

  const handleClick = async () => {
    const response = await orderConfirmationApi.downloadOrderConfirmationRaw({
      id: orderConfirmation.id,
    });

    const extractFileName = (header: string): string => {
      const match = header.match(/attachment\s*;\s*filename\s*=\s*"([^"]+)"/i);
      invariant(match != null, `Invalid Header Content-Disposition: ${header}`);
      return match[1];
    };
    const contentDispositionHeader = response.raw.headers.get('Content-Disposition');
    invariant(contentDispositionHeader != null, 'Header Content-Disposition missing');
    const fileName = extractFileName(contentDispositionHeader);

    openDownload(await response.raw.blob(), fileName);

    await orderConfirmationFetcher.mutate();
  };

  return (
    <>
      <DisableableButton
        {...props}
        ref={ref}
        onClick={handleClick}
        aria-label={t('action.download.label')}
        isDisabled={!pdfWasCreated}
        disableReason={t('action.download.disabled')}
        data-testid="order-confirmation-download-button"
      >
        {children}
      </DisableableButton>
    </>
  );
});

function isListItem(
  orderConfirmation: OrderConfirmationViewerDto | OrderConfirmationListItemDto,
): orderConfirmation is OrderConfirmationListItemDto {
  return has(orderConfirmation, 'documentGenerated');
}
