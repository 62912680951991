import { faHistory, faHomeAlt } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, Outlet } from 'react-router-dom';
import { PageHeader, PageTab } from '../../../ui/page';
import Page from '../../../ui/page/page';
import usePlugins from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import useParam from '../../../util/use-params/use-params';
import { FilmHelmet } from '../film-helmet';
import { fetchFilm } from '../film-queries';
import { mostRelevantTitle } from '../film-utils';
import { FILM_TAB_ITEM } from './film-tab-item';

export const Component = FilmPageRoute;

export const loader = ({ params }: LoaderFunctionArgs) => {
  return fetchFilm.preload({ id: params.filmId! });
};

export const handle = {
  helmet: <FilmHelmet />,
};

export default function FilmPageRoute() {
  const film = useFetcher(fetchFilm, { id: useParam('filmId') });
  const tabs = <FilmPageTabs />;

  return (
    <Page>
      <PageHeader title={mostRelevantTitle(film)} tabs={tabs} />
      <Outlet />
    </Page>
  );
}

function FilmPageTabs() {
  const { t } = useTranslation('common');
  const tabExtensions = usePlugins(FILM_TAB_ITEM);

  return (
    <>
      <PageTab to="." icon={faHomeAlt} />
      {tabExtensions.map((tabItem, index) => (
        <PageTab key={'additionalTab' + index} to={tabItem.to} icon={tabItem.icon}>
          {tabItem.label}
        </PageTab>
      ))}
      <PageTab to="./history" icon={faHistory}>
        {t('history.label')}
      </PageTab>
    </>
  );
}
