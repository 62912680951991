/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';

/**
 * 
 * @export
 * @interface ProductBundlePriceDto
 */
export interface ProductBundlePriceDto {
    /**
     * The price of the product bundle in euro excluding taxes.
     * @type {number}
     * @memberof ProductBundlePriceDto
     */
    amount: number;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof ProductBundlePriceDto
     */
    availability: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlePriceDto
     */
    taxRate: number;
}

/**
 * Check if a given object implements the ProductBundlePriceDto interface.
 */
export function instanceOfProductBundlePriceDto(value: object): boolean {
    if (!('amount' in value)) return false;
    if (!('availability' in value)) return false;
    if (!('taxRate' in value)) return false;
    return true;
}

export function ProductBundlePriceDtoFromJSON(json: any): ProductBundlePriceDto {
    return ProductBundlePriceDtoFromJSONTyped(json, false);
}

export function ProductBundlePriceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBundlePriceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'availability': ClosedDateTimeRangeDtoFromJSON(json['availability']),
        'taxRate': json['taxRate'],
    };
}

export function ProductBundlePriceDtoToJSON(value?: ProductBundlePriceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
        'availability': ClosedDateTimeRangeDtoToJSON(value['availability']),
        'taxRate': value['taxRate'],
    };
}

