/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';

/**
 * 
 * @export
 * @interface CountryReferencePageDto
 */
export interface CountryReferencePageDto {
    /**
     * The number of the page.
     * @type {number}
     * @memberof CountryReferencePageDto
     */
    page: number;
    /**
     * The size of the page.
     * @type {number}
     * @memberof CountryReferencePageDto
     */
    size: number;
    /**
     * The total number of data returned by the search.
     * @type {number}
     * @memberof CountryReferencePageDto
     */
    totalElements: number;
    /**
     * The total number of pages of current size to fit all data.
     * @type {number}
     * @memberof CountryReferencePageDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<CountryReferenceDto>}
     * @memberof CountryReferencePageDto
     */
    content: Array<CountryReferenceDto>;
}

/**
 * Check if a given object implements the CountryReferencePageDto interface.
 */
export function instanceOfCountryReferencePageDto(value: object): boolean {
    if (!('page' in value)) return false;
    if (!('size' in value)) return false;
    if (!('totalElements' in value)) return false;
    if (!('totalPages' in value)) return false;
    if (!('content' in value)) return false;
    return true;
}

export function CountryReferencePageDtoFromJSON(json: any): CountryReferencePageDto {
    return CountryReferencePageDtoFromJSONTyped(json, false);
}

export function CountryReferencePageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryReferencePageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'content': ((json['content'] as Array<any>).map(CountryReferenceDtoFromJSON)),
    };
}

export function CountryReferencePageDtoToJSON(value?: CountryReferencePageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
        'content': ((value['content'] as Array<any>).map(CountryReferenceDtoToJSON)),
    };
}

