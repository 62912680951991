/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyStandPermissionDto
 */
export interface CompanyStandPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyStandPermissionDto
     */
    name: CompanyStandPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyStandPermissionDto
     */
    action: CompanyStandPermissionDtoActionEnum;
}


/**
 * @export
 */
export const CompanyStandPermissionDtoNameEnum = {
    COMPANY_STAND: 'COMPANY-STAND'
} as const;
export type CompanyStandPermissionDtoNameEnum = typeof CompanyStandPermissionDtoNameEnum[keyof typeof CompanyStandPermissionDtoNameEnum];

/**
 * @export
 */
export const CompanyStandPermissionDtoActionEnum = {
    CAN_LINK: 'CAN_LINK'
} as const;
export type CompanyStandPermissionDtoActionEnum = typeof CompanyStandPermissionDtoActionEnum[keyof typeof CompanyStandPermissionDtoActionEnum];


/**
 * Check if a given object implements the CompanyStandPermissionDto interface.
 */
export function instanceOfCompanyStandPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function CompanyStandPermissionDtoFromJSON(json: any): CompanyStandPermissionDto {
    return CompanyStandPermissionDtoFromJSONTyped(json, false);
}

export function CompanyStandPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyStandPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function CompanyStandPermissionDtoToJSON(value?: CompanyStandPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

