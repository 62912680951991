import { NumberInput, NumberInputField, Stack, Text, useId } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEBOUNCE_TIME } from '../../../util/constants';
import useDebouncedState from '../../../util/debounce/use-debounced-state';
import { useDataTableFilter } from '../data-table-context';
import RecordSelect from './record-select';

const OperatorOptions = {
  GREATER_THAN_OR_EQUALS: 'gte',
  LESS_THAN_OR_EQUALS: 'lte',
  EQUAL: 'eq',
} as const;

interface NumberFilterProps {
  label: React.ReactNode;
}

export default function NumberFilter({ label }: NumberFilterProps) {
  const { t } = useTranslation('common');
  const { property, getFilter, setFilter, removeFilters, initialFocusRef } = useDataTableFilter();
  const id = useId(undefined, 'number-filter');

  const { operator, value: filterValue } = getFilter(property) ?? {};

  const [operatorOption, setOperatorOption] = React.useState(operator ?? OperatorOptions.GREATER_THAN_OR_EQUALS);

  const [value, setValue] = useDebouncedState(
    filterValue ?? '',
    (value) => {
      if (value === '') {
        removeFilters(property);
      } else {
        setFilter({ operator: operatorOption, value, property });
      }
    },
    DEBOUNCE_TIME,
  );

  React.useEffect(() => {
    setOperatorOption((operatorOption) => operator ?? operatorOption);
  }, [operator]);

  const handleOperatorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const operatorOption = event.target.value;

    setOperatorOption(operatorOption);

    if (value !== '') {
      setFilter({ operator: operatorOption, value, property });
    }
  };

  return (
    <Stack as="fieldset" spacing={2} aria-labelledby={`${id}-label`}>
      <Text as="div" fontSize="sm" fontWeight="medium" id={`${id}-label`}>
        {label}
      </Text>
      <RecordSelect<typeof OperatorOptions>
        size="sm"
        value={operatorOption}
        onChange={handleOperatorChange}
        options={OperatorOptions}
        aria-label={t(`data_table.number_filter.operator_label`)}
      >
        {(key) => t(`data_table.number_filter.operator_option.${key}`)}
      </RecordSelect>

      <NumberInput
        size="sm"
        aria-label={t('data_table.string_filter.filter_label')}
        value={value}
        onChange={(value) => {
          if (value.match(/^\d*$/)) {
            setValue(value);
          }
        }}
        max={9999}
        min={0}
      >
        <NumberInputField ref={initialFocusRef} placeholder={t('select.placeholder')} />
      </NumberInput>
    </Stack>
  );
}
