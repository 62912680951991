/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductReferenceDto } from './ProductReferenceDto';
import {
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoFromJSONTyped,
    ProductReferenceDtoToJSON,
} from './ProductReferenceDto';

/**
 * 
 * @export
 * @interface GroupTemplatePermittedProductDto
 */
export interface GroupTemplatePermittedProductDto {
    /**
     * 
     * @type {number}
     * @memberof GroupTemplatePermittedProductDto
     */
    numberOf: number;
    /**
     * 
     * @type {ProductReferenceDto}
     * @memberof GroupTemplatePermittedProductDto
     */
    product: ProductReferenceDto;
}

/**
 * Check if a given object implements the GroupTemplatePermittedProductDto interface.
 */
export function instanceOfGroupTemplatePermittedProductDto(value: object): boolean {
    if (!('numberOf' in value)) return false;
    if (!('product' in value)) return false;
    return true;
}

export function GroupTemplatePermittedProductDtoFromJSON(json: any): GroupTemplatePermittedProductDto {
    return GroupTemplatePermittedProductDtoFromJSONTyped(json, false);
}

export function GroupTemplatePermittedProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupTemplatePermittedProductDto {
    if (json == null) {
        return json;
    }
    return {
        
        'numberOf': json['numberOf'],
        'product': ProductReferenceDtoFromJSON(json['product']),
    };
}

export function GroupTemplatePermittedProductDtoToJSON(value?: GroupTemplatePermittedProductDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'numberOf': value['numberOf'],
        'product': ProductReferenceDtoToJSON(value['product']),
    };
}

