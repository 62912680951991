import { Alert, AlertDescription, AlertIcon, Flex, GridItem, Stack } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { EventEvaluationDto, EventEvaluationStatusDto } from '../../../api';
import eventEvaluationApi from '../../../data-access/event-evaluation-api';
import Page from '../../../ui/page';
import PageContent from '../../../ui/page/page-content';
import PagePublicHeader from '../../../ui/page/page-public-header';
import PageSpinner from '../../../ui/page/page-spinner';
import { LightModeBox } from '../../../util/color-mode/color-mode-box';
import usePromise from '../../../util/use-promise/use-promise';
import { EventEvaluationBaseViewer } from '../../event-evaluation/event-evaluation-viewer/event-evaluation-viewer';
import { PublicEventReferenceHeader } from '../../event/event-reference/event-reference-header';
import PublicEventEvaluationEditor from './public-event-evaluation-editor';

export default function PublicEventEvaluationPage() {
  const { t } = useTranslation('event_evaluation');
  const { eventEvaluationId } = useParams<{ eventEvaluationId: string }>();

  invariant(eventEvaluationId != null, 'Missing eventEvaluationId');
  const publicEventEvaluation = usePublicEditorEventEvaluation(eventEvaluationId);

  return (
    <LightModeBox>
      <Page>
        <GridItem>
          <PagePublicHeader />
          {publicEventEvaluation != null && !canBeEdited(publicEventEvaluation.eventEvaluation) && (
            <Alert status="warning" aria-live="polite">
              <AlertIcon />
              <AlertDescription>
                <Trans t={t} i18nKey="error_when_not_editable" />
              </AlertDescription>
            </Alert>
          )}
        </GridItem>
        {publicEventEvaluation != null ? (
          <PageContent useFullWidth fixedHeight mb={4}>
            <React.Suspense fallback={<PageSpinner />}>
              <Stack spacing={6} height="full">
                <Flex as="header">
                  <PublicEventReferenceHeader
                    eventSettings={publicEventEvaluation.eventSettings}
                    title={
                      canBeEdited(publicEventEvaluation.eventEvaluation)
                        ? t('title_edit', { event: publicEventEvaluation.eventSettings.eventReference.title })
                        : t('title_view', { event: publicEventEvaluation.eventSettings.eventReference.title })
                    }
                  />
                </Flex>
                {canBeEdited(publicEventEvaluation.eventEvaluation) ? (
                  <PublicEventEvaluationEditor publicEventEvaluation={publicEventEvaluation} />
                ) : (
                  <Stack>
                    <EventEvaluationBaseViewer
                      eventEvaluation={publicEventEvaluation.eventEvaluation}
                      editable={false}
                      showLastModified={false}
                    />
                  </Stack>
                )}
              </Stack>
            </React.Suspense>
          </PageContent>
        ) : (
          <PageSpinner />
        )}
      </Page>
    </LightModeBox>
  );
}

/**
 * The object used in the editor, template in status pending if new or else existing object.
 */
function usePublicEditorEventEvaluation(eventEvaluationId: string) {
  return usePromise(
    (signal) => {
      return eventEvaluationApi.fetchPublicEvaluation({ id: eventEvaluationId }, { signal });
    },
    [eventEvaluationId],
  );
}

function canBeEdited(eventEvaluation: EventEvaluationDto): boolean {
  return (
    eventEvaluation.status === EventEvaluationStatusDto.PENDING ||
    eventEvaluation.status === EventEvaluationStatusDto.SUBMITTED
  );
}
