import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useSystemMailingPermissionClassGroupExtension(): PermissionClassGroupExtension<'SYSTEM_MAILING'> {
  const { t } = useTranslation(['system_mailing']);

  return {
    name: 'SYSTEM_MAILING',
    label: t('system_mailing:systemMailing'),
    getActionLabel: (action) => t(`system_mailing:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`system_mailing:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`system_mailing:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
