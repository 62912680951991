import React from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyFilmRelationListItemDto } from '../../../api';
import DataTable, { DataTableColumn, DataTableState, useDataTableState } from '../../../ui/data-table';
import EnumFilter from '../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchRelatedCompaniesForFilm } from '../company-queries';
import CompanyReference from '../company-reference/company-reference';
import { companyFilmRoleOptions } from './film-enum-constants';

function useRelatedCompanies(state: DataTableState, filmId: string) {
  const requestParams = useRequestParams(state);

  return useFetcher(fetchRelatedCompaniesForFilm, { ...requestParams, filmId }, { use: [fallbackMiddleware] });
}

interface CompanyFilmDataTableProps {
  filmId: string;
}

export default function FilmCompanyDataTable({ filmId }: CompanyFilmDataTableProps) {
  const { t } = useTranslation(['company']);
  const [state, setState] = useDataTableState({ sort: [{ property: 'company.name', direction: 'ASC' }] });

  const page = useRelatedCompanies(state, filmId);

  const columns: DataTableColumn<CompanyFilmRelationListItemDto>[] = React.useMemo(() => {
    return [
      {
        key: 'company.name',
        name: t('company:company'),
        sticky: true,
        isSortable: true,
        filter: <StringFilter label={t('company:company')} />,
        renderCell: ({ company }) => <CompanyReference companyReference={company} />,
      },
      {
        key: 'role',
        name: t('company:relation.film.role'),
        isSortable: true,
        filter: (
          <EnumFilter
            label={t('company:relation.film.role')}
            options={companyFilmRoleOptions}
            getOptionStringValue={(v) => t(`company:relation.film.roleOptions.${v}`)}
            renderOptionLabel={(v) => t(`company:relation.film.roleOptions.${v}`)}
          />
        ),
        renderCell: ({ role }) => t(`company:relation.film.roleOptions.${role}`),
      },
    ] satisfies DataTableColumn<CompanyFilmRelationListItemDto>[];
  }, [t]);

  return (
    <DataTable
      page={page}
      state={state}
      columns={columns}
      rowKey={({ id }) => id}
      onStateChange={setState}
      isPageable
    />
  );
}
