/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupExternalApplicationStatusDto } from './GroupExternalApplicationStatusDto';
import {
    GroupExternalApplicationStatusDtoFromJSON,
    GroupExternalApplicationStatusDtoFromJSONTyped,
    GroupExternalApplicationStatusDtoToJSON,
} from './GroupExternalApplicationStatusDto';

/**
 * 
 * @export
 * @interface RemovePersonFromGroupApplicationDto
 */
export interface RemovePersonFromGroupApplicationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof RemovePersonFromGroupApplicationDto
     */
    id?: string;
    /**
     * 
     * @type {GroupExternalApplicationStatusDto}
     * @memberof RemovePersonFromGroupApplicationDto
     */
    status?: GroupExternalApplicationStatusDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof RemovePersonFromGroupApplicationDto
     */
    groupId?: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof RemovePersonFromGroupApplicationDto
     */
    personId?: string;
}

/**
 * Check if a given object implements the RemovePersonFromGroupApplicationDto interface.
 */
export function instanceOfRemovePersonFromGroupApplicationDto(value: object): boolean {
    return true;
}

export function RemovePersonFromGroupApplicationDtoFromJSON(json: any): RemovePersonFromGroupApplicationDto {
    return RemovePersonFromGroupApplicationDtoFromJSONTyped(json, false);
}

export function RemovePersonFromGroupApplicationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemovePersonFromGroupApplicationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'status': json['status'] == null ? undefined : GroupExternalApplicationStatusDtoFromJSON(json['status']),
        'groupId': json['groupId'] == null ? undefined : json['groupId'],
        'personId': json['personId'] == null ? undefined : json['personId'],
    };
}

export function RemovePersonFromGroupApplicationDtoToJSON(value?: RemovePersonFromGroupApplicationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'status': GroupExternalApplicationStatusDtoToJSON(value['status']),
        'groupId': value['groupId'],
        'personId': value['personId'],
    };
}

