import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useProductBundlePermissionClassGroupExtension(): PermissionClassGroupExtension<'PRODUCT_BUNDLE'> {
  const { t } = useTranslation(['product_bundle']);

  return {
    name: 'PRODUCT_BUNDLE',
    label: t('product_bundle:productBundle'),
    getActionLabel: (action) => t(`product_bundle:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`product_bundle:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`product_bundle:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
