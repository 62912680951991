/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanySectionDto } from './CompanySectionDto';
import {
    CompanySectionDtoFromJSON,
    CompanySectionDtoFromJSONTyped,
    CompanySectionDtoToJSON,
} from './CompanySectionDto';

/**
 * Reference of a company
 * @export
 * @interface CompanyReferenceDto
 */
export interface CompanyReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CompanyReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyReferenceDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyReferenceDto
     */
    status?: CompanyReferenceDtoStatusEnum;
    /**
     * 
     * @type {Array<CompanySectionDto>}
     * @memberof CompanyReferenceDto
     */
    companySections?: Array<CompanySectionDto>;
}


/**
 * @export
 */
export const CompanyReferenceDtoStatusEnum = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    HISTORIC: 'HISTORIC',
    UNCONFIRMED: 'UNCONFIRMED',
    ARCHIVED: 'ARCHIVED'
} as const;
export type CompanyReferenceDtoStatusEnum = typeof CompanyReferenceDtoStatusEnum[keyof typeof CompanyReferenceDtoStatusEnum];


/**
 * Check if a given object implements the CompanyReferenceDto interface.
 */
export function instanceOfCompanyReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function CompanyReferenceDtoFromJSON(json: any): CompanyReferenceDto {
    return CompanyReferenceDtoFromJSONTyped(json, false);
}

export function CompanyReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'] == null ? undefined : json['status'],
        'companySections': json['companySections'] == null ? undefined : ((json['companySections'] as Array<any>).map(CompanySectionDtoFromJSON)),
    };
}

export function CompanyReferenceDtoToJSON(value?: CompanyReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'status': value['status'],
        'companySections': value['companySections'] == null ? undefined : ((value['companySections'] as Array<any>).map(CompanySectionDtoToJSON)),
    };
}

