/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LiveTicketingPermissionDto
 */
export interface LiveTicketingPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof LiveTicketingPermissionDto
     */
    name: LiveTicketingPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof LiveTicketingPermissionDto
     */
    action: LiveTicketingPermissionDtoActionEnum;
}


/**
 * @export
 */
export const LiveTicketingPermissionDtoNameEnum = {
    LIVE_TICKETING: 'LIVE_TICKETING'
} as const;
export type LiveTicketingPermissionDtoNameEnum = typeof LiveTicketingPermissionDtoNameEnum[keyof typeof LiveTicketingPermissionDtoNameEnum];

/**
 * @export
 */
export const LiveTicketingPermissionDtoActionEnum = {
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    ADMIT: 'CAN_ADMIT',
    ADMIT_OWN: 'CAN_ADMIT_OWN',
    EDIT: 'CAN_EDIT',
    EDIT_OWN: 'CAN_EDIT_OWN'
} as const;
export type LiveTicketingPermissionDtoActionEnum = typeof LiveTicketingPermissionDtoActionEnum[keyof typeof LiveTicketingPermissionDtoActionEnum];


/**
 * Check if a given object implements the LiveTicketingPermissionDto interface.
 */
export function instanceOfLiveTicketingPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function LiveTicketingPermissionDtoFromJSON(json: any): LiveTicketingPermissionDto {
    return LiveTicketingPermissionDtoFromJSONTyped(json, false);
}

export function LiveTicketingPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LiveTicketingPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function LiveTicketingPermissionDtoToJSON(value?: LiveTicketingPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

