/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VisaTemplateExistForCountryRequestDto
 */
export interface VisaTemplateExistForCountryRequestDto {
    /**
     * The current visa template id
     * @type {string}
     * @memberof VisaTemplateExistForCountryRequestDto
     */
    visaTemplateId: string;
    /**
     * Countries to search for
     * @type {Array<string>}
     * @memberof VisaTemplateExistForCountryRequestDto
     */
    countryIds: Array<string>;
}

/**
 * Check if a given object implements the VisaTemplateExistForCountryRequestDto interface.
 */
export function instanceOfVisaTemplateExistForCountryRequestDto(value: object): boolean {
    if (!('visaTemplateId' in value)) return false;
    if (!('countryIds' in value)) return false;
    return true;
}

export function VisaTemplateExistForCountryRequestDtoFromJSON(json: any): VisaTemplateExistForCountryRequestDto {
    return VisaTemplateExistForCountryRequestDtoFromJSONTyped(json, false);
}

export function VisaTemplateExistForCountryRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisaTemplateExistForCountryRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'visaTemplateId': json['visaTemplateId'],
        'countryIds': json['countryIds'],
    };
}

export function VisaTemplateExistForCountryRequestDtoToJSON(value?: VisaTemplateExistForCountryRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'visaTemplateId': value['visaTemplateId'],
        'countryIds': value['countryIds'],
    };
}

