import React from 'react';
import invariant from 'tiny-invariant';
import { OrderListItemDto } from '../../../api';
import DataTable, { DataTableState, useDataTableState } from '../../../ui/data-table';
import useRequestParams from '../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';
import useFetcher from '../../../util/swr/use-fetcher';
import { statusDisplay } from '../order-enum-constants';
import { fetchGroupOrders } from '../order-queries';
import useOrderTableColumns from './use-order-table-columns';

function useGroupOrderListItems(state: DataTableState, groupId: string) {
  const requestParams = useRequestParams(state, [{ property: 'createdAt', direction: 'DESC' }]);
  return useFetcher(
    fetchGroupOrders,
    {
      ...requestParams,
      id: groupId,
    },
    { use: [fallbackMiddleware] },
  );
}

export default function GroupOrderDataTable({ groupId }: { groupId: string }) {
  const [state, setState] = useDataTableState();
  const page = useGroupOrderListItems(state, groupId);

  const columns = useOrderTableColumns('../../../orders/');

  const rowKey = React.useCallback((order: OrderListItemDto) => {
    invariant(order.id != null, 'Missing order id');

    return order.id;
  }, []);

  return (
    <DataTable
      page={page == null ? { content: [] } : page}
      state={state}
      columns={columns}
      rowKey={rowKey}
      onStateChange={setState}
      isPageable
      rowStyle={(data) => ({
        bg: statusDisplay[data.status]?.bgColor,
      })}
    />
  );
}
