import { FormControl, FormLabel } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ValueSelect from '../../select/value-select';
import { useDataTableFilter } from '../data-table-context';

export interface BooleanFilterProps {
  label: React.ReactNode;

  renderOptionLabel?(value: boolean): string;
}

/**
 * Boolean filter, may be used for all boolean columns in data tables.
 */
export default function BooleanFilter({ label, renderOptionLabel }: BooleanFilterProps) {
  const { t } = useTranslation('common');
  const { property, getFilter, setFilter, removeFilters, initialFocusRef } = useDataTableFilter();

  const filterStringValue = getFilter(property)?.value;
  const value = filterStringValue == null ? null : filterStringValue === 'true';

  const onSelectChange = (value: boolean | null) => {
    if (value != null) {
      setFilter({ operator: 'eq', value: String(value), property });
    } else {
      removeFilters(property);
    }
  };

  return (
    <FormControl>
      <FormLabel fontSize="sm">{label}</FormLabel>
      <ValueSelect
        aria-label={t('select.placeholder')}
        renderLabel={renderOptionLabel ?? ((value) => t(`boolean_viewer.${value}`))}
        options={[true, false]}
        value={value}
        onChange={onSelectChange}
        isClearable
        size="sm"
        ref={initialFocusRef}
      />
    </FormControl>
  );
}
