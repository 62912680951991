import { HStack, Stack } from '@chakra-ui/react';
import { TFunction } from 'i18next';
import React from 'react';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DiscountDto,
  DiscountDtoDiscountLevelEnum,
  DiscountDtoDiscountTypeEnum,
  SectionReferenceDto,
  StaffReferenceDto,
} from '../../../api';
import sectionApi from '../../../data-access/section-api';
import staffApi from '../../../data-access/staff-api';
import CurrencyFormControl from '../../../ui/form/currency-form-control/currency-form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import PercentageFormControl from '../../../ui/form/percentage-form-control/percentage-form-control';
import ValueAsyncSelectFormControl from '../../../ui/form/select-control/value-async-select-form-control';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import { LayoutType } from '../../common/layout-type';

const levelOptions: DiscountDtoDiscountLevelEnum[] = ['PAYMENT_EXEMPTION', 'CUSTOM'];
const typeOptions: DiscountDtoDiscountTypeEnum[] = ['PERCENTAGE', 'FIXED_AMOUNT'];

interface DiscountWrapper {
  // TODO remove initiator extension, when initiator moves inside discount
  discount?: DiscountDto & { initiator?: StaffReferenceDto };
}

interface DiscountControlProps<T extends FieldValues> {
  layout?: LayoutType;
  enableInitiatorSelection?: boolean;
  initialFocusRef?: React.RefObject<any>;
  maxDiscountAmount?: number;
  path?: FieldPath<T>;
}

export default function DiscountControl<T extends FieldValues>({
  layout = LayoutType.NORMAL,
  enableInitiatorSelection,
  initialFocusRef,
  maxDiscountAmount,
  path,
}: DiscountControlProps<T>) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation(['common', 'code']);
  const { setValue, getValues, watch } = useFormContext<DiscountWrapper>();

  const values = getValues();
  const discountTypeInitial = values.discount?.discountType;
  const discountLevelInitial = values.discount?.discountLevel;

  const discountType = watch(prefixWithPath('discount.discountType'), discountTypeInitial);
  const discountLevel = watch(prefixWithPath('discount.discountLevel'), discountLevelInitial);

  const sectionLoadOptions = async (value: string, pageSizeLimit: number) => {
    const page = await sectionApi.searchSections({
      pageable: { size: pageSizeLimit, sort: ['name,ASC'] },
      q: value,
    });
    return page.content.map((section) => ({
      id: section.id!,
      name: section.name,
      englishName: section.englishName,
      abbreviation: section.abbreviation,
    }));
  };

  const staffLoadOptions = async (query: string, pageSizeLimit: number) => {
    const page = await staffApi.searchStaff({
      pageable: { size: pageSizeLimit },
      filter: ['status,in,ACTIVE'],
      q: query,
    });

    return page.content.map((value) => {
      return {
        id: value.id,
        firstName: value.firstName,
        surname: value.surname,
      } as StaffReferenceDto;
    });
  };

  return (
    <>
      <Stack direction={layout === LayoutType.SMALL ? 'column' : 'row'} alignItems="start">
        <ValueAsyncSelectFormControl<SectionReferenceDto, DiscountWrapper>
          name={prefixWithPath('discount.section')}
          label={t('code:discount.section')}
          isRequired
          optionIdentifier={(section) => section.id}
          loadOptions={sectionLoadOptions}
          renderLabel={(option) => option.name}
          ref={initialFocusRef}
        />
        {enableInitiatorSelection && (
          <ValueAsyncSelectFormControl<StaffReferenceDto, DiscountWrapper>
            name={prefixWithPath('discount.initiator')}
            label={t('code:discount.initiator')}
            isRequired
            optionIdentifier={(staff) => staff.id!}
            loadOptions={staffLoadOptions}
            renderLabel={(staff) => `${staff.firstName} ${staff.surname}`}
          />
        )}
        <InputFormControl<DiscountWrapper>
          label={t('code:discount.reason')}
          name={prefixWithPath('discount.reason')}
          isRequired
          helperPopover={<HelperPopover children={t('code:discount.reasonHelp')} />}
        />
        <ValueSelectFormControl<DiscountDtoDiscountLevelEnum, DiscountWrapper>
          name={prefixWithPath('discount.discountLevel')}
          label={t('code:discount.level')}
          isRequired
          options={levelOptions}
          renderLabel={(option) => t(`code:discount.levelOptions.${option}`)}
          onChange={(newValue) => {
            if (newValue === discountLevel) {
              return;
            }
            if (newValue !== 'CUSTOM') {
              setValue(prefixWithPath('discount.discountPercentage'), undefined);
              setValue(prefixWithPath('discount.discountAmount'), undefined);
              setValue(prefixWithPath('discount.discountType'), undefined);
            }
          }}
        />
      </Stack>
      <HStack alignItems="start">
        {discountLevel === 'CUSTOM' && (
          <ValueSelectFormControl<DiscountDtoDiscountTypeEnum, DiscountWrapper>
            name={prefixWithPath('discount.discountType')}
            label={t('code:discount.type')}
            isRequired
            options={typeOptions}
            renderLabel={(option) => t(`code:discount.typeOptions.${option}`)}
            onChange={(value) => {
              if (discountType === value) {
                return;
              }
              if (value === 'FIXED_AMOUNT') {
                setValue(prefixWithPath('discount.discountPercentage'), undefined);
              }

              if (value === 'PERCENTAGE') {
                setValue(prefixWithPath('discount.discountAmount'), undefined);
              }
            }}
          />
        )}
        {discountType === 'FIXED_AMOUNT' && (
          <CurrencyFormControl<DiscountWrapper>
            min={0.01}
            max={maxDiscountAmount ?? 99999}
            label={t('code:discount.amount')}
            name={prefixWithPath('discount.discountAmount')}
            isRequired
          />
        )}
        {discountType === 'PERCENTAGE' && (
          <PercentageFormControl<DiscountWrapper>
            label={t('code:discount.percentage')}
            name={prefixWithPath('discount.discountPercentage')}
            isRequired
            min={0.0001}
            max={1}
          />
        )}
      </HStack>
    </>
  );
}

export const displayDiscount = (t: TFunction, discount: DiscountDto): string => {
  if (discount.discountLevel === DiscountDtoDiscountLevelEnum.PAYMENT_EXEMPTION) {
    return t('pricing.free');
  } else if (discount.discountType === DiscountDtoDiscountTypeEnum.FIXED_AMOUNT) {
    return t('format.currency', { value: discount.discountAmount! });
  } else {
    return t('format.percent', { value: discount.discountPercentage! });
  }
};
