import invariant from 'tiny-invariant';
import { productBundleIcon } from '../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../ui/reference/reference';

interface ProductBundleReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  productBundleReference: { id: string; title: string; ownerSectionIds?: string[] };
}

export default function ProductBundleReference({ productBundleReference, ...props }: ProductBundleReferenceProps) {
  const ownerSectionIds = productBundleReference.ownerSectionIds;
  invariant(ownerSectionIds != null, 'ownerSectionIds must not be null');

  return (
    <Reference
      icon={productBundleIcon}
      to={'/product-bundles/' + productBundleReference.id}
      necessaryPermission={({ hasPermission, hasPermissionFromSection }) => {
        return (
          hasPermission('PRODUCT_BUNDLE:CAN_SEE_DETAILS') ||
          ownerSectionIds.some((sectionId) => hasPermissionFromSection(sectionId, 'PRODUCT_BUNDLE:CAN_SEE_DETAILS_OWN'))
        );
      }}
      {...props}
    >
      {productBundleReference.title}
    </Reference>
  );
}
