/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventEvaluationPermissionDto
 */
export interface EventEvaluationPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof EventEvaluationPermissionDto
     */
    name: EventEvaluationPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof EventEvaluationPermissionDto
     */
    action: EventEvaluationPermissionDtoActionEnum;
}


/**
 * @export
 */
export const EventEvaluationPermissionDtoNameEnum = {
    EVENT_EVALUATION: 'EVENT_EVALUATION'
} as const;
export type EventEvaluationPermissionDtoNameEnum = typeof EventEvaluationPermissionDtoNameEnum[keyof typeof EventEvaluationPermissionDtoNameEnum];

/**
 * @export
 */
export const EventEvaluationPermissionDtoActionEnum = {
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    SUBMIT: 'CAN_SUBMIT',
    EDIT: 'CAN_EDIT',
    EDIT_OWN: 'CAN_EDIT_OWN',
    REVIEW: 'CAN_REVIEW',
    REVIEW_OWN: 'CAN_REVIEW_OWN'
} as const;
export type EventEvaluationPermissionDtoActionEnum = typeof EventEvaluationPermissionDtoActionEnum[keyof typeof EventEvaluationPermissionDtoActionEnum];


/**
 * Check if a given object implements the EventEvaluationPermissionDto interface.
 */
export function instanceOfEventEvaluationPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function EventEvaluationPermissionDtoFromJSON(json: any): EventEvaluationPermissionDto {
    return EventEvaluationPermissionDtoFromJSONTyped(json, false);
}

export function EventEvaluationPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventEvaluationPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function EventEvaluationPermissionDtoToJSON(value?: EventEvaluationPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

