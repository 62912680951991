import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { teamIcon } from '../../ui/icons/business-objects';
import renderPhoneNumber from '../../ui/phone-number/render-phone-number';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function TeamSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'TEAM', `${result.resultType} is no instance of TeamSearchResult`);

  const team = result.result;
  const { t } = useTranslation(['team', 'common']);

  return (
    <SearchResult
      icon={teamIcon}
      title={team.name}
      link={`/teams/${team.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="TEAM:CAN_SEE_DETAILS"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('team:status'),
            element: t(`team:statusOptions.${team.status}`),
          },
          {
            label: t('team:nameEnglish'),
            element: team.englishName,
          },
          {
            label: t('team:email'),
            element: team.emailAddress,
          },
          {
            label: t('common:phoneNumber.header'),
            element: team.phoneNumbers.map(renderPhoneNumber).join(', '),
          },
        ]}
      />
    </SearchResult>
  );
}
