/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportPermissionDto
 */
export interface ExportPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof ExportPermissionDto
     */
    name: ExportPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ExportPermissionDto
     */
    action: ExportPermissionDtoActionEnum;
}


/**
 * @export
 */
export const ExportPermissionDtoNameEnum = {
    EXPORT: 'EXPORT'
} as const;
export type ExportPermissionDtoNameEnum = typeof ExportPermissionDtoNameEnum[keyof typeof ExportPermissionDtoNameEnum];

/**
 * @export
 */
export const ExportPermissionDtoActionEnum = {
    CAN_SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW'
} as const;
export type ExportPermissionDtoActionEnum = typeof ExportPermissionDtoActionEnum[keyof typeof ExportPermissionDtoActionEnum];


/**
 * Check if a given object implements the ExportPermissionDto interface.
 */
export function instanceOfExportPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function ExportPermissionDtoFromJSON(json: any): ExportPermissionDto {
    return ExportPermissionDtoFromJSONTyped(json, false);
}

export function ExportPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function ExportPermissionDtoToJSON(value?: ExportPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

