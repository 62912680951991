import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingPageTextModuleContentDto } from '../../../api';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import { richTextAsMarkup } from '../../../util/rich-text/rich-text';
import LandingPageModuleConfig, { LANDING_PAGE_MODULE_HOOK } from '../landing-page-module-config';
import TextModuleContentControl from './text-module-content-control';

export default function LandingPageModuleProvider(registry: PluginRegistry) {
  registry.register(LANDING_PAGE_MODULE_HOOK, useLandingPageTextModule);
}

function useLandingPageTextModule(): LandingPageModuleConfig<LandingPageTextModuleContentDto> {
  const { t } = useTranslation(['landing_page_template', 'landing_page']);

  return {
    type: 'text',
    label: t('landing_page_template:moduleSettings.text'),
    renderControl: ({ name }) => <TextModuleContentControl name={name} />,
    historyDisplaySettings: {
      attributeLabels: {
        content: t('landing_page:textModule.label'),
        contentEnglish: t('landing_page:textModule.labelEnglish'),
      },
      valueFormatter: {
        content: (value) => value && richTextAsMarkup(value),
        contentEnglish: (value) => value && richTextAsMarkup(value),
      },
      preformatted: {
        content: true,
        contentEnglish: true,
      },
    },
  };
}
