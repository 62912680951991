/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OfferStatusDto = {
    NOT_SEND: 'NOT_SEND',
    SEND: 'SEND',
    BEING_EDITED: 'BEING_EDITED',
    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED'
} as const;
export type OfferStatusDto = typeof OfferStatusDto[keyof typeof OfferStatusDto];


export function OfferStatusDtoFromJSON(json: any): OfferStatusDto {
    return OfferStatusDtoFromJSONTyped(json, false);
}

export function OfferStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferStatusDto {
    return json as OfferStatusDto;
}

export function OfferStatusDtoToJSON(value?: OfferStatusDto | null): any {
    return value as any;
}

