import { Flex, Stack, Text, useFormControlContext } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { FileMetadataDto } from '../../../api';
import { LayoutType } from '../../../feature/common/layout-type';
import { DataTableColumn } from '../../data-table';
import DataTable from '../../data-table/data-table';
import DeleteElementButton from '../../form/element-control/delete-element-button';
import ElementContext from '../../form/element-control/element-context';
import { ElementTableDispatchContext } from '../../form/element-control/element-table-control';

interface UploadTableProps extends React.ComponentPropsWithoutRef<'div'> {
  fileData: FileMetadataDto[] | undefined;
  layout?: LayoutType;
  onDelete: (index: number) => void;
  columns: DataTableColumn<FileMetadataDto>[];
  uploadButton?: React.ReactElement;
  maxElements?: number;
  helperText?: string;
}

export default function UploadTable({
  fileData,
  layout,
  onDelete,
  columns,
  uploadButton,
  maxElements,
  helperText,
  ...props
}: UploadTableProps) {
  const { t } = useTranslation(['attachment', 'common']);
  const columnDef = useMemo(
    () => [
      ...columns,
      {
        key: 'delete',
        renderCell: (data, index) => (
          <ElementTableDispatchContext.Provider
            value={(item) => {
              invariant(item.type === 'DELETE_ELEMENT', 'Item is not of type DELETE_ELEMENT');
              onDelete(item.payload);
            }}
          >
            <ElementContext.Provider value={{ element: data, index }}>
              <DeleteElementButton
                label={t('attachment:upload_table.delete')}
                renderDeleteMessage={() => (
                  <Trans
                    t={t}
                    i18nKey="attachment:upload_table.delete_message"
                    values={{ photo_name: data.originalFileName }}
                  />
                )}
              />
            </ElementContext.Provider>
          </ElementTableDispatchContext.Provider>
        ),
      },
    ],
    [columns, onDelete, t],
  );
  const page = useMemo(() => ({ content: fileData != null ? fileData : [] }), [fileData]);
  const rowKey = useCallback((element: FileMetadataDto, index: number) => index, []);
  const empty = useMemo(
    () => (
      <Flex p={3} color="text.muted" fontSize="sm" justify="center">
        {t('common:data_table.empty')}
      </Flex>
    ),
    [t],
  );
  const footer = useMemo(
    () =>
      uploadButton != null &&
      (maxElements == null || page.content.length < maxElements) && (
        <Flex sx={{ borderTop: '1px solid', borderColor: 'border.01' }}>{React.cloneElement(uploadButton)}</Flex>
      ),
    [maxElements, page.content.length, uploadButton],
  );
  const field = useFormControlContext();

  return (
    <Stack>
      <DataTable<FileMetadataDto>
        size="sm"
        page={page}
        columns={columnDef}
        rowKey={rowKey}
        empty={empty}
        layout={layout}
        footer={footer}
        aria-labelledby={field?.labelId}
        aria-describedby={`${field?.feedbackId} ${field?.helpTextId}`}
        aria-invalid={field?.isInvalid}
        data-invalid={field?.isInvalid ? '' : undefined}
        {...props}
      />
      {helperText && <Text sx={{ fontSize: 'sm', color: 'text.muted' }}>{helperText}</Text>}
    </Stack>
  );
}
