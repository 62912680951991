/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RelationTypeDto } from './RelationTypeDto';
import {
    RelationTypeDtoFromJSON,
    RelationTypeDtoFromJSONTyped,
    RelationTypeDtoToJSON,
} from './RelationTypeDto';

/**
 * 
 * @export
 * @interface AcceptAddPersonToGroupApplicationDto
 */
export interface AcceptAddPersonToGroupApplicationDto {
    /**
     * 
     * @type {RelationTypeDto}
     * @memberof AcceptAddPersonToGroupApplicationDto
     */
    type: RelationTypeDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AcceptAddPersonToGroupApplicationDto
     */
    personId?: string;
}

/**
 * Check if a given object implements the AcceptAddPersonToGroupApplicationDto interface.
 */
export function instanceOfAcceptAddPersonToGroupApplicationDto(value: object): boolean {
    if (!('type' in value)) return false;
    return true;
}

export function AcceptAddPersonToGroupApplicationDtoFromJSON(json: any): AcceptAddPersonToGroupApplicationDto {
    return AcceptAddPersonToGroupApplicationDtoFromJSONTyped(json, false);
}

export function AcceptAddPersonToGroupApplicationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptAddPersonToGroupApplicationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': RelationTypeDtoFromJSON(json['type']),
        'personId': json['personId'] == null ? undefined : json['personId'],
    };
}

export function AcceptAddPersonToGroupApplicationDtoToJSON(value?: AcceptAddPersonToGroupApplicationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': RelationTypeDtoToJSON(value['type']),
        'personId': value['personId'],
    };
}

