/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyEmailAddressDto } from './CompanyEmailAddressDto';
import {
    CompanyEmailAddressDtoFromJSON,
    CompanyEmailAddressDtoFromJSONTyped,
    CompanyEmailAddressDtoToJSON,
} from './CompanyEmailAddressDto';
import type { CompanyPhoneNumberDto } from './CompanyPhoneNumberDto';
import {
    CompanyPhoneNumberDtoFromJSON,
    CompanyPhoneNumberDtoFromJSONTyped,
    CompanyPhoneNumberDtoToJSON,
} from './CompanyPhoneNumberDto';
import type { CompanyPostalAddressDto } from './CompanyPostalAddressDto';
import {
    CompanyPostalAddressDtoFromJSON,
    CompanyPostalAddressDtoFromJSONTyped,
    CompanyPostalAddressDtoToJSON,
} from './CompanyPostalAddressDto';
import type { CompanyPublicationNameDto } from './CompanyPublicationNameDto';
import {
    CompanyPublicationNameDtoFromJSON,
    CompanyPublicationNameDtoFromJSONTyped,
    CompanyPublicationNameDtoToJSON,
} from './CompanyPublicationNameDto';
import type { IndustryDto } from './IndustryDto';
import {
    IndustryDtoFromJSON,
    IndustryDtoFromJSONTyped,
    IndustryDtoToJSON,
} from './IndustryDto';

/**
 * 
 * @export
 * @interface CompanyUpdateDataDto
 */
export interface CompanyUpdateDataDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CompanyUpdateDataDto
     */
    id: string;
    /**
     * Name of a company
     * @type {string}
     * @memberof CompanyUpdateDataDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdateDataDto
     */
    shortDescription?: string;
    /**
     * The publication names of the company.
     * @type {Array<CompanyPublicationNameDto>}
     * @memberof CompanyUpdateDataDto
     */
    namesForPublication: Array<CompanyPublicationNameDto>;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdateDataDto
     */
    homepageUrl?: string;
    /**
     * List of email addresses
     * @type {Array<CompanyEmailAddressDto>}
     * @memberof CompanyUpdateDataDto
     */
    emailAddresses: Array<CompanyEmailAddressDto>;
    /**
     * List of phone numbers
     * @type {Array<CompanyPhoneNumberDto>}
     * @memberof CompanyUpdateDataDto
     */
    phoneNumbers: Array<CompanyPhoneNumberDto>;
    /**
     * List of postal addresses
     * @type {Array<CompanyPostalAddressDto>}
     * @memberof CompanyUpdateDataDto
     */
    postalAddresses: Array<CompanyPostalAddressDto>;
    /**
     * List of industries, within the company operates.
     * @type {Array<IndustryDto>}
     * @memberof CompanyUpdateDataDto
     */
    industries: Array<IndustryDto>;
}

/**
 * Check if a given object implements the CompanyUpdateDataDto interface.
 */
export function instanceOfCompanyUpdateDataDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('namesForPublication' in value)) return false;
    if (!('emailAddresses' in value)) return false;
    if (!('phoneNumbers' in value)) return false;
    if (!('postalAddresses' in value)) return false;
    if (!('industries' in value)) return false;
    return true;
}

export function CompanyUpdateDataDtoFromJSON(json: any): CompanyUpdateDataDto {
    return CompanyUpdateDataDtoFromJSONTyped(json, false);
}

export function CompanyUpdateDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUpdateDataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'shortDescription': json['shortDescription'] == null ? undefined : json['shortDescription'],
        'namesForPublication': ((json['namesForPublication'] as Array<any>).map(CompanyPublicationNameDtoFromJSON)),
        'homepageUrl': json['homepageUrl'] == null ? undefined : json['homepageUrl'],
        'emailAddresses': ((json['emailAddresses'] as Array<any>).map(CompanyEmailAddressDtoFromJSON)),
        'phoneNumbers': ((json['phoneNumbers'] as Array<any>).map(CompanyPhoneNumberDtoFromJSON)),
        'postalAddresses': ((json['postalAddresses'] as Array<any>).map(CompanyPostalAddressDtoFromJSON)),
        'industries': ((json['industries'] as Array<any>).map(IndustryDtoFromJSON)),
    };
}

export function CompanyUpdateDataDtoToJSON(value?: CompanyUpdateDataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'shortDescription': value['shortDescription'],
        'namesForPublication': ((value['namesForPublication'] as Array<any>).map(CompanyPublicationNameDtoToJSON)),
        'homepageUrl': value['homepageUrl'],
        'emailAddresses': ((value['emailAddresses'] as Array<any>).map(CompanyEmailAddressDtoToJSON)),
        'phoneNumbers': ((value['phoneNumbers'] as Array<any>).map(CompanyPhoneNumberDtoToJSON)),
        'postalAddresses': ((value['postalAddresses'] as Array<any>).map(CompanyPostalAddressDtoToJSON)),
        'industries': ((value['industries'] as Array<any>).map(IndustryDtoToJSON)),
    };
}

