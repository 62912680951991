import React from 'react';
import { productBundleIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useProductBundlePermissionClassGroupExtension } from './product-bundle-permissions';
import ProductBundleSearchResult from './product-bundle-search-result';

export default function ProductBundlePluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'PRODUCT_BUNDLE',
    Component: ProductBundleSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'PRODUCT_BUNDLE',
    icon: productBundleIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.PRODUCT_BUNDLE" />,
    necessaryPermission: 'PRODUCT_BUNDLE:CAN_SEE_DETAILS_OWN',
  });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useProductBundlePermissionClassGroupExtension);
}
