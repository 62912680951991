/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryPhonePrefixDto } from './CountryPhonePrefixDto';
import {
    CountryPhonePrefixDtoFromJSON,
    CountryPhonePrefixDtoFromJSONTyped,
    CountryPhonePrefixDtoToJSON,
} from './CountryPhonePrefixDto';

/**
 * A phone number of a person
 * @export
 * @interface DefaultPhoneNumberDto
 */
export interface DefaultPhoneNumberDto {
    /**
     * 
     * @type {CountryPhonePrefixDto}
     * @memberof DefaultPhoneNumberDto
     */
    countryCode: CountryPhonePrefixDto;
    /**
     * The dialing code of the phone number
     * @type {string}
     * @memberof DefaultPhoneNumberDto
     */
    dialingCode?: string;
    /**
     * The number of the phone number
     * @type {string}
     * @memberof DefaultPhoneNumberDto
     */
    number: string;
    /**
     * The extension of the phone number
     * @type {string}
     * @memberof DefaultPhoneNumberDto
     */
    extension?: string;
    /**
     * Note for the phone number
     * @type {string}
     * @memberof DefaultPhoneNumberDto
     */
    note?: string;
    /**
     * 
     * @type {Date}
     * @memberof DefaultPhoneNumberDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the DefaultPhoneNumberDto interface.
 */
export function instanceOfDefaultPhoneNumberDto(value: object): boolean {
    if (!('countryCode' in value)) return false;
    if (!('number' in value)) return false;
    return true;
}

export function DefaultPhoneNumberDtoFromJSON(json: any): DefaultPhoneNumberDto {
    return DefaultPhoneNumberDtoFromJSONTyped(json, false);
}

export function DefaultPhoneNumberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultPhoneNumberDto {
    if (json == null) {
        return json;
    }
    return {
        
        'countryCode': CountryPhonePrefixDtoFromJSON(json['countryCode']),
        'dialingCode': json['dialingCode'] == null ? undefined : json['dialingCode'],
        'number': json['number'],
        'extension': json['extension'] == null ? undefined : json['extension'],
        'note': json['note'] == null ? undefined : json['note'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function DefaultPhoneNumberDtoToJSON(value?: DefaultPhoneNumberDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'countryCode': CountryPhonePrefixDtoToJSON(value['countryCode']),
        'dialingCode': value['dialingCode'],
        'number': value['number'],
        'extension': value['extension'],
        'note': value['note'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

