/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductBundlePermissionDto
 */
export interface ProductBundlePermissionDto {
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePermissionDto
     */
    name: ProductBundlePermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePermissionDto
     */
    action: ProductBundlePermissionDtoActionEnum;
}


/**
 * @export
 */
export const ProductBundlePermissionDtoNameEnum = {
    PRODUCT_BUNDLE: 'PRODUCT_BUNDLE'
} as const;
export type ProductBundlePermissionDtoNameEnum = typeof ProductBundlePermissionDtoNameEnum[keyof typeof ProductBundlePermissionDtoNameEnum];

/**
 * @export
 */
export const ProductBundlePermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT_OWN: 'CAN_EDIT_OWN',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    ARCHIVE_OWN: 'CAN_ARCHIVE_OWN',
    ARCHIVE: 'CAN_ARCHIVE',
    ACTIVATE_OWN: 'CAN_ACTIVATE_OWN',
    ACTIVATE: 'CAN_ACTIVATE'
} as const;
export type ProductBundlePermissionDtoActionEnum = typeof ProductBundlePermissionDtoActionEnum[keyof typeof ProductBundlePermissionDtoActionEnum];


/**
 * Check if a given object implements the ProductBundlePermissionDto interface.
 */
export function instanceOfProductBundlePermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function ProductBundlePermissionDtoFromJSON(json: any): ProductBundlePermissionDto {
    return ProductBundlePermissionDtoFromJSONTyped(json, false);
}

export function ProductBundlePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBundlePermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function ProductBundlePermissionDtoToJSON(value?: ProductBundlePermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

