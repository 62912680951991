import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { GroupCompanyRelationListItemDto } from '../../../../../api';
import groupCompanyApi from '../../../../../data-access/group-company-api';
import { DisableableButton } from '../../../../../ui/disableable-button/disableable-button';
import useToast from '../../../../../ui/use-toast/use-toast';
import useDialog from '../../../../../util/use-dialog/use-dialog';
import GroupCompanyDeleteDialog from './group-company-delete-dialog';

export interface GroupCompanyDeleteButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  onSuccess?(): void;
  deletable: boolean;
  relation: GroupCompanyRelationListItemDto;
}

const GroupCompanyDeleteButton = React.forwardRef<HTMLButtonElement, GroupCompanyDeleteButtonProps>(
  ({ children, relation, onSuccess, onClick, ...props }, ref) => {
    const { t } = useTranslation('group');
    const [deleteDialogIsOpen, onDeleteDialogClose, openDeleteDialog] = useDialog<false>();
    const showDeleteSuccessToast = useToast({
      id: 'group-company-deletion-success-toast',
      status: 'success',
    });

    const deleteRelation = async (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);
      if (event.defaultPrevented) {
        return;
      }
      invariant(relation.id != null, 'Missing relation id');

      const checked = await openDeleteDialog();
      if (checked) {
        await groupCompanyApi.deleteRelationById({ id: relation.id }, {
          allowedErrorCodes: [409],
        } as RequestInit);

        showDeleteSuccessToast({
          title: t('connections.toast_delete'),
          description: t('connections.toast_delete_message', {
            name: relation.company.name,
          }),
        });
        onSuccess?.();
      }
    };

    return (
      <>
        <DisableableButton {...props} ref={ref} onClick={deleteRelation} disableReason="">
          {children}
        </DisableableButton>

        <GroupCompanyDeleteDialog isOpen={deleteDialogIsOpen} relation={relation} onClose={onDeleteDialogClose} />
      </>
    );
  },
);

export default GroupCompanyDeleteButton;
