import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useKeyPlayerPermissionClassGroupExtension(): PermissionClassGroupExtension<'KEYPLAYER_LIST'> {
  const { t } = useTranslation(['key_player_list']);

  return {
    name: 'KEYPLAYER_LIST',
    label: t('key_player_list:permissionLabel'),
    getActionLabel: (action) => t(`key_player_list:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`key_player_list:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`key_player_list:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
