import { ParseKeys } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import { LayoutType } from '../common/layout-type';
import placementTemplateRoutes, {
  placementTemplateEditorRoutes,
} from '../placement/placement-template/placement-template-routes';
import LOCATION_CHILD_ROUTES from './location-child-routes';
import useLocation from './use-location/use-location';

const LocationEditor = React.lazy(() => import('./location-editor/location-editor'));
const LocationLister = React.lazy(() => import('./location-lister/location-lister'));
const LocationPage = React.lazy(() => import('./location-page/location-page'));
const LocationViewer = React.lazy(() => import('./location-viewer/location-viewer'));
const LocationHistory = React.lazy(() => import('./location-history/location-history'));
const LocationAttachmentPageContent = React.lazy(
  () => import('./location-attachments/location-attachments-page-content'),
);

const locationRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'locations',
    handle: {
      breadcrumb: <Translate ns="location" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        element: <LocationLister />,
        handle: {
          helmet: <Translate ns="location">{(t) => <Helmet title={t('lister.title')} />}</Translate>,
        },
      },
      {
        path: 'new',
        handle: {
          breadcrumb: <Translate ns="location" i18nKey="action.new" />,
          helmet: <Translate ns="location">{(t) => <Helmet title={t('editor.helmetNew')} />}</Translate>,
        },
        element: <LocationEditor layout={LayoutType.NORMAL} />,
      },
      {
        path: ':locationId',
        handle: {
          breadcrumb: <LocationBreadcrumb />,
        },
        children: [
          {
            path: '',
            element: <LocationPage />,
            children: [
              {
                path: '',
                handle: {
                  helmet: <LocationHelmet i18nKey="viewer.helmet" />,
                },
                element: <LocationViewer />,
              },
              {
                path: 'history',
                handle: {
                  helmet: <LocationHelmet i18nKey="history.helmet" />,
                },
                element: <LocationHistory />,
              },
              {
                path: 'attachments',
                handle: {
                  helmet: <LocationHelmet i18nKey="attachments.helmet" />,
                },
                element: <LocationAttachmentPageContent />,
              },
              ...placementTemplateRoutes,
            ],
          },
          {
            path: 'edit',
            handle: {
              breadcrumb: <Translate ns="location" i18nKey="action.edit" />,
              helmet: <LocationHelmet i18nKey="editor.helmetEdit" />,
            },
            element: <LocationEditor layout={LayoutType.NORMAL} />,
          },
          ...placementTemplateEditorRoutes,
          ...registry.getAll(LOCATION_CHILD_ROUTES).flat(),
        ],
      },
    ],
  },
];

interface LocationHelmetProps {
  i18nKey: ParseKeys<'location'>;
}

function LocationHelmet({ i18nKey }: LocationHelmetProps) {
  const { t } = useTranslation('location');
  const params = useParams<{ locationId: string }>();
  invariant(params.locationId, 'Empty locationId');
  const { name, abbreviation } = useLocation(params.locationId);

  return <Helmet title={t(i18nKey, { location: name, abbreviation: abbreviation })} />;
}

function LocationBreadcrumb() {
  const { locationId } = useParams<{ locationId: string }>();
  invariant(locationId, 'Empty locationId');
  const location = useLocation(locationId);

  return <>{`${location.name} (${location.abbreviation})`}</>;
}

export default locationRoutes;
