import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useEventEvaluationPermissionClassGroupExtension(): PermissionClassGroupExtension<'EVENT_EVALUATION'> {
  const { t } = useTranslation(['event_evaluation']);

  return {
    name: 'EVENT_EVALUATION',
    label: t('event_evaluation:permissionLabel'),
    getActionLabel: (action) => t(`event_evaluation:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`event_evaluation:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`event_evaluation:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
