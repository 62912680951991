import { chakra } from '@chakra-ui/react';
import { faH1 } from '@fortawesome/pro-regular-svg-icons';
import React, { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react';
import { Editor } from 'slate';
import { Render } from '../render';
import { BlockFormatOption, WithFunction } from '../slate-types';
import { useRichTextStyles } from '../styles-context';
import { BlockToolbarButton } from '../toolbar/toolbar-button';
import { applyAlignment } from './with-alignment';

const HEADING_1: BlockFormatOption = 'heading1';

const withHeading1: WithFunction = (editor: Editor) => {
  const { markOrInlineBlockAllowed } = editor;

  const heading1: Render<'block'> = {
    type: HEADING_1,
    render: ({ children, attributes, element }) => (
      <Heading1 {...attributes} {...applyAlignment(element)}>
        {children}
      </Heading1>
    ),
  };

  editor.renderers = [...editor.renderers, heading1];

  editor.toolbarButtons.block = [...editor.toolbarButtons.block, <BlockToolbarButton format={HEADING_1} icon={faH1} />];

  editor.markOrInlineBlockAllowed = (element) => element.type !== HEADING_1 && markOrInlineBlockAllowed(element);

  return editor;
};

export default withHeading1;

const Heading1 = forwardRef(
  ({ children, ...props }: ComponentPropsWithoutRef<typeof chakra.h1>, ref: ForwardedRef<HTMLHeadingElement>) => {
    const styles = useRichTextStyles();

    return (
      <chakra.h1 __css={styles.heading1} {...props} ref={ref}>
        {children}
      </chakra.h1>
    );
  },
);
