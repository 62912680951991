import React from 'react';
import { evaluationIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import EVENT_CHILD_ROUTES from '../event/event-child-routes';
import { EVENT_MENU_ITEM, EventMenuItem } from '../event/event-page/event-page-menu';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useEventEvaluationPermissionClassGroupExtension } from './event-evaluation-permissions';
import eventEvaluationRoutes from './event-evaluation-routes';
import EventEvaluationSearchResult from './event-evaluation-search-result';

export const eventEvaluationMenuItem: EventMenuItem = {
  order: 8,
  label: <Translate ns="event_evaluation" i18nKey="title" />,
  icon: evaluationIcon,
  path: (eventId) => `/events/${eventId}/evaluation`,
  isVisible: (ownerSectionIds, permissions) => permissions.hasPermission('EVENT_EVALUATION:CAN_SEE_DETAILS'),
};

export default function EventEvaluationProvider(registry: PluginRegistry) {
  registry.register(EVENT_MENU_ITEM, eventEvaluationMenuItem);
  registry.register(EVENT_CHILD_ROUTES, eventEvaluationRoutes);
  registry.register(SEARCH_RESULT_CONTENT, { type: 'EVENT_EVALUATION', Component: EventEvaluationSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'EVENT_EVALUATION',
    icon: evaluationIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.EVENT_EVALUATION" />,
    necessaryPermission: 'EVENT_EVALUATION:CAN_SEE_DETAILS',
  });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useEventEvaluationPermissionClassGroupExtension);
}
