import { identity } from 'lodash-es';
import { FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import usePrompt from '../../util/use-prompt/use-prompt';

export default function useUnsavedChangesPrompt({
  formState: { dirtyFields, isSubmitting },
  promptExemptions,
  when = identity,
}: {
  formState: FormState<any>;
  promptExemptions?: RegExp;
  when?: (isDirty: boolean) => boolean;
}) {
  const { t } = useTranslation('common');
  const isDirty = Object.keys(dirtyFields).length > 0;

  usePrompt({
    message: t('misc.unsaved_changes'),
    when: isSubmitting ? false : when(isDirty),
    exemption: promptExemptions,
  });
}
