import { ParseKeys } from 'i18next/typescript/t';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import { LayoutType } from '../common/layout-type';
import useEvent from '../event/use-event/use-event';

const GuestListEditor = React.lazy(() => import('./guest-list-editor/guest-list-editor'));
const GuestListPage = React.lazy(() => import('./guest-list-page/guest-list-page'));
const GuestListSettingsViewer = React.lazy(() => import('./guest-list-settings-viewer/guest-list-settings-viewer'));
const GuestListViewer = React.lazy(() => import('./guest-list-viewer/guest-list-viewer'));
const GuestListHistory = React.lazy(() => import('./guest-list-history/guest-list-history'));
const GuestListCapacityViewer = React.lazy(() => import('./guest-list-capacity-viewer/guest-list-capacity-viewer'));

const guestListRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'guest-list',
    handle: {
      breadcrumb: <Translate ns="guest_list" i18nKey="guestList" />,
    },
    children: [
      {
        path: '',
        element: <GuestListPage useFullWidth fixedHeight />,
        children: [
          {
            path: '',
            element: <GuestListViewer />,
            handle: {
              helmet: <GuestListHelmet i18nKey="viewer.helmet" />,
            },
          },
        ],
      },
      {
        path: '',
        element: <GuestListPage />,
        children: [
          {
            path: 'settings',
            element: <GuestListSettingsViewer />,
            handle: {
              helmet: <GuestListHelmet i18nKey="settings.helmet" />,
            },
          },
          {
            path: 'capacity',
            element: <GuestListCapacityViewer />,
            handle: {
              helmet: <GuestListHelmet i18nKey="capacity.helmet" />,
            },
          },
          {
            path: 'history',
            element: <GuestListHistory />,
            handle: {
              helmet: <GuestListHelmet i18nKey="history.helmet" />,
            },
          },
        ],
      },
      {
        path: 'new',
        element: <GuestListEditor layout={LayoutType.NORMAL} />,
        handle: {
          breadcrumb: <Translate ns="guest_list" i18nKey="action.new" />,
          helmet: <GuestListHelmet i18nKey="editor.helmetNew" />,
        },
      },
      {
        path: 'edit',
        element: <GuestListEditor layout={LayoutType.NORMAL} />,
        handle: {
          breadcrumb: <Translate ns="guest_list" i18nKey="action.edit" />,
          helmet: <GuestListHelmet i18nKey="editor.helmetEdit" />,
        },
      },
    ],
  },
];

export default guestListRoutes;

interface GuestListHelmetProps {
  i18nKey: ParseKeys<'guest_list'>;
}

function GuestListHelmet({ i18nKey }: GuestListHelmetProps) {
  const { t } = useTranslation('guest_list');
  const params = useParams<{ eventId: string }>();
  invariant(params.eventId, 'Empty eventId');
  const { title } = useEvent(params.eventId);

  return <Helmet title={t(i18nKey, { title })} />;
}
