import { Alert, AlertIcon, Box, Button, ChakraProps, HStack, Link, ListItem, UnorderedList } from '@chakra-ui/react';
import { faCodeMerge } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { SimplePersonDto } from '../../../api';
import { PageSpinner } from '../../../ui/page';

export interface DuplicateInfoBoxProps extends ChakraProps {
  mainPerson?: string;
  duplicates: SimplePersonDto[] | undefined;
  canMerge: boolean;
}

export default function DuplicateInfoBox({ mainPerson, duplicates, canMerge, ...props }: DuplicateInfoBoxProps) {
  const { t } = useTranslation('person');

  return duplicates != null ? (
    <Alert status="warning" {...props}>
      <HStack>
        <AlertIcon />
        <Box>
          <span>{t('foundDuplicate')}</span>
          <UnorderedList>
            {duplicates.map((duplicate) => (
              <ListItem key={duplicate.id}>
                <HStack>
                  <Link as={RouterLink} to={`/persons/${duplicate.id}`}>
                    {`${duplicate.firstName} ${duplicate.surname}`}
                  </Link>
                  {canMerge && mainPerson && (
                    <Button
                      leftIcon={<FontAwesomeIcon icon={faCodeMerge} />}
                      as={RouterLink}
                      to={`/persons/merge?p1=${mainPerson}&p2=${duplicate.id}`}
                      size="sm"
                      colorScheme="gray"
                      variant="ghost"
                    >
                      {t('action.start_merge')}
                    </Button>
                  )}
                </HStack>
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
      </HStack>
    </Alert>
  ) : (
    <PageSpinner minH={0} />
  );
}
