import { Stack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LandingPageTemplateDto } from '../../../api';
import CheckboxControl from '../../../ui/form/checkbox-control';
import InputFormControl from '../../../ui/form/input-form-control';

interface RsvpModuleSettingsControlProps {
  name: `moduleSettings.${number}`;
}

export default function RsvpModuleSettingsControl({ name }: RsvpModuleSettingsControlProps) {
  const form = useFormContext<LandingPageTemplateDto>();
  const commentsAllowed = useWatch<LandingPageTemplateDto, `${typeof name}.commentsAllowed`>({
    name: `${name}.commentsAllowed`,
  });
  const { t } = useTranslation('guest_list');

  return (
    <Stack spacing={4}>
      <CheckboxControl<LandingPageTemplateDto>
        name={`${name}.commentsAllowed`}
        label={t('landingPage.rsvpModule.commentsAllowed')}
        onChange={() => {
          form.setValue(`${name}.helperText`, '');
        }}
        helperText={t('landingPage.rsvpModule.commentHelperText')}
      />
      {commentsAllowed && (
        <InputFormControl<LandingPageTemplateDto>
          name={`${name}.helperText`}
          label={t('landingPage.rsvpModule.helperTextSettingsLabel')}
          maxLength={150}
        />
      )}
    </Stack>
  );
}
