/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LandingPageDesignSponsorLogoDto } from './LandingPageDesignSponsorLogoDto';
import {
    LandingPageDesignSponsorLogoDtoFromJSON,
    LandingPageDesignSponsorLogoDtoFromJSONTyped,
    LandingPageDesignSponsorLogoDtoToJSON,
} from './LandingPageDesignSponsorLogoDto';

/**
 * 
 * @export
 * @interface LandingPageDesignSponsorRowDto
 */
export interface LandingPageDesignSponsorRowDto {
    /**
     * 
     * @type {string}
     * @memberof LandingPageDesignSponsorRowDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageDesignSponsorRowDto
     */
    titleEnglish: string;
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignSponsorRowDto
     */
    backgroundColor: string;
    /**
     * 
     * @type {Array<LandingPageDesignSponsorLogoDto>}
     * @memberof LandingPageDesignSponsorRowDto
     */
    sponsors: Array<LandingPageDesignSponsorLogoDto>;
}

/**
 * Check if a given object implements the LandingPageDesignSponsorRowDto interface.
 */
export function instanceOfLandingPageDesignSponsorRowDto(value: object): boolean {
    if (!('title' in value)) return false;
    if (!('titleEnglish' in value)) return false;
    if (!('backgroundColor' in value)) return false;
    if (!('sponsors' in value)) return false;
    return true;
}

export function LandingPageDesignSponsorRowDtoFromJSON(json: any): LandingPageDesignSponsorRowDto {
    return LandingPageDesignSponsorRowDtoFromJSONTyped(json, false);
}

export function LandingPageDesignSponsorRowDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageDesignSponsorRowDto {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'titleEnglish': json['titleEnglish'],
        'backgroundColor': json['backgroundColor'],
        'sponsors': ((json['sponsors'] as Array<any>).map(LandingPageDesignSponsorLogoDtoFromJSON)),
    };
}

export function LandingPageDesignSponsorRowDtoToJSON(value?: LandingPageDesignSponsorRowDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'titleEnglish': value['titleEnglish'],
        'backgroundColor': value['backgroundColor'],
        'sponsors': ((value['sponsors'] as Array<any>).map(LandingPageDesignSponsorLogoDtoToJSON)),
    };
}

