/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BlockElementDto } from './BlockElementDto';
import {
    BlockElementDtoFromJSON,
    BlockElementDtoFromJSONTyped,
    BlockElementDtoToJSON,
} from './BlockElementDto';

/**
 * 
 * @export
 * @interface StandInvoiceDataDto
 */
export interface StandInvoiceDataDto {
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof StandInvoiceDataDto
     */
    comment?: Array<BlockElementDto>;
    /**
     * 
     * @type {number}
     * @memberof StandInvoiceDataDto
     */
    squareMeters?: number;
    /**
     * 
     * @type {number}
     * @memberof StandInvoiceDataDto
     */
    squareMeterPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof StandInvoiceDataDto
     */
    specialDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof StandInvoiceDataDto
     */
    earlyPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof StandInvoiceDataDto
     */
    includedBadges?: number;
    /**
     * 
     * @type {Date}
     * @memberof StandInvoiceDataDto
     */
    contractDate?: Date;
}

/**
 * Check if a given object implements the StandInvoiceDataDto interface.
 */
export function instanceOfStandInvoiceDataDto(value: object): boolean {
    return true;
}

export function StandInvoiceDataDtoFromJSON(json: any): StandInvoiceDataDto {
    return StandInvoiceDataDtoFromJSONTyped(json, false);
}

export function StandInvoiceDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandInvoiceDataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'comment': json['comment'] == null ? undefined : ((json['comment'] as Array<any>).map(BlockElementDtoFromJSON)),
        'squareMeters': json['squareMeters'] == null ? undefined : json['squareMeters'],
        'squareMeterPrice': json['squareMeterPrice'] == null ? undefined : json['squareMeterPrice'],
        'specialDiscount': json['specialDiscount'] == null ? undefined : json['specialDiscount'],
        'earlyPercentage': json['earlyPercentage'] == null ? undefined : json['earlyPercentage'],
        'includedBadges': json['includedBadges'] == null ? undefined : json['includedBadges'],
        'contractDate': json['contractDate'] == null ? undefined : (new Date(json['contractDate'])),
    };
}

export function StandInvoiceDataDtoToJSON(value?: StandInvoiceDataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'comment': value['comment'] == null ? undefined : ((value['comment'] as Array<any>).map(BlockElementDtoToJSON)),
        'squareMeters': value['squareMeters'],
        'squareMeterPrice': value['squareMeterPrice'],
        'specialDiscount': value['specialDiscount'],
        'earlyPercentage': value['earlyPercentage'],
        'includedBadges': value['includedBadges'],
        'contractDate': value['contractDate'] == null ? undefined : ((value['contractDate']).toISOString().substring(0,10)),
    };
}

