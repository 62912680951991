/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceUpdateResultDto
 */
export interface InvoiceUpdateResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceUpdateResultDto
     */
    paymentReturn: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateResultDto
     */
    amount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceUpdateResultDto
     */
    newInvoiceCreated: boolean;
}

/**
 * Check if a given object implements the InvoiceUpdateResultDto interface.
 */
export function instanceOfInvoiceUpdateResultDto(value: object): boolean {
    if (!('paymentReturn' in value)) return false;
    if (!('newInvoiceCreated' in value)) return false;
    return true;
}

export function InvoiceUpdateResultDtoFromJSON(json: any): InvoiceUpdateResultDto {
    return InvoiceUpdateResultDtoFromJSONTyped(json, false);
}

export function InvoiceUpdateResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceUpdateResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'paymentReturn': json['paymentReturn'],
        'amount': json['amount'] == null ? undefined : json['amount'],
        'newInvoiceCreated': json['newInvoiceCreated'],
    };
}

export function InvoiceUpdateResultDtoToJSON(value?: InvoiceUpdateResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'paymentReturn': value['paymentReturn'],
        'amount': value['amount'],
        'newInvoiceCreated': value['newInvoiceCreated'],
    };
}

