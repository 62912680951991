/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MarkElementDto } from './MarkElementDto';
import {
    MarkElementDtoFromJSON,
    MarkElementDtoFromJSONTyped,
    MarkElementDtoToJSON,
} from './MarkElementDto';

/**
 * 
 * @export
 * @interface InlineElementDto
 */
export interface InlineElementDto {
    /**
     * 
     * @type {string}
     * @memberof InlineElementDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof InlineElementDto
     */
    url?: string;
    /**
     * 
     * @type {Array<MarkElementDto>}
     * @memberof InlineElementDto
     */
    children?: Array<MarkElementDto>;
}

/**
 * Check if a given object implements the InlineElementDto interface.
 */
export function instanceOfInlineElementDto(value: object): boolean {
    if (!('type' in value)) return false;
    return true;
}

export function InlineElementDtoFromJSON(json: any): InlineElementDto {
    return InlineElementDtoFromJSONTyped(json, false);
}

export function InlineElementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineElementDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'url': json['url'] == null ? undefined : json['url'],
        'children': json['children'] == null ? undefined : ((json['children'] as Array<any>).map(MarkElementDtoFromJSON)),
    };
}

export function InlineElementDtoToJSON(value?: InlineElementDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'url': value['url'],
        'children': value['children'] == null ? undefined : ((value['children'] as Array<any>).map(MarkElementDtoToJSON)),
    };
}

