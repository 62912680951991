import { Alert, AlertDescription, AlertIcon, Button, Center, Stack, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { EventDto, EventStatusDto } from '../../../api';
import liveTicketingApi from '../../../data-access/live-ticketing-api';
import { PageContent } from '../../../ui/page';
import useToast from '../../../ui/use-toast/use-toast';
import useFetcher from '../../../util/swr/use-fetcher';
import Translate from '../../../util/translate/translate';
import useEvent from '../../event/use-event/use-event';
import usePermission from '../../permission/use-permission';
import { fetchLiveTicketingSettings } from '../live-ticketing-queries';
import { LiveTicketingActivationMode } from '../live-ticketing-settings-editor/live-ticketing-settings-form-type';
import AttendeesList from './attendees-list';

export default function LiveTicketingViewer() {
  const { eventId } = useParams<{ eventId: string }>();
  invariant(eventId != null);
  const event = useEvent(eventId);
  const liveTicketingSettings = useFetcher(fetchLiveTicketingSettings, { eventId });

  if (event.status !== EventStatusDto.SCHEDULED) {
    return <LiveTicketingEventNotScheduledWarning />;
  }

  if (!liveTicketingSettings) {
    return <NoLiveTicketingSettingsPresent event={event} />;
  }

  return <AttendeesList eventId={eventId} />;
}

function NoLiveTicketingSettingsPresent({ event }: { event: EventDto }) {
  const { t } = useTranslation('live_ticketing');
  const { hasPermission, hasPermissionFromSection } = usePermission();
  const navigate = useNavigate();

  const isOwnerForCreate =
    hasPermission('LIVE_TICKETING:CAN_EDIT') ||
    event.owners
      .map((owner) => owner.section.id)
      .some((sectionId) => hasPermissionFromSection(sectionId, 'LIVE_TICKETING:CAN_EDIT_OWN'));

  const showSuccessToast = useToast({
    id: 'live-ticketing-activated-toast',
    status: 'success',
  });
  const activateLiveTicketing = useCallback(
    async (eventIdentifier: string) => {
      await liveTicketingApi.createOrUpdateSettings({
        eventId: eventIdentifier,
        liveTicketingSettingsDto: {
          active: true,
          started: false,
          mode: LiveTicketingActivationMode.MANUAL,
        },
      });

      showSuccessToast({
        title: t('toast.success.activated'),
      });

      await fetchLiveTicketingSettings.mutate({ eventId: eventIdentifier });
      navigate('./settings');
    },
    [navigate, showSuccessToast, t],
  );

  return (
    <PageContent>
      <Center
        sx={{
          borderWidth: '1px',
          minHeight: 256,
          borderRadius: 'base',
          borderColor: 'border.01',
          backgroundColor: 'layer.01',
        }}
      >
        <Stack spacing={8} align="center">
          <Text>{t('live_ticketing_not_activated')}</Text>
          {isOwnerForCreate && <Button onClick={() => activateLiveTicketing(event.id!)}>{t('activate')}</Button>}
        </Stack>
      </Center>
    </PageContent>
  );
}

export function LiveTicketingEventNotScheduledWarning() {
  return (
    <Stack>
      <Alert status="warning">
        <AlertIcon />
        <AlertDescription>
          <Translate ns="live_ticketing" i18nKey="event_not_scheduled_warning" />
        </AlertDescription>
      </Alert>
    </Stack>
  );
}
