import { theme } from '@chakra-ui/react';

const foundation = {
  fonts: {
    body: 'IBM Plex Sans, sans-serif',
    heading: 'IBM Plex Sans, sans-serif',
    mono: 'IBM Plex Mono, monospace',
    landingPage: '"Ciutadella Web", sans-serif',
  },
  shadows: {
    outline: `0 0 0 3px color-mix(in srgb, var(--chakra-colors-text-interactive) 60%, transparent)`,
  },
  colors: {
    gray: {
      50: '#FAFAFA',
      100: '#EBEBEB',
      200: '#D1D1D1',
      300: '#B8B8B8',
      400: '#969696',
      500: '#737373',
      600: '#525252',
      700: '#404040',
      800: '#262626',
      900: '#171717',
      950: '#0D0D0D',
    },
    teal: {
      50: '#EEFBF9',
      100: '#CCF7F0',
      200: '#9EE8DE',
      300: '#66D0C6',
      400: '#47B8B2',
      950: '#12292B',
    },
    red: {
      950: '#26090A',
    },
    yellow: {
      950: '#261606',
    },
    green: {
      950: '#0C1F16',
    },
    blue: {
      950: '#121B27',
    },
  },
  zIndices: {
    stickyTableColumnHeader: 7,
    stickyTableColumn: 6,
    stickyTableHeader: 5,
    // We nest popovers and modals a lot ("this is fine").
    // To fix stacking issues, use same zIndex for both to define stacking by order of addition to
    // the DOM.
    modal: theme.zIndices.popover,
  },
  sizes: {
    18: '4.5rem',
  },
  fontSizes: {
    '2xs': '0.625rem',
  },
};

export default foundation;
