/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateRangeDto } from './ClosedDateRangeDto';
import {
    ClosedDateRangeDtoFromJSON,
    ClosedDateRangeDtoFromJSONTyped,
    ClosedDateRangeDtoToJSON,
} from './ClosedDateRangeDto';
import type { EditionTypeDto } from './EditionTypeDto';
import {
    EditionTypeDtoFromJSON,
    EditionTypeDtoFromJSONTyped,
    EditionTypeDtoToJSON,
} from './EditionTypeDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * List item for edition.
 * @export
 * @interface EditionListItemDto
 */
export interface EditionListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof EditionListItemDto
     */
    id: string;
    /**
     * The unique name. For regular ones following the pattern "number. Berlinale".
     * @type {string}
     * @memberof EditionListItemDto
     */
    name: string;
    /**
     * 
     * @type {ClosedDateRangeDto}
     * @memberof EditionListItemDto
     */
    dateRange: ClosedDateRangeDto;
    /**
     * 
     * @type {EditionTypeDto}
     * @memberof EditionListItemDto
     */
    editionType: EditionTypeDto;
    /**
     * Non visible editions are shown to administrators only.
     * @type {boolean}
     * @memberof EditionListItemDto
     */
    visible?: boolean;
    /**
     * There is only one active edition which is the default selection for users.
     * @type {boolean}
     * @memberof EditionListItemDto
     */
    readonly active?: boolean;
    /**
     * 
     * @type {VersionDto}
     * @memberof EditionListItemDto
     */
    version?: VersionDto;
}

/**
 * Check if a given object implements the EditionListItemDto interface.
 */
export function instanceOfEditionListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('dateRange' in value)) return false;
    if (!('editionType' in value)) return false;
    return true;
}

export function EditionListItemDtoFromJSON(json: any): EditionListItemDto {
    return EditionListItemDtoFromJSONTyped(json, false);
}

export function EditionListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditionListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'dateRange': ClosedDateRangeDtoFromJSON(json['dateRange']),
        'editionType': EditionTypeDtoFromJSON(json['editionType']),
        'visible': json['visible'] == null ? undefined : json['visible'],
        'active': json['active'] == null ? undefined : json['active'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
    };
}

export function EditionListItemDtoToJSON(value?: EditionListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'dateRange': ClosedDateRangeDtoToJSON(value['dateRange']),
        'editionType': EditionTypeDtoToJSON(value['editionType']),
        'visible': value['visible'],
        'version': VersionDtoToJSON(value['version']),
    };
}

