/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LandingPageTemplateStatusDto } from './LandingPageTemplateStatusDto';
import {
    LandingPageTemplateStatusDtoFromJSON,
    LandingPageTemplateStatusDtoFromJSONTyped,
    LandingPageTemplateStatusDtoToJSON,
} from './LandingPageTemplateStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface LandingPageTemplateListItemDto
 */
export interface LandingPageTemplateListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LandingPageTemplateListItemDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof LandingPageTemplateListItemDto
     */
    version: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof LandingPageTemplateListItemDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageTemplateListItemDto
     */
    type: string;
    /**
     * 
     * @type {LandingPageTemplateStatusDto}
     * @memberof LandingPageTemplateListItemDto
     */
    status: LandingPageTemplateStatusDto;
    /**
     * 
     * @type {number}
     * @memberof LandingPageTemplateListItemDto
     */
    numberOfLandingPages: number;
}

/**
 * Check if a given object implements the LandingPageTemplateListItemDto interface.
 */
export function instanceOfLandingPageTemplateListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('version' in value)) return false;
    if (!('name' in value)) return false;
    if (!('type' in value)) return false;
    if (!('status' in value)) return false;
    if (!('numberOfLandingPages' in value)) return false;
    return true;
}

export function LandingPageTemplateListItemDtoFromJSON(json: any): LandingPageTemplateListItemDto {
    return LandingPageTemplateListItemDtoFromJSONTyped(json, false);
}

export function LandingPageTemplateListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageTemplateListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'type': json['type'],
        'status': LandingPageTemplateStatusDtoFromJSON(json['status']),
        'numberOfLandingPages': json['numberOfLandingPages'],
    };
}

export function LandingPageTemplateListItemDtoToJSON(value?: LandingPageTemplateListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'type': value['type'],
        'status': LandingPageTemplateStatusDtoToJSON(value['status']),
        'numberOfLandingPages': value['numberOfLandingPages'],
    };
}

