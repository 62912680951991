/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupCompanyRelationListItemCompanyDto } from './GroupCompanyRelationListItemCompanyDto';
import {
    GroupCompanyRelationListItemCompanyDtoFromJSON,
    GroupCompanyRelationListItemCompanyDtoFromJSONTyped,
    GroupCompanyRelationListItemCompanyDtoToJSON,
} from './GroupCompanyRelationListItemCompanyDto';
import type { GroupReferenceDto } from './GroupReferenceDto';
import {
    GroupReferenceDtoFromJSON,
    GroupReferenceDtoFromJSONTyped,
    GroupReferenceDtoToJSON,
} from './GroupReferenceDto';

/**
 * The connection between a group and a company
 * @export
 * @interface GroupCompanyRelationListItemDto
 */
export interface GroupCompanyRelationListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupCompanyRelationListItemDto
     */
    id?: string;
    /**
     * 
     * @type {GroupReferenceDto}
     * @memberof GroupCompanyRelationListItemDto
     */
    group: GroupReferenceDto;
    /**
     * 
     * @type {GroupCompanyRelationListItemCompanyDto}
     * @memberof GroupCompanyRelationListItemDto
     */
    company: GroupCompanyRelationListItemCompanyDto;
}

/**
 * Check if a given object implements the GroupCompanyRelationListItemDto interface.
 */
export function instanceOfGroupCompanyRelationListItemDto(value: object): boolean {
    if (!('group' in value)) return false;
    if (!('company' in value)) return false;
    return true;
}

export function GroupCompanyRelationListItemDtoFromJSON(json: any): GroupCompanyRelationListItemDto {
    return GroupCompanyRelationListItemDtoFromJSONTyped(json, false);
}

export function GroupCompanyRelationListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupCompanyRelationListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'group': GroupReferenceDtoFromJSON(json['group']),
        'company': GroupCompanyRelationListItemCompanyDtoFromJSON(json['company']),
    };
}

export function GroupCompanyRelationListItemDtoToJSON(value?: GroupCompanyRelationListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'group': GroupReferenceDtoToJSON(value['group']),
        'company': GroupCompanyRelationListItemCompanyDtoToJSON(value['company']),
    };
}

