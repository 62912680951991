import { Button, Flex, Grid, Heading, Spacer, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TeamDto, TeamStatusDto } from '../../../api';
import PageContent from '../../../ui/page/page-content';
import usePermission from '../../permission/use-permission';
import { TeamRelationExtension } from '../../team/team-relation/team-relation-extension';
import TeamStaffEditorDialog from './team-staff-editor-dialog/team-staff-editor-dialog';
import TeamStaffRelationDataTable from './team-staff-viewer/team-staff-relation-data-table';

export const teamStaffPageContentExtension: TeamRelationExtension = {
  pageContent: (team) => <TeamStaffPageContent team={team} />,
};

interface TeamStaffPageContentProps {
  team: TeamDto;
}

function TeamStaffPageContent({ team }: TeamStaffPageContentProps) {
  const { t } = useTranslation('team');
  const { isOpen: editDialogIsOpen, onOpen: onEditDialogOpen, onClose: onEditDialogClose } = useDisclosure();

  const { hasPermission } = usePermission();

  return (
    <PageContent useFullWidth fixedHeight>
      <Grid gridRowGap={6} maxH="full" gridTemplateRows="auto 1fr">
        <Flex as="header">
          <Heading size="md" as="h3">
            {t('connections.staff')}
          </Heading>
          <Spacer />
          {hasPermission('TEAM:CAN_LINK_STAFF_WITH_TEAM') && (
            <Button
              variant="primary"
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onEditDialogOpen}
              aria-label={t('connections.add')}
              isDisabled={team.status !== TeamStatusDto.ACTIVE}
            >
              {t('connections.add')}
            </Button>
          )}
        </Flex>
        <TeamStaffRelationDataTable team={team} />
        <TeamStaffEditorDialog isOpen={editDialogIsOpen} onClose={onEditDialogClose} team={team} />
      </Grid>
    </PageContent>
  );
}
