import { faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Element, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { Alignment, WithFunction } from '../slate-types';
import ToolbarButton, { ToolbarButtonProps } from '../toolbar/toolbar-button';

const ALIGNMENT_ICONS = {
  left: faAlignLeft,
  right: faAlignRight,
  center: faAlignCenter,
  justify: faAlignJustify,
} as const;

const withAlignment = (alignment: Alignment): WithFunction => {
  return (editor) => {
    editor.toolbarButtons.alignment = [
      ...editor.toolbarButtons.alignment,
      <AlignmentToolbarButton alignment={alignment} icon={ALIGNMENT_ICONS[alignment]} />,
    ];

    return editor;
  };
};

export const withAlignLeft = withAlignment('left');

export const withAlignRight = withAlignment('right');

export const withAlignCenter = withAlignment('center');

export const withAlignJustify = withAlignment('justify');

function AlignmentToolbarButton({
  alignment,
  ...props
}: Omit<ToolbarButtonProps, 'label' | 'onClick'> & {
  alignment: Alignment;
}) {
  const { t } = useTranslation('common');
  const editor = useSlate();

  return (
    <ToolbarButton
      label={t(`wysiwyg.aria_labels.${alignment}`)}
      onClick={(event) => {
        Transforms.setNodes(editor, { align: alignment });
        ReactEditor.focus(editor);
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export function applyAlignment(element: Element) {
  return { style: { textAlign: 'align' in element ? element.align : undefined } } as const;
}
