/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferPaymentTargetDto
 */
export interface OfferPaymentTargetDto {
    /**
     * 
     * @type {number}
     * @memberof OfferPaymentTargetDto
     */
    grossPrice: number;
    /**
     * 
     * @type {Date}
     * @memberof OfferPaymentTargetDto
     */
    dueDate: Date;
}

/**
 * Check if a given object implements the OfferPaymentTargetDto interface.
 */
export function instanceOfOfferPaymentTargetDto(value: object): boolean {
    if (!('grossPrice' in value)) return false;
    if (!('dueDate' in value)) return false;
    return true;
}

export function OfferPaymentTargetDtoFromJSON(json: any): OfferPaymentTargetDto {
    return OfferPaymentTargetDtoFromJSONTyped(json, false);
}

export function OfferPaymentTargetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferPaymentTargetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'grossPrice': json['grossPrice'],
        'dueDate': (new Date(json['dueDate'])),
    };
}

export function OfferPaymentTargetDtoToJSON(value?: OfferPaymentTargetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'grossPrice': value['grossPrice'],
        'dueDate': ((value['dueDate']).toISOString().substring(0,10)),
    };
}

