import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface FilmTabItem {
  order?: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export const FILM_TAB_ITEM = new PluginToken<FilmTabItem>('FilmTabItem');
