import {
  Center,
  chakra,
  forwardRef,
  HTMLChakraProps,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileMetadataDto } from '../../api';
import { DescriptionGroup, DescriptionItem } from '../description';
import Optional from '../optional/optional';

interface AttachmentModalButtonProps extends HTMLChakraProps<'button'> {
  metadata: FileMetadataDto;
  alt?: string;
  onOpen?(): void;
  onClose?(): void;
}

const AttachmentModalButton = forwardRef<AttachmentModalButtonProps, 'button'>(
  ({ metadata, alt = metadata.originalFileName, onOpen: onOpenProp, onClose: onCloseProp, ...props }, ref) => {
    const { t } = useTranslation(['attachment']);
    const { getDisclosureProps, getButtonProps, isOpen, onClose } = useDisclosure({
      onOpen: onOpenProp,
      onClose: onCloseProp,
    });

    return (
      <>
        <chakra.button type="button" {...getButtonProps(props)} ref={ref} />
        <Modal size="6xl" {...getDisclosureProps()} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{metadata.originalFileName}</ModalHeader>
            <ModalCloseButton />
            <ModalBody p={6}>
              <Stack spacing={6}>
                <Center>
                  <Image src={metadata.url} objectFit="contain" alt={alt} />
                </Center>
                <DescriptionGroup columnCount={2}>
                  <DescriptionItem label={t('attachment:file_name')}>{metadata.originalFileName}</DescriptionItem>
                  <DescriptionItem label={t('attachment:mimeType')}>{metadata.mimeType}</DescriptionItem>
                  <DescriptionItem label={t('attachment:height')}>
                    <Optional>{metadata.width !== 0 && metadata.width}</Optional>
                  </DescriptionItem>
                  <DescriptionItem label={t('attachment:width')}>
                    <Optional>{metadata.height !== 0 && metadata.height}</Optional>
                  </DescriptionItem>
                </DescriptionGroup>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  },
);

export default AttachmentModalButton;
