import { PersonDto } from '../../../api';
import useFetcher from '../../../util/swr/use-fetcher';
import { PendingProvider } from '../../../util/use-pending/use-pending';
import { fetchPerson, fetchPersonTemplate } from '../person-queries';
import PersonDrawerForm from './person-drawer-form';

interface PersonDrawerProps {
  isOpen: boolean;
  onClose(): void;
  onSave?(person: Omit<PersonDto, 'id'> & { id: string }): void;
  personId?: string;
  defaultValue?: Partial<PersonDto>;
}

export default function PersonDrawer({ isOpen, onClose, onSave, personId, defaultValue }: PersonDrawerProps) {
  const template = useFetcher(fetchPersonTemplate, {}, { active: personId == null });
  const person = useFetcher(fetchPerson, { id: personId ?? '' }, { active: personId != null });

  const personToUse = template != null ? { ...template, ...defaultValue } : person;

  return (
    <>
      {personToUse != null && (
        <PendingProvider>
          <PersonDrawerForm
            key={personToUse.version?.revision}
            templatePerson={personToUse}
            isOpen={isOpen}
            onClose={onClose}
            onSave={onSave}
          />
        </PendingProvider>
      )}
    </>
  );
}
