/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CostBearerLabelDto = {
    ACCREDITATION: 'ACCREDITATION',
    HOTELNIGHT: 'HOTELNIGHT'
} as const;
export type CostBearerLabelDto = typeof CostBearerLabelDto[keyof typeof CostBearerLabelDto];


export function CostBearerLabelDtoFromJSON(json: any): CostBearerLabelDto {
    return CostBearerLabelDtoFromJSONTyped(json, false);
}

export function CostBearerLabelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostBearerLabelDto {
    return json as CostBearerLabelDto;
}

export function CostBearerLabelDtoToJSON(value?: CostBearerLabelDto | null): any {
    return value as any;
}

