import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef, RefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import invariant from 'tiny-invariant';
import Translate from '../../../util/translate/translate';
import { getActiveBlockNode, isBlockActive } from '../block';
import { Render } from '../render';
import { CitationElement, WithFunction } from '../slate-types';
import { useRichTextStyles } from '../styles-context';
import ToolbarButton from '../toolbar/toolbar-button';
import { applyAlignment } from './with-alignment';

const CITATION = 'citation';

const withCitation: WithFunction = (editor) => {
  const renderCitation: Render<'block'> = {
    type: 'citation',
    render: ({ children, attributes, element }) => {
      invariant(element.type === 'citation');

      return (
        <Citation {...attributes} author={element.author} {...applyAlignment(element)}>
          {children}
        </Citation>
      );
    },
  };

  editor.renderers = [...editor.renderers, renderCitation];

  editor.toolbarButtons.block = [...editor.toolbarButtons.block, <CitationToolbarButton />];

  return editor;
};

export default withCitation;

const CitationToolbarButton = forwardRef((_, ref: ForwardedRef<HTMLButtonElement>) => {
  const editor = useSlate();
  const initialFocusRef = useRef(null);
  const activeCitation = getActiveBlockNode(editor, CITATION)?.[0];
  const { t } = useTranslation('common');

  if (editor.onlyOneBlockOption) {
    return null;
  }

  return (
    <Popover initialFocusRef={initialFocusRef} isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <ToolbarButton
              isActive={isBlockActive(editor, CITATION)}
              label={t(`wysiwyg.aria_labels.${CITATION}`)}
              icon={faQuoteLeft}
              ref={ref}
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="xl">
              <PopoverArrow />
              <CitationPopoverContent
                onClose={onClose}
                initialFocusRef={initialFocusRef}
                activeCitation={activeCitation}
              />
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
});

function CitationPopoverContent({
  onClose,
  activeCitation,
  initialFocusRef,
}: {
  onClose: () => void;
  activeCitation: CitationElement | undefined;
  initialFocusRef: RefObject<HTMLInputElement>;
}) {
  const editor = useSlate();
  const [author, setAuthor] = useState(activeCitation?.author ?? '');
  const { t } = useTranslation('common');

  return (
    <PopoverBody>
      <HStack>
        <FormControl>
          <HStack>
            <FormLabel size="sm" mb={0} mr={1}>
              {t('wysiwyg.citationAuthorLabel')}:
            </FormLabel>
            <Input
              ref={initialFocusRef}
              size="sm"
              value={author}
              onChange={(newValue) => setAuthor(newValue.target.value)}
            />
          </HStack>
        </FormControl>
        <Button
          size="sm"
          onClick={(event) => {
            event.preventDefault();
            Transforms.setNodes(editor, { type: CITATION, author });
            setAuthor('');
            ReactEditor.focus(editor);
            onClose();
          }}
        >
          <Translate ns="common" i18nKey="action.apply" />
        </Button>
        {isBlockActive(editor, CITATION) && (
          <Button
            size="sm"
            onClick={(event) => {
              event.preventDefault();
              Transforms.setNodes(editor, { type: 'paragraph' });
              ReactEditor.focus(editor);
              onClose();
            }}
          >
            <Translate ns="common" i18nKey="action.delete" />
          </Button>
        )}
      </HStack>
    </PopoverBody>
  );
}

const Citation = forwardRef(
  (
    { children, author, ...props }: { author: string } & ComponentPropsWithoutRef<typeof chakra.div>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const styles = useRichTextStyles();

    return (
      <chakra.div __css={styles.citation} {...props} ref={ref}>
        <chakra.strong __css={styles.citationQuote}>
          <span contentEditable={false}>„</span>
          {children}
          <span contentEditable={false}>“</span>
        </chakra.strong>
        <chakra.p contentEditable={false} __css={styles.citationAuthor}>
          {author}
        </chakra.p>
      </chakra.div>
    );
  },
);
