import { chakra, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PublicationDto } from '../../../api';
import { DataTableColumn, DataTableTruncatedText } from '../../../ui/data-table';
import BooleanFilter from '../../../ui/data-table/filter/boolean-filter';
import DateRangeFilter from '../../../ui/data-table/filter/date-range-filter';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import Optional from '../../../ui/optional/optional';
import Translate from '../../../util/translate/translate';
import PersonReference from '../../person/person-reference/person-reference';

export default function usePublicationColumns(): DataTableColumn<PublicationDto>[] {
  const { t } = useTranslation(['event', 'common']);

  return React.useMemo(() => {
    return [
      {
        key: 'recipient',
        sticky: true,
        isSortable: true,
        name: <Translate ns="event" i18nKey="publication.recipient" />,
        renderCell: (publication) => (
          <PersonReference personReference={publication.recipient} hidePersonKey usePortalForCard />
        ),
        sortProperty: 'recipient.displayName',
        filterProperty: 'recipient.displayName',
        filter: (
          <StringFilter
            label={t('event:publication.recipient')}
            availableOperators={['CONTAIN', 'NOT_CONTAIN', 'START_WITH', 'NOT_START_WITH', 'END_WITH', 'NOT_END_WITH']}
          />
        ),
      },
      {
        key: 'dateOfPublication',
        isSortable: true,
        name: <Translate ns="event" i18nKey="publication.dateOfPublication" />,
        renderCell: (publication) => (
          <Translate ns="common">{(t) => t('format.date_time', { dateTime: publication.dateOfPublication })}</Translate>
        ),
        filter: <DateRangeFilter isTimestamp={true} />,
        filterWidth: 'md',
      },
      {
        key: 'publishedBy',
        isSortable: true,
        sortProperty: 'publishedBy.displayName',
        name: <Translate ns="event" i18nKey="publication.publishedBy" />,
        renderCell: (publication) => publication.publishedBy?.displayName,
        // TODO BX-3020 filter:
      },
      {
        key: 'cause',
        isSortable: true,
        name: <Translate ns="event" i18nKey="publication.cause" />,
        cellProps: {
          width: '20%',
        },
        renderCell: (publication) => (
          <Tooltip label={publication.cause}>
            {/* Add additional div for tooltip to open*/}
            <chakra.div>
              <DataTableTruncatedText>{publication.cause}</DataTableTruncatedText>
            </chakra.div>
          </Tooltip>
        ),
        filter: <StringFilter label={t('event:publication.cause')} />,
      },
      {
        key: 'expiryDate',
        isSortable: true,
        cellProps: { width: '10%' },
        name: (
          <>
            {t('event:publication.expiryDate')}
            <HelperPopover>{t('event:publication.expiry_date_tooltip')}</HelperPopover>
          </>
        ),
        renderCell: (publication) => (
          <Translate ns="common">{(t) => t('format.date_time', { dateTime: publication.expiryDate })}</Translate>
        ),
        filter: <DateRangeFilter isTimestamp={true} />,
        filterWidth: 'md',
      },
      {
        key: 'anonymizePersonalData',
        isSortable: true,
        name: <Translate ns="event" i18nKey="publication.anonymize_personal_data" />,
        renderCell: (publication) =>
          publication.anonymizePersonalData ? (
            <Translate ns="common" i18nKey="boolean_viewer.true" />
          ) : (
            <Translate ns="common" i18nKey="boolean_viewer.false" />
          ),
        filter: <BooleanFilter label={t('event:publication.anonymize_personal_data')} />,
      },
      {
        key: 'suspended',
        isSortable: true,
        cellProps: { width: '10%' },
        name: <Translate ns="event" i18nKey="publication.suspended" />,
        renderCell: (publication) => (
          <Optional isEmpty={publication.suspended == null}>
            <Translate ns="common">{(t) => t('format.date_time', { dateTime: publication.suspended })}</Translate>
          </Optional>
        ),
        filter: <DateRangeFilter isTimestamp={true} />,
        filterWidth: 'md',
      },
      {
        key: 'suspendedBy',
        isSortable: true,
        sortProperty: 'suspendedBy.displayName',
        name: <Translate ns="event" i18nKey="publication.suspendedBy" />,
        renderCell: (publication) => (
          <Optional isEmpty={publication.suspendedBy == null}>{publication.suspendedBy?.displayName}</Optional>
        ),
        // TODO BX-3020 filter:
      },
    ];
  }, [t]);
}

export function useMinimalPublicationColumns(): DataTableColumn<PublicationDto>[] {
  const { t } = useTranslation(['event', 'common']);
  return React.useMemo(() => {
    return [
      {
        key: 'dateOfPublication',
        name: <Translate ns="event" i18nKey="publication.dateOfPublication" />,
        renderCell: (publication) => (
          <Translate ns="common">{(t) => t('format.date_time', { dateTime: publication.dateOfPublication })}</Translate>
        ),
        isSortable: true,
        headerCellProps: {
          width: '50%',
        },
      },
      {
        key: 'expiryDate',
        name: (
          <>
            {t('event:publication.expiryDate')}
            <HelperPopover>{t('event:publication.expiry_date_tooltip')}</HelperPopover>
          </>
        ),
        renderCell: (publication) => (
          <Translate ns="common">{(t) => t('format.date_time', { dateTime: publication.expiryDate })}</Translate>
        ),
        isSortable: true,
        headerCellProps: {
          width: '50%',
        },
      },
    ];
  }, [t]);
}
