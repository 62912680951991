import { HStack } from '@chakra-ui/react';
import React from 'react';
import { OrderConfirmationReferenceDto, OrderConfirmationReferenceDtoFromJSON, OrderDto } from '../../../api';
import { DescriptionItem } from '../../../ui/description';
import Translate from '../../../util/translate/translate';
import OrderConfirmationReference from '../order-confirmation-reference/order-confirmation-reference';

interface OrderConfirmationOrderDescriptionItemProps {
  order: OrderDto;
}

export default function OrderConfirmationOrderDescriptionItem({ order }: OrderConfirmationOrderDescriptionItemProps) {
  const orderConfirmations = useOrderConfirmations(order);

  return orderConfirmations != null ? (
    <DescriptionItem label={<Translate ns="order_confirmation" i18nKey="orderConfirmation" />} span={2}>
      <HStack>
        {orderConfirmations.map((orderConfirmation, index) => (
          <OrderConfirmationReference orderConfirmation={orderConfirmation} key={index} />
        ))}
      </HStack>
    </DescriptionItem>
  ) : undefined;
}

function useOrderConfirmations(order: OrderDto): OrderConfirmationReferenceDto[] {
  return order.extensions.orderConfirmation?.map((item: any) => OrderConfirmationReferenceDtoFromJSON(item));
}
