/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CountryPermissionDto
 */
export interface CountryPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof CountryPermissionDto
     */
    name: CountryPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof CountryPermissionDto
     */
    action: CountryPermissionDtoActionEnum;
}


/**
 * @export
 */
export const CountryPermissionDtoNameEnum = {
    COUNTRY: 'COUNTRY'
} as const;
export type CountryPermissionDtoNameEnum = typeof CountryPermissionDtoNameEnum[keyof typeof CountryPermissionDtoNameEnum];

/**
 * @export
 */
export const CountryPermissionDtoActionEnum = {
    LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    DETAILS: 'CAN_SEE_DETAILS'
} as const;
export type CountryPermissionDtoActionEnum = typeof CountryPermissionDtoActionEnum[keyof typeof CountryPermissionDtoActionEnum];


/**
 * Check if a given object implements the CountryPermissionDto interface.
 */
export function instanceOfCountryPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function CountryPermissionDtoFromJSON(json: any): CountryPermissionDto {
    return CountryPermissionDtoFromJSONTyped(json, false);
}

export function CountryPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function CountryPermissionDtoToJSON(value?: CountryPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

