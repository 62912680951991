import { BreadcrumbRouteObject } from '../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';
import Translate from '../../../util/translate/translate';
import PaymentBreadcrumb from './common/payment-breadcrumb';

const paymentRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'payments',
    handle: {
      breadcrumb: <Translate ns="payment" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./payment-lister/payment-lister-route'),
      },
      {
        path: ':paymentId',
        handle: {
          breadcrumb: <PaymentBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./payment-page/payment-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./payment-viewer/payment-viewer-route'),
              },
            ],
          },
        ],
      },
    ],
  },
];

export default paymentRoutes;
