import { chakra } from '@chakra-ui/react';
import { faListOl, faListUl } from '@fortawesome/pro-regular-svg-icons';
import React, { ComponentPropsWithoutRef, ForwardedRef, forwardRef, ReactNode } from 'react';
import { Render } from '../render';
import { ElementFormatOption, WithFunction } from '../slate-types';
import { useRichTextStyles } from '../styles-context';
import { BlockToolbarButton } from '../toolbar/toolbar-button';
import { applyAlignment } from './with-alignment';

const ORDERED_LIST = 'orderedList';
const UNORDERED_LIST = 'unorderedList';
const LIST_ITEM = 'listItem';

export const withOrderedList: WithFunction = (editor) => {
  const renderer: Render<'block'> = {
    type: ORDERED_LIST,
    render: ({ children, attributes, element }) => (
      <OrderedList {...attributes} {...applyAlignment(element)}>
        {children}
      </OrderedList>
    ),
  };

  editor.renderers = [...editor.renderers, renderer];

  editor.toolbarButtons.list = [
    ...editor.toolbarButtons.list,
    <BlockToolbarButton format={ORDERED_LIST} icon={faListOl} />,
  ];

  return withListItem(editor);
};

export const withUnorderedList: WithFunction = (editor) => {
  const renderer: Render<'block'> = {
    type: UNORDERED_LIST,
    render: ({ children, attributes, element }) => (
      <UnorderedList {...attributes} {...applyAlignment(element)}>
        {children}
      </UnorderedList>
    ),
  };

  editor.renderers = [...editor.renderers, renderer];

  editor.toolbarButtons.list = [
    ...editor.toolbarButtons.list,
    <BlockToolbarButton format={UNORDERED_LIST} icon={faListUl} />,
  ];

  return withListItem(editor);
};

const withListItem: WithFunction = (editor) => {
  const renderer: Render<'block'> = {
    type: LIST_ITEM,
    render: ({ children, attributes, element }) => (
      <li {...attributes} {...applyAlignment(element)}>
        {children}
      </li>
    ),
  };

  editor.renderers = [...editor.renderers, renderer];

  return editor;
};

export function isListType(type: ElementFormatOption): type is 'orderedList' | 'unorderedList' {
  return type === ORDERED_LIST || type === UNORDERED_LIST;
}

export const OrderedList = forwardRef(
  (
    { children, ...props }: { children?: ReactNode } & ComponentPropsWithoutRef<'ol'>,
    ref: ForwardedRef<HTMLOListElement>,
  ) => {
    const styles = useRichTextStyles();

    return (
      <chakra.ol __css={styles.orderedList} {...props} ref={ref}>
        {children}
      </chakra.ol>
    );
  },
);

export const UnorderedList = forwardRef(
  (
    { children, ...props }: { children?: ReactNode } & ComponentPropsWithoutRef<'ul'>,
    ref: ForwardedRef<HTMLUListElement>,
  ) => {
    const styles = useRichTextStyles();

    return (
      <chakra.ul __css={styles.unorderedList} {...props} ref={ref}>
        {children}
      </chakra.ul>
    );
  },
);
