/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RichTextOptionsDto } from './RichTextOptionsDto';
import {
    RichTextOptionsDtoFromJSON,
    RichTextOptionsDtoFromJSONTyped,
    RichTextOptionsDtoToJSON,
} from './RichTextOptionsDto';

/**
 * 
 * @export
 * @interface LandingPageTextModuleSettingsDto
 */
export interface LandingPageTextModuleSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof LandingPageTextModuleSettingsDto
     */
    moduleType: string;
    /**
     * 
     * @type {Date}
     * @memberof LandingPageTextModuleSettingsDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {Array<RichTextOptionsDto>}
     * @memberof LandingPageTextModuleSettingsDto
     */
    options: Array<RichTextOptionsDto>;
}

/**
 * Check if a given object implements the LandingPageTextModuleSettingsDto interface.
 */
export function instanceOfLandingPageTextModuleSettingsDto(value: object): boolean {
    if (!('moduleType' in value)) return false;
    if (!('options' in value)) return false;
    return true;
}

export function LandingPageTextModuleSettingsDtoFromJSON(json: any): LandingPageTextModuleSettingsDto {
    return LandingPageTextModuleSettingsDtoFromJSONTyped(json, false);
}

export function LandingPageTextModuleSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageTextModuleSettingsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'moduleType': json['moduleType'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'options': ((json['options'] as Array<any>).map(RichTextOptionsDtoFromJSON)),
    };
}

export function LandingPageTextModuleSettingsDtoToJSON(value?: LandingPageTextModuleSettingsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'moduleType': value['moduleType'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'options': ((value['options'] as Array<any>).map(RichTextOptionsDtoToJSON)),
    };
}

