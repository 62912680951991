import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import useFetcher from '../../util/swr/use-fetcher';
import { Component } from '../group/group-page/group-page-route';
import { fetchGroup } from '../group/group-queries';
import { GroupOrderPageContent } from './order-lister/group-order-page-content';

const groupOrderRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <Component />,
    children: [
      {
        path: 'orders',
        element: <GroupOrderPageContent />,
        handle: {
          helmet: <GroupOrdersHelmet />,
        },
      },
    ],
  },
];

export default groupOrderRoutes;

function GroupOrdersHelmet() {
  const { t } = useTranslation('order');
  const params = useParams<{ groupId: string }>();
  invariant(params.groupId, 'Empty groupId');
  const group = useFetcher(fetchGroup, { id: params.groupId });

  return <Helmet title={t('connections.groupHelmet', { name: group.name })} />;
}
