import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import useFetcher from '../../util/swr/use-fetcher';
import useParam from '../../util/use-params/use-params';
import { fetchFilm } from './film-queries';
import { mostRelevantTitle } from './film-utils';

interface FilmHelmetProps {
  suffix?: string;
}

export function FilmHelmet({ suffix }: FilmHelmetProps) {
  const { t } = useTranslation('film');
  const film = useFetcher(fetchFilm, { id: useParam('filmId') }, { suspense: true });
  const title = mostRelevantTitle(film);

  let helmet = t('viewer.helmet', { film: mostRelevantTitle(film) });
  if (suffix) {
    helmet = `${title}: ${suffix} ${t('helmet.postfix')}`;
  }

  return <Helmet title={helmet} />;
}
