import { chakra } from '@chakra-ui/react';
import { faS } from '@fortawesome/pro-regular-svg-icons';
import React, { ComponentPropsWithoutRef } from 'react';
import { Render } from '../render';
import { WithFunction } from '../slate-types';
import { useRichTextStyles } from '../styles-context';
import { MarkToolbarButton } from '../toolbar/toolbar-button';

const SMALL = 'small';

const withSmall: WithFunction = (editor) => {
  const renderSmall: Render<'mark'> = {
    type: SMALL,
    render: (children) => <Small>{children}</Small>,
  };
  editor.renderers = [...editor.renderers, renderSmall];

  editor.toolbarButtons.mark = [...editor.toolbarButtons.mark, <MarkToolbarButton format={SMALL} icon={faS} />];

  return editor;
};

export default withSmall;

function Small({ children, ...props }: ComponentPropsWithoutRef<typeof chakra.small>) {
  const styles = useRichTextStyles();

  return (
    <chakra.small __css={styles.small} {...props}>
      {children}
    </chakra.small>
  );
}
