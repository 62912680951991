/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemMailingPermissionDto
 */
export interface SystemMailingPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof SystemMailingPermissionDto
     */
    name: SystemMailingPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemMailingPermissionDto
     */
    action: SystemMailingPermissionDtoActionEnum;
}


/**
 * @export
 */
export const SystemMailingPermissionDtoNameEnum = {
    SYSTEM_MAILING: 'SYSTEM_MAILING'
} as const;
export type SystemMailingPermissionDtoNameEnum = typeof SystemMailingPermissionDtoNameEnum[keyof typeof SystemMailingPermissionDtoNameEnum];

/**
 * @export
 */
export const SystemMailingPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT'
} as const;
export type SystemMailingPermissionDtoActionEnum = typeof SystemMailingPermissionDtoActionEnum[keyof typeof SystemMailingPermissionDtoActionEnum];


/**
 * Check if a given object implements the SystemMailingPermissionDto interface.
 */
export function instanceOfSystemMailingPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function SystemMailingPermissionDtoFromJSON(json: any): SystemMailingPermissionDto {
    return SystemMailingPermissionDtoFromJSONTyped(json, false);
}

export function SystemMailingPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemMailingPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function SystemMailingPermissionDtoToJSON(value?: SystemMailingPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

