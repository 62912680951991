import { editionIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useEditionPermissionClassGroupExtension } from './edition-permissions';
import EditionSearchResult from './edition-search-result';

export default function EditionPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'EDITION', Component: EditionSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'EDITION',
    icon: editionIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.EDITION" />,
    necessaryPermission: 'EDITION:CAN_SEE_DETAILS',
  });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useEditionPermissionClassGroupExtension);
}
