import React from 'react';
import { FieldValues, useController, useFormContext } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { useTranslation } from 'react-i18next';
import { LayoutType } from '../../../feature/common/layout-type';
import { FileSuffixes, FileType } from '../../attachments/upload/file-type';
import UploadFileButton from '../../attachments/upload/upload-file-button';
import UploadTable from '../../attachments/upload/upload-table';
import { useFileUpload } from '../../attachments/upload/use-file-upload';
import FormControl from '../form-control';
import imageColumns from './image-columns';

interface ImageControlProps<T extends FieldValues, TPath extends FieldPath<T>> {
  label: string;
  name: TPath;
  maxFileSizeInMB: number;
  acceptFileTypes: FileType[];
  namespace: string;
  isRequired?: boolean;
  helperText?: React.ReactNode;
  helperPopover?: React.ReactElement;
  layout?: LayoutType;
}

export default function ImageControl<T extends FieldValues, TPath extends FieldPath<T> = FieldPath<T>>({
  label,
  name,
  maxFileSizeInMB,
  acceptFileTypes,
  namespace,
  isRequired,
  helperText,
  helperPopover,
  layout,
}: ImageControlProps<T, TPath>) {
  const { t } = useTranslation(['common', 'attachment']);
  const { fileMetadata, deleteFile, isUploading, uploadFile, validationErrors } = useFileUpload<T, TPath>({
    name,
    maxFileSizeInMB,
    acceptFileTypes,
    namespace,
  });

  // Validators in useFileUpload are expecting special kind of validators. So we need to use
  // useController to use the correct validators. :/
  useController<T, TPath>({
    name,
    rules: {
      validate: (metadata) =>
        !isRequired || metadata != null || t('common:validation_error.required', { field: label }),
    },
  });

  const { setError, clearErrors } = useFormContext();
  const { field } = useController<T, TPath>({ name });

  // useFileUpload does return validation errors from uploading a file instead of setting them on
  // the specified field. So let's do that our-self.
  React.useEffect(() => {
    if (validationErrors.length === 0) {
      clearErrors(name);
    } else {
      validationErrors.forEach((error, index) => {
        setError(name, { type: `custom-${index}`, message: error });
      });
    }
  }, [clearErrors, name, setError, validationErrors]);

  return (
    <FormControl<T>
      name={name}
      label={label}
      helperText={
        helperText ??
        t('attachment:helper_text.size_and_accepted_types', {
          size_mb: maxFileSizeInMB,
          acceptedFileTypes: acceptFileTypes.flatMap((type) => FileSuffixes[type]),
        })
      }
      isRequired={isRequired}
      helperPopover={helperPopover}
    >
      <UploadTable
        aria-label={label}
        columns={imageColumns}
        fileData={fileMetadata != null ? [fileMetadata] : undefined}
        onDelete={deleteFile}
        maxElements={1}
        layout={layout}
        uploadButton={
          <UploadFileButton
            ref={field.ref}
            acceptFileTypes={acceptFileTypes}
            isUploading={isUploading}
            uploadFile={uploadFile}
            buttonText={t('attachment:upload', { fileType: label })}
            variant="ghost"
            w="full"
          />
        }
      />
    </FormControl>
  );
}
