import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { StandCompanyListItemDto } from '../../../api';
import standApi from '../../../data-access/stand-api';
import DataTable, { DataTableColumn, DataTableState, useDataTableState } from '../../../ui/data-table';
import { DataTableFilter, DataTableSort } from '../../../ui/data-table/data-table-state';
import DataTableTruncatedText from '../../../ui/data-table/data-table-truncated-text';
import BooleanFilter from '../../../ui/data-table/filter/boolean-filter';
import DateFilter from '../../../ui/data-table/filter/date-filter';
import EnumFilter from '../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../ui/data-table/use-request-params';
import LastModifiedLabel from '../../../ui/version/last-modified-label';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';
import useFetcher from '../../../util/swr/use-fetcher';
import Translate from '../../../util/translate/translate';
import HasPermission from '../../permission/has-permission';
import {
  standCompanyRelationStatusOptions,
  standCompanyRelationTypeOptions,
  statusOptions,
} from '../stand-enum-constants';
import { searchStandCompanyRelationListItems } from '../stand-queries';
import StandReference from '../stand-reference/stand-reference';

function useStandCompanies(
  state: DataTableState,
  fallbackSort: DataTableSort[] | undefined,
  forcedFilter: DataTableFilter[],
) {
  const requestParams = useRequestParams(state, fallbackSort, forcedFilter);

  return useFetcher(searchStandCompanyRelationListItems, requestParams, { use: [fallbackMiddleware] });
}

interface CompanyStandDataTableProps {
  defaultState?: Partial<DataTableState>;
  fallbackSort?: DataTableSort[];
  forcedFilter: DataTableFilter[];
}

export default function CompanyStandDataTable({
  defaultState,
  fallbackSort,
  forcedFilter,
}: CompanyStandDataTableProps) {
  const { t } = useTranslation(['common', 'stand']);
  const [state, setState] = useDataTableState(defaultState);
  const page = useStandCompanies(state, fallbackSort, forcedFilter);

  const columns: DataTableColumn<StandCompanyListItemDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<StandCompanyListItemDto>[] = [
      {
        key: 'edition.name',
        name: t('stand:edition'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        renderCell: (relation) => <DataTableTruncatedText>{relation.edition.name}</DataTableTruncatedText>,
        filter: (
          <StringFilter
            label={t('stand:edition')}
            loadOptions={async (value: string) => {
              const page = await standApi.searchStandCompanyRelationListItems({
                pageable: { size: 10 },
                filter: [`edition.name,eq,${value}`],
              });

              return page.content.map((relation) => relation.edition.name!);
            }}
          />
        ),
      },
      {
        key: 'name',
        filterProperty: 'stand.name',
        sticky: true,
        name: t('stand:name'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '100%',
        },
        isSortable: true,
        sortProperty: 'stand.name',
        renderCell: (standCompany) => (
          <HasPermission necessaryPermission="STAND:CAN_SEE_DETAILS" fallback={standCompany.stand.name}>
            <StandReference stand={standCompany.stand} />
          </HasPermission>
        ),
        filter: (
          <StringFilter
            label={t('stand:name')}
            loadOptions={async (value: string) => {
              const page = await standApi.searchStandListItems({
                pageable: { size: 10 },
                filter: [`name,contain,${value}`],
              });

              return page.content.map((relation) => relation.name);
            }}
          />
        ),
      },
      {
        key: 'relationType',
        name: t('stand:companyRelation.relationType'),
        isSortable: true,
        renderCell: (standCompany) => t(`stand:companyRelation.relationTypeOptions.${standCompany.relationType}`),
        filter: (
          <EnumFilter
            label={t('stand:companyRelation.relationType')}
            options={standCompanyRelationTypeOptions}
            renderOptionLabel={(key) => t(`stand:companyRelation.relationTypeOptions.${key}`)}
          />
        ),
      },
      {
        key: 'relationStatus',
        name: t('stand:companyRelation.relationStatus'),
        isSortable: true,
        renderCell: (standCompany) => t(`stand:companyRelation.relationStatusOptions.${standCompany.relationStatus}`),
        filter: (
          <EnumFilter
            label={t('stand:companyRelation.relationStatus')}
            options={standCompanyRelationStatusOptions}
            renderOptionLabel={(key) => t(`stand:companyRelation.relationStatusOptions.${key}`)}
          />
        ),
      },
      {
        key: 'sharingFees',
        name: t('stand:companyRelation.sharingFees'),
        isSortable: true,
        renderCell: (standCompany) => t(`common:boolean_viewer.${standCompany.sharingFees}`),
        filter: <BooleanFilter label={t('stand:companyRelation.sharingFees')} />,
      },
      {
        key: 'standStatus',
        name: t('stand:status'),
        isSortable: true,
        renderCell: (standCompany) => t(`stand:statusOptions.${standCompany.standStatus}`),
        filter: (
          <EnumFilter
            label={t('stand:status')}
            options={statusOptions}
            renderOptionLabel={(key) => t(`stand:statusOptions.${key}`)}
          />
        ),
      },
      {
        key: 'modifiedAt',
        name: <Translate ns="common" i18nKey="viewer.last_modification" />,
        isSortable: true,
        renderCell: (data) => <LastModifiedLabel version={data.version} />,
        filter: <DateFilter showTimeSelect />,
        filterWidth: 'md',
      },
    ];

    return columns;
  }, [t]);

  const rowKey = React.useCallback((standCompany: StandCompanyListItemDto) => {
    invariant(standCompany.id != null, 'Missing stand id');

    return standCompany.id;
  }, []);

  return (
    <DataTable
      page={page == null ? { content: [] } : page}
      state={state}
      columns={columns}
      rowKey={rowKey}
      onStateChange={setState}
      isPageable={false}
    />
  );
}
