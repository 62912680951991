import { Flex, IconButton } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { MailingTypeDto, ModuleTypeDto } from '../../../../api';
import usePlugins, { PluginToken } from '../../../../util/plugin/use-plugins';
import ModuleControl from './module-control';

interface MailingTemplateModuleProps {
  isDeletable?: boolean;
  onDelete?: () => void;
  name: string;
  mailingType: MailingTypeDto;
}

export function ModuleSettingsModuleControl({ isDeletable, onDelete, name, mailingType }: MailingTemplateModuleProps) {
  const { t } = useTranslation('mailing_template');

  const { field } = useController({
    name,
  });
  const extension = usePlugins(MODULE_SETTINGS_CONTROL_EXTENSION).find(
    (extension) => extension.type === field.value.moduleType,
  );
  invariant(extension != null, `Missing control extension for ${field.value.moduleType}`);

  return (
    <Flex alignItems="stretch" w="full" data-testid={`module-${extension.type}`}>
      <ModuleControl label={t(`moduleSettings.moduleTypeOptions.${extension.type}`)}>
        {extension.render?.({
          mailingType,
          name,
        })}
      </ModuleControl>
      {isDeletable && (
        <IconButton
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          variant="solid"
          aria-label={t('editor.deleteModule')}
          sx={{ borderRadius: '0', h: 'auto' }}
          onClick={onDelete}
        />
      )}
    </Flex>
  );
}

export interface ModuleSettingsControlExtension {
  type: ModuleTypeDto;
  render?(props: { name: string; mailingType: MailingTypeDto }): ReactNode;
}

export const MODULE_SETTINGS_CONTROL_EXTENSION = new PluginToken<ModuleSettingsControlExtension>(
  'ModuleSettingsControlExtension',
);
