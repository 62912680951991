import { Editor } from 'slate';
import { MarkFormatOption } from './slate-types';

export function toggleMark(editor: Editor, format: MarkFormatOption) {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}

export function isMarkActive(editor: Editor, format: MarkFormatOption) {
  const marks = Editor.marks(editor);
  return marks != null ? marks[format] === true : false;
}
