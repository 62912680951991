import React from 'react';
import invariant from 'tiny-invariant';
import { CompanyFilmRelationListItemDto } from '../../../../api';
import HistoryPage from '../../../../ui/history/history-page';
import usePaginationState from '../../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../../util/swr/use-fetcher';
import Translate from '../../../../util/translate/translate';
import { FilmHistoryExtension } from '../../../film/film-history/film-history-extension';
import { fetchFilmCompanyHistory } from '../../company-queries';
import { useFilmCompanyHistorySettings } from './use-company-film-history-settings';

export const filmCompanyHistoryExtension: FilmHistoryExtension = {
  key: 'companies',
  buttonLabel: <Translate ns="company" i18nKey="relation.film.relatedCompanies" />,
  content: (props) => <FilmCompanyHistory {...props} />,
};

function FilmCompanyHistory({ filmId }: { filmId: string }) {
  const [state, setState] = usePaginationState();
  const companyFormatHistorySettings = useFilmCompanyHistorySettings();

  const companyFilmHistoryPage = useFetcher(fetchFilmCompanyHistory, {
    filmId,
    pageable: state,
  });

  invariant(companyFilmHistoryPage != null, 'Missing film history page');

  return (
    <HistoryPage<CompanyFilmRelationListItemDto>
      page={companyFilmHistoryPage}
      state={state}
      onStateChange={setState}
      historyDisplaySettings={companyFormatHistorySettings}
      showLine={false}
    />
  );
}
