/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TagRelationTypeDto = {
    COMPANY: 'COMPANY',
    EVENT: 'EVENT',
    FORMAT: 'FORMAT',
    LOCATION: 'LOCATION',
    PERSON: 'PERSON',
    PRODUCT: 'PRODUCT',
    STAND: 'STAND',
    FILM: 'FILM'
} as const;
export type TagRelationTypeDto = typeof TagRelationTypeDto[keyof typeof TagRelationTypeDto];


export function TagRelationTypeDtoFromJSON(json: any): TagRelationTypeDto {
    return TagRelationTypeDtoFromJSONTyped(json, false);
}

export function TagRelationTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagRelationTypeDto {
    return json as TagRelationTypeDto;
}

export function TagRelationTypeDtoToJSON(value?: TagRelationTypeDto | null): any {
    return value as any;
}

