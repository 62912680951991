import { Box, ButtonGroup, HStack } from '@chakra-ui/react';
import { Fragment } from 'react';
import { useSlate } from 'slate-react';
import { ToolbarGroup } from '../slate-types';

const TOOLBAR_GROUPS: ToolbarGroup[] = ['block', 'mark', 'link', 'list', 'alignment'];

export default function Toolbar() {
  const editor = useSlate();

  if (TOOLBAR_GROUPS.every((group) => editor.toolbarButtons[group].length === 0)) {
    return null;
  }

  return (
    <Box borderX="1px" borderTop="1px" borderTopRadius="base" borderColor="border.01" p={2} bgColor="layer.01">
      <HStack spacing="5px" wrap="wrap">
        {TOOLBAR_GROUPS.map((group) => (
          <ButtonGroup isAttached key={group}>
            {editor.toolbarButtons[group].map((button, index) => (
              <Fragment key={index}>{button}</Fragment>
            ))}
          </ButtonGroup>
        ))}
      </HStack>
    </Box>
  );
}
