/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GroupExternalApplicationTypeDto = {
    ADD: 'ADD',
    REMOVE: 'REMOVE'
} as const;
export type GroupExternalApplicationTypeDto = typeof GroupExternalApplicationTypeDto[keyof typeof GroupExternalApplicationTypeDto];


export function GroupExternalApplicationTypeDtoFromJSON(json: any): GroupExternalApplicationTypeDto {
    return GroupExternalApplicationTypeDtoFromJSONTyped(json, false);
}

export function GroupExternalApplicationTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupExternalApplicationTypeDto {
    return json as GroupExternalApplicationTypeDto;
}

export function GroupExternalApplicationTypeDtoToJSON(value?: GroupExternalApplicationTypeDto | null): any {
    return value as any;
}

