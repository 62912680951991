import { Stack } from '@chakra-ui/react';
import React from 'react';
import { LayoutType } from '../../common/layout-type';
import PersonLocationRelationControl from './person-location-relation/person-location-relation-control';
import PersonPersonConnectionControl from './person-person-connection/person-person-connection-control';

interface RelationControlProps {
  layout: LayoutType;
}

export default function RelationControl({ layout }: RelationControlProps) {
  return (
    <Stack spacing={4}>
      <PersonPersonConnectionControl layout={layout} />
      <PersonLocationRelationControl layout={layout} />
    </Stack>
  );
}
