/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderConfirmationReferenceDto } from './OrderConfirmationReferenceDto';
import {
    OrderConfirmationReferenceDtoFromJSON,
    OrderConfirmationReferenceDtoFromJSONTyped,
    OrderConfirmationReferenceDtoToJSON,
} from './OrderConfirmationReferenceDto';
import type { PaymentTransactionStatusDto } from './PaymentTransactionStatusDto';
import {
    PaymentTransactionStatusDtoFromJSON,
    PaymentTransactionStatusDtoFromJSONTyped,
    PaymentTransactionStatusDtoToJSON,
} from './PaymentTransactionStatusDto';
import type { PaymentTypeDto } from './PaymentTypeDto';
import {
    PaymentTypeDtoFromJSON,
    PaymentTypeDtoFromJSONTyped,
    PaymentTypeDtoToJSON,
} from './PaymentTypeDto';

/**
 * Payment or return as list item
 * @export
 * @interface PaymentListItemDto
 */
export interface PaymentListItemDto {
    /**
     * Unique Identifier of a payment
     * @type {string}
     * @memberof PaymentListItemDto
     */
    id: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PaymentListItemDto
     */
    paymentTransactionId: string;
    /**
     * 
     * @type {OrderConfirmationReferenceDto}
     * @memberof PaymentListItemDto
     */
    orderConfirmation: OrderConfirmationReferenceDto;
    /**
     * 
     * @type {number}
     * @memberof PaymentListItemDto
     */
    chargetotal: number;
    /**
     * 
     * @type {PaymentTypeDto}
     * @memberof PaymentListItemDto
     */
    paymentType: PaymentTypeDto;
    /**
     * 
     * @type {Date}
     * @memberof PaymentListItemDto
     */
    txndatetime: Date;
    /**
     * 
     * @type {PaymentTransactionStatusDto}
     * @memberof PaymentListItemDto
     */
    status: PaymentTransactionStatusDto;
    /**
     * 
     * @type {string}
     * @memberof PaymentListItemDto
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentListItemDto
     */
    ipgTransactionId?: string;
}

/**
 * Check if a given object implements the PaymentListItemDto interface.
 */
export function instanceOfPaymentListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('paymentTransactionId' in value)) return false;
    if (!('orderConfirmation' in value)) return false;
    if (!('chargetotal' in value)) return false;
    if (!('paymentType' in value)) return false;
    if (!('txndatetime' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function PaymentListItemDtoFromJSON(json: any): PaymentListItemDto {
    return PaymentListItemDtoFromJSONTyped(json, false);
}

export function PaymentListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'paymentTransactionId': json['paymentTransactionId'],
        'orderConfirmation': OrderConfirmationReferenceDtoFromJSON(json['orderConfirmation']),
        'chargetotal': json['chargetotal'],
        'paymentType': PaymentTypeDtoFromJSON(json['paymentType']),
        'txndatetime': (new Date(json['txndatetime'])),
        'status': PaymentTransactionStatusDtoFromJSON(json['status']),
        'paymentMethod': json['paymentMethod'] == null ? undefined : json['paymentMethod'],
        'ipgTransactionId': json['ipgTransactionId'] == null ? undefined : json['ipgTransactionId'],
    };
}

export function PaymentListItemDtoToJSON(value?: PaymentListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'paymentTransactionId': value['paymentTransactionId'],
        'orderConfirmation': OrderConfirmationReferenceDtoToJSON(value['orderConfirmation']),
        'chargetotal': value['chargetotal'],
        'paymentType': PaymentTypeDtoToJSON(value['paymentType']),
        'txndatetime': ((value['txndatetime']).toISOString()),
        'status': PaymentTransactionStatusDtoToJSON(value['status']),
        'paymentMethod': value['paymentMethod'],
        'ipgTransactionId': value['ipgTransactionId'],
    };
}

