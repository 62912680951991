import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchOffer } from '../offer-queries';

export default function OfferBreadcrumb() {
  const { offerId } = useParams<{ offerId: string }>();
  invariant(offerId, 'Empty offerId');
  const offer = useFetcher(fetchOffer, { id: offerId });

  return <>{offer.offerNumber}</>;
}
