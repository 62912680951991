import { forwardRef, HTMLChakraProps, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { ProductDto, ProductListItemDto, ProductStatusDto } from '../../../api';
import productApi from '../../../data-access/product-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import usePermission from '../../permission/use-permission';
import ProductStatusToggleDialog from './product-status-toggel-dialog';

interface ProductStatusToggleButtonProps extends HTMLChakraProps<'button'> {
  product: ProductDto | ProductListItemDto;
  canBeArchived: boolean;
  belongsToSections: string[];

  onSuccess?(): void;
}

function ProductStatusToggleButton(
  { children, product, canBeArchived, onSuccess, belongsToSections, ...props }: ProductStatusToggleButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(['common', 'product']);
  const { hasPermission, hasPermissionFromSection } = usePermission();

  const shouldActivate = product.status === ProductStatusDto.ARCHIVED;

  const canActivate =
    hasPermission('PRODUCT:CAN_ACTIVATE') ||
    belongsToSections.some((sectionId) => hasPermissionFromSection(sectionId, 'PRODUCT:CAN_ACTIVATE_OWN'));

  const canArchive =
    hasPermission('PRODUCT:CAN_ARCHIVE') ||
    belongsToSections.some((sectionId) => hasPermissionFromSection(sectionId, 'PRODUCT:CAN_ARCHIVE_OWN'));

  const showSuccessToast = useToast({
    id: 'product-status-toggle-success-toast',
    status: 'success',
  });

  const handleToggle = async () => {
    invariant(product.id != null, 'Missing product id');

    if (shouldActivate) {
      await productApi.activateProduct({ id: product.id });
    } else {
      await productApi.archiveProduct({ id: product.id });
    }

    showSuccessToast({
      title: shouldActivate ? t('product:toast.activate.header') : t('product:toast.archive.header'),
      description: shouldActivate
        ? t('product:toast.activate.message', { name: product.title })
        : t('product:toast.archive.message', { name: product.title }),
    });
    onSuccess?.();

    onClose();
  };

  return (
    <>
      {shouldActivate ? (
        <DisableableButton
          ref={ref}
          {...props}
          aria-label={t('product:action.reactivate')}
          onClick={onOpen}
          isDisabled={!canActivate}
          disableReason={t('common:validation_error.incomplete_permission')}
        >
          {children}
        </DisableableButton>
      ) : (
        <DisableableButton
          ref={ref}
          {...props}
          aria-label={t('product:action.archive')}
          onClick={onOpen}
          isDisabled={!canArchive || !canBeArchived}
          disableReason={
            !canArchive ? t('common:validation_error.incomplete_permission') : t('product:archiveForbiddenMessage')
          }
        >
          {children}
        </DisableableButton>
      )}
      <ProductStatusToggleDialog
        product={product}
        shouldActivate={shouldActivate}
        isOpen={isOpen}
        onClose={onClose}
        onToggle={handleToggle}
      />
    </>
  );
}

export default forwardRef<ProductStatusToggleButtonProps, 'button'>(ProductStatusToggleButton);
