export const RichTextOptions = {
  HEADLINE: 'HEADLINE',
  SUB_HEADLINE: 'SUB_HEADLINE',
  PARAGRAPH: 'PARAGRAPH',
  CITATION: 'CITATION',
  BOLD: 'BOLD',
  ITALIC: 'ITALIC',
  SMALL: 'SMALL',
  HYPERLINK: 'HYPERLINK',
  HYPERLINK_BUTTON: 'HYPERLINK_BUTTON',
  UNORDERED_LIST: 'UNORDERED_LIST',
  ORDERED_LIST: 'ORDERED_LIST',
  ALIGN_LEFT: 'ALIGN_LEFT',
  CENTER: 'CENTER',
  ALIGN_RIGHT: 'ALIGN_RIGHT',
  JUSTIFY: 'JUSTIFY',
} as const;

export type RichTextOptions = (typeof RichTextOptions)[keyof typeof RichTextOptions];
