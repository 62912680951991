export interface Formatter {
  line(string?: string): void;
  indent(callbackOrString: string | (() => void)): void;
  format(): string;
}

export default function createFormatter(): Formatter {
  const lines: string[] = [];
  let indentations = 0;

  return {
    line(string?: string) {
      const subLines = string?.split('\n') ?? [''];

      lines.push(...subLines.map((part) => `${'  '.repeat(indentations)}${part}`));
    },
    indent(callbackOrString: string | (() => void)) {
      indentations++;

      if (typeof callbackOrString === 'string') {
        this.line(callbackOrString);
      } else {
        callbackOrString();
      }

      indentations--;
    },
    format() {
      return lines.join('\n');
    },
  };
}
