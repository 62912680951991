/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilmPermissionDto
 */
export interface FilmPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof FilmPermissionDto
     */
    name: FilmPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof FilmPermissionDto
     */
    action: FilmPermissionDtoActionEnum;
}


/**
 * @export
 */
export const FilmPermissionDtoNameEnum = {
    FILM: 'FILM'
} as const;
export type FilmPermissionDtoNameEnum = typeof FilmPermissionDtoNameEnum[keyof typeof FilmPermissionDtoNameEnum];

/**
 * @export
 */
export const FilmPermissionDtoActionEnum = {
    LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    DETAILS: 'CAN_SEE_DETAILS'
} as const;
export type FilmPermissionDtoActionEnum = typeof FilmPermissionDtoActionEnum[keyof typeof FilmPermissionDtoActionEnum];


/**
 * Check if a given object implements the FilmPermissionDto interface.
 */
export function instanceOfFilmPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function FilmPermissionDtoFromJSON(json: any): FilmPermissionDto {
    return FilmPermissionDtoFromJSONTyped(json, false);
}

export function FilmPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilmPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function FilmPermissionDtoToJSON(value?: FilmPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

