/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { StandCompanyRelationStatusDto } from './StandCompanyRelationStatusDto';
import {
    StandCompanyRelationStatusDtoFromJSON,
    StandCompanyRelationStatusDtoFromJSONTyped,
    StandCompanyRelationStatusDtoToJSON,
} from './StandCompanyRelationStatusDto';
import type { StandCompanyRelationTypeDto } from './StandCompanyRelationTypeDto';
import {
    StandCompanyRelationTypeDtoFromJSON,
    StandCompanyRelationTypeDtoFromJSONTyped,
    StandCompanyRelationTypeDtoToJSON,
} from './StandCompanyRelationTypeDto';
import type { StandReferenceDto } from './StandReferenceDto';
import {
    StandReferenceDtoFromJSON,
    StandReferenceDtoFromJSONTyped,
    StandReferenceDtoToJSON,
} from './StandReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * The relation between a stand and a company.
 * @export
 * @interface StandCompanyRelationDto
 */
export interface StandCompanyRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StandCompanyRelationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof StandCompanyRelationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {StandReferenceDto}
     * @memberof StandCompanyRelationDto
     */
    stand: StandReferenceDto;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof StandCompanyRelationDto
     */
    company: CompanyReferenceDto;
    /**
     * 
     * @type {StandCompanyRelationTypeDto}
     * @memberof StandCompanyRelationDto
     */
    relationType: StandCompanyRelationTypeDto;
    /**
     * 
     * @type {StandCompanyRelationStatusDto}
     * @memberof StandCompanyRelationDto
     */
    relationStatus: StandCompanyRelationStatusDto;
    /**
     * 
     * @type {boolean}
     * @memberof StandCompanyRelationDto
     */
    sharingFees: boolean;
}

/**
 * Check if a given object implements the StandCompanyRelationDto interface.
 */
export function instanceOfStandCompanyRelationDto(value: object): boolean {
    if (!('stand' in value)) return false;
    if (!('company' in value)) return false;
    if (!('relationType' in value)) return false;
    if (!('relationStatus' in value)) return false;
    if (!('sharingFees' in value)) return false;
    return true;
}

export function StandCompanyRelationDtoFromJSON(json: any): StandCompanyRelationDto {
    return StandCompanyRelationDtoFromJSONTyped(json, false);
}

export function StandCompanyRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandCompanyRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'stand': StandReferenceDtoFromJSON(json['stand']),
        'company': CompanyReferenceDtoFromJSON(json['company']),
        'relationType': StandCompanyRelationTypeDtoFromJSON(json['relationType']),
        'relationStatus': StandCompanyRelationStatusDtoFromJSON(json['relationStatus']),
        'sharingFees': json['sharingFees'],
    };
}

export function StandCompanyRelationDtoToJSON(value?: StandCompanyRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'stand': StandReferenceDtoToJSON(value['stand']),
        'company': CompanyReferenceDtoToJSON(value['company']),
        'relationType': StandCompanyRelationTypeDtoToJSON(value['relationType']),
        'relationStatus': StandCompanyRelationStatusDtoToJSON(value['relationStatus']),
        'sharingFees': value['sharingFees'],
    };
}

