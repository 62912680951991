/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { LandingPageDesignStatusDto } from './LandingPageDesignStatusDto';
import {
    LandingPageDesignStatusDtoFromJSON,
    LandingPageDesignStatusDtoFromJSONTyped,
    LandingPageDesignStatusDtoToJSON,
} from './LandingPageDesignStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface LandingPageDesignListItemDto
 */
export interface LandingPageDesignListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LandingPageDesignListItemDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof LandingPageDesignListItemDto
     */
    version: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof LandingPageDesignListItemDto
     */
    brand: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof LandingPageDesignListItemDto
     */
    edition?: EditionReferenceDto;
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignListItemDto
     */
    backgroundHeader: string;
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignListItemDto
     */
    backgroundFooter: string;
    /**
     * 
     * @type {LandingPageDesignStatusDto}
     * @memberof LandingPageDesignListItemDto
     */
    status: LandingPageDesignStatusDto;
}

/**
 * Check if a given object implements the LandingPageDesignListItemDto interface.
 */
export function instanceOfLandingPageDesignListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('version' in value)) return false;
    if (!('brand' in value)) return false;
    if (!('backgroundHeader' in value)) return false;
    if (!('backgroundFooter' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function LandingPageDesignListItemDtoFromJSON(json: any): LandingPageDesignListItemDto {
    return LandingPageDesignListItemDtoFromJSONTyped(json, false);
}

export function LandingPageDesignListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageDesignListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': VersionDtoFromJSON(json['version']),
        'brand': json['brand'],
        'edition': json['edition'] == null ? undefined : EditionReferenceDtoFromJSON(json['edition']),
        'backgroundHeader': json['backgroundHeader'],
        'backgroundFooter': json['backgroundFooter'],
        'status': LandingPageDesignStatusDtoFromJSON(json['status']),
    };
}

export function LandingPageDesignListItemDtoToJSON(value?: LandingPageDesignListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'brand': value['brand'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'backgroundHeader': value['backgroundHeader'],
        'backgroundFooter': value['backgroundFooter'],
        'status': LandingPageDesignStatusDtoToJSON(value['status']),
    };
}

