import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DateRangeControl from '../../../ui/form/date-input-control/date-range-control';

interface ValidityPeriodControlProps<T extends FieldValues> {
  initialFocusRef?: React.RefObject<HTMLInputElement>;
  path?: FieldPath<T>;
}

export default function ValidityPeriodControl<T extends FieldValues>({
  initialFocusRef,
  path,
}: ValidityPeriodControlProps<T>) {
  const { t } = useTranslation('code');
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);

  return (
    <DateRangeControl
      path={prefixWithPath('validityPeriod')}
      showTimeSelect
      startLabel={t('start')}
      endLabel={t('end')}
      initialFocusRef={initialFocusRef}
    />
  );
}
