import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import invariant from 'tiny-invariant';
import { CompanyReferenceDto } from '../../../../api';
import companyApi from '../../../../data-access/company-api';
import ValueAsyncSelectControl, {
  ValueAsyncSelectControlProps,
} from '../../../../ui/form/select-control/value-async-select-control';

export interface CompanySelectControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<
    ValueAsyncSelectControlProps<CompanyReferenceDto, TFieldValues, TName>,
    'loadOptions' | 'renderLabel' | 'optionIdentifier' | 'defaultOptions'
  > {
  filter?: string[];
}

export default function CompanySelectControl<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ filter = [], isRequired, name, ...props }: CompanySelectControlProps<TFieldValues, TName>) {
  return (
    <ValueAsyncSelectControl<CompanyReferenceDto, TFieldValues, TName>
      {...props}
      name={name}
      loadOptions={async (q: string, size: number) => {
        const { content } = await companyApi.searchActiveCompanyReferences({
          pageable: { size },
          filter,
          q,
        });
        return content.map(({ name, id, companySections }) => {
          invariant(id != null);
          return {
            id,
            name,
            companySections,
          };
        });
      }}
      defaultOptions={false}
      isRequired={isRequired}
      renderLabel={(company) => company.name}
      optionIdentifier={(company) => company.id}
    />
  );
}
