/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CostBearerLabelDto } from './CostBearerLabelDto';
import {
    CostBearerLabelDtoFromJSON,
    CostBearerLabelDtoFromJSONTyped,
    CostBearerLabelDtoToJSON,
} from './CostBearerLabelDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { GroupStatusDto } from './GroupStatusDto';
import {
    GroupStatusDtoFromJSON,
    GroupStatusDtoFromJSONTyped,
    GroupStatusDtoToJSON,
} from './GroupStatusDto';
import type { LandingPageReferenceDto } from './LandingPageReferenceDto';
import {
    LandingPageReferenceDtoFromJSON,
    LandingPageReferenceDtoFromJSONTyped,
    LandingPageReferenceDtoToJSON,
} from './LandingPageReferenceDto';
import type { ProductReferenceDto } from './ProductReferenceDto';
import {
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoFromJSONTyped,
    ProductReferenceDtoToJSON,
} from './ProductReferenceDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';

/**
 * 
 * @export
 * @interface GroupListItemDto
 */
export interface GroupListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GroupListItemDto
     */
    name: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof GroupListItemDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof GroupListItemDto
     */
    owners: Array<SectionReferenceDto>;
    /**
     * 
     * @type {number}
     * @memberof GroupListItemDto
     */
    capacity?: number;
    /**
     * 
     * @type {LandingPageReferenceDto}
     * @memberof GroupListItemDto
     */
    landingPage?: LandingPageReferenceDto;
    /**
     * 
     * @type {Array<ProductReferenceDto>}
     * @memberof GroupListItemDto
     */
    permittedProducts: Array<ProductReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof GroupListItemDto
     */
    costBearer?: string;
    /**
     * 
     * @type {CostBearerLabelDto}
     * @memberof GroupListItemDto
     */
    costBearerLabel?: CostBearerLabelDto;
    /**
     * 
     * @type {GroupStatusDto}
     * @memberof GroupListItemDto
     */
    status: GroupStatusDto;
}

/**
 * Check if a given object implements the GroupListItemDto interface.
 */
export function instanceOfGroupListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('owners' in value)) return false;
    if (!('permittedProducts' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function GroupListItemDtoFromJSON(json: any): GroupListItemDto {
    return GroupListItemDtoFromJSONTyped(json, false);
}

export function GroupListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'owners': ((json['owners'] as Array<any>).map(SectionReferenceDtoFromJSON)),
        'capacity': json['capacity'] == null ? undefined : json['capacity'],
        'landingPage': json['landingPage'] == null ? undefined : LandingPageReferenceDtoFromJSON(json['landingPage']),
        'permittedProducts': ((json['permittedProducts'] as Array<any>).map(ProductReferenceDtoFromJSON)),
        'costBearer': json['costBearer'] == null ? undefined : json['costBearer'],
        'costBearerLabel': json['costBearerLabel'] == null ? undefined : CostBearerLabelDtoFromJSON(json['costBearerLabel']),
        'status': GroupStatusDtoFromJSON(json['status']),
    };
}

export function GroupListItemDtoToJSON(value?: GroupListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'owners': ((value['owners'] as Array<any>).map(SectionReferenceDtoToJSON)),
        'capacity': value['capacity'],
        'landingPage': LandingPageReferenceDtoToJSON(value['landingPage']),
        'permittedProducts': ((value['permittedProducts'] as Array<any>).map(ProductReferenceDtoToJSON)),
        'costBearer': value['costBearer'],
        'costBearerLabel': CostBearerLabelDtoToJSON(value['costBearerLabel']),
        'status': GroupStatusDtoToJSON(value['status']),
    };
}

