import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { filmIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';
import { mostRelevantTitle } from './film-utils';

export default function FilmSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'FILM', `${result.resultType} is no instance of FilmSearchResult`);

  const film = result.result;
  const { t } = useTranslation(['film']);

  return (
    <SearchResult
      icon={filmIcon}
      title={mostRelevantTitle(film)!}
      link={`/films/${film.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="FILM:CAN_SEE_DETAILS"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('film:ffId'),
            element: String(film.ffId),
          },
          {
            label: t('film:edition'),
            element: film.edition.name,
          },
          {
            label: t('film:originalTitle'),
            element: film.originalTitle,
          },
          {
            label: t('film:germanTitle'),
            element: film.germanTitle,
          },
          {
            label: t('film:englishTitle'),
            element: film.englishTitle,
          },
          {
            label: t('film:status'),
            element: t(`film:statusOptions.${film.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}
