import { Box, Grid, GridItem, IconButton, Tooltip } from '@chakra-ui/react';
import { faPencil, faUserCrown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { concat, isEmpty } from 'lodash-es';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { GroupDto, GroupPersonRelationDto, GroupStatusDto, RelationTypeDto } from '../../../../../api';
import groupPersonApi from '../../../../../data-access/group-person-api';
import { DataTableColumn, DataTableState, useDataTableState } from '../../../../../ui/data-table';
import DataTable from '../../../../../ui/data-table/data-table';
import EnumFilter from '../../../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../../../ui/data-table/use-request-params';
import Optional from '../../../../../ui/optional/optional';
import fallbackMiddleware from '../../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../../util/swr/use-fetcher';
import usePermission from '../../../../permission/use-permission';
import PersonReference from '../../../../person/person-reference/person-reference';
import DeleteRelationsButton from '../../../common/delete-relations-button';
import { fetchGroupPersonRelations, groupPersonFetcher } from '../group-person-queries';
import PersonInGroupEditorButton from '../person-in-group-editor/person-in-group-editor-button';

function useGroupPersonRelations(state: DataTableState, groupId: string) {
  const requestParams = useRequestParams(state, [
    { property: 'person.surname', direction: 'ASC' },
    { property: 'person.firstName', direction: 'ASC' },
  ]);

  return useFetcher(
    fetchGroupPersonRelations,
    {
      ...requestParams,
      filter: concat(requestParams.filter as string[], [`group.id,eq,${groupId}`]),
    },
    { use: [fallbackMiddleware] },
  );
}

export default function GroupPersonRelationDataTable({ group }: { group: GroupDto }) {
  const [state, setState] = useDataTableState();
  const { t } = useTranslation(['common', 'person', 'relations', 'group']);
  const page = useGroupPersonRelations(state, group.id!);
  const { hasPermission } = usePermission();

  const columns: DataTableColumn<GroupPersonRelationDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<GroupPersonRelationDto>[] = [
      {
        key: 'person.surname',
        name: t('person:surname'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        sticky: true,
        renderCell: (relation) => (
          <PersonReference
            personReference={{
              id: relation.person.id!,
              firstName: relation.person.firstName,
              surname: relation.person.surname,
            }}
            usePortalForCard
            surnameOnly
            hidePersonKey
            hideIcon
          />
        ),
        filter: (
          <StringFilter
            label={t('person:surname')}
            loadOptions={async (value: string) => {
              const page = await groupPersonApi.searchGroupPersonRelations({
                pageable: { size: 10 },
                filter: [`group.id,eq,${group.id}`, `person.surname,contain,${value}`],
              });

              return page.content.map((relation) => relation.person.surname);
            }}
          />
        ),
      },
      {
        key: 'person.firstName',
        name: t('person:firstName'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        renderCell: (relation) => (
          <PersonReference
            personReference={{
              id: relation.person.id!,
              firstName: relation.person.firstName,
              surname: relation.person.surname,
            }}
            usePortalForCard
            firstNameOnly
            hidePersonKey
            hideIcon
          />
        ),
        filter: (
          <StringFilter
            label={t('person:firstName')}
            loadOptions={async (value: string) => {
              const page = await groupPersonApi.searchGroupPersonRelations({
                pageable: { size: 10 },
                filter: [`group.id,eq,${group.id}`, `person.firstName,contain,${value}`],
              });

              return page.content.map((relation) => relation.person.firstName);
            }}
          />
        ),
      },
      {
        key: `isAdmin`,
        renderCell: (relation) => {
          return (
            <Optional>
              {group.externalGroupAdmins.some((admin) => admin.id === relation.person.id) && (
                <Tooltip label={t(`group:internalGroupAdmins.label`)}>
                  <Box color="text.muted">
                    <FontAwesomeIcon icon={faUserCrown} />
                  </Box>
                </Tooltip>
              )}
            </Optional>
          );
        },
        cellProps: {
          textAlign: 'center',
        },
      },
      {
        key: 'person.jobTitle',
        name: t('person:occupations.jobTitle'),
        cellProps: {},
        isSortable: true,
        renderCell: (relation) => (
          <Optional isEmpty={relation.person.jobTitle == null}>{relation.person.jobTitle}</Optional>
        ),
        filter: <StringFilter label={t('person:occupations.jobTitle')} />,
      },
      {
        key: 'person.company',
        name: t('person:occupations.company'),
        cellProps: {},
        isSortable: true,
        renderCell: (relation) => (
          <Optional isEmpty={relation.person.company == null}>{relation.person.company?.name}</Optional>
        ),
        filter: <StringFilter label={t('person:occupations.company')} />,
      },
      {
        key: 'relationType',
        name: t('group:connections.relationType'),
        cellProps: {},
        isSortable: true,
        renderCell: (relation) => t(`group:connections.relationTypeOptions.${relation.relationType}`),
        filter: (
          <EnumFilter
            label={t('group:connections.relationType')}
            options={[RelationTypeDto.MAIN_GROUP, RelationTypeDto.SECONDARY_GROUP]}
            renderOptionLabel={(key) => t(`group:connections.relationTypeOptions.${key}`)}
          />
        ),
      },
    ];
    return columns;
  }, [t, group]);

  const actions = useCallback(
    (relation: GroupPersonRelationDto) => {
      return (
        hasPermission('GROUP:CAN_EDIT') && (
          <Tooltip label={t('group:connections.edit')}>
            <PersonInGroupEditorButton
              as={IconButton}
              group={group}
              groupPersonRelation={relation}
              size="sm"
              variant="ghost"
              icon={<FontAwesomeIcon icon={faPencil} />}
              isDisabled={group.status !== GroupStatusDto.ACTIVE}
              aria-label={t('group:connections.edit')}
            />
          </Tooltip>
        )
      );
    },
    [t, group, hasPermission],
  );

  const rowKey = React.useCallback((groupPerson: GroupPersonRelationDto) => {
    invariant(groupPerson.id != null, 'Missing group person relation id');

    return groupPerson.id;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" height="full" gridRowGap={4}>
      <GridItem minHeight={0}>
        <DataTable
          page={page == null ? { content: [] } : page}
          state={state}
          columns={columns}
          actions={actions}
          rowKey={rowKey}
          selection={hasPermission('GROUP:CAN_LINK_GROUP_WITH_PERSON') ? { keySelector: rowKey } : undefined}
          onStateChange={setState}
          isPageable
        />
      </GridItem>
      <GridItem display="flex" flexDir="row" justifyContent="end">
        {hasPermission('GROUP:CAN_LINK_GROUP_WITH_PERSON') && (
          <DeleteRelationsButton
            selection={state.selection}
            isDisabled={isEmpty(state.selection)}
            deleteRelations={(ids) => groupPersonApi.deleteRelationsByIds({ ids: ids })}
            mutate={() => groupPersonFetcher.mutate()}
            resetSelection={() => {
              setState({ ...state, selection: [] });
            }}
          />
        )}
      </GridItem>
    </Grid>
  );
}
