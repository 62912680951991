/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderPermissionDto
 */
export interface OrderPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof OrderPermissionDto
     */
    name: OrderPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderPermissionDto
     */
    action: OrderPermissionDtoActionEnum;
}


/**
 * @export
 */
export const OrderPermissionDtoNameEnum = {
    ORDER: 'ORDER'
} as const;
export type OrderPermissionDtoNameEnum = typeof OrderPermissionDtoNameEnum[keyof typeof OrderPermissionDtoNameEnum];

/**
 * @export
 */
export const OrderPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    FORWARD: 'CAN_FORWARD',
    EDIT: 'CAN_EDIT',
    BLOCK_UNBLOCK: 'CAN_BLOCK_UNBLOCK',
    VERIFY: 'CAN_VERIFY',
    CREATE: 'CAN_CREATE'
} as const;
export type OrderPermissionDtoActionEnum = typeof OrderPermissionDtoActionEnum[keyof typeof OrderPermissionDtoActionEnum];


/**
 * Check if a given object implements the OrderPermissionDto interface.
 */
export function instanceOfOrderPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function OrderPermissionDtoFromJSON(json: any): OrderPermissionDto {
    return OrderPermissionDtoFromJSONTyped(json, false);
}

export function OrderPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function OrderPermissionDtoToJSON(value?: OrderPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

