import React from 'react';
import { useTranslation } from 'react-i18next';
import { GroupFilmListItemDto } from '../../../api';
import DataTable, { DataTableColumn, DataTableState, useDataTableState } from '../../../ui/data-table';
import { DataTableFilter, DataTableSort } from '../../../ui/data-table/data-table-state';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';
import useFetcher from '../../../util/swr/use-fetcher';
import { searchGroupFilmRelationListItems } from '../group-queries';
import GroupReference from '../group-reference/group-reference';

function useRelatedGroups(
  state: DataTableState,
  fallbackSort: DataTableSort[] | undefined,
  forcedFilter: DataTableFilter[],
) {
  const requestParams = useRequestParams(state, fallbackSort, forcedFilter);

  return useFetcher(searchGroupFilmRelationListItems, requestParams, { use: [fallbackMiddleware] });
}

interface GroupFilmDataTableProps {
  fallbackSort?: DataTableSort[];
  forcedFilter: DataTableFilter[];
}

export default function FilmGroupDataTable({ fallbackSort, forcedFilter }: GroupFilmDataTableProps) {
  const { t } = useTranslation(['group']);
  const [state, setState] = useDataTableState({ sort: [{ property: 'group.name', direction: 'ASC' }] });

  const page = useRelatedGroups(state, fallbackSort, forcedFilter);

  const columns: DataTableColumn<GroupFilmListItemDto>[] = React.useMemo(() => {
    return [
      {
        key: 'group.name',
        name: t('group:group'),
        sticky: true,
        isSortable: true,
        filter: <StringFilter label={t('group:group')} />,
        renderCell: ({ group }) => <GroupReference groupReference={group} />,
      },
    ] satisfies DataTableColumn<GroupFilmListItemDto>[];
  }, [t]);

  return (
    <DataTable
      page={page}
      state={state}
      columns={columns}
      rowKey={({ id }) => id}
      onStateChange={setState}
      isPageable
    />
  );
}
