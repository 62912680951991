/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManualPaymentDto
 */
export interface ManualPaymentDto {
    /**
     * 
     * @type {Date}
     * @memberof ManualPaymentDto
     */
    receiptOfPayment: Date;
    /**
     * 
     * @type {string}
     * @memberof ManualPaymentDto
     */
    method: string;
    /**
     * 
     * @type {string}
     * @memberof ManualPaymentDto
     */
    reason: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManualPaymentDto
     */
    generateInvoice: boolean;
}

/**
 * Check if a given object implements the ManualPaymentDto interface.
 */
export function instanceOfManualPaymentDto(value: object): boolean {
    if (!('receiptOfPayment' in value)) return false;
    if (!('method' in value)) return false;
    if (!('reason' in value)) return false;
    if (!('generateInvoice' in value)) return false;
    return true;
}

export function ManualPaymentDtoFromJSON(json: any): ManualPaymentDto {
    return ManualPaymentDtoFromJSONTyped(json, false);
}

export function ManualPaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualPaymentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'receiptOfPayment': (new Date(json['receiptOfPayment'])),
        'method': json['method'],
        'reason': json['reason'],
        'generateInvoice': json['generateInvoice'],
    };
}

export function ManualPaymentDtoToJSON(value?: ManualPaymentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'receiptOfPayment': ((value['receiptOfPayment']).toISOString().substring(0,10)),
        'method': value['method'],
        'reason': value['reason'],
        'generateInvoice': value['generateInvoice'],
    };
}

