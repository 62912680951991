/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TagCompanyRelationDto } from './TagCompanyRelationDto';
import {
    instanceOfTagCompanyRelationDto,
    TagCompanyRelationDtoFromJSON,
    TagCompanyRelationDtoFromJSONTyped,
    TagCompanyRelationDtoToJSON,
} from './TagCompanyRelationDto';
import type { TagEventRelationDto } from './TagEventRelationDto';
import {
    instanceOfTagEventRelationDto,
    TagEventRelationDtoFromJSON,
    TagEventRelationDtoFromJSONTyped,
    TagEventRelationDtoToJSON,
} from './TagEventRelationDto';
import type { TagFilmRelationDto } from './TagFilmRelationDto';
import {
    instanceOfTagFilmRelationDto,
    TagFilmRelationDtoFromJSON,
    TagFilmRelationDtoFromJSONTyped,
    TagFilmRelationDtoToJSON,
} from './TagFilmRelationDto';
import type { TagFormatRelationDto } from './TagFormatRelationDto';
import {
    instanceOfTagFormatRelationDto,
    TagFormatRelationDtoFromJSON,
    TagFormatRelationDtoFromJSONTyped,
    TagFormatRelationDtoToJSON,
} from './TagFormatRelationDto';
import type { TagLocationRelationDto } from './TagLocationRelationDto';
import {
    instanceOfTagLocationRelationDto,
    TagLocationRelationDtoFromJSON,
    TagLocationRelationDtoFromJSONTyped,
    TagLocationRelationDtoToJSON,
} from './TagLocationRelationDto';
import type { TagPersonRelationDto } from './TagPersonRelationDto';
import {
    instanceOfTagPersonRelationDto,
    TagPersonRelationDtoFromJSON,
    TagPersonRelationDtoFromJSONTyped,
    TagPersonRelationDtoToJSON,
} from './TagPersonRelationDto';
import type { TagProductRelationDto } from './TagProductRelationDto';
import {
    instanceOfTagProductRelationDto,
    TagProductRelationDtoFromJSON,
    TagProductRelationDtoFromJSONTyped,
    TagProductRelationDtoToJSON,
} from './TagProductRelationDto';
import type { TagStandRelationDto } from './TagStandRelationDto';
import {
    instanceOfTagStandRelationDto,
    TagStandRelationDtoFromJSON,
    TagStandRelationDtoFromJSONTyped,
    TagStandRelationDtoToJSON,
} from './TagStandRelationDto';

/**
 * @type TagRelationDto
 * A relation between a tag and single business object.
 * @export
 */
export type TagRelationDto = { relationType: 'COMPANY' } & TagCompanyRelationDto | { relationType: 'EVENT' } & TagEventRelationDto | { relationType: 'FILM' } & TagFilmRelationDto | { relationType: 'FORMAT' } & TagFormatRelationDto | { relationType: 'LOCATION' } & TagLocationRelationDto | { relationType: 'PERSON' } & TagPersonRelationDto | { relationType: 'PRODUCT' } & TagProductRelationDto | { relationType: 'STAND' } & TagStandRelationDto;

export function TagRelationDtoFromJSON(json: any): TagRelationDto {
    return TagRelationDtoFromJSONTyped(json, false);
}

export function TagRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagRelationDto {
    if (json == null) {
        return json;
    }
    switch (json['relationType']) {
        case 'COMPANY':
            return {...TagCompanyRelationDtoFromJSONTyped(json, true), relationType: 'COMPANY'};
        case 'EVENT':
            return {...TagEventRelationDtoFromJSONTyped(json, true), relationType: 'EVENT'};
        case 'FILM':
            return {...TagFilmRelationDtoFromJSONTyped(json, true), relationType: 'FILM'};
        case 'FORMAT':
            return {...TagFormatRelationDtoFromJSONTyped(json, true), relationType: 'FORMAT'};
        case 'LOCATION':
            return {...TagLocationRelationDtoFromJSONTyped(json, true), relationType: 'LOCATION'};
        case 'PERSON':
            return {...TagPersonRelationDtoFromJSONTyped(json, true), relationType: 'PERSON'};
        case 'PRODUCT':
            return {...TagProductRelationDtoFromJSONTyped(json, true), relationType: 'PRODUCT'};
        case 'STAND':
            return {...TagStandRelationDtoFromJSONTyped(json, true), relationType: 'STAND'};
        default:
            throw new Error(`No variant of TagRelationDto exists with 'relationType=${json['relationType']}'`);
    }
}

export function TagRelationDtoToJSON(value?: TagRelationDto | null): any {
    if (value == null) {
        return value;
    }
    switch (value['relationType']) {
        case 'COMPANY':
            return TagCompanyRelationDtoToJSON(value);
        case 'EVENT':
            return TagEventRelationDtoToJSON(value);
        case 'FILM':
            return TagFilmRelationDtoToJSON(value);
        case 'FORMAT':
            return TagFormatRelationDtoToJSON(value);
        case 'LOCATION':
            return TagLocationRelationDtoToJSON(value);
        case 'PERSON':
            return TagPersonRelationDtoToJSON(value);
        case 'PRODUCT':
            return TagProductRelationDtoToJSON(value);
        case 'STAND':
            return TagStandRelationDtoToJSON(value);
        default:
            throw new Error(`No variant of TagRelationDto exists with 'relationType=${value['relationType']}'`);
    }

}

