/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryStatusDto } from './CountryStatusDto';
import {
    CountryStatusDtoFromJSON,
    CountryStatusDtoFromJSONTyped,
    CountryStatusDtoToJSON,
} from './CountryStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A country used for the Berlinale festival.
 * @export
 * @interface CountryDto
 */
export interface CountryDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CountryDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof CountryDto
     */
    version?: VersionDto;
    /**
     * The name of the country.
     * @type {string}
     * @memberof CountryDto
     */
    name: string;
    /**
     * The publication name of the country.
     * @type {string}
     * @memberof CountryDto
     */
    nameForPublication?: string;
    /**
     * The english name of the country.
     * @type {string}
     * @memberof CountryDto
     */
    englishName: string;
    /**
     * The english publication name of the country.
     * @type {string}
     * @memberof CountryDto
     */
    englishNameForPublication?: string;
    /**
     * List of synonyms of the country.
     * @type {Array<string>}
     * @memberof CountryDto
     */
    synonyms?: Array<string>;
    /**
     * List of english synonyms of the country.
     * @type {Array<string>}
     * @memberof CountryDto
     */
    englishSynonyms?: Array<string>;
    /**
     * The code of the country.
     * @type {string}
     * @memberof CountryDto
     */
    countryCode?: string;
    /**
     * Is a visa required.
     * @type {boolean}
     * @memberof CountryDto
     */
    visaRequired?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CountryDto
     */
    comment?: string;
    /**
     * 
     * @type {CountryStatusDto}
     * @memberof CountryDto
     */
    status: CountryStatusDto;
}

/**
 * Check if a given object implements the CountryDto interface.
 */
export function instanceOfCountryDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('englishName' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function CountryDtoFromJSON(json: any): CountryDto {
    return CountryDtoFromJSONTyped(json, false);
}

export function CountryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'nameForPublication': json['nameForPublication'] == null ? undefined : json['nameForPublication'],
        'englishName': json['englishName'],
        'englishNameForPublication': json['englishNameForPublication'] == null ? undefined : json['englishNameForPublication'],
        'synonyms': json['synonyms'] == null ? undefined : json['synonyms'],
        'englishSynonyms': json['englishSynonyms'] == null ? undefined : json['englishSynonyms'],
        'countryCode': json['countryCode'] == null ? undefined : json['countryCode'],
        'visaRequired': json['visaRequired'] == null ? undefined : json['visaRequired'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'status': CountryStatusDtoFromJSON(json['status']),
    };
}

export function CountryDtoToJSON(value?: CountryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'nameForPublication': value['nameForPublication'],
        'englishName': value['englishName'],
        'englishNameForPublication': value['englishNameForPublication'],
        'synonyms': value['synonyms'],
        'englishSynonyms': value['englishSynonyms'],
        'countryCode': value['countryCode'],
        'visaRequired': value['visaRequired'],
        'comment': value['comment'],
        'status': CountryStatusDtoToJSON(value['status']),
    };
}

