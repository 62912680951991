/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilmDto } from './FilmDto';
import {
    FilmDtoFromJSON,
    FilmDtoFromJSONTyped,
    FilmDtoToJSON,
} from './FilmDto';

/**
 * 
 * @export
 * @interface FilmSearchResultDto
 */
export interface FilmSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof FilmSearchResultDto
     */
    resultType: FilmSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FilmSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {FilmDto}
     * @memberof FilmSearchResultDto
     */
    result: FilmDto;
}


/**
 * @export
 */
export const FilmSearchResultDtoResultTypeEnum = {
    FILM: 'FILM'
} as const;
export type FilmSearchResultDtoResultTypeEnum = typeof FilmSearchResultDtoResultTypeEnum[keyof typeof FilmSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the FilmSearchResultDto interface.
 */
export function instanceOfFilmSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function FilmSearchResultDtoFromJSON(json: any): FilmSearchResultDto {
    return FilmSearchResultDtoFromJSONTyped(json, false);
}

export function FilmSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilmSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': FilmDtoFromJSON(json['result']),
    };
}

export function FilmSearchResultDtoToJSON(value?: FilmSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': FilmDtoToJSON(value['result']),
    };
}

