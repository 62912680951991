/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportingPermissionDto
 */
export interface ReportingPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof ReportingPermissionDto
     */
    name: ReportingPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportingPermissionDto
     */
    action: ReportingPermissionDtoActionEnum;
}


/**
 * @export
 */
export const ReportingPermissionDtoNameEnum = {
    REPORTING: 'REPORTING'
} as const;
export type ReportingPermissionDtoNameEnum = typeof ReportingPermissionDtoNameEnum[keyof typeof ReportingPermissionDtoNameEnum];

/**
 * @export
 */
export const ReportingPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EXPORT: 'CAN_EXPORT',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE'
} as const;
export type ReportingPermissionDtoActionEnum = typeof ReportingPermissionDtoActionEnum[keyof typeof ReportingPermissionDtoActionEnum];


/**
 * Check if a given object implements the ReportingPermissionDto interface.
 */
export function instanceOfReportingPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function ReportingPermissionDtoFromJSON(json: any): ReportingPermissionDto {
    return ReportingPermissionDtoFromJSONTyped(json, false);
}

export function ReportingPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function ReportingPermissionDtoToJSON(value?: ReportingPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

