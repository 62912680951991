import { get, keys } from 'lodash-es';
import React from 'react';
import { RenderElementProps, RenderLeafProps, useSlate } from 'slate-react';
import invariant from 'tiny-invariant';
import { getMarkRenderers, getRenderer } from './render';

export const Element = (props: RenderElementProps) => {
  const editor = useSlate();
  const render = getRenderer<'block' | 'inline'>(props.element.type, editor);

  invariant(render != null, 'action must be defined for element type, missing action is: ' + props.element.type);

  return render(props);
};

export function Leaf({ leaf, children, attributes }: RenderLeafProps) {
  const editor = useSlate();
  const types = keys(leaf).filter((key) => get(leaf, key) === true);
  const renderers = getMarkRenderers(types, editor);

  return (
    <span
      style={{
        fontSize: 'inherit',
        lineHeight: 'inherit',
        fontFamily: 'inherit',
        color: 'inherit',
      }}
      {...attributes}
    >
      {renderers.reduce((children, render) => render(children), children)}
    </span>
  );
}
