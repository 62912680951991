/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Type of the payment.
 * @export
 */
export const PaymentTypeDto = {
    PAYMENT: 'PAYMENT',
    RETURN: 'RETURN'
} as const;
export type PaymentTypeDto = typeof PaymentTypeDto[keyof typeof PaymentTypeDto];


export function PaymentTypeDtoFromJSON(json: any): PaymentTypeDto {
    return PaymentTypeDtoFromJSONTyped(json, false);
}

export function PaymentTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTypeDto {
    return json as PaymentTypeDto;
}

export function PaymentTypeDtoToJSON(value?: PaymentTypeDto | null): any {
    return value as any;
}

