/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';

/**
 * 
 * @export
 * @interface LiveTicketingSettingsDto
 */
export interface LiveTicketingSettingsDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LiveTicketingSettingsDto
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiveTicketingSettingsDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveTicketingSettingsDto
     */
    started: boolean;
    /**
     * 
     * @type {string}
     * @memberof LiveTicketingSettingsDto
     */
    mode?: string;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof LiveTicketingSettingsDto
     */
    liveTicketingActiveTime?: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {boolean}
     * @memberof LiveTicketingSettingsDto
     */
    checkAccessGroups?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveTicketingSettingsDto
     */
    checkOccupancy?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LiveTicketingSettingsDto
     */
    maximalOccupancy?: number;
}

/**
 * Check if a given object implements the LiveTicketingSettingsDto interface.
 */
export function instanceOfLiveTicketingSettingsDto(value: object): boolean {
    if (!('active' in value)) return false;
    if (!('started' in value)) return false;
    return true;
}

export function LiveTicketingSettingsDtoFromJSON(json: any): LiveTicketingSettingsDto {
    return LiveTicketingSettingsDtoFromJSONTyped(json, false);
}

export function LiveTicketingSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LiveTicketingSettingsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'active': json['active'],
        'started': json['started'],
        'mode': json['mode'] == null ? undefined : json['mode'],
        'liveTicketingActiveTime': json['liveTicketingActiveTime'] == null ? undefined : ClosedDateTimeRangeDtoFromJSON(json['liveTicketingActiveTime']),
        'checkAccessGroups': json['checkAccessGroups'] == null ? undefined : json['checkAccessGroups'],
        'checkOccupancy': json['checkOccupancy'] == null ? undefined : json['checkOccupancy'],
        'maximalOccupancy': json['maximalOccupancy'] == null ? undefined : json['maximalOccupancy'],
    };
}

export function LiveTicketingSettingsDtoToJSON(value?: LiveTicketingSettingsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'active': value['active'],
        'started': value['started'],
        'mode': value['mode'],
        'liveTicketingActiveTime': ClosedDateTimeRangeDtoToJSON(value['liveTicketingActiveTime']),
        'checkAccessGroups': value['checkAccessGroups'],
        'checkOccupancy': value['checkOccupancy'],
        'maximalOccupancy': value['maximalOccupancy'],
    };
}

