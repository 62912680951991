import { Box } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { PaymentListItemDto } from '../../../../api';
import paymentApi from '../../../../data-access/payment-api';
import { DataTableColumn, DataTableLink, DataTableTruncatedText } from '../../../../ui/data-table';
import DateFilter from '../../../../ui/data-table/filter/date-filter';
import EnumFilter from '../../../../ui/data-table/filter/enum-filter';
import NumberFilter from '../../../../ui/data-table/filter/number-filter';
import StringFilter from '../../../../ui/data-table/filter/string-filter';
import Optional from '../../../../ui/optional/optional';
import HasPermission from '../../../permission/has-permission';
import { paymemtTypes, paymentTransactionStatuses } from '../payment-enum-constants';

export default function useOrderConfirmationPaymentTableColumns(linkPath: string = '') {
  const { t } = useTranslation(['common', 'payment']);
  return React.useMemo(() => {
    const columns: DataTableColumn<PaymentListItemDto>[] = [
      {
        key: 'paymentTransactionId',
        sticky: true,
        name: t('payment:paymentTransactionId'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '25%',
        },
        isSortable: true,
        renderCell: (payment) => (
          <HasPermission necessaryPermission="PAYMENT:CAN_SEE_DETAILS" fallback={payment.id}>
            <DataTableTruncatedText>
              <DataTableLink as={RouterLink} to={`${linkPath}${payment.id!}`}>
                {payment.paymentTransactionId}
              </DataTableLink>
            </DataTableTruncatedText>
          </HasPermission>
        ),
        filter: (
          <StringFilter
            label={t('payment:paymentId')}
            loadOptions={async (value: string) => {
              const page = await paymentApi.searchPayments({
                pageable: { size: 10 },
                filter: [`id,contain,${value}`],
              });

              return page.content.map((payment) => payment.paymentTransactionId);
            }}
          />
        ),
      },
      {
        key: 'chargetotal',
        name: t('payment:chargetotal'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '5%',
        },
        isSortable: true,
        renderCell: (payment) => (
          <Box textAlign="right">{t('common:format.currency', { value: payment.chargetotal })}</Box>
        ),
        filter: <NumberFilter label={t('payment:chargetotal')} />,
      },
      {
        key: 'paymentType',
        name: t('payment:paymentType'),
        cellProps: {
          width: '10%',
        },
        isSortable: true,
        renderCell: (payment) => t(`payment:paymentTypeOptions.${payment.paymentType}`),
        filter: (
          <EnumFilter
            label={t('payment:paymentType')}
            options={paymemtTypes}
            renderOptionLabel={(key) => t(`payment:paymentTypeOptions.${key}`)}
          />
        ),
      },
      {
        key: 'txndatetime',
        name: t('payment:txndatetime'),
        renderCell: (payment) => t('common:format.date_time', { dateTime: payment.txndatetime }),
        isSortable: true,
        filter: <DateFilter showTimeSelect />,
        filterWidth: 'md',
      },
      {
        key: 'status',
        name: t('payment:status'),
        cellProps: {
          width: '10%',
        },
        isSortable: true,
        renderCell: (payment) => t(`payment:statusOptions.${payment.status}`),
        filter: (
          <EnumFilter
            label={t('payment:status')}
            options={paymentTransactionStatuses}
            renderOptionLabel={(key) => t(`payment:statusOptions.${key}`)}
          />
        ),
      },
      {
        key: 'paymentMethod',
        name: t('payment:paymentMethod'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '25%',
        },
        isSortable: true,
        renderCell: (payment) => <Optional>{payment.paymentMethod}</Optional>,
        filter: (
          <StringFilter
            label={t('payment:paymentMethod')}
            loadOptions={async (value: string) => {
              const page = await paymentApi.searchPayments({
                pageable: { size: 10 },
                filter: [`paymentMethod,contain,${value}`],
              });

              return page.content.map((payment) => payment.paymentMethod!);
            }}
          />
        ),
      },
      {
        key: 'ipgTransactionId',
        name: t('payment:ipgTransactionId'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '25%',
        },
        isSortable: true,
        renderCell: (payment) => <Optional>{payment.ipgTransactionId}</Optional>,
        filter: (
          <StringFilter
            label={t('payment:ipgTransactionId')}
            loadOptions={async (value: string) => {
              const page = await paymentApi.searchPayments({
                pageable: { size: 10 },
                filter: [`ipgTransactionId,contain,${value}`],
              });

              return page.content.map((payment) => payment.ipgTransactionId!);
            }}
          />
        ),
      },
    ];

    return columns;
  }, [linkPath, t]);
}
