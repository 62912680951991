/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupExternalApplicationStatusDto } from './GroupExternalApplicationStatusDto';
import {
    GroupExternalApplicationStatusDtoFromJSON,
    GroupExternalApplicationStatusDtoFromJSONTyped,
    GroupExternalApplicationStatusDtoToJSON,
} from './GroupExternalApplicationStatusDto';
import type { RelationTypeDto } from './RelationTypeDto';
import {
    RelationTypeDtoFromJSON,
    RelationTypeDtoFromJSONTyped,
    RelationTypeDtoToJSON,
} from './RelationTypeDto';

/**
 * 
 * @export
 * @interface AddPersonToGroupApplicationDto
 */
export interface AddPersonToGroupApplicationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AddPersonToGroupApplicationDto
     */
    id: string;
    /**
     * 
     * @type {GroupExternalApplicationStatusDto}
     * @memberof AddPersonToGroupApplicationDto
     */
    status: GroupExternalApplicationStatusDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AddPersonToGroupApplicationDto
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof AddPersonToGroupApplicationDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AddPersonToGroupApplicationDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof AddPersonToGroupApplicationDto
     */
    stageName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddPersonToGroupApplicationDto
     */
    occupation?: string;
    /**
     * 
     * @type {string}
     * @memberof AddPersonToGroupApplicationDto
     */
    company?: string;
    /**
     * 
     * @type {RelationTypeDto}
     * @memberof AddPersonToGroupApplicationDto
     */
    type: RelationTypeDto;
}

/**
 * Check if a given object implements the AddPersonToGroupApplicationDto interface.
 */
export function instanceOfAddPersonToGroupApplicationDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('status' in value)) return false;
    if (!('groupId' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('type' in value)) return false;
    return true;
}

export function AddPersonToGroupApplicationDtoFromJSON(json: any): AddPersonToGroupApplicationDto {
    return AddPersonToGroupApplicationDtoFromJSONTyped(json, false);
}

export function AddPersonToGroupApplicationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddPersonToGroupApplicationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': GroupExternalApplicationStatusDtoFromJSON(json['status']),
        'groupId': json['groupId'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'stageName': json['stageName'] == null ? undefined : json['stageName'],
        'occupation': json['occupation'] == null ? undefined : json['occupation'],
        'company': json['company'] == null ? undefined : json['company'],
        'type': RelationTypeDtoFromJSON(json['type']),
    };
}

export function AddPersonToGroupApplicationDtoToJSON(value?: AddPersonToGroupApplicationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'status': GroupExternalApplicationStatusDtoToJSON(value['status']),
        'groupId': value['groupId'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'stageName': value['stageName'],
        'occupation': value['occupation'],
        'company': value['company'],
        'type': RelationTypeDtoToJSON(value['type']),
    };
}

