import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { systemMailingIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function SystemMailingSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'SYSTEM_MAILING', `${result.resultType} is no instance of SystemMailingSearchResult`);

  const systemMailing = result.result;
  const { t } = useTranslation(['system_mailing', 'mailing_template']);

  return (
    <SearchResult
      icon={systemMailingIcon}
      title={t(`system_mailing:typeLabels.${systemMailing.type}`)}
      link={`/system-mailings/${systemMailing.id}`}
      score={result.score}
      necessaryPermission="SYSTEM_MAILING:CAN_SEE_DETAILS"
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('system_mailing:title.label_de'),
            element: systemMailing.title,
          },
          {
            label: t('system_mailing:title.label_en'),
            element: systemMailing.titleEnglish,
          },
          {
            label: t('system_mailing:subject.label_de'),
            element: systemMailing.subject,
          },
          {
            label: t('system_mailing:subject.label_en'),
            element: systemMailing.subjectEnglish,
          },
          {
            label: t('mailing_template:mailingBrand'),
            element: systemMailing.template.mailingDesignBrand,
          },
        ]}
      />
    </SearchResult>
  );
}
