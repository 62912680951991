import { IconButton, PlacementWithLogical, Tooltip, useDisclosure } from '@chakra-ui/react';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useCallback, useTransition } from 'react';
import useDefinedContext from '../../../util/context/use-defined-context/use-defined-context';
import { DisableableButton } from '../../disableable-button/disableable-button';
import ElementContext from './element-context';
import { ElementFormModalProvider, ElementFormProvider } from './element-form-modal-context';
import { ElementTableDispatchContext } from './element-table-control';

/**
 * Properties for edit button.
 */
interface EditElementButtonProps {
  label: string;
  formModal: React.ReactElement;
  tooltipPlacement?: PlacementWithLogical;
  isDisabled?: ReactNode | boolean | string;
}

/**
 * Button to edit element in a list or table.
 */
export default function EditElementButton({
  label,
  formModal,
  isDisabled,
  tooltipPlacement = 'bottom',
}: EditElementButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDefinedContext(ElementTableDispatchContext);
  const { element, index } = useDefinedContext(ElementContext);
  const [isPending, startTransition] = useTransition();

  const handleSubmit = useCallback(
    (element: unknown) => {
      startTransition(() => {
        dispatch({ type: 'EDIT_ELEMENT', payload: { element, index } });
      });
    },
    [dispatch, index],
  );

  return (
    <>
      <Tooltip label={label} placement={tooltipPlacement}>
        <IconButton
          as={DisableableButton}
          onClick={onOpen}
          size="sm"
          variant="ghost"
          isDisabled={!!isDisabled}
          disableReason={isDisabled}
          icon={<FontAwesomeIcon icon={faPencil} />}
          aria-label={label}
        />
      </Tooltip>

      <ElementFormProvider element={element} onSubmit={handleSubmit}>
        <ElementFormModalProvider label={label} isOpen={isOpen} onClose={onClose} isPending={isPending}>
          {formModal}
        </ElementFormModalProvider>
      </ElementFormProvider>
    </>
  );
}
