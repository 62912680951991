import React from 'react';
import { rundownIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import EVENT_CHILD_ROUTES from '../event/event-child-routes';
import EVENT_CHILD_ROUTES_MOBILE from '../event/event-child-routes-mobile';
import { EVENT_MENU_ITEM, EVENT_MOBILE_MENU_ITEM, EventMenuItem } from '../event/event-page/event-page-menu';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useRundownPermissionClassGroupExtension } from './rundown-permissions';
import rundownRoutes, { rundownRoutesMobile } from './rundown-routes';
import RundownSearchLaneElementResult from './rundown-search-lane-element-result';
import RundownSearchLaneResult from './rundown-search-lane-result';

function createRundownEventMenuItem(isMobile = false): EventMenuItem {
  return {
    order: 5,
    label: <Translate ns="rundown" i18nKey="rundown" />,
    icon: rundownIcon,
    path: (eventId) => `${isMobile ? '/mobile' : ''}/events/${eventId}/rundown`,
    isVisible: (ownerSectionIds, permissions) =>
      permissions.hasPermission('RUNDOWN:CAN_SEE_DETAILS') ||
      ownerSectionIds.some((sectionId) =>
        permissions.hasPermissionFromSection(sectionId, 'RUNDOWN:CAN_SEE_DETAILS_OWN'),
      ),
  };
}

export default function RundownPluginProvider(registry: PluginRegistry) {
  registry.register(EVENT_MENU_ITEM, createRundownEventMenuItem());
  registry.register(EVENT_MOBILE_MENU_ITEM, createRundownEventMenuItem(true));
  registry.register(EVENT_CHILD_ROUTES, rundownRoutes);
  registry.register(EVENT_CHILD_ROUTES_MOBILE, rundownRoutesMobile);
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'RUNDOWN_LANE_ELEMENT',
    Component: RundownSearchLaneElementResult,
  });
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'RUNDOWN_LANE',
    Component: RundownSearchLaneResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'RUNDOWN_LANE_ELEMENT',
    icon: rundownIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.RUNDOWN_LANE_ELEMENT" />,
    necessaryPermission: 'RUNDOWN:CAN_SEE_DETAILS_OWN',
  });
  registry.register(SEARCH_FILTER, {
    key: 'RUNDOWN_LANE',
    icon: rundownIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.RUNDOWN_LANE" />,
    necessaryPermission: 'RUNDOWN:CAN_SEE_DETAILS_OWN',
  });

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useRundownPermissionClassGroupExtension);
}
