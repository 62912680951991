import { Editor, Element, Node, NodeEntry, Transforms } from 'slate';
import { BlockElement, BlockFormatOption, ElementFormatOption } from './slate-types';
import { isListType } from './with/with-list';

export function isBlockActive(editor: Editor, type: ElementFormatOption) {
  return getActiveBlockNode(editor, type) != null;
}

export function getActiveBlockNode(editor: Editor): NodeEntry<Element> | undefined;
export function getActiveBlockNode<T extends ElementFormatOption>(
  editor: Editor,
  type: T,
): NodeEntry<Element & { type: T }> | undefined;
export function getActiveBlockNode<T extends ElementFormatOption>(
  editor: Editor,
  type?: T,
): NodeEntry<Element & { type: T }> | undefined {
  const { selection } = editor;

  if (!selection) {
    return undefined;
  }

  const [firstNode] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (node): node is Element & { type: T } =>
      !Editor.isEditor(node) && Element.isElement(node) && (type == null || type === node['type']),
  });

  return firstNode;
}

export function getActiveBlock(editor: Editor) {
  const [node] = getActiveBlockNode(editor) ?? [];

  return node;
}

export function toggleBlock(editor: Editor, type: BlockFormatOption) {
  const [activeBlock] = getActiveBlockNode(editor) ?? [];

  if (activeBlock != null && !editor.paragraphPresent) {
    return;
  }

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && isListType(n.type),
    split: true,
  });

  const toggleActive = activeBlock != null && activeBlock.type !== type;
  const toggleList = isListType(type);

  const props: Partial<BlockElement> = {
    type: toggleActive ? (toggleList ? 'listItem' : type) : 'paragraph',
  };

  if (activeBlock != null && 'align' in activeBlock) {
    props.align = activeBlock.align;
  }

  Transforms.setNodes(editor, props);

  if (toggleList && toggleActive) {
    Transforms.wrapNodes(editor, { type, children: [] });
  }
}

export function normalizeBlocks(editor: Editor) {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (Element.isElement(node) && isListType(node.type)) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (!Element.isElement(child)) {
          continue;
        }

        if (isListType(child.type) || child.type !== 'listItem') {
          Transforms.unwrapNodes(editor, {
            at: childPath,
            match: (n) => !Editor.isEditor(n) && Element.isElement(n) && isListType(n.type),
            split: true,
          });
          return;
        }
      }
    }

    normalizeNode(entry);
  };

  return editor;
}
