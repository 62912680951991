import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useCountryPermissionClassGroupExtension(): PermissionClassGroupExtension<'COUNTRY'> {
  const { t } = useTranslation(['country']);

  return {
    name: 'COUNTRY',
    label: t('country:permissionLabel'),
    getActionLabel: (action) => t(`country:permission.${action}`),
    getAdditionalRequirement: (action) => t(`country:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`country:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
