import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useTeamPermissionClassGroupExtension(): PermissionClassGroupExtension<'TEAM'> {
  const { t } = useTranslation(['team']);

  return {
    name: 'TEAM',
    label: t('team:team'),
    getActionLabel: (action) => t(`team:permission.${action}`),
    getAdditionalRequirement: (action) => t(`team:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`team:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
