import {
  Badge,
  Button,
  ButtonGroup,
  HStack,
  Image,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tag,
  useDisclosure,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCodeMerge, faHistory, faHomeAlt, faQrcode, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { Link as RouterLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { PersonDto, PersonStatusDto, PersonTypeDto, SyncStatusInnerDto, VisaCreationCheckDto } from '../../../api';
import accountApi from '../../../data-access/account-api';
import personApi from '../../../data-access/person-api';
import visaApi from '../../../data-access/visa-api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import { accountIcon, staffIcon, visaIcon } from '../../../ui/icons/business-objects';
import { PageHeader, PageTab } from '../../../ui/page';
import Page from '../../../ui/page/page';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import AccountConvertButton from '../../account/account-convert/account-convert-button';
import AccountCreationButton from '../../account/account-create/account-creation-button';
import AccountDeleteButton from '../../account/account-delete/account-delete-button';
import useHasPermission from '../../permission/use-permission';
import VisaCreateButton from '../../visa/visa-create/visa-create-button';
import PersonDeleteButton from '../person-delete/person-delete-button';
import { editableStatus } from '../person-enum-constants';
import PersonHistory from '../person-history/person-history';
import { fetchPerson, fetchPersonComments, personFetcher } from '../person-queries';
import PersonDeleteCompletelyButton from '../person-to-delete-lister/person-delete-completely-button';

const DISPLAY_TAGS: PersonStatusDto[] = [PersonStatusDto.HISTORIC, PersonStatusDto.DELETED];

export interface PersonTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export const PERSON_TAB_ITEM = new PluginToken<PersonTabItem>('PersonTabItem');

export default function PersonPage() {
  const { t } = useTranslation('person');
  const { personId } = useParams<{
    personId: string;
  }>();
  invariant(personId, 'Missing personId');
  const person = useFetcher(fetchPerson, { id: personId });

  const getPersonActions = () => {
    if (editableStatus.includes(person.status)) {
      return <PersonPageActions person={person} />;
    }
    if (person.status === PersonStatusDto.DELETED) {
      return <PersonPageDeletedActions person={person} />;
    }
    return null;
  };

  const anonymised = person.status === PersonStatusDto.ANONYMISED;
  const tabs = !anonymised && <PersonPageTabs personId={personId} />;

  return (
    <Page>
      <PageHeader
        title={t('personFormat', { firstName: person.firstName, surname: person.surname })}
        titleAddon={
          <HStack spacing={2}>
            {DISPLAY_TAGS.includes(person.status) && <Tag>{t(`statusLabel.${person.status}`)}</Tag>}
            {person.types?.includes(PersonTypeDto.STAFF) && <Tag>{t('label.staff')}</Tag>}
          </HStack>
        }
        picture={
          person.photoMetadata && (
            <Image
              src={`/image-manipulation/api/resize/${person.photoMetadata.namespace}/${person.photoMetadata.id}/72x72`}
              boxSize="72px"
              objectFit="cover"
              borderRadius="base"
              alt={t('photo.picture_alt_text')}
            />
          )
        }
        actions={getPersonActions()}
        tabs={tabs}
      />
      {anonymised ? <PersonHistory /> : <Outlet />}
    </Page>
  );
}

function PersonPageDeletedActions({ person }: { person: PersonDto }) {
  const { t } = useTranslation('person');

  const personId = person.id;
  invariant(personId != null, 'missing person.id');
  const navigate = useNavigate();

  return (
    <Button
      as={PersonDeleteCompletelyButton}
      person={person}
      colorScheme="red"
      variant="solid"
      onSuccess={() => navigate(`/persons-to-delete`)}
    >
      {t('action.delete.title')}
    </Button>
  );
}

function PersonPageActions({ person }: { person: PersonDto }) {
  const personId = person.id;
  invariant(personId != null, 'missing person.id');
  const { t } = useTranslation('person');
  const [isDeletable, setDeletable] = React.useState<boolean>(false);
  const [visaCreationCheckStatus, setVisaCreationCheckStatus] = React.useState<VisaCreationCheckDto>();
  const [syncStatus, setSyncStatus] = React.useState<SyncStatusInnerDto[]>([]);
  const [canDeleteProfessionalAccount, setCanDeleteProfessionalAccount] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const { hasPermission } = useHasPermission();

  const checkStatus = useCallback(async () => {
    setDeletable(false);
    const statusCheck = await personApi.checkPersonStatus({ personId });
    setDeletable(statusCheck.isDeletable);

    const visaCreationStatus = await visaApi.canCreateVisa({
      applicantId: personId,
    });
    setVisaCreationCheckStatus(visaCreationStatus);
  }, [personId]);

  const getSyncStatus = useCallback(async () => {
    if (!hasPermission('ACCOUNT:CAN_CREATE_PROFESSIONAL_ACCOUNT')) {
      return;
    }
    const syncStatus = await accountApi.getSyncStatus({
      emails: person.emailAddresses == null ? [] : person.emailAddresses?.map((value) => value.email),
    });
    setSyncStatus(syncStatus);
  }, [hasPermission, person.emailAddresses]);

  const canDeleteProfessionalAccountOfPerson = async () => {
    setCanDeleteProfessionalAccount(false);
    if (!hasPermission('ACCOUNT:CAN_DELETE_PROFESSIONAL_ACCOUNT')) {
      return;
    }

    const canDelete = await personApi.canDeleteProfessionalAccount({ id: personId });
    setCanDeleteProfessionalAccount(canDelete);
  };

  return (
    <ButtonGroup>
      <Menu
        onOpen={() => {
          checkStatus();
          canDeleteProfessionalAccountOfPerson();
          getSyncStatus();
        }}
      >
        <BurgerMenuButton />
        <MenuList>
          {hasPermission('PERSON:CAN_MERGE') && (
            <MenuItem as={RouterLink} to="duplicate" icon={<FontAwesomeIcon icon={faCodeMerge} />}>
              {t('action.duplicate')}
            </MenuItem>
          )}
          {hasPermission('PERSON:CAN_SEE_BADGE') && (
            <MenuItem as={ShowPersonBadgeButton} person={person} icon={<FontAwesomeIcon icon={faQrcode} />}>
              Person Badge
            </MenuItem>
          )}
          {hasPermission('STAFF:CAN_CREATE') && !person.types?.includes(PersonTypeDto.STAFF) && (
            <MenuItem as={RouterLink} to={`../../staff/new/${person.id}`} icon={<FontAwesomeIcon icon={staffIcon} />}>
              {t('action.create-staff')}
            </MenuItem>
          )}
          {!person.types?.includes(PersonTypeDto.PROFESSIONAL) &&
            person.status !== PersonStatusDto.HISTORIC &&
            hasPermission('ACCOUNT:CAN_CREATE_PROFESSIONAL_ACCOUNT') && (
              <MenuItem
                as={AccountCreationButton}
                onSuccess={() => personFetcher.mutate()}
                syncStatus={syncStatus}
                icon={<FontAwesomeIcon icon={accountIcon} />}
                person={person}
              >
                {t('action.createProfessionalAccount')}
              </MenuItem>
            )}
          {person.types?.includes(PersonTypeDto.PROFESSIONAL) &&
            hasPermission('ACCOUNT:CAN_DELETE_PROFESSIONAL_ACCOUNT') && (
              <MenuItem
                as={AccountDeleteButton}
                displayName={`${person.firstName} ${person.surname}`}
                isDeletable={canDeleteProfessionalAccount}
                icon={<FontAwesomeIcon icon={accountIcon} />}
                onDelete={() => personApi.deleteProfessionalAccount({ id: person.id! })}
                onSuccess={async () => {
                  await personFetcher.mutate();
                }}
              >
                {t('action.deleteProfessionalAccount')}
              </MenuItem>
            )}
          {person.types?.includes(PersonTypeDto.PROFESSIONAL) &&
            hasPermission('ACCOUNT:CAN_REMOVE_ACCOUNT_FROM_PERSON') && (
              <MenuItem
                as={AccountConvertButton}
                displayName={`${person.firstName} ${person.surname}`}
                isDeletable={canDeleteProfessionalAccount}
                icon={<FontAwesomeIcon icon={accountIcon} />}
                onSuccess={() => personFetcher.mutate()}
                onConvert={() => personApi.convertProfessionalToBasicAccount({ id: person.id! })}
              >
                {t('action.convertProfessionalAccount')}
              </MenuItem>
            )}
          <MenuItem
            as={VisaCreateButton}
            person={person}
            visaCreationCheckStatus={visaCreationCheckStatus}
            icon={<FontAwesomeIcon icon={visaIcon} />}
          >
            {t('action.createVisa')}
          </MenuItem>
          {hasPermission('PERSON:CAN_SET_DELETE_STATUS') && (
            <>
              <MenuDivider />
              <MenuItem
                color="text.error"
                as={PersonDeleteButton}
                person={person}
                isDeletable={isDeletable}
                onSuccess={() => navigate(`/persons`)}
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
              >
                {t('action.delete.title')}
              </MenuItem>
            </>
          )}
        </MenuList>
      </Menu>
      {hasPermission('PERSON:CAN_EDIT') && (
        <Button as={RouterLink} to="edit" variant="primary">
          {t('action.edit')}
        </Button>
      )}
    </ButtonGroup>
  );
}

function PersonPageTabs({ personId }: { personId: string }) {
  const { t } = useTranslation('person');
  const { t: tCommon } = useTranslation('common');

  const tabItems = usePlugins(PERSON_TAB_ITEM);
  const sortedTabItems = React.useMemo(() => sortBy(tabItems, 'order'), [tabItems]);

  const comments = useFetcher(
    fetchPersonComments,
    {
      personId: personId,
      pageable: { size: 1 },
    },
    { suspense: false },
  );

  return (
    <>
      <PageTab to="." icon={faHomeAlt} />
      <PageTab to="./relations">{t('connections.label')}</PageTab>
      <PageTab to="./comments">
        {t('comments.label')}
        <Badge colorScheme="teal" variant="solid" ml={2} display="inline-flex">
          {comments?.totalElements ?? <Spinner color="white" size="xs" my={1} />}
        </Badge>
      </PageTab>
      {sortedTabItems.map((tabItem, index) => (
        <PageTab key={'additionalTab' + index} to={tabItem.to} icon={tabItem.icon}>
          {tabItem.label}
        </PageTab>
      ))}
      <PageTab to="./history" icon={faHistory}>
        {tCommon('history.label')}
      </PageTab>
    </>
  );
}

interface ShowPersonBadgeButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  person: PersonDto;
  children: React.ReactNode;
}

const ShowPersonBadgeButton = React.forwardRef<HTMLButtonElement, ShowPersonBadgeButtonProps>(
  ({ person: { badge }, children, ...props }: ShowPersonBadgeButtonProps, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const badgeString = useMemo(
      // encode badge as it may contain non-ASCII characters (e.g. person's name)
      () => encodeURIComponent(JSON.stringify(badge)),
      [badge],
    );

    return (
      <>
        <button ref={ref} {...props} onClick={onOpen}>
          {children}
        </button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Badge</ModalHeader>
            <ModalCloseButton />
            <ModalBody padding={8}>
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={badgeString}
                viewBox="0 0 256 256"
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  },
);
