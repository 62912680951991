import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../../util/swr/use-fetcher';
import { fetchPayment } from '../payment-queries';

export default function PaymentBreadcrumb() {
  const { paymentId } = useParams<{ paymentId: string }>();
  invariant(paymentId, 'Empty paymentId');
  const payment = useFetcher(fetchPayment, { id: paymentId });

  return <>{payment.id}</>;
}
