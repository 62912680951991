import { Flex, Grid, GridItem, Spinner, Table, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { last } from 'lodash-es';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutType } from '../../feature/common/layout-type';
import { DataTableSize } from '../../theme/component/data-table';
import { DEBOUNCE_TIME } from '../../util/constants';
import useDefinedContext from '../../util/context/use-defined-context/use-defined-context';
import useDebounce from '../../util/debounce/use-debounce';
import EmptyLazy from '../no-data/empty-lazy';
import DataTableBody from './data-table-body';
import DataTableColumn, { addBroomToLastColumn, isFilterableOrSortable } from './data-table-column';
import { DataTableStateContext, DataTableStateProvider, DataTableStylesContext } from './data-table-context';
import DataTableFooter from './data-table-footer';
import DataTableHeader from './data-table-header';
import DataTablePage from './data-table-page';
import DataTableSelection from './data-table-selection';
import DataTableState from './data-table-state';
import DataTableTiles from './filter/data-table-tiles';
import useSelectionColumn, { adjustColumnIfSticky } from './use-selection-column';

export interface DataTableProps<TData, TChildData = undefined> extends React.ComponentPropsWithoutRef<'div'> {
  page: DataTablePage<TData>;
  state?: Partial<DataTableState>;

  onStateChange?(state: Partial<DataTableState>): void;

  columns: DataTableColumn<TData, TChildData>[];

  rowKey(data: NonNullable<TData | TChildData>, index: number): React.Key;

  childRows?(data: TData): TChildData[] | undefined;

  rowStyle?(data: NonNullable<TData | TChildData>): SystemStyleObject;

  size?: DataTableSize;
  isLoading?: boolean;
  isPageable?: boolean;
  empty?: React.ReactNode;
  footer?: React.ReactNode;
  showHeader?: boolean;
  layout?: LayoutType;
  selection?: DataTableSelection<TData>;
  isInvalid?: boolean;
  actions?: (element: TData) => ReactNode;
}

function DefaultSelectionFooter() {
  const { state } = useDefinedContext(DataTableStateContext);
  const { t } = useTranslation(['common']);
  return (
    state.selection != null &&
    state.selection.length > 0 && (
      <Text ml={2}>{t('common:data_table.selection.count', { count: state.selection.length })}</Text>
    )
  );
}

function DataTable<TData, TChildData = undefined>({
  page,
  state,
  columns,
  rowKey,
  childRows,
  rowStyle,
  onStateChange,
  size,
  isLoading,
  isPageable,
  showHeader = true,
  layout = LayoutType.NORMAL,
  empty = <EmptyLazy />,
  selection,
  footer = selection == null ? undefined : <DefaultSelectionFooter />,
  isInvalid,
  actions,
  ...props
}: DataTableProps<TData, TChildData>) {
  const tableRef = React.useRef<HTMLTableElement>(null);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const isDebouncedLoading = useDebounce(isLoading, DEBOUNCE_TIME);
  const styles = useMultiStyleConfig('DataTable', { size });

  if (actions != null) {
    columns = [
      ...columns,
      {
        key: 'action',
        sticky: true,
        cellProps: { width: '0%' },
        renderCell: actions,
      },
    ];
  }

  // add empty sticky column so broomable is also sticky
  const lastColumn = last(columns);
  if (columns.some(isFilterableOrSortable) && lastColumn !== false && lastColumn?.key !== 'action') {
    columns = [
      ...columns,
      {
        key: 'action',
        sticky: true,
        cellProps: { width: '0%' },
        renderCell: () => <></>,
      },
    ];
  }

  // clear filters and sorting - only available if header is shown and at least one column is filterable or sortable
  if (columns.some(isFilterableOrSortable)) {
    columns = addBroomToLastColumn(columns);
  }
  const selectionColumn = useSelectionColumn({ selection, page });
  if (selectionColumn != null) {
    columns = [selectionColumn, adjustColumnIfSticky(columns[0]), ...columns.slice(1)];
  }

  useDataTableFixedWidth(tableRef, page);

  const scrollToTop = () => {
    tableContainerRef.current?.scrollTo({ top: 0, left: 0 });
  };

  const handleStateChange = (state: DataTableState) => {
    onStateChange?.(state);
    scrollToTop();
  };

  return (
    <DataTableStylesContext.Provider value={styles}>
      <DataTableStateProvider state={state} onStateChange={handleStateChange}>
        <Grid
          gridTemplateRows="1fr auto"
          sx={styles.container}
          maxHeight="full"
          data-invalid={isInvalid ? '' : undefined}
          role="group"
          {...props}
        >
          <GridItem sx={styles.tableContainer} ref={tableContainerRef}>
            {layout === LayoutType.NORMAL ? (
              <Table ref={tableRef} size={size}>
                {showHeader && <DataTableHeader columns={columns} />}
                {page.content.length > 0 && (
                  <DataTableBody
                    page={page}
                    columns={columns}
                    rowKey={rowKey}
                    rowStyle={rowStyle}
                    childRows={childRows}
                  />
                )}
              </Table>
            ) : (
              <DataTableTiles page={page} columns={columns} />
            )}

            {isDebouncedLoading && <DataTableSpinner />}
            {!isDebouncedLoading && page.content.length === 0 && empty}
          </GridItem>

          <GridItem>{isPageable ? <DataTableFooter page={page} children={footer} /> : footer}</GridItem>
        </Grid>
      </DataTableStateProvider>
    </DataTableStylesContext.Provider>
  );
}

export default React.memo(DataTable) as typeof DataTable;

function DataTableSpinner() {
  const styles = useDefinedContext(DataTableStylesContext);

  return (
    <Flex sx={styles.spinnerContainer} data-testid="data-table-spinner">
      <Spinner />
    </Flex>
  );
}

function getFirstTableRow(table: HTMLTableElement | null) {
  if (table?.rows == null || table.rows.length > 0) {
    return Array.from(table?.rows[0].cells ?? []);
  }
  return Array.from([]);
}

function useDataTableFixedWidth(tableRef: React.RefObject<HTMLTableElement>, page: DataTablePage<any>) {
  const sizesRef = React.useRef<number[]>([]);

  const setSizes = React.useCallback(() => {
    getFirstTableRow(tableRef.current).forEach((cell, index) => {
      const size = sizesRef.current[index];
      cell.style.width = size != null ? `${size}px` : '';
    });
  }, [tableRef]);

  const resetSizes = React.useCallback(() => {
    getFirstTableRow(tableRef.current).forEach((cell) => {
      cell.style.width = '';
    });
  }, [tableRef]);

  const updateSizes = React.useCallback(() => {
    sizesRef.current = getFirstTableRow(tableRef.current).map((cell) => cell.offsetWidth);
  }, [tableRef]);

  const hasContent = page.content.length > 0;

  React.useLayoutEffect(() => {
    if (hasContent) {
      resetSizes();
      updateSizes();
    } else {
      setSizes();
    }
  }, [resetSizes, setSizes, updateSizes, hasContent]);

  React.useEffect(() => {
    if (!hasContent) {
      return;
    }

    window.addEventListener('resize', updateSizes);

    return () => {
      window.removeEventListener('resize', updateSizes);
    };
  }, [updateSizes, hasContent]);
}
