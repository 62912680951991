/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GroupExternalApplicationStatusDto = {
    REQUESTED: 'REQUESTED',
    APPROVED: 'APPROVED',
    DENIED: 'DENIED'
} as const;
export type GroupExternalApplicationStatusDto = typeof GroupExternalApplicationStatusDto[keyof typeof GroupExternalApplicationStatusDto];


export function GroupExternalApplicationStatusDtoFromJSON(json: any): GroupExternalApplicationStatusDto {
    return GroupExternalApplicationStatusDtoFromJSONTyped(json, false);
}

export function GroupExternalApplicationStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupExternalApplicationStatusDto {
    return json as GroupExternalApplicationStatusDto;
}

export function GroupExternalApplicationStatusDtoToJSON(value?: GroupExternalApplicationStatusDto | null): any {
    return value as any;
}

