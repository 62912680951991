import { Button, HStack, Spacer, Stack, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import {
  LocationStatusDto,
  PersonDto,
  PersonLocationRelationDto,
  PersonReferenceDto,
  PersonStatusDto,
} from '../../../../api';
import DateInputFormControl from '../../../../ui/form/date-input-control/date-input-form-control';
import { ElementFormModal, ElementTableControl, useElementForm } from '../../../../ui/form/element-control';
import AddElementButton from '../../../../ui/form/element-control/add-element-button';
import DeleteElementButton from '../../../../ui/form/element-control/delete-element-button';
import EditElementButton from '../../../../ui/form/element-control/edit-element-button';
import ElementContext from '../../../../ui/form/element-control/element-context';
import InputFormControl from '../../../../ui/form/input-form-control';
import useDefinedContext from '../../../../util/context/use-defined-context/use-defined-context';
import now from '../../../../util/now';
import { LayoutType } from '../../../common/layout-type';
import useActiveEdition from '../../../edition/use-active-edition/use-active-edition';
import personLocationRelationColumns from '../../person-table-columns/person-location-relation-columns';
import CancelConnectionButton from './cancel-connection-button';
import ConnectionControl from './connection-control';

interface NewPersonLocationRelation extends PersonLocationRelationDto {
  isNew?: boolean;
}

export interface PersonLocationRelationControlProps {
  layout: LayoutType;
  showAddButton?: boolean;
}

export default function PersonLocationRelationControl({
  layout,
  showAddButton = true,
}: PersonLocationRelationControlProps) {
  const { t } = useTranslation('person');
  const [currentPersonId, surname, firstName, personKey, status] = useWatch<PersonDto>({
    name: ['id', 'surname', 'firstName', 'personKey', 'status'],
  });
  const fromPersonReference: PersonReferenceDto = {
    id: String(currentPersonId),
    personKey: String(personKey),
    firstName: String(firstName),
    surname: String(surname),
  };

  return (
    <ElementTableControl<PersonDto, PersonLocationRelationDto>
      label={t('relations.locations')}
      name="personLocationRelations"
      columns={personLocationRelationColumns}
      addButton={
        status === PersonStatusDto.ACTIVE && showAddButton ? (
          <AddElementButton
            label={t('relations.add')}
            formModal={<LocationPersonRelationFormModal fromPersonReference={fromPersonReference} />}
          />
        ) : undefined
      }
      editButton={
        status === PersonStatusDto.ACTIVE ? (
          <EditConnectionButton fromPersonReference={fromPersonReference} />
        ) : undefined
      }
      deleteButton={<EndOrDeleteConnectionButton fromPersonReference={fromPersonReference} />}
      layout={layout}
    />
  );
}

interface EditConnectionButtonProps {
  fromPersonReference: PersonReferenceDto;
}

function EditConnectionButton({ fromPersonReference }: EditConnectionButtonProps) {
  const { t } = useTranslation('person');
  const { element } = useDefinedContext(ElementContext);

  const isLocationInactive = (element as PersonLocationRelationDto).locationStatus === LocationStatusDto.INACTIVE;

  return (
    <EditElementButton
      label={t('relations.edit')}
      isDisabled={isLocationInactive ? t('relations.edit_disabled') : false}
      formModal={<LocationPersonRelationFormModal fromPersonReference={fromPersonReference} isEdit />}
    />
  );
}

interface DeleteConnectionButtonProps {
  fromPersonReference: PersonReferenceDto;
}

function EndOrDeleteConnectionButton({ fromPersonReference }: DeleteConnectionButtonProps) {
  const { t } = useTranslation('person');
  const { element } = useDefinedContext(ElementContext);

  // jaj, lh: Show delete button for unsaved connections and the cancel dialog for saved ones.
  return (element as NewPersonLocationRelation).isNew ? (
    <DeleteElementButton<PersonLocationRelationDto>
      label={t('relations.delete_connection')}
      renderDeleteMessage={(element) => (
        <Trans t={t} i18nKey="relations.delete_message" values={{ connectedName: element.connectedLocation.name }} />
      )}
    />
  ) : (
    <CancelConnectionButton label={t('relations.end_connection')} fromPersonReference={fromPersonReference} />
  );
}

interface LocationPersonRelationFormModalProps {
  fromPersonReference: PersonReferenceDto;
  isEdit?: boolean;
}

function LocationPersonRelationFormModal({ fromPersonReference, isEdit }: LocationPersonRelationFormModalProps) {
  const { t } = useTranslation('person');
  const { t: tCommon } = useTranslation('common');
  const { element: locationPersonRelation, onSubmit } = useElementForm<PersonLocationRelationDto>();
  const initialFocusRef = React.useRef<HTMLSelectElement>(null);
  const { setValue, register } = useFormContext<NewPersonLocationRelation>();
  register('timestamp');

  const activeEdition = useActiveEdition();

  const prefillToday = () =>
    setValue(
      'dateRange',
      { start: new Date(now()) },
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );
  const prefillActiveEdition = () =>
    setValue('dateRange', activeEdition.dateRange, { shouldValidate: true, shouldDirty: true });
  const start = useWatch<PersonLocationRelationDto>({ name: 'dateRange.start' }) as Date;
  const isNew = locationPersonRelation == null || (locationPersonRelation as NewPersonLocationRelation).isNew;

  const handleSubmit = (locationPersonRelation: PersonLocationRelationDto) => {
    // jaj, lh: Add flag to be able to show different delete dialogs for unsaved connections.
    onSubmit({ ...locationPersonRelation, isNew } as NewPersonLocationRelation);
  };

  return (
    <ElementFormModal<PersonLocationRelationDto>
      onSubmit={handleSubmit}
      element={locationPersonRelation}
      initialFocusRef={initialFocusRef}
      defaultElement={{ dateRange: { start: new Date(now()) }, timestamp: new Date(now()) }}
    >
      <ConnectionControl fromReference={fromPersonReference} ref={initialFocusRef} toReferenceEditable={!isEdit} />
      <Stack
        spacing={4}
        sx={{
          marginTop: 4,
          borderWidth: 1,
          borderRadius: 4,
          padding: 4,
          borderColor: 'border.01',
        }}
      >
        <HStack spacing={2}>
          <span>{t('relations.prefill')}: </span> <Spacer />
          <Tooltip label={t('relations.as_of_today_tooltip')}>
            <Button size="sm" onClick={prefillToday}>
              {t('relations.as_of_today')}
            </Button>
          </Tooltip>
          <Tooltip label={t('relations.active_edition_tooltip')}>
            <Button size="sm" onClick={prefillActiveEdition}>
              {t('relations.active_edition')}
            </Button>
          </Tooltip>
        </HStack>
        <HStack alignItems="start">
          <DateInputFormControl<PersonLocationRelationDto>
            name="dateRange.start"
            isRequired
            deps={['dateRange.end']}
            label={t('relations.date_range_start')}
          />
          <DateInputFormControl<PersonLocationRelationDto>
            name="dateRange.end"
            deps={['dateRange.start']}
            label={t('relations.date_range_end')}
            referenceDate={start}
            min={{
              value: start,
              message: tCommon('validation_error.date_end_before_date_start'),
            }}
          />
        </HStack>
        <InputFormControl<PersonLocationRelationDto>
          label={t('relations.comment')}
          name="comment"
          maxLength={50}
          helperText={t('relations.comment_hint')}
        />
      </Stack>
    </ElementFormModal>
  );
}
