import { Flex, Grid, GridItem, Heading } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../../ui/page/page-content';
import useParam from '../../../../util/use-params/use-params';
import OrderConfirmationPaymentDataTable from './order-confirmation-payment-data-table';

export function OrderConfirmationPaymentPageContent() {
  const orderConfirmationId = useParam('orderConfirmationId');
  const { t } = useTranslation(['payment']);

  return (
    <PageContent useFullWidth fixedHeight>
      <Grid gridRowGap={6} maxHeight="full" gridTemplateRows="auto 1fr">
        <Flex as="header">
          <Heading size="md" as="h3">
            {t('payment:lister.title')}
          </Heading>
        </Flex>
        <GridItem minH={0}>
          <OrderConfirmationPaymentDataTable orderConfirmationId={orderConfirmationId} />
        </GridItem>
      </Grid>
    </PageContent>
  );
}
