/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PermissionsPerLevelDto } from './PermissionsPerLevelDto';
import {
    PermissionsPerLevelDtoFromJSON,
    PermissionsPerLevelDtoFromJSONTyped,
    PermissionsPerLevelDtoToJSON,
} from './PermissionsPerLevelDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface PermissionClassGroupDto
 */
export interface PermissionClassGroupDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PermissionClassGroupDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PermissionClassGroupDto
     */
    name: string;
    /**
     * Crucial to make filter and sort work as expected.
     * @type {string}
     * @memberof PermissionClassGroupDto
     */
    germanName?: string;
    /**
     * 
     * @type {PermissionsPerLevelDto}
     * @memberof PermissionClassGroupDto
     */
    permissionsPerLevel: PermissionsPerLevelDto;
    /**
     * 
     * @type {VersionDto}
     * @memberof PermissionClassGroupDto
     */
    version: VersionDto;
}

/**
 * Check if a given object implements the PermissionClassGroupDto interface.
 */
export function instanceOfPermissionClassGroupDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('permissionsPerLevel' in value)) return false;
    if (!('version' in value)) return false;
    return true;
}

export function PermissionClassGroupDtoFromJSON(json: any): PermissionClassGroupDto {
    return PermissionClassGroupDtoFromJSONTyped(json, false);
}

export function PermissionClassGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionClassGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'germanName': json['germanName'] == null ? undefined : json['germanName'],
        'permissionsPerLevel': PermissionsPerLevelDtoFromJSON(json['permissionsPerLevel']),
        'version': VersionDtoFromJSON(json['version']),
    };
}

export function PermissionClassGroupDtoToJSON(value?: PermissionClassGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'germanName': value['germanName'],
        'permissionsPerLevel': PermissionsPerLevelDtoToJSON(value['permissionsPerLevel']),
        'version': VersionDtoToJSON(value['version']),
    };
}

