/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { FilmStatusDto } from './FilmStatusDto';
import {
    FilmStatusDtoFromJSON,
    FilmStatusDtoFromJSONTyped,
    FilmStatusDtoToJSON,
} from './FilmStatusDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A Film (content of FF).
 * @export
 * @interface FilmDto
 */
export interface FilmDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof FilmDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof FilmDto
     */
    ffId: number;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof FilmDto
     */
    ffLink: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof FilmDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof FilmDto
     */
    originalTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof FilmDto
     */
    englishTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof FilmDto
     */
    germanTitle?: string;
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof FilmDto
     */
    owner?: SectionReferenceDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilmDto
     */
    countries: Array<string>;
    /**
     * 
     * @type {FilmStatusDto}
     * @memberof FilmDto
     */
    status: FilmStatusDto;
    /**
     * 
     * @type {VersionDto}
     * @memberof FilmDto
     */
    version: VersionDto;
}

/**
 * Check if a given object implements the FilmDto interface.
 */
export function instanceOfFilmDto(value: object): boolean {
    if (!('ffId' in value)) return false;
    if (!('ffLink' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('countries' in value)) return false;
    if (!('status' in value)) return false;
    if (!('version' in value)) return false;
    return true;
}

export function FilmDtoFromJSON(json: any): FilmDto {
    return FilmDtoFromJSONTyped(json, false);
}

export function FilmDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilmDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'ffId': json['ffId'],
        'ffLink': json['ffLink'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'originalTitle': json['originalTitle'] == null ? undefined : json['originalTitle'],
        'englishTitle': json['englishTitle'] == null ? undefined : json['englishTitle'],
        'germanTitle': json['germanTitle'] == null ? undefined : json['germanTitle'],
        'owner': json['owner'] == null ? undefined : SectionReferenceDtoFromJSON(json['owner']),
        'countries': json['countries'],
        'status': FilmStatusDtoFromJSON(json['status']),
        'version': VersionDtoFromJSON(json['version']),
    };
}

export function FilmDtoToJSON(value?: FilmDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'ffId': value['ffId'],
        'ffLink': value['ffLink'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'originalTitle': value['originalTitle'],
        'englishTitle': value['englishTitle'],
        'germanTitle': value['germanTitle'],
        'owner': SectionReferenceDtoToJSON(value['owner']),
        'countries': value['countries'],
        'status': FilmStatusDtoToJSON(value['status']),
        'version': VersionDtoToJSON(value['version']),
    };
}

