import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TagRelationDto } from '../../../../api';
import HistoryDisplaySettings from '../../../../ui/history/history-display-settings';

export default function useTagFilmHistorySettings(): HistoryDisplaySettings<TagRelationDto> {
  const { t } = useTranslation(['tag']);

  return useMemo<HistoryDisplaySettings<TagRelationDto>>(() => {
    return {
      attributeLabels: {
        tag: t('tag:tag'),
      },
      valueFormatter: {
        tag: (value) => (value == null ? undefined : value.name == null ? t('history.deletedTag') : value.name),
      },
      name: () => t('tag:tag'),
      showEditDescription: false,
    };
  }, [t]);
}
