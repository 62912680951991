import { Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { GroupDto, GroupListItemDto } from '../../../api';
import groupApi from '../../../data-access/group-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import useDialog from '../../../util/use-dialog/use-dialog';
import GroupDeleteDialog from './group-delete-dialog';

export interface GroupDeleteButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  onSuccess?(): void;
  deletable: boolean;
  group: GroupDto | GroupListItemDto;
  inActiveEdition: boolean;
}

const GroupDeleteButton = React.forwardRef<HTMLButtonElement, GroupDeleteButtonProps>(
  ({ children, deletable, group, inActiveEdition, onSuccess, onClick, ...props }, ref) => {
    const { t } = useTranslation('group');
    const [deleteDialogIsOpen, onDeleteDialogClose, openDeleteDialog] = useDialog<false>();
    const showDeleteSuccessToast = useToast({
      id: 'group-deletion-success-toast',
      status: 'success',
    });

    const deleteGroup = async (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);
      if (event.defaultPrevented) {
        return;
      }
      invariant(group.id != null, 'Missing group id');

      const checked = await openDeleteDialog();
      if (checked) {
        await groupApi.deleteGroupById({ id: group.id }, {
          allowedErrorCodes: [409],
        } as RequestInit);
        showDeleteSuccessToast({
          title: t('action.toast_delete'),
          description: t('action.toast_delete_message', {
            name: group.name,
          }),
        });
        onSuccess?.();
      }
    };

    return (
      <>
        <DisableableButton
          {...props}
          ref={ref}
          onClick={deleteGroup}
          isDisabled={!deletable}
          disableReason={
            inActiveEdition ? (
              <Text>{t('action.delete_failed.reason_ref')}</Text>
            ) : (
              <Text>{t('action.delete_failed.reason_inactiveEdition')}</Text>
            )
          }
        >
          {children}
        </DisableableButton>

        <GroupDeleteDialog isOpen={deleteDialogIsOpen} groupName={group.name} onClose={onDeleteDialogClose} />
      </>
    );
  },
);

export default GroupDeleteButton;
