/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DefaultPhoneNumberDto } from './DefaultPhoneNumberDto';
import {
    DefaultPhoneNumberDtoFromJSON,
    DefaultPhoneNumberDtoFromJSONTyped,
    DefaultPhoneNumberDtoToJSON,
} from './DefaultPhoneNumberDto';
import type { LocationReferenceDto } from './LocationReferenceDto';
import {
    LocationReferenceDtoFromJSON,
    LocationReferenceDtoFromJSONTyped,
    LocationReferenceDtoToJSON,
} from './LocationReferenceDto';

/**
 * 
 * @export
 * @interface StandLocationDto
 */
export interface StandLocationDto {
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof StandLocationDto
     */
    location: LocationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof StandLocationDto
     */
    zone?: string;
    /**
     * 
     * @type {string}
     * @memberof StandLocationDto
     */
    subZone?: string;
    /**
     * Manually assigned stand number
     * @type {string}
     * @memberof StandLocationDto
     */
    standNumber?: string;
    /**
     * 
     * @type {Array<DefaultPhoneNumberDto>}
     * @memberof StandLocationDto
     */
    phoneNumber: Array<DefaultPhoneNumberDto>;
}

/**
 * Check if a given object implements the StandLocationDto interface.
 */
export function instanceOfStandLocationDto(value: object): boolean {
    if (!('location' in value)) return false;
    if (!('phoneNumber' in value)) return false;
    return true;
}

export function StandLocationDtoFromJSON(json: any): StandLocationDto {
    return StandLocationDtoFromJSONTyped(json, false);
}

export function StandLocationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandLocationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'location': LocationReferenceDtoFromJSON(json['location']),
        'zone': json['zone'] == null ? undefined : json['zone'],
        'subZone': json['subZone'] == null ? undefined : json['subZone'],
        'standNumber': json['standNumber'] == null ? undefined : json['standNumber'],
        'phoneNumber': ((json['phoneNumber'] as Array<any>).map(DefaultPhoneNumberDtoFromJSON)),
    };
}

export function StandLocationDtoToJSON(value?: StandLocationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'location': LocationReferenceDtoToJSON(value['location']),
        'zone': value['zone'],
        'subZone': value['subZone'],
        'standNumber': value['standNumber'],
        'phoneNumber': ((value['phoneNumber'] as Array<any>).map(DefaultPhoneNumberDtoToJSON)),
    };
}

