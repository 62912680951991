import { Editor, Element, Range, Transforms } from 'slate';
import { ParagraphElement, WithFunction } from '../slate-types';
import { isListType } from './with-list';

const withEnhancedBreaks: WithFunction = (editor) => {
  const { insertBreak } = editor;

  editor.insertSoftBreak = () => {
    Editor.insertText(editor, '\n');
  };

  editor.insertBreak = () => {
    // Text is selected, proceed with default behavior.
    if (editor.selection == null || (Range.isRange(editor.selection) && Range.isExpanded(editor.selection))) {
      return insertBreak();
    }

    const [firstNode] = Editor.nodes(editor, {
      match: (node): node is Element => !Editor.isEditor(node) && Element.isElement(node),
    });
    const [node, path] = firstNode;
    const text = Editor.string(editor, path);

    // If the selection is not at the end of the node, proceed with default behavior.
    if (editor.selection.focus.offset !== text.length) {
      return insertBreak();
    }

    // If the selection is inside a list and the last list item had some text content, proceed with default behavior
    // (split current node = insert a new list item block)
    if (node != null && isListType(node.type)) {
      return insertBreak();
    }

    const props: ParagraphElement = {
      type: 'paragraph',
      children: [{ type: 'text', text: '' }],
    };

    if ('align' in node) {
      props.align = node.align;
    }

    // Insert a new paragraph after the current node
    Transforms.insertNodes(editor, props, {
      at: [editor.selection.focus.path[0] + 1],
      select: true,
    });
  };

  return editor;
};

export default withEnhancedBreaks;
