import { createEditor as createSlateEditor, Editor, Element, Node, Range, Transforms } from 'slate';
import { normalizeBlocks } from './block';
import { normalizeInline } from './inline';
import { RichTextOptions } from './rich-text-options';

const BLOCK_OPTIONS = [
  RichTextOptions.HEADLINE,
  RichTextOptions.SUB_HEADLINE,
  RichTextOptions.CITATION,
  RichTextOptions.PARAGRAPH,
];

export default function createEditor({ options }: { options: RichTextOptions[] }) {
  const editor = createSlateEditor();
  editor.renderers = [];
  editor.toolbarButtons = {
    block: [],
    mark: [],
    link: [],
    list: [],
    alignment: [],
  };
  editor.hotkeys = [];
  editor.options = options;
  editor.markOrInlineBlockAllowed = () => true;

  Object.defineProperty(editor, 'blockOptions', {
    get() {
      return BLOCK_OPTIONS.filter((option) => this.options.includes(option));
    },
  });

  Object.defineProperty(editor, 'onlyOneBlockOption', {
    get() {
      return this.blockOptions.length === 1;
    },
  });

  Object.defineProperty(editor, 'paragraphPresent', {
    get() {
      return this.blockOptions.includes(RichTextOptions.PARAGRAPH);
    },
  });

  // normalization
  normalizeBlocks(editor);
  normalizeInline(editor);
  normalizeTextNodes(editor);

  const { deleteBackward } = editor;

  editor.deleteBackward = (unit) => {
    if (editor.selection == null || !Range.isCollapsed(editor.selection)) {
      return deleteBackward(unit);
    }

    const [entry] = Editor.nodes(editor, {
      mode: 'lowest',
      match: (node): node is Element => !Editor.isEditor(node) && Element.isElement(node),
    });

    if (entry != null) {
      const [node, path] = entry;

      if (Editor.isInline(editor, node) && Node.string(node) === '') {
        return Transforms.removeNodes(editor, { at: path });
      }
    }

    return deleteBackward(unit);
  };

  return editor;
}

function normalizeTextNodes(editor: Editor) {
  const { normalizeNode } = editor;

  // Add type attribute to all text nodes.
  editor.normalizeNode = ([node, path]) => {
    if (path.length > 0) {
      if (node.type == null && (node as any).text != null) {
        Transforms.setNodes(editor, { type: 'text' }, { at: path });
      }
    }

    return normalizeNode([node, path]);
  };
}
