/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { EventEvaluationAudienceStatusDto } from './EventEvaluationAudienceStatusDto';
import {
    EventEvaluationAudienceStatusDtoFromJSON,
    EventEvaluationAudienceStatusDtoFromJSONTyped,
    EventEvaluationAudienceStatusDtoToJSON,
} from './EventEvaluationAudienceStatusDto';
import type { EventEvaluationStatusDto } from './EventEvaluationStatusDto';
import {
    EventEvaluationStatusDtoFromJSON,
    EventEvaluationStatusDtoFromJSONTyped,
    EventEvaluationStatusDtoToJSON,
} from './EventEvaluationStatusDto';
import type { EventEvaluationTechnicalDisruptionsDto } from './EventEvaluationTechnicalDisruptionsDto';
import {
    EventEvaluationTechnicalDisruptionsDtoFromJSON,
    EventEvaluationTechnicalDisruptionsDtoFromJSONTyped,
    EventEvaluationTechnicalDisruptionsDtoToJSON,
} from './EventEvaluationTechnicalDisruptionsDto';
import type { StaffAccountReferenceDto } from './StaffAccountReferenceDto';
import {
    StaffAccountReferenceDtoFromJSON,
    StaffAccountReferenceDtoFromJSONTyped,
    StaffAccountReferenceDtoToJSON,
} from './StaffAccountReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Event evaluation
 * @export
 * @interface EventEvaluationDto
 */
export interface EventEvaluationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof EventEvaluationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof EventEvaluationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof EventEvaluationDto
     */
    realisation: EventEvaluationDtoRealisationEnum;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof EventEvaluationDto
     */
    delayedDateTimeRange?: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {number}
     * @memberof EventEvaluationDto
     */
    ticketsIssued?: number;
    /**
     * 
     * @type {number}
     * @memberof EventEvaluationDto
     */
    accessWithTicket?: number;
    /**
     * 
     * @type {number}
     * @memberof EventEvaluationDto
     */
    accessWithoutTicket?: number;
    /**
     * 
     * @type {number}
     * @memberof EventEvaluationDto
     */
    occupancy: number;
    /**
     * 
     * @type {string}
     * @memberof EventEvaluationDto
     */
    filmTeam: EventEvaluationDtoFilmTeamEnum;
    /**
     * List of technical disruptions
     * @type {Array<EventEvaluationTechnicalDisruptionsDto>}
     * @memberof EventEvaluationDto
     */
    technicalDisruption?: Array<EventEvaluationTechnicalDisruptionsDto>;
    /**
     * 
     * @type {EventEvaluationAudienceStatusDto}
     * @memberof EventEvaluationDto
     */
    audienceEntry: EventEvaluationAudienceStatusDto;
    /**
     * 
     * @type {EventEvaluationAudienceStatusDto}
     * @memberof EventEvaluationDto
     */
    audienceExit: EventEvaluationAudienceStatusDto;
    /**
     * 
     * @type {string}
     * @memberof EventEvaluationDto
     */
    furtherInformation?: string;
    /**
     * 
     * @type {EventEvaluationStatusDto}
     * @memberof EventEvaluationDto
     */
    status: EventEvaluationStatusDto;
    /**
     * 
     * @type {StaffAccountReferenceDto}
     * @memberof EventEvaluationDto
     */
    reviewer?: StaffAccountReferenceDto;
}


/**
 * @export
 */
export const EventEvaluationDtoRealisationEnum = {
    ON_SCHEDULE: 'ON_SCHEDULE',
    DELAYED: 'DELAYED'
} as const;
export type EventEvaluationDtoRealisationEnum = typeof EventEvaluationDtoRealisationEnum[keyof typeof EventEvaluationDtoRealisationEnum];

/**
 * @export
 */
export const EventEvaluationDtoFilmTeamEnum = {
    PRESENT: 'PRESENT',
    NOT_PRESENT: 'NOT_PRESENT'
} as const;
export type EventEvaluationDtoFilmTeamEnum = typeof EventEvaluationDtoFilmTeamEnum[keyof typeof EventEvaluationDtoFilmTeamEnum];


/**
 * Check if a given object implements the EventEvaluationDto interface.
 */
export function instanceOfEventEvaluationDto(value: object): boolean {
    if (!('realisation' in value)) return false;
    if (!('occupancy' in value)) return false;
    if (!('filmTeam' in value)) return false;
    if (!('audienceEntry' in value)) return false;
    if (!('audienceExit' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function EventEvaluationDtoFromJSON(json: any): EventEvaluationDto {
    return EventEvaluationDtoFromJSONTyped(json, false);
}

export function EventEvaluationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventEvaluationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'realisation': json['realisation'],
        'delayedDateTimeRange': json['delayedDateTimeRange'] == null ? undefined : ClosedDateTimeRangeDtoFromJSON(json['delayedDateTimeRange']),
        'ticketsIssued': json['ticketsIssued'] == null ? undefined : json['ticketsIssued'],
        'accessWithTicket': json['accessWithTicket'] == null ? undefined : json['accessWithTicket'],
        'accessWithoutTicket': json['accessWithoutTicket'] == null ? undefined : json['accessWithoutTicket'],
        'occupancy': json['occupancy'],
        'filmTeam': json['filmTeam'],
        'technicalDisruption': json['technicalDisruption'] == null ? undefined : ((json['technicalDisruption'] as Array<any>).map(EventEvaluationTechnicalDisruptionsDtoFromJSON)),
        'audienceEntry': EventEvaluationAudienceStatusDtoFromJSON(json['audienceEntry']),
        'audienceExit': EventEvaluationAudienceStatusDtoFromJSON(json['audienceExit']),
        'furtherInformation': json['furtherInformation'] == null ? undefined : json['furtherInformation'],
        'status': EventEvaluationStatusDtoFromJSON(json['status']),
        'reviewer': json['reviewer'] == null ? undefined : StaffAccountReferenceDtoFromJSON(json['reviewer']),
    };
}

export function EventEvaluationDtoToJSON(value?: EventEvaluationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'realisation': value['realisation'],
        'delayedDateTimeRange': ClosedDateTimeRangeDtoToJSON(value['delayedDateTimeRange']),
        'ticketsIssued': value['ticketsIssued'],
        'accessWithTicket': value['accessWithTicket'],
        'accessWithoutTicket': value['accessWithoutTicket'],
        'occupancy': value['occupancy'],
        'filmTeam': value['filmTeam'],
        'technicalDisruption': value['technicalDisruption'] == null ? undefined : ((value['technicalDisruption'] as Array<any>).map(EventEvaluationTechnicalDisruptionsDtoToJSON)),
        'audienceEntry': EventEvaluationAudienceStatusDtoToJSON(value['audienceEntry']),
        'audienceExit': EventEvaluationAudienceStatusDtoToJSON(value['audienceExit']),
        'furtherInformation': value['furtherInformation'],
        'status': EventEvaluationStatusDtoToJSON(value['status']),
        'reviewer': StaffAccountReferenceDtoToJSON(value['reviewer']),
    };
}

