import { extendTheme, Tooltip, TooltipProps, withDefaultColorScheme } from '@chakra-ui/react';
import Accordion from './component/accordion';
import Alert from './component/alert';
import Breadcrumb from './component/breadcrumb';
import Button from './component/button';
import Checkbox from './component/checkbox';
import Container from './component/container';
import DataTable from './component/data-table';
import Divider from './component/divider';
import Drawer from './component/drawer';
import Form from './component/form';
import Heading from './component/heading';
import Input from './component/input';
import Link from './component/link';
import Menu from './component/menu';
import Modal from './component/modal';
import NumberInput from './component/number-input';
import Popover from './component/popover';
import ReactSelect from './component/react-select';
import RichText from './component/rich-text';
import Select from './component/select';
import Spinner from './component/spinner';
import { Switch } from './component/switch';
import Table from './component/table';
import Tag from './component/tag';
import Textarea from './component/textarea';
import foundation from './foundation';
import semanticTokens from './semantic-tokens';
import styles from './styles';
import './fonts/fonts.css';

// lh: At the moment these properties cannot be overwritten via theme.
// See https://github.com/chakra-ui/chakra-ui/issues/1424
Tooltip.defaultProps = {
  ...Tooltip.defaultProps,
  hasArrow: true,
  arrowSize: 8,
  openDelay: 400,
} as TooltipProps;

const theme = extendTheme(withDefaultColorScheme({ colorScheme: 'teal' }), {
  ...foundation,
  semanticTokens,
  styles,
  components: {
    Alert,
    Modal,
    Heading,
    Select,
    Input,
    NumberInput,
    Textarea,
    Table,
    DataTable,
    Container,
    Checkbox,
    Spinner,
    Tag,
    Button,
    Popover,
    Link,
    Breadcrumb,
    Accordion,
    ReactSelect,
    Form,
    Menu,
    Drawer,
    Divider,
    Switch,
    RichText,
  },
});

export default theme;
