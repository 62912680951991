import { EditionReferenceDto } from '../../api';
import { editionIcon } from '../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../ui/reference/reference';

interface EditionReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  edition: EditionReferenceDto;
}

export default function EditionReference({ edition, ...props }: EditionReferenceProps) {
  return (
    <Reference
      to={'/editions/' + edition.id}
      icon={editionIcon}
      necessaryPermission="EDITION:CAN_SEE_DETAILS"
      {...props}
    >
      {edition.name}
    </Reference>
  );
}
