import { snakeCase } from 'lodash-es';
import { BlockChildDto, BlockElementDto, InlineElementDto, MarkElementDto } from '../../api';
import createFormatter, { Formatter } from '../formatter/create-formatter';

export function richTextAsMarkup(textModuleContent: BlockElementDto[]) {
  const formatter = createFormatter();

  textModuleContent.forEach((content, index) => {
    if (index > 0) {
      formatter.line();
    }

    blockElementAsMarkup(formatter, content as BlockChildDto);
  });

  return formatter.format();
}

function blockElementAsMarkup(formatter: Formatter, element: BlockChildDto) {
  if (element.type === 'text') {
    markElementAsMarkup(formatter, element);
  } else if (element.type === 'link' || element.type === 'linkButton') {
    inlineElementAsMarkup(formatter, element);
  } else {
    asMarkup(
      formatter,
      {
        ...element,
        attributes: { author: element.author, align: element.align },
      },
      () => {
        element.children?.forEach((element) => {
          blockElementAsMarkup(formatter, element as BlockChildDto);
        });
      },
    );
  }
}

function markElementAsMarkup(formatter: Formatter, element: MarkElementDto) {
  let text = element.text;

  if (text == null || text === '') {
    return;
  }

  if (element.italic) {
    text = text.replace(/(.*)(\n)?/, '<italic>$1</italic>$2');
  }

  if (element.bold) {
    text = text.replace(/(.*)(\n)?/, '<bold>$1</bold>$2');
  }

  if (element.small) {
    text = text.replace(/(.*)(\n)?/, '<small>$1</small>$2');
  }

  text = text.replace('\n', '<br/>');

  formatter.line(text);
}

function inlineElementAsMarkup(formatter: Formatter, element: InlineElementDto) {
  return asMarkup(
    formatter,
    {
      type: element.type,
      attributes: element.url != null ? { url: element.url } : undefined,
    },
    () => {
      element.children?.forEach((element) => {
        markElementAsMarkup(formatter, element);
      });
    },
  );
}

function asMarkup(
  formatter: Formatter,
  {
    type,
    attributes,
  }: {
    type: string;
    attributes?: Record<string, string | undefined>;
  },
  content: () => void,
) {
  type = snakeCase(type);

  const attributeStrings =
    attributes != null
      ? Object.entries(attributes)
          .filter((entry): entry is [string, string] => entry[1] != null)
          .map(([name, value]) => `${name}="${value.replaceAll('"', '\\"')}"`)
      : [];
  formatter.line(`<${type}${attributeStrings.length > 0 ? ` ${attributeStrings.join(' ')}` : ''}>`);

  formatter.indent(content);

  formatter.line(`</${type}>`);
}

export function isContentEmpty(blockElements: BlockElementDto[] | undefined): boolean {
  const isChildEmpty = (blockChild: BlockChildDto): boolean => {
    if (blockChild.type === 'text') {
      return blockChild.text == null || blockChild.text === '';
    }

    if (blockChild.type === 'link' || blockChild.type === 'linkButton') {
      return blockChild.url == null || blockChild.url === '';
    }

    return blockChild.children == null || blockChild.children.every((blockChild) => isChildEmpty(blockChild));
  };

  return blockElements == null || blockElements.every((blockChild) => isChildEmpty(blockChild as BlockChildDto));
}

export function richTextAsString(textModuleContent: BlockElementDto[] | undefined) {
  return textModuleContent?.flatMap((content) => content.children?.map((children) => blockChildAsString(children)));
}

function blockChildAsString(element: BlockChildDto): string {
  if (element.type === 'text') {
    return element.text ?? '';
  }

  return element.children?.map((blockChild) => blockChildAsString(blockChild as BlockChildDto)).join('') ?? '';
}
