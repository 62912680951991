import { chakra } from '@chakra-ui/react';
import { faItalic } from '@fortawesome/pro-regular-svg-icons';
import React, { ComponentPropsWithoutRef } from 'react';
import { toggleMark } from '../mark';
import { Render } from '../render';
import { HotkeyAction, WithFunction } from '../slate-types';
import { useRichTextStyles } from '../styles-context';
import { MarkToolbarButton } from '../toolbar/toolbar-button';

const ITALIC = 'italic';

const withItalic: WithFunction = (editor) => {
  const renderItalic: Render<'mark'> = {
    type: ITALIC,
    render: (children) => <Italic>{children}</Italic>,
  };
  editor.renderers = [...editor.renderers, renderItalic];

  editor.toolbarButtons.mark = [...editor.toolbarButtons.mark, <MarkToolbarButton format={ITALIC} icon={faItalic} />];

  const hotkey: HotkeyAction = { hotkey: 'mod+i', action: () => toggleMark(editor, ITALIC) };
  editor.hotkeys = [...editor.hotkeys, hotkey];

  return editor;
};

export default withItalic;

function Italic({ children, ...props }: ComponentPropsWithoutRef<typeof chakra.em>) {
  const styles = useRichTextStyles();

  return (
    <chakra.em __css={styles.italic} {...props}>
      {children}
    </chakra.em>
  );
}
