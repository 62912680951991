import { Alert, AlertIcon, HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import {
  CountryReferenceDto,
  CountryStatusDto,
  PersonDto,
  PostalAddressDto,
  PostalAddressDtoLabelEnum,
} from '../../../api';
import countryApi from '../../../data-access/country-api';
import { ElementFormModal, ElementTableControl, useElementForm } from '../../../ui/form/element-control';
import AddElementButton from '../../../ui/form/element-control/add-element-button';
import DeleteElementButton from '../../../ui/form/element-control/delete-element-button';
import EditElementButton from '../../../ui/form/element-control/edit-element-button';
import InputFormControl from '../../../ui/form/input-form-control';
import ValueAsyncSelectFormControl from '../../../ui/form/select-control/value-async-select-form-control';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import { postalAddressToString } from '../../../ui/postal-address/render-postal-address';
import now from '../../../util/now';
import { LayoutType } from '../../common/layout-type';
import addressDataTableColumns from '../person-table-columns/postal-address-columns';

export interface PostalAddressesControlProps<T extends FieldValues> {
  layout: LayoutType;
  path?: FieldPath<T>;
  showAddButton?: boolean;
}

export default function PostalAddressesControl<T extends FieldValues>({
  layout,
  path,
  showAddButton = true,
}: PostalAddressesControlProps<T>) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation('person');

  return (
    <ElementTableControl<PersonDto, PostalAddressDto>
      label={t('contacts.postal_address.header')}
      addButton={
        showAddButton ? (
          <AddElementButton label={t('contacts.postal_address.add')} formModal={<PostalAddressFormModal />} />
        ) : undefined
      }
      editButton={
        <EditElementButton label={t('contacts.postal_address.edit')} formModal={<PostalAddressFormModal />} />
      }
      deleteButton={
        <DeleteElementButton<PostalAddressDto>
          label={t('contacts.postal_address.delete')}
          renderDeleteMessage={(address) => (
            <Trans
              t={t}
              i18nKey="contacts.postal_address.delete_message"
              values={{ address: postalAddressToString(address) }}
            />
          )}
        />
      }
      name={prefixWithPath('postalAddresses')}
      columns={addressDataTableColumns}
      layout={layout}
    />
  );
}

const POSTAL_ADDRESS_LABEL_OPTIONS = [PostalAddressDtoLabelEnum.PRIVATE, PostalAddressDtoLabelEnum.OTHER];

function PostalAddressFormModal() {
  const { t } = useTranslation(['common', 'person']);
  const { element: postalAddress, onSubmit } = useElementForm<PostalAddressDto>();
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { register } = useFormContext<PostalAddressDto>();
  const COUNTRY_PAGE_SIZE = 500;
  register('timestamp');

  return (
    <ElementFormModal<PostalAddressDto>
      onSubmit={onSubmit}
      element={postalAddress}
      initialFocusRef={initialFocusRef}
      defaultElement={{ label: PostalAddressDtoLabelEnum.PRIVATE, timestamp: new Date(now()) }}
    >
      <Stack spacing={4}>
        <Alert status="info" mt={3} id="postalAddress-info" aria-live="polite">
          <AlertIcon />
          {t('person:contacts.postal_address.dialog.info')}
        </Alert>
        <InputFormControl<PostalAddressDto>
          label={t('person:contacts.postal_address.address')}
          name="address"
          helperText={t('person:contacts.postal_address.address_info')}
          ref={initialFocusRef}
        />
        <InputFormControl<PostalAddressDto>
          label={t('person:contacts.postal_address.address_supplement')}
          name="addressSupplement"
        />
        <HStack alignItems="flex-start">
          <InputFormControl<PostalAddressDto>
            label={t('person:contacts.postal_address.dialog.post_code')}
            name="postCode"
          />
          <InputFormControl<PostalAddressDto>
            label={t('person:contacts.postal_address.dialog.city')}
            name="city"
            isRequired
          />
        </HStack>
        <HStack>
          <InputFormControl<PostalAddressDto> label={t('person:contacts.postal_address.state')} name="state" />
        </HStack>
        <ValueAsyncSelectFormControl<CountryReferenceDto>
          name="country"
          label={t('person:contacts.postal_address.dialog.state')}
          isRequired
          loadOptions={async (value: string) => {
            const page = await countryApi.searchCountryReferences({
              pageable: { size: COUNTRY_PAGE_SIZE },
              filter: [`status,eq,${CountryStatusDto.ACTIVE}`],
              q: value,
            });

            return page.content.map((country) => ({
              id: country.id!,
              name: country.name,
              englishName: country.englishName,
            }));
          }}
          renderLabel={(country) => country.name}
          optionIdentifier={(country) => country.id!}
          defaultOptions
        />
        <ValueSelectFormControl<PostalAddressDtoLabelEnum>
          name="label"
          label={t('person:contacts.postal_address.label')}
          isRequired
          options={POSTAL_ADDRESS_LABEL_OPTIONS}
          renderLabel={(value) => t(`person:contacts.postal_address.labelOptions.${value}`)}
          defaultValue={PostalAddressDtoLabelEnum.PRIVATE}
        />
      </Stack>
    </ElementFormModal>
  );
}
