/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LandingPagePermissionDto
 */
export interface LandingPagePermissionDto {
    /**
     * 
     * @type {string}
     * @memberof LandingPagePermissionDto
     */
    name: LandingPagePermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof LandingPagePermissionDto
     */
    action: LandingPagePermissionDtoActionEnum;
}


/**
 * @export
 */
export const LandingPagePermissionDtoNameEnum = {
    LANDING_PAGE: 'LANDING_PAGE'
} as const;
export type LandingPagePermissionDtoNameEnum = typeof LandingPagePermissionDtoNameEnum[keyof typeof LandingPagePermissionDtoNameEnum];

/**
 * @export
 */
export const LandingPagePermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    COPY: 'CAN_COPY',
    DELETE: 'CAN_DELETE'
} as const;
export type LandingPagePermissionDtoActionEnum = typeof LandingPagePermissionDtoActionEnum[keyof typeof LandingPagePermissionDtoActionEnum];


/**
 * Check if a given object implements the LandingPagePermissionDto interface.
 */
export function instanceOfLandingPagePermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function LandingPagePermissionDtoFromJSON(json: any): LandingPagePermissionDto {
    return LandingPagePermissionDtoFromJSONTyped(json, false);
}

export function LandingPagePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPagePermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function LandingPagePermissionDtoToJSON(value?: LandingPagePermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

