import { filmIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useFilmPermissionClassGroupExtension } from './film-permissions';
import FilmSearchResult from './film-search-result';

export default function FilmPluginProvider(registry: PluginRegistry) {
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useFilmPermissionClassGroupExtension);
  registry.register(SEARCH_FILTER, {
    key: 'FILM',
    necessaryPermission: 'FILM:CAN_SEE_DETAILS',
    icon: filmIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.FILM" />,
  });
  registry.register(SEARCH_RESULT_CONTENT, { type: 'FILM', Component: FilmSearchResult });
}
