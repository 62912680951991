/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OperatorTypeDto = {
    EQUAL: 'EQUAL',
    NOT_EQUAL: 'NOT_EQUAL',
    LESS_THAN: 'LESS_THAN',
    LESS_OR_EQUAL: 'LESS_OR_EQUAL',
    GREATER_THAN: 'GREATER_THAN',
    GREATER_OR_EQUAL: 'GREATER_OR_EQUAL',
    IN: 'IN',
    NOT_IN: 'NOT_IN',
    LIKE: 'LIKE',
    NOT_LIKE: 'NOT_LIKE',
    REGEX_MATCH: 'REGEX_MATCH',
    NOT_REGEX_MATCH: 'NOT_REGEX_MATCH',
    IS_NULL: 'IS_NULL',
    IS_NOT_NULL: 'IS_NOT_NULL',
    EQUAL_PATH: 'EQUAL_PATH',
    NOT_EQUAL_PATH: 'NOT_EQUAL_PATH'
} as const;
export type OperatorTypeDto = typeof OperatorTypeDto[keyof typeof OperatorTypeDto];


export function OperatorTypeDtoFromJSON(json: any): OperatorTypeDto {
    return OperatorTypeDtoFromJSONTyped(json, false);
}

export function OperatorTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatorTypeDto {
    return json as OperatorTypeDto;
}

export function OperatorTypeDtoToJSON(value?: OperatorTypeDto | null): any {
    return value as any;
}

