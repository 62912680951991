import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { mailingBlocklistIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function MailingBlocklistSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'BLOCKLIST', `${result.resultType} is no instance of MailingBlocklistSearchResult`);

  const blocklistEntry = result.result;
  const { t } = useTranslation('mailing_blocklist');

  return (
    <SearchResult
      icon={mailingBlocklistIcon}
      title={t('lister.title')}
      link="/mailing-blocklist"
      score={result.score}
      onClick={onClick}
      necessaryPermission="MAILING_BLOCKLIST:CAN_SEE_LIST_VIEW"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('email'),
            element: blocklistEntry.emailAddress,
          },
        ]}
      />
    </SearchResult>
  );
}
