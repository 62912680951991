import { Stack } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import {
  EventStatusDto,
  LiveTicketingPublicationDto,
  LiveTicketingPublicationPageDto,
  PublicationDto,
} from '../../../api';
import liveTicketingPublicationApi from '../../../data-access/live-ticketing-publication-api';
import PageContent from '../../../ui/page/page-content';
import useFetcher from '../../../util/swr/use-fetcher';
import PublicationDataTable from '../../event/publication/publication-data-table';
import usePublicationColumns from '../../event/publication/publication-table-columns';
import useEvent from '../../event/use-event/use-event';
import usePermission from '../../permission/use-permission';
import {
  fetchLiveTicketingPublications,
  fetchLiveTicketingSettings,
  liveTicketingPublication,
} from '../live-ticketing-queries';
import { LiveTicketingEventNotScheduledWarning } from '../live-ticketing-viewer/live-ticketing-viewer';

export default function LiveTicketingPublicationLister() {
  const { eventId } = useParams<{ eventId: string }>();
  invariant(eventId != null, 'Missing eventId');
  const { hasPermission, hasPermissionFromSection } = usePermission();
  const event = useEvent(eventId);

  const isOwnerForEdit =
    hasPermission('LIVE_TICKETING:CAN_EDIT') ||
    event.owners
      .map((owner) => owner.section.id)
      .some((sectionId) => hasPermissionFromSection(sectionId, 'LIVE_TICKETING:CAN_EDIT_OWN'));

  const publicationSettings = useFetcher(fetchLiveTicketingSettings, { eventId });
  invariant(publicationSettings, 'publication needs to be actived before it can be published');

  const onSuspend = async (publication: PublicationDto) => {
    await liveTicketingPublicationApi.suspendLiveTicketingPublication({
      eventId: eventId,
      publicationId: publication.id,
    });
    await liveTicketingPublication.mutate();
  };

  const baseColumns = usePublicationColumns();

  return (
    <PageContent useFullWidth fixedHeight>
      <Stack>
        {event.status !== EventStatusDto.SCHEDULED && <LiveTicketingEventNotScheduledWarning />}
        <PublicationDataTable<LiveTicketingPublicationDto, LiveTicketingPublicationPageDto>
          canSuspendPublication={isOwnerForEdit}
          baseColumns={baseColumns}
          onSuspend={onSuspend}
          publicationFetcher={fetchLiveTicketingPublications}
          linkContext="live-ticketing"
        />
      </Stack>
    </PageContent>
  );
}
