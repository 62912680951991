/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamPermissionDto
 */
export interface TeamPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof TeamPermissionDto
     */
    name: TeamPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamPermissionDto
     */
    action: TeamPermissionDtoActionEnum;
}


/**
 * @export
 */
export const TeamPermissionDtoNameEnum = {
    TEAM: 'TEAM'
} as const;
export type TeamPermissionDtoNameEnum = typeof TeamPermissionDtoNameEnum[keyof typeof TeamPermissionDtoNameEnum];

/**
 * @export
 */
export const TeamPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    LINK_STAFF_WITH_TEAM: 'CAN_LINK_STAFF_WITH_TEAM',
    ACTIVATE_REACTIVATE: 'CAN_ACTIVATE_REACTIVATE'
} as const;
export type TeamPermissionDtoActionEnum = typeof TeamPermissionDtoActionEnum[keyof typeof TeamPermissionDtoActionEnum];


/**
 * Check if a given object implements the TeamPermissionDto interface.
 */
export function instanceOfTeamPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function TeamPermissionDtoFromJSON(json: any): TeamPermissionDto {
    return TeamPermissionDtoFromJSONTyped(json, false);
}

export function TeamPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function TeamPermissionDtoToJSON(value?: TeamPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

