/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface ReportListItemDto
 */
export interface ReportListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ReportListItemDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof ReportListItemDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof ReportListItemDto
     */
    name: string;
}

/**
 * Check if a given object implements the ReportListItemDto interface.
 */
export function instanceOfReportListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function ReportListItemDtoFromJSON(json: any): ReportListItemDto {
    return ReportListItemDtoFromJSONTyped(json, false);
}

export function ReportListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'name': json['name'],
    };
}

export function ReportListItemDtoToJSON(value?: ReportListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
    };
}

