/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilmReferenceDto } from './FilmReferenceDto';
import {
    FilmReferenceDtoFromJSON,
    FilmReferenceDtoFromJSONTyped,
    FilmReferenceDtoToJSON,
} from './FilmReferenceDto';
import type { GroupReferenceDto } from './GroupReferenceDto';
import {
    GroupReferenceDtoFromJSON,
    GroupReferenceDtoFromJSONTyped,
    GroupReferenceDtoToJSON,
} from './GroupReferenceDto';

/**
 * The relation between a group and a film.
 * @export
 * @interface CreateGroupFilmRelationDto
 */
export interface CreateGroupFilmRelationDto {
    /**
     * 
     * @type {GroupReferenceDto}
     * @memberof CreateGroupFilmRelationDto
     */
    group: GroupReferenceDto;
    /**
     * 
     * @type {FilmReferenceDto}
     * @memberof CreateGroupFilmRelationDto
     */
    film: FilmReferenceDto;
}

/**
 * Check if a given object implements the CreateGroupFilmRelationDto interface.
 */
export function instanceOfCreateGroupFilmRelationDto(value: object): boolean {
    if (!('group' in value)) return false;
    if (!('film' in value)) return false;
    return true;
}

export function CreateGroupFilmRelationDtoFromJSON(json: any): CreateGroupFilmRelationDto {
    return CreateGroupFilmRelationDtoFromJSONTyped(json, false);
}

export function CreateGroupFilmRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateGroupFilmRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'group': GroupReferenceDtoFromJSON(json['group']),
        'film': FilmReferenceDtoFromJSON(json['film']),
    };
}

export function CreateGroupFilmRelationDtoToJSON(value?: CreateGroupFilmRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'group': GroupReferenceDtoToJSON(value['group']),
        'film': FilmReferenceDtoToJSON(value['film']),
    };
}

