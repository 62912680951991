import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  ButtonGroup,
  chakra,
  Flex,
  HStack,
  Spacer,
  Stack,
  useId,
} from '@chakra-ui/react';
import { endOfDay, startOfDay } from 'date-fns';
import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { EditionReferenceDto, EventDto, EventDtoFromJSON, EventStatusDto } from '../../../api';
import eventApi from '../../../data-access/event-api';
import AccordionIcon from '../../../ui/accordion/accordion-icon';
import useAccordionState from '../../../ui/accordion/use-accordion-state';
import Dialog from '../../../ui/dialog/dialog';
import Form from '../../../ui/form/form';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import SubmitButton from '../../../ui/form/submit-button';
import TranslationWithLink from '../../../ui/form/translation-with-link';
import useAccordionForm, { AccordionFormItem } from '../../../ui/form/use-accordion-form/use-accordion-form';
import useWatchChange from '../../../ui/form/use-watch-change/use-watch-change';
import useOptimisticLockingDialog from '../../../ui/optimistic-locking/use-optimistic-locking-dialog';
import { ResetButton } from '../../../ui/reset-button/reset-button';
import useUnsavedChangesPrompt from '../../../ui/use-unsaved-changes-prompt/use-unsaved-changes-prompt';
import AnchorLinkDestination from '../../../util/anchor-link-destination/anchor-link-destination';
import HistoryBackButton from '../../../util/history/history-back-button';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import useAsyncValidation, { AsyncValidationCallback } from '../../../util/use-async-validation/use-async-validation';
import useDialog from '../../../util/use-dialog/use-dialog';
import { LayoutType } from '../../common/layout-type';
import useEditions from '../../edition/use-editions/use-editions';
import { useFocusedOrActiveEditionId } from '../../edition/use-focused-or-active-edition/use-focused-or-active-edition-id';
import CopySettingsDialog, { EventCopySettings } from '../copy-settings-dialog/copy-settings-dialog';
import { EventEditorAction } from '../event-editor/event-editor';
import { statusOptions } from '../event-enum-constants';
import useEventHistorySettings from '../event-history/use-event-history-settings';
import ClassificationAndAccessControl from './classification-and-access-control';
import ConfirmChangeDialog from './confirm-change-dialog';
import EditionSelectionControl from './edition-selection-control';
import EventDateTimeRangeControl from './event-date-time-range-control';
import EventEventConnectionControl from './event-event-connection-control';
import { EventSupertype, eventSupertypeOfEventType } from './event-supertype';
import EventVisibilityControl from './event-visibility-control';
import ExtrasControl from './extras-control';
import LinksControl from './links-control';
import OwnersControl from './owners-control';
import SecurityControl from './security-control';
import VenueControl from './venue-control';

// matches /events/${entityId}/edit
const editEventRegex = /^\/events\/[\da-f-]*\/edit$/;

const accordionItems: AccordionFormItem<EventDto>[] = [
  {
    name: 'venue',
    button: <Translate ns="event">{(t) => t('venueGroupLabel')}</Translate>,
    panel: <VenueControl />,
    controls: ['venue'],
  },
  {
    name: 'classificationAndAccess',
    button: <Translate ns="event">{(t) => t('classification_and_access')}</Translate>,
    panel: <ClassificationAndAccessControl />,
    controls: ['accessControls', 'type'],
  },
  {
    name: 'security',
    button: <Translate ns="event">{(t) => t('security')}</Translate>,
    panel: <SecurityControl />,
  },
  {
    name: 'links',
    button: <Translate ns="event">{(t) => t('linksLabel')}</Translate>,
    panel: <LinksControl />,
  },
  {
    name: 'extras',
    button: <Translate ns="event">{(t) => t('extras')}</Translate>,
    panel: <ExtrasControl />,
    controls: ['partners'],
  },
];

type EventFormType = EventDto & { supertype?: EventSupertype };

export type EventCopyInfo = ({ eventId }: { eventId: string }) => JSX.Element;
export const EVENT_COPY_INFO = new PluginToken<EventCopyInfo>('EventCopyInfo');

export interface EventFormProps {
  event?: DefaultValues<EventDto>;
  layout: LayoutType;
  editorAction: EventEditorAction;

  onEventSave(event: EventDto, copySettings: EventCopySettings): Promise<void>;
}

/**
 * Represents the form of an {@link EventDto} (deutsch: Veranstaltung).
 */
export default function EventForm({ event, onEventSave, layout, editorAction }: EventFormProps) {
  const [mindate, setMindate] = React.useState<Date>(new Date());
  const [maxdate, setMaxdate] = React.useState<Date>(new Date());
  const previousEdition = React.useRef<EditionReferenceDto>();
  const { t } = useTranslation(['common', 'event']);
  const form = useForm<EventFormType>({
    mode: 'all',
    defaultValues: {
      ...event,
      supertype: (event?.type ? eventSupertypeOfEventType[event.type] : EventSupertype.MOVIE) as EventSupertype,
      partners: event?.partners ?? { partnerType: undefined, companies: [] },
    },
  });
  const copyInfoAlertTitleId = useId(undefined, 'copy-info');
  const { eventId } = useParams<{ eventId: string }>();
  const focusedEditionId = useFocusedOrActiveEditionId();
  const eventCopyInfo = usePlugins(EVENT_COPY_INFO);

  const hasCopyInfo =
    editorAction === EventEditorAction.COPY ? eventCopyInfo?.some((e) => e({ eventId: eventId! }) != null) : false;

  const reset = () => form.reset();

  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const eventHistorySettings = useEventHistorySettings();
  const { catchOptimisticLockingFailure, optimisticLockDialog } = useOptimisticLockingDialog<EventFormType>({
    historyDisplaySettings: eventHistorySettings,
    form,
    mapFunction: EventDtoFromJSON,
    clearEntity: cleanUpEvent,
  });

  const isDirty = Object.keys(form.formState.dirtyFields).length > 0;
  const navigate = useNavigate();

  useUnsavedChangesPrompt({ formState: form.formState, promptExemptions: editEventRegex });

  const navigateToEvent = useCallback(
    (entityId: string) => {
      navigate(`/events/${entityId}/edit`);
    },
    [navigate],
  );

  const editionId = form.getValues('edition.id');

  const validateTitle = useAsyncValidation(
    createEventTitleValidationFunction(event?.id, editionId, focusedEditionId, false, t, navigateToEvent),
  );

  const validateEnglishTitle = useAsyncValidation(
    createEventTitleValidationFunction(event?.id, editionId, focusedEditionId, true, t, navigateToEvent),
  );

  const [isConfirmDialogOpen, onConfirmDialogClose, openConfirmDialog] = useDialog();
  const [isInfoDialogOpen, onInfoDialogClose, openInfoDialog] = useDialog();
  const [isCopyListDialogOpen, onCopyListDialogClose, openCopyListDialog] = useDialog();
  const ref = React.useRef<HTMLButtonElement>(null);
  const copySettingsRef = React.useRef<EventCopySettings>({
    sourceEventId: eventId ?? '',
    copyKeyPlayerList: false,
    copyRundown: false,
    copyGuestList: false,
  });

  const handleValid = React.useCallback(
    async (editedEvent: EventDto) => {
      editedEvent = cleanUpEvent(editedEvent);

      if (editorAction === EventEditorAction.COPY) {
        if (await openCopyListDialog()) {
          if (copySettingsRef.current.copyKeyPlayerList && hasCopyInfo) {
            await openInfoDialog();
          }
        } else {
          return;
        }
      }

      if (
        editorAction === EventEditorAction.NEW ||
        (event?.status === editedEvent.status &&
          event?.visible === editedEvent.visible &&
          event?.edition?.id === editedEvent.edition.id) ||
        editedEvent.visible ||
        (await openConfirmDialog())
      ) {
        await catchOptimisticLockingFailure(() => onEventSave(editedEvent, copySettingsRef.current));
      }
    },
    [
      editorAction,
      event?.status,
      event?.visible,
      event?.edition?.id,
      openConfirmDialog,
      openCopyListDialog,
      hasCopyInfo,
      openInfoDialog,
      copySettingsRef,
      catchOptimisticLockingFailure,
      onEventSave,
    ],
  );

  const [expandedIndices, setExpandedIndices] = useAccordionState(accordionItems);
  const { handleInvalid } = useAccordionForm(accordionItems, setExpandedIndices);

  const [editedStatus, setEditedStatus] = React.useState<EventStatusDto | undefined>(undefined);
  const [visible, setVisible] = React.useState<boolean | undefined>(undefined);
  const [edition, setEdition] = React.useState<EditionReferenceDto>(event?.edition as EditionReferenceDto);

  useWatchChange<EventDto>(
    ['status', 'visible', 'edition'],
    async (e) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      e.status !== event?.status ? setEditedStatus(e.status) : setEditedStatus(undefined);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      e.visible !== event?.visible ? setVisible(e.visible) : setVisible(undefined);

      if (e.edition !== event?.edition) {
        previousEdition.current = event?.edition as EditionReferenceDto;
        setEdition(e.edition);
      } else {
        setEdition(event?.edition as EditionReferenceDto);
      }
    },
    form,
  );

  const { page: editionsPage } = useEditions();

  React.useEffect(() => {
    const selectedEdition = editionsPage.content.find((e) => e.id === edition.id);
    if (selectedEdition != null) {
      setMindate(startOfDay(selectedEdition.dateRange.start));
      setMaxdate(endOfDay(selectedEdition.dateRange.end));
    }
  }, [editionsPage, edition]);

  return (
    <>
      <FormProvider {...form}>
        <Form<EventDto> onValid={handleValid} onInvalid={handleInvalid} initialFocusRef={initialFocusRef}>
          <Stack spacing={6}>
            {editorAction === EventEditorAction.COPY && (
              <Alert status="info" mt={3} aria-labelledby={copyInfoAlertTitleId} aria-live="polite">
                <AlertIcon />
                <chakra.div flex="1">
                  <AlertTitle id={copyInfoAlertTitleId}>{t('event:editor.copy_info.title')}</AlertTitle>
                  <AlertDescription>
                    <TranslationWithLink
                      route="events"
                      entity={{ id: eventId, title: event?.title }}
                      i18nKey="event:editor.copy_info.message"
                      openInNewTab
                    />
                  </AlertDescription>
                </chakra.div>
              </Alert>
            )}

            <InputFormControl<EventDto>
              label={t(`event:title`)}
              helperText={t('event:editor.title_helper_text')}
              name="title"
              isRequired
              validate={validateTitle}
              onChange={validateTitle.reset}
              maxLength={150}
              ref={initialFocusRef}
            />

            <InputFormControl<EventDto>
              label={t(`event:englishTitle`)}
              helperText={t('event:editor.title_helper_text')}
              name="englishTitle"
              isRequired
              validate={validateEnglishTitle}
              onChange={validateEnglishTitle.reset}
              maxLength={150}
            />

            <EditionSelectionControl />

            <HStack spacing={6} alignItems="flex-start">
              <EventDateTimeRangeControl editionStart={mindate} editionEnd={maxdate} />
            </HStack>

            <OwnersControl layout={layout} />

            <EventEventConnectionControl />

            <Accordion variant="simple" allowMultiple mt={6} index={expandedIndices} onChange={setExpandedIndices}>
              {accordionItems.map((item) => (
                <AccordionItem key={item.name}>
                  <AnchorLinkDestination name={item.name} />
                  <AccordionButton>
                    {item.button} <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>{item.panel}</AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>

            <EventVisibilityControl />

            <FormControl<EventDto>
              label={t('event:status.label')}
              name="status"
              helperText={
                <Stack shouldWrapChildren={true} spacing={0}>
                  <Trans ns="event" i18nKey="status.helper_text_scheduled" />
                  <Trans ns="event" i18nKey="status.helper_text_draft" />
                  <Trans ns="event" i18nKey="status.helper_text_cancelled" />
                </Stack>
              }
              isRequired
            >
              <ValueSelectControl
                options={statusOptions}
                renderLabel={(option) => t(`event:statusOptions.${option}`)}
                name="status"
                defaultValue={EventStatusDto.SCHEDULED}
                isRequired
              />
            </FormControl>
          </Stack>

          <Flex mt={10}>
            <Button as={HistoryBackButton}>{t('common:action.abort')}</Button>
            <Spacer />
            <ButtonGroup spacing={4}>
              <ResetButton reset={reset} isDisabled={!isDirty} />
              <SubmitButton loadingText={t('common:action.proof_and_save')} variant="primary" isDisabled={!isDirty}>
                {t('common:action.proof_and_save')}
              </SubmitButton>
            </ButtonGroup>
          </Flex>
          {optimisticLockDialog}
        </Form>
      </FormProvider>
      <CopySettingsDialog
        leastDestructiveRef={ref}
        onClose={onCopyListDialogClose}
        isOpen={isCopyListDialogOpen}
        copySettingsRef={copySettingsRef}
      />
      <ConfirmChangeDialog
        status={editedStatus}
        visible={visible}
        edition={edition}
        previousEdition={previousEdition.current}
        isOpen={isConfirmDialogOpen}
        onClose={onConfirmDialogClose}
      />
      {hasCopyInfo && (
        <Dialog
          leastDestructiveRef={ref}
          isOpen={isInfoDialogOpen}
          size="lg"
          status="info"
          title={t('common:misc.info')}
          onClose={() => onInfoDialogClose(false)}
          closeOnOverlayClick={false}
          footer={
            <>
              <Button ref={ref} onClick={() => onInfoDialogClose(false)}>
                {t('common:action.ok')}
              </Button>
            </>
          }
        >
          <Stack>{eventCopyInfo?.map((Info, index) => <Info key={index} eventId={eventId!} />)}</Stack>
        </Dialog>
      )}
    </>
  );
}

function createEventTitleValidationFunction(
  eventId: string | undefined,
  editionId: string | undefined,
  focusedEditionId: string,
  englishTitle: boolean,
  t: TFunction<('common' | 'event')[]>,
  navigateToEvent: (entityId: string) => void,
): AsyncValidationCallback<string | null> {
  return async (fieldValue, { signal }) => {
    if (fieldValue == null) {
      return true;
    }
    fieldValue = fieldValue.trim();
    if (fieldValue.length === 0) {
      return true;
    }

    const property = englishTitle ? 'englishTitle' : 'title';
    const i18nKey = englishTitle ? 'event:validation_error.unique_en' : 'event:validation_error.unique';

    const page = await eventApi.searchEvents(
      {
        filter: [`${property},fuzzy,${fieldValue}`, `edition.id,eq,${editionId ?? focusedEditionId}`],
      },
      { signal },
    );

    const handleLinkClicked = (eventId: string) => {
      const message = t('event:edit_event_with_same_name_confirmation');
      if (window.confirm(message)) {
        navigateToEvent(eventId);
      }
    };

    return (
      page.content.filter((a) => a.id !== eventId).length === 0 || (
        <span>
          <Trans
            t={t}
            i18nKey={i18nKey}
            values={page.content[0]}
            components={{
              entityLink: (
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleLinkClicked(page.content[0].id!)}
                />
              ),
            }}
          />
        </span>
      )
    );
  };
}

function cleanUpEvent(event: EventDto): EventDto {
  return {
    ...event,
    venue: {
      realisation: event.venue.realisation,
      location: event.venue.location?.id == null ? undefined : event.venue.location,
      digitalVenue: event.venue.digitalVenue,
      comment: event.venue.comment,
    },
  };
}
