import React from 'react';
import { landingPageDesignIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useLandingPageDesignPermissionClassGroupExtension } from './landing-page-design-permissions';
import LandingPageDesignSearchResult from './landing-page-design-search-result';

export default function LandingPageDesignPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'LANDING_PAGE_DESIGN',
    Component: LandingPageDesignSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'LANDING_PAGE_DESIGN',
    icon: landingPageDesignIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.LANDING_PAGE_DESIGN" />,
    necessaryPermission: 'LANDING_PAGE_DESIGN:CAN_SEE_DETAILS',
  });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useLandingPageDesignPermissionClassGroupExtension);
}
