/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { StandCompanyRelationStatusDto } from './StandCompanyRelationStatusDto';
import {
    StandCompanyRelationStatusDtoFromJSON,
    StandCompanyRelationStatusDtoFromJSONTyped,
    StandCompanyRelationStatusDtoToJSON,
} from './StandCompanyRelationStatusDto';
import type { StandCompanyRelationTypeDto } from './StandCompanyRelationTypeDto';
import {
    StandCompanyRelationTypeDtoFromJSON,
    StandCompanyRelationTypeDtoFromJSONTyped,
    StandCompanyRelationTypeDtoToJSON,
} from './StandCompanyRelationTypeDto';
import type { StandReferenceDto } from './StandReferenceDto';
import {
    StandReferenceDtoFromJSON,
    StandReferenceDtoFromJSONTyped,
    StandReferenceDtoToJSON,
} from './StandReferenceDto';
import type { StandStatusDto } from './StandStatusDto';
import {
    StandStatusDtoFromJSON,
    StandStatusDtoFromJSONTyped,
    StandStatusDtoToJSON,
} from './StandStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * The list item for a relation between a stand and a company.
 * @export
 * @interface StandCompanyListItemDto
 */
export interface StandCompanyListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StandCompanyListItemDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof StandCompanyListItemDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof StandCompanyListItemDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {StandReferenceDto}
     * @memberof StandCompanyListItemDto
     */
    stand: StandReferenceDto;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof StandCompanyListItemDto
     */
    company: CompanyReferenceDto;
    /**
     * 
     * @type {StandCompanyRelationTypeDto}
     * @memberof StandCompanyListItemDto
     */
    relationType: StandCompanyRelationTypeDto;
    /**
     * 
     * @type {StandCompanyRelationStatusDto}
     * @memberof StandCompanyListItemDto
     */
    relationStatus: StandCompanyRelationStatusDto;
    /**
     * 
     * @type {boolean}
     * @memberof StandCompanyListItemDto
     */
    sharingFees: boolean;
    /**
     * 
     * @type {StandStatusDto}
     * @memberof StandCompanyListItemDto
     */
    standStatus: StandStatusDto;
}

/**
 * Check if a given object implements the StandCompanyListItemDto interface.
 */
export function instanceOfStandCompanyListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('stand' in value)) return false;
    if (!('company' in value)) return false;
    if (!('relationType' in value)) return false;
    if (!('relationStatus' in value)) return false;
    if (!('sharingFees' in value)) return false;
    if (!('standStatus' in value)) return false;
    return true;
}

export function StandCompanyListItemDtoFromJSON(json: any): StandCompanyListItemDto {
    return StandCompanyListItemDtoFromJSONTyped(json, false);
}

export function StandCompanyListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandCompanyListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'stand': StandReferenceDtoFromJSON(json['stand']),
        'company': CompanyReferenceDtoFromJSON(json['company']),
        'relationType': StandCompanyRelationTypeDtoFromJSON(json['relationType']),
        'relationStatus': StandCompanyRelationStatusDtoFromJSON(json['relationStatus']),
        'sharingFees': json['sharingFees'],
        'standStatus': StandStatusDtoFromJSON(json['standStatus']),
    };
}

export function StandCompanyListItemDtoToJSON(value?: StandCompanyListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'stand': StandReferenceDtoToJSON(value['stand']),
        'company': CompanyReferenceDtoToJSON(value['company']),
        'relationType': StandCompanyRelationTypeDtoToJSON(value['relationType']),
        'relationStatus': StandCompanyRelationStatusDtoToJSON(value['relationStatus']),
        'sharingFees': value['sharingFees'],
        'standStatus': StandStatusDtoToJSON(value['standStatus']),
    };
}

