import { Alert, AlertIcon, Box, HStack, IconButton, Link, Stack, Tooltip } from '@chakra-ui/react';
import { faExchange, faFontCase } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useController, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { PersonDto } from '../../../api';
import InputFormControl from '../../../ui/form/input-form-control';
import useWatchChange from '../../../ui/form/use-watch-change/use-watch-change';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import { PERSON_NAME } from '../../../util/constants';
import { LayoutType } from '../../common/layout-type';
import { DuplicateWarning } from './duplicate-warning';

export interface PersonNameControlProps {
  layout?: LayoutType;
  initialFocusRef?: React.RefObject<HTMLInputElement>;
}

export default function PersonNameControl({ layout = LayoutType.NORMAL, initialFocusRef }: PersonNameControlProps) {
  enum NAME_FORMAT_TYPES {
    LOWER_CASE,
    UPPER_CASE,
    FIRST_LETTER_UPPER_CASE,
  }

  const [formatNameState, setFormatNameState] = React.useState(NAME_FORMAT_TYPES.LOWER_CASE);

  const { t } = useTranslation('person');
  const { t: tCommon } = useTranslation('common');
  const [showNameChangeInfo, setShowNameChangeInfo] = React.useState(false);

  const { field: firstNameField } = useController({
    name: 'firstName',
  });
  const { field: surnameField } = useController({
    name: 'surname',
  });
  const switchNames = () => {
    surnameField.onChange(firstNameField.value);
    firstNameField.onChange(surnameField.value);
  };

  const changeNameFormat = () => {
    const firstNameValue = firstNameField.value;
    const surnameValue = surnameField.value;
    if (formatNameState === NAME_FORMAT_TYPES.LOWER_CASE) {
      firstNameField.onChange(firstNameValue.toLowerCase());
      surnameField.onChange(surnameValue.toLowerCase());
      setFormatNameState(NAME_FORMAT_TYPES.FIRST_LETTER_UPPER_CASE);
    } else if (formatNameState === NAME_FORMAT_TYPES.FIRST_LETTER_UPPER_CASE) {
      firstNameField.onChange(capitalize(firstNameValue));
      surnameField.onChange(capitalize(surnameValue));
      setFormatNameState(NAME_FORMAT_TYPES.UPPER_CASE);
    } else if (formatNameState === NAME_FORMAT_TYPES.UPPER_CASE) {
      firstNameField.onChange(firstNameValue.toUpperCase());
      surnameField.onChange(surnameValue.toUpperCase());
      setFormatNameState(NAME_FORMAT_TYPES.LOWER_CASE);
    }
  };

  const capitalize = (names: string) => {
    const wordsInName = names.split(/([-\s]+)/);
    return wordsInName.map((word) => `${word[0].toUpperCase()}${word.slice(1)}`).join('');
  };

  const getNameFormatLabel = () => {
    return formatNameState === NAME_FORMAT_TYPES.LOWER_CASE
      ? t('action.change_name_format_lower_case')
      : formatNameState === NAME_FORMAT_TYPES.UPPER_CASE
        ? t('action.change_name_format_upper_case')
        : formatNameState === NAME_FORMAT_TYPES.FIRST_LETTER_UPPER_CASE
          ? t('action.change_name_format_first_letter_upper_case')
          : '';
  };

  const firstNameRef = React.useRef(firstNameField.value);
  const surnameRef = React.useRef(surnameField.value);

  const personId = useWatch<PersonDto, 'id'>({ name: 'id' });

  useWatchChange<PersonDto>(['firstName', 'surname'], (person) => {
    if (
      personId != null &&
      (firstNameRef.current.toLowerCase() !== person.firstName.toLowerCase() ||
        surnameRef.current.toLowerCase() !== person.surname.toLowerCase())
    ) {
      setShowNameChangeInfo(true);
    } else {
      setShowNameChangeInfo(false);
    }
  });

  const showChangeNameInfo = () => {
    return (
      <Alert status="info" mt={4}>
        <AlertIcon />
        <span>
          <Trans
            t={t}
            i18nKey="change_name_info"
            components={{ newPersonLink: <Link as={RouterLink} to="/persons/new" /> }}
          />
        </span>
      </Alert>
    );
  };

  return (
    <>
      <Stack direction={layout} spacing={4} alignItems="flex-start">
        <InputFormControl<PersonDto>
          label={t('firstName')}
          name="firstName"
          isRequired
          maxLength={50}
          pattern={{
            value: PERSON_NAME,
            message: tCommon('validation_error.latin_alphabet', { field: t('firstName') }),
          }}
          helperPopover={<HelperPopover children={t('passportNameTooltip')} />}
          ref={initialFocusRef}
        />
        {layout === LayoutType.NORMAL && (
          <Box pt={8}>
            <Tooltip label={t('action.switch_names')} closeOnClick={false}>
              <IconButton
                variant="solid"
                icon={<FontAwesomeIcon icon={faExchange} />}
                aria-label={t('action.switch_names')}
                onClick={switchNames}
              />
            </Tooltip>
          </Box>
        )}
        <InputFormControl<PersonDto>
          label={t('surname')}
          name="surname"
          isRequired
          maxLength={50}
          pattern={{
            value: PERSON_NAME,
            message: tCommon('validation_error.latin_alphabet', { field: t('surname') }),
          }}
          helperPopover={<HelperPopover children={t('passportNameTooltip')} />}
        />
        <HStack>
          {layout === LayoutType.SMALL && (
            <Box pt={2}>
              <Tooltip label={t('action.switch_names')} closeOnClick={false}>
                <IconButton
                  variant="solid"
                  icon={<FontAwesomeIcon icon={faExchange} />}
                  aria-label={t('action.switch_names')}
                  onClick={switchNames}
                />
              </Tooltip>
            </Box>
          )}
          <Box pt={layout === LayoutType.NORMAL ? 8 : 2}>
            <Tooltip label={getNameFormatLabel()} closeOnClick={false}>
              <IconButton
                variant="solid"
                icon={<FontAwesomeIcon icon={faFontCase} />}
                aria-label={getNameFormatLabel()}
                onClick={changeNameFormat}
              />
            </Tooltip>
          </Box>
        </HStack>
      </Stack>
      <DuplicateWarning />
      {showNameChangeInfo ? showChangeNameInfo() : null}
    </>
  );
}
