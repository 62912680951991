/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentPermissionDto
 */
export interface PaymentPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentPermissionDto
     */
    name: PaymentPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentPermissionDto
     */
    action: PaymentPermissionDtoActionEnum;
}


/**
 * @export
 */
export const PaymentPermissionDtoNameEnum = {
    PAYMENT: 'PAYMENT'
} as const;
export type PaymentPermissionDtoNameEnum = typeof PaymentPermissionDtoNameEnum[keyof typeof PaymentPermissionDtoNameEnum];

/**
 * @export
 */
export const PaymentPermissionDtoActionEnum = {
    LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    DETAILS: 'CAN_SEE_DETAILS'
} as const;
export type PaymentPermissionDtoActionEnum = typeof PaymentPermissionDtoActionEnum[keyof typeof PaymentPermissionDtoActionEnum];


/**
 * Check if a given object implements the PaymentPermissionDto interface.
 */
export function instanceOfPaymentPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function PaymentPermissionDtoFromJSON(json: any): PaymentPermissionDto {
    return PaymentPermissionDtoFromJSONTyped(json, false);
}

export function PaymentPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function PaymentPermissionDtoToJSON(value?: PaymentPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

