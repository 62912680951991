/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CountryDto,
  CountryListItemPageDto,
  CountryReferencePageDto,
  FailureDto,
  PageableDto,
} from '../models/index';
import {
    CountryDtoFromJSON,
    CountryDtoToJSON,
    CountryListItemPageDtoFromJSON,
    CountryListItemPageDtoToJSON,
    CountryReferencePageDtoFromJSON,
    CountryReferencePageDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface FetchCountryRequest {
    id: string;
}

export interface SearchCountriesRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchCountryReferencesRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

/**
 * 
 */
export class CountryApi extends runtime.BaseAPI {

    /**
     * Get the country business object.
     */
    async fetchCountryRaw(requestParameters: FetchCountryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountryDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchCountry().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/countries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountryDtoFromJSON(jsonValue));
    }

    /**
     * Get the country business object.
     */
    async fetchCountry(requestParameters: FetchCountryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountryDto> {
        const response = await this.fetchCountryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for countries.
     */
    async searchCountriesRaw(requestParameters: SearchCountriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountryListItemPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountryListItemPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for countries.
     */
    async searchCountries(requestParameters: SearchCountriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountryListItemPageDto> {
        const response = await this.searchCountriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for country references.
     */
    async searchCountryReferencesRaw(requestParameters: SearchCountryReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountryReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/countries/references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountryReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for country references.
     */
    async searchCountryReferences(requestParameters: SearchCountryReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountryReferencePageDto> {
        const response = await this.searchCountryReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
