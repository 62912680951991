import { Text, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { OrderStatusDto, PersonOrderListItemDto } from '../../../api';
import editionApi from '../../../data-access/edition-api';
import orderApi from '../../../data-access/order-api';
import productApi from '../../../data-access/product-api';
import productBundleApi from '../../../data-access/product-bundle-api';
import { DataTableColumn, DataTableLink, DataTableTruncatedText } from '../../../ui/data-table';
import DateFilter from '../../../ui/data-table/filter/date-filter';
import EnumFilter from '../../../ui/data-table/filter/enum-filter';
import { OperatorOption } from '../../../ui/data-table/filter/exists-or-in-filter';
import InFilter from '../../../ui/data-table/filter/in-filter';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import Optional from '../../../ui/optional/optional';
import LastModifiedLabel from '../../../ui/version/last-modified-label';
import Translate from '../../../util/translate/translate';
import EditionReference from '../../edition/edition-reference';
import ProductBundleReference from '../../product-bundle/product-bundle-reference';
import ProductReference from '../../product/product-reference/product-reference';
import { paymentOptions, statusOptions } from '../order-enum-constants';

export default function usePersonOrderTableColumns() {
  const { t } = useTranslation(['common', 'order', 'edition']);
  return React.useMemo(() => {
    const columns: DataTableColumn<PersonOrderListItemDto>[] = [
      {
        key: 'edition.id',
        sticky: true,
        name: t('edition:edition'),
        isSortable: true,
        sortProperty: 'edition.name',
        renderCell: ({ edition }) => <EditionReference edition={edition} />,
        filter: (
          <InFilter
            label={t('edition:edition')}
            options={[OperatorOption.IN]}
            loadOptions={async (q) => {
              const page = await editionApi.searchEditionReferences({
                pageable: { size: 10 },
                filter: [`name,contain,${q}`],
              });

              return page.content.map(({ id, name }) => ({
                label: name,
                value: id!,
              }));
            }}
          />
        ),
      },
      {
        key: 'orderNumber',
        name: t('order:orderNumber'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '10%',
        },
        isSortable: true,
        renderCell: (order) => (
          <DataTableTruncatedText>
            <DataTableLink as={RouterLink} to={'/orders/' + order.id}>
              <Text color={getColorByStatus(order.status)}>{order.orderNumber}</Text>
            </DataTableLink>
          </DataTableTruncatedText>
        ),
        filter: (
          <StringFilter
            label={t('order:orderNumber')}
            loadOptions={async (value: string) => {
              const page = await orderApi.searchOrders({
                pageable: { size: 10 },
                filter: [`orderNumber,contain,${value}`],
              });
              return page.content.map((order) => order.orderNumber);
            }}
          />
        ),
      },
      {
        key: 'products',
        filterProperty: 'products[*].id',
        name: <Translate ns="order" i18nKey="products" />,
        renderCell: (order) => (
          <Optional>
            {order.products.length > 0 && (
              <Wrap spacingX={4} spacingY={2}>
                {order.products.map((product, idx) => (
                  <WrapItem key={idx}>
                    <ProductReference productReference={product} color={getColorByStatus(order.status)} />
                  </WrapItem>
                ))}
              </Wrap>
            )}
          </Optional>
        ),
        filter: (
          <InFilter
            label={<Translate ns="order" i18nKey="products" />}
            loadOptions={async (query: string) => {
              const products = await productApi.searchProducts({ filter: [`title,contain,${query}`] });

              return products.content.map((product) => ({
                label: product.title,
                value: product.id!,
              }));
            }}
          />
        ),
      },
      {
        key: 'productBundles',
        filterProperty: 'productBundles[*].id',
        name: <Translate ns="order" i18nKey="productBundles" />,
        renderCell: (order) => (
          <Optional>
            {order.productBundles.length > 0 && (
              <Wrap spacingX={4} spacingY={2}>
                {order.productBundles.map((bundle, idx) => (
                  <WrapItem key={idx}>
                    <ProductBundleReference productBundleReference={bundle} color={getColorByStatus(order.status)} />
                  </WrapItem>
                ))}
              </Wrap>
            )}
          </Optional>
        ),
        filter: (
          <InFilter
            label={<Translate ns="order" i18nKey="productBundles" />}
            loadOptions={async (query: string) => {
              const productBundles = await productBundleApi.searchProductBundleReferences({
                q: query,
              });

              return productBundles.content.map((bundle) => ({
                label: bundle.title,
                value: bundle.id!,
              }));
            }}
          />
        ),
      },
      {
        key: 'status',
        name: t('order:status'),
        isSortable: true,
        renderCell: (order) => (
          <Text color={getColorByStatus(order.status)}>{t(`order:statusOptions.${order.status}`)}</Text>
        ),
        filter: (
          <EnumFilter
            label={t('order:status')}
            options={statusOptions}
            renderOptionLabel={(key) => t(`order:statusOptions.${key}`)}
          />
        ),
      },
      {
        key: 'paymentStatus',
        name: t('order:paymentStatus'),
        isSortable: true,
        renderCell: (order) => (
          <Text color={getColorByStatus(order.status)}>{t(`order:paymentOptions.${order.paymentStatus}`)}</Text>
        ),
        filter: (
          <EnumFilter
            label={t('order:paymentStatus')}
            options={paymentOptions}
            renderOptionLabel={(key) => t(`order:paymentOptions.${key}`)}
          />
        ),
      },
      {
        key: 'comment',
        name: t('order:comment'),
        isSortable: true,
        renderCell: (order) => (
          <Optional>
            {order.comment != null && <Text color={getColorByStatus(order.status)}>{order.comment}</Text>}
          </Optional>
        ),
        filter: <StringFilter label={t('order:comment')} />,
      },
      {
        key: 'createdAt',
        name: t('order:createdAt'),
        renderCell: (order) => (
          <Text color={getColorByStatus(order.status)}>{t('common:format.date', { date: order.createdAt })}</Text>
        ),
        isSortable: true,
        sortProperty: 'createdAt',
        filter: <DateFilter showTimeSelect />,
        filterWidth: 'md',
      },
      {
        key: 'modifiedAt',
        name: t('common:viewer.last_modification'),
        renderCell: (order) => <LastModifiedLabel version={order.version} color={getColorByStatus(order.status)} />,
        isSortable: true,
        filter: <DateFilter showTimeSelect />,
        filterWidth: 'md',
      },
    ];

    return columns;
  }, [t]);
}

function getColorByStatus(status: OrderStatusDto) {
  return status === OrderStatusDto.BLOCKED ? 'text.muted' : undefined;
}
