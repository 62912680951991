import React from 'react';
import { useTranslation } from 'react-i18next';
import { MailingDtoStatusEnum, RecipientLinkDto } from '../../../api';
import personApi from '../../../data-access/person-api';
import { DataTableColumn } from '../../../ui/data-table';
import InFilter from '../../../ui/data-table/filter/in-filter';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import Optional from '../../../ui/optional/optional';
import useFetcher from '../../../util/swr/use-fetcher';
import PersonReference from '../../person/person-reference/person-reference';
import { fetchMailing } from '../mailing-queries';
import { isPersonRecipientLink } from '../mailing-recipient-util/person-recipient-link';
import DeleteRecipientButton from './delete-recipient-button';

export default function usePersonRecipientColumns(mailingId: string) {
  const { t } = useTranslation('mailing');
  const mailing = useFetcher(fetchMailing, { id: mailingId });

  const columns: DataTableColumn<RecipientLinkDto>[] = React.useMemo(
    () => [
      {
        key: 'linkedRecipient.email',
        name: t('recipients.emails'),
        isSortable: true,
        renderCell: (recipientLink) =>
          isPersonRecipientLink(recipientLink) &&
          recipientLink.linkedRecipient.emailAddresses
            ?.filter((address) => address.label === 'CONTACT')
            .map((address) => address.email)
            .join(', '),
        filter: (
          <StringFilter
            label={t('recipients.emails')}
            availableOperators={['CONTAIN', 'NOT_CONTAIN', 'START_WITH', 'NOT_START_WITH', 'END_WITH', 'NOT_END_WITH']}
          />
        ),
      },
      {
        key: 'linkedRecipient',
        name: t('recipients.person'),
        isSortable: true,
        sortProperty: 'linkedRecipient.name',
        filterProperty: 'linkedRecipient.id',
        filter: (
          <InFilter
            label={t('recipients.person')}
            loadOptions={async (value: string) => {
              if (!value) {
                return [];
              }

              const page = await personApi.searchPersonReferences({
                pageable: { size: 10 },
                q: value,
              });

              return page.content.map((person) => ({
                value: person.id!,
                label: `${person.firstName} ${person.surname}`,
              }));
            }}
          />
        ),
        renderCell: (recipientLink) =>
          isPersonRecipientLink(recipientLink) ? (
            <PersonReference
              hidePersonKey
              flipName
              personReference={recipientLink.linkedRecipient}
              isTruncated
              usePortalForCard
            />
          ) : (
            <Optional />
          ),
      },
      {
        key: 'action',
        sticky: true,
        renderCell: (recipientLink) =>
          isPersonRecipientLink(recipientLink) && (
            <DeleteRecipientButton
              mailing={mailing}
              recipientId={recipientLink.id}
              recipientType="PERSON"
              displayName={`${recipientLink.linkedRecipient.firstName} ${recipientLink.linkedRecipient.surname}`}
              isDisabled={mailing.status !== MailingDtoStatusEnum.DRAFT}
            />
          ),
      },
    ],
    [mailing, t],
  );
  return columns;
}
