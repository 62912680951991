import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { offerIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function OfferSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'OFFER', `${result.resultType} is no instance of OfferSearchResult`);

  const offer = result.result;
  const { t } = useTranslation(['offer']);

  return (
    <SearchResult
      icon={offerIcon}
      title={offer.offerNumber!}
      link={`/offers/${offer.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('offer:issuer'),
            element: t(`offer:issuerOptions.${offer.issuer}`),
          },
        ]}
      />
    </SearchResult>
  );
}
