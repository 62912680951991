/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LandingPageDesignSponsorLogoVariantDto } from './LandingPageDesignSponsorLogoVariantDto';
import {
    LandingPageDesignSponsorLogoVariantDtoFromJSON,
    LandingPageDesignSponsorLogoVariantDtoFromJSONTyped,
    LandingPageDesignSponsorLogoVariantDtoToJSON,
} from './LandingPageDesignSponsorLogoVariantDto';

/**
 * 
 * @export
 * @interface LandingPageDesignSponsorLogoDto
 */
export interface LandingPageDesignSponsorLogoDto {
    /**
     * 
     * @type {LandingPageDesignSponsorLogoVariantDto}
     * @memberof LandingPageDesignSponsorLogoDto
     */
    german: LandingPageDesignSponsorLogoVariantDto;
    /**
     * 
     * @type {LandingPageDesignSponsorLogoVariantDto}
     * @memberof LandingPageDesignSponsorLogoDto
     */
    english: LandingPageDesignSponsorLogoVariantDto;
    /**
     * 
     * @type {Date}
     * @memberof LandingPageDesignSponsorLogoDto
     */
    timestamp: Date;
}

/**
 * Check if a given object implements the LandingPageDesignSponsorLogoDto interface.
 */
export function instanceOfLandingPageDesignSponsorLogoDto(value: object): boolean {
    if (!('german' in value)) return false;
    if (!('english' in value)) return false;
    if (!('timestamp' in value)) return false;
    return true;
}

export function LandingPageDesignSponsorLogoDtoFromJSON(json: any): LandingPageDesignSponsorLogoDto {
    return LandingPageDesignSponsorLogoDtoFromJSONTyped(json, false);
}

export function LandingPageDesignSponsorLogoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageDesignSponsorLogoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'german': LandingPageDesignSponsorLogoVariantDtoFromJSON(json['german']),
        'english': LandingPageDesignSponsorLogoVariantDtoFromJSON(json['english']),
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function LandingPageDesignSponsorLogoDtoToJSON(value?: LandingPageDesignSponsorLogoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'german': LandingPageDesignSponsorLogoVariantDtoToJSON(value['german']),
        'english': LandingPageDesignSponsorLogoVariantDtoToJSON(value['english']),
        'timestamp': ((value['timestamp']).toISOString()),
    };
}

