/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RundownPermissionDto
 */
export interface RundownPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof RundownPermissionDto
     */
    name: RundownPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof RundownPermissionDto
     */
    action: RundownPermissionDtoActionEnum;
}


/**
 * @export
 */
export const RundownPermissionDtoNameEnum = {
    RUNDOWN: 'RUNDOWN'
} as const;
export type RundownPermissionDtoNameEnum = typeof RundownPermissionDtoNameEnum[keyof typeof RundownPermissionDtoNameEnum];

/**
 * @export
 */
export const RundownPermissionDtoActionEnum = {
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    SEE_HISTORY: 'CAN_SEE_HISTORY',
    SEE_HISTORY_OWN: 'CAN_SEE_HISTORY_OWN',
    EDIT: 'CAN_EDIT',
    EDIT_OWN: 'CAN_EDIT_OWN',
    SEE_SETTINGS: 'CAN_SEE_SETTINGS',
    SEE_SETTINGS_OWN: 'CAN_SEE_SETTINGS_OWN',
    EDIT_SETTINGS: 'CAN_EDIT_SETTINGS',
    EDIT_SETTINGS_OWN: 'CAN_EDIT_SETTINGS_OWN',
    SEE_PUBLICATION: 'CAN_SEE_PUBLICATION',
    SEE_PUBLICATION_OWN: 'CAN_SEE_PUBLICATION_OWN',
    PUBLISH: 'CAN_PUBLISH',
    PUBLISH_OWN: 'CAN_PUBLISH_OWN',
    UNPUBLISH: 'CAN_UNPUBLISH',
    UNPUBLISH_OWN: 'CAN_UNPUBLISH_OWN'
} as const;
export type RundownPermissionDtoActionEnum = typeof RundownPermissionDtoActionEnum[keyof typeof RundownPermissionDtoActionEnum];


/**
 * Check if a given object implements the RundownPermissionDto interface.
 */
export function instanceOfRundownPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function RundownPermissionDtoFromJSON(json: any): RundownPermissionDto {
    return RundownPermissionDtoFromJSONTyped(json, false);
}

export function RundownPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RundownPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function RundownPermissionDtoToJSON(value?: RundownPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

