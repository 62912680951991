import useSWRImmutable from 'swr/immutable';
import editionApi from '../../../data-access/edition-api';

export default function useEditionHeader(editionId: string | undefined) {
  const { data: editionHeader } = useSWRImmutable(
    editionId != null ? ['editionHeader', editionId] : null,
    () => editionApi.fetchEditionHeader({ id: editionId! }),
    { suspense: true },
  );

  return editionHeader;
}
