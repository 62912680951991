/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Status of the payment.
 * @export
 */
export const PaymentTransactionStatusDto = {
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    WAITING: 'WAITING',
    UNKNOWN: 'UNKNOWN'
} as const;
export type PaymentTransactionStatusDto = typeof PaymentTransactionStatusDto[keyof typeof PaymentTransactionStatusDto];


export function PaymentTransactionStatusDtoFromJSON(json: any): PaymentTransactionStatusDto {
    return PaymentTransactionStatusDtoFromJSONTyped(json, false);
}

export function PaymentTransactionStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTransactionStatusDto {
    return json as PaymentTransactionStatusDto;
}

export function PaymentTransactionStatusDtoToJSON(value?: PaymentTransactionStatusDto | null): any {
    return value as any;
}

