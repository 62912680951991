/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryStatusDto } from './CountryStatusDto';
import {
    CountryStatusDtoFromJSON,
    CountryStatusDtoFromJSONTyped,
    CountryStatusDtoToJSON,
} from './CountryStatusDto';

/**
 * A List item of a country
 * @export
 * @interface CountryListItemDto
 */
export interface CountryListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CountryListItemDto
     */
    id: string;
    /**
     * the german name of the country
     * @type {string}
     * @memberof CountryListItemDto
     */
    name: string;
    /**
     * the english name of the country
     * @type {string}
     * @memberof CountryListItemDto
     */
    englishName: string;
    /**
     * 
     * @type {string}
     * @memberof CountryListItemDto
     */
    comment?: string;
    /**
     * Is a visa required.
     * @type {boolean}
     * @memberof CountryListItemDto
     */
    visaRequired: boolean;
    /**
     * 
     * @type {CountryStatusDto}
     * @memberof CountryListItemDto
     */
    status: CountryStatusDto;
}

/**
 * Check if a given object implements the CountryListItemDto interface.
 */
export function instanceOfCountryListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('englishName' in value)) return false;
    if (!('visaRequired' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function CountryListItemDtoFromJSON(json: any): CountryListItemDto {
    return CountryListItemDtoFromJSONTyped(json, false);
}

export function CountryListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'englishName': json['englishName'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'visaRequired': json['visaRequired'],
        'status': CountryStatusDtoFromJSON(json['status']),
    };
}

export function CountryListItemDtoToJSON(value?: CountryListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'englishName': value['englishName'],
        'comment': value['comment'],
        'visaRequired': value['visaRequired'],
        'status': CountryStatusDtoToJSON(value['status']),
    };
}

