/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentStatusDto = {
    AWAITING_PAYMENT: 'AWAITING_PAYMENT',
    PAID: 'PAID',
    COMPLIMENTARY: 'COMPLIMENTARY',
    IN_CANCELLATION: 'IN_CANCELLATION',
    CANCELLED: 'CANCELLED',
    OBSOLETE: 'OBSOLETE'
} as const;
export type PaymentStatusDto = typeof PaymentStatusDto[keyof typeof PaymentStatusDto];


export function PaymentStatusDtoFromJSON(json: any): PaymentStatusDto {
    return PaymentStatusDtoFromJSONTyped(json, false);
}

export function PaymentStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentStatusDto {
    return json as PaymentStatusDto;
}

export function PaymentStatusDtoToJSON(value?: PaymentStatusDto | null): any {
    return value as any;
}

