import { Flex, Grid, GridItem, Heading } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../ui/page/page-content';
import useParam from '../../../util/use-params/use-params';
import GroupOrderDataTable from './group-order-data-table';

export function GroupOrderPageContent() {
  const groupId = useParam('groupId');
  const { t } = useTranslation(['order']);

  return (
    <PageContent useFullWidth fixedHeight>
      <Grid gridRowGap={6} maxHeight="full" gridTemplateRows="auto 1fr">
        <Flex as="header">
          <Heading size="md" as="h3">
            {t('order:lister.title')}
          </Heading>
        </Flex>
        <GridItem minH={0}>
          <GroupOrderDataTable groupId={groupId} />
        </GridItem>
      </Grid>
    </PageContent>
  );
}
