/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KeyplayerListPermissionDto
 */
export interface KeyplayerListPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof KeyplayerListPermissionDto
     */
    name: KeyplayerListPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof KeyplayerListPermissionDto
     */
    action: KeyplayerListPermissionDtoActionEnum;
}


/**
 * @export
 */
export const KeyplayerListPermissionDtoNameEnum = {
    KEYPLAYER_LIST: 'KEYPLAYER_LIST'
} as const;
export type KeyplayerListPermissionDtoNameEnum = typeof KeyplayerListPermissionDtoNameEnum[keyof typeof KeyplayerListPermissionDtoNameEnum];

/**
 * @export
 */
export const KeyplayerListPermissionDtoActionEnum = {
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    SEE_HISTORY: 'CAN_SEE_HISTORY',
    SEE_HISTORY_OWN: 'CAN_SEE_HISTORY_OWN',
    EDIT: 'CAN_EDIT',
    EDIT_OWN: 'CAN_EDIT_OWN',
    EDIT_SETTINGS: 'CAN_EDIT_SETTINGS',
    EDIT_SETTINGS_OWN: 'CAN_EDIT_SETTINGS_OWN',
    SEE_PUBLICATION: 'CAN_SEE_PUBLICATION',
    SEE_PUBLICATION_OWN: 'CAN_SEE_PUBLICATION_OWN',
    PUBLISH: 'CAN_PUBLISH',
    PUBLISH_OWN: 'CAN_PUBLISH_OWN',
    UNPUBLISH: 'CAN_UNPUBLISH',
    UNPUBLISH_OWN: 'CAN_UNPUBLISH_OWN'
} as const;
export type KeyplayerListPermissionDtoActionEnum = typeof KeyplayerListPermissionDtoActionEnum[keyof typeof KeyplayerListPermissionDtoActionEnum];


/**
 * Check if a given object implements the KeyplayerListPermissionDto interface.
 */
export function instanceOfKeyplayerListPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function KeyplayerListPermissionDtoFromJSON(json: any): KeyplayerListPermissionDto {
    return KeyplayerListPermissionDtoFromJSONTyped(json, false);
}

export function KeyplayerListPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyplayerListPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function KeyplayerListPermissionDtoToJSON(value?: KeyplayerListPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

