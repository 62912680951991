/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Personal data to be shown within a visa
 * @export
 */
export const PersonalDataToShowDto = {
    FIRST_NAME: 'FIRST_NAME',
    SURNAME: 'SURNAME',
    DATE_OF_BIRTH: 'DATE_OF_BIRTH',
    PLACE_OF_BIRTH: 'PLACE_OF_BIRTH',
    NATIONALITIES: 'NATIONALITIES',
    POSTAL_ADDRESS: 'POSTAL_ADDRESS'
} as const;
export type PersonalDataToShowDto = typeof PersonalDataToShowDto[keyof typeof PersonalDataToShowDto];


export function PersonalDataToShowDtoFromJSON(json: any): PersonalDataToShowDto {
    return PersonalDataToShowDtoFromJSONTyped(json, false);
}

export function PersonalDataToShowDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonalDataToShowDto {
    return json as PersonalDataToShowDto;
}

export function PersonalDataToShowDtoToJSON(value?: PersonalDataToShowDto | null): any {
    return value as any;
}

