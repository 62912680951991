import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { landingPageIcon } from '../../ui/icons/business-objects';
import { useLandingPageTypes } from '../landing-page-template/landing-page-type-config';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';
import useLandingPageExtension from './use-landing-page-extension';

export function LandingPageSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'LANDING_PAGE', `${result.resultType} is no instance of LandingPageSearchResult`);
  const { t } = useTranslation(['landing_page', 'landing_page_template', 'common']);
  const landingPage = result.result;
  const { getLandingPageTypeConfig } = useLandingPageTypes();
  const getLandingPageExtension = useLandingPageExtension();

  return (
    <SearchResult
      icon={landingPageIcon}
      title={landingPage.name}
      link={`/landing-pages/${landingPage.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="LANDING_PAGE:CAN_SEE_DETAILS"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('landing_page:template'),
            element: landingPage.template.name,
          },
          {
            label: t('landing_page_template:type'),
            element: getLandingPageTypeConfig(landingPage.type).label,
          },
          {
            label: t('landing_page:brand'),
            element: landingPage.brand,
          },
          {
            label: t('landing_page:edition'),
            element: landingPage.edition?.name,
          },
          {
            label: t('landing_page:active.label'),
            element: t('common:format.date_range', { dateRange: landingPage.active }),
          },
        ]}
      />
      {getLandingPageExtension(landingPage.type).searchResultConnection?.({ onClick, landingPage })}
    </SearchResult>
  );
}
