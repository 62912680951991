import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import isHotkey from 'is-hotkey';
import { isEmpty } from 'lodash-es';
import React, { ForwardedRef, useCallback, useState } from 'react';
import { BasePoint, BaseRange, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';
import invariant from 'tiny-invariant';
import { RichTextOptions } from './rich-text-options';
import { Element, Leaf } from './slate-components';
import { WithFunction } from './slate-types';
import { RichTextStylesProvider } from './styles-context';
import Toolbar from './toolbar/toolbar';
import createEditor from './create-editor';
import { withAlignCenter, withAlignJustify, withAlignLeft, withAlignRight } from './with/with-alignment';
import withBold from './with/with-bold';
import withCitation from './with/with-citation';
import withEnhancedBreaks from './with/with-enhanced-breaks';
import withHeading1 from './with/with-heading-1';
import withHeading2 from './with/with-heading-2';
import withItalic from './with/with-italic';
import withLink, { withLinkButton } from './with/with-link';
import { withOrderedList, withUnorderedList } from './with/with-list';
import withParagraph from './with/with-paragraph';
import withSmall from './with/with-small';

export type RichTextVariant = 'mailing' | 'landingPage';

interface RichTextProps {
  onChange: (value: Descendant[]) => void;
  value: Descendant[];
  variant: RichTextVariant;
  labelId?: string;
  feedbackId?: string;
  isInvalid?: boolean;
  defaultOptions: RichTextOptions[];
}

const RICH_TEXT_PLUGINS: Record<RichTextOptions, WithFunction> = {
  HEADLINE: withHeading1,
  SUB_HEADLINE: withHeading2,
  PARAGRAPH: withParagraph,
  BOLD: withBold,
  ITALIC: withItalic,
  SMALL: withSmall,
  HYPERLINK: withLink,
  HYPERLINK_BUTTON: withLinkButton,
  CITATION: withCitation,
  ALIGN_LEFT: withAlignLeft,
  ALIGN_RIGHT: withAlignRight,
  CENTER: withAlignCenter,
  JUSTIFY: withAlignJustify,
  UNORDERED_LIST: withUnorderedList,
  ORDERED_LIST: withOrderedList,
};

// Wraps slate with styling to make it look and feel like a chakra component
function RichText(
  { onChange, value, labelId, feedbackId, isInvalid = false, defaultOptions, variant }: RichTextProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const styles = useMultiStyleConfig('RichText', { variant });
  const [initialValue] = useState(isEmpty(value) ? getInitialValue(defaultOptions) : value);
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

  const [editor] = useState(() => {
    const editor = createEditor({ options: defaultOptions });
    invariant(editor.blockOptions.length > 0, 'At least one block option must be chosen.');

    return (Object.keys(RichTextOptions) as RichTextOptions[])
      .filter((option) => defaultOptions.includes(option))
      .reduce(
        (editor, option) => (RICH_TEXT_PLUGINS[option] != null ? RICH_TEXT_PLUGINS[option](editor) : editor),
        withHistory(withReact(withEnhancedBreaks(editor))),
      );
  });

  if (editor.selection != null && !isRangeInNodes(editor.selection, value)) {
    editor.selection = null;
  }
  editor.children = isEmpty(value) ? initialValue : value;

  const handleChange = (value: Descendant[]) => {
    // Slate calls the change event when the editor is focused. This leads to problems when
    // validating the content (e.g. checking for required).
    // Prevent the change event when the value is actually the initial value.
    if (value !== initialValue) {
      onChange?.(value);
    }
  };

  return (
    <RichTextStylesProvider value={styles}>
      <Box ref={ref}>
        <Slate editor={editor} onChange={handleChange} initialValue={initialValue}>
          <Toolbar />
          <Box
            as={Editable}
            data-toolbar={editor.options.length > 1}
            aria-labelledby={labelId}
            aria-describedby={feedbackId}
            aria-invalid={isInvalid}
            data-invalid={isInvalid}
            typeof="textarea"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            __css={styles.editable}
            onKeyDown={(event) => {
              const pressedHotkey = editor.hotkeys.find((hotkey) => isHotkey(hotkey.hotkey, event));
              if (pressedHotkey == null) {
                return;
              }
              event.preventDefault();
              pressedHotkey.action();
            }}
          />
        </Slate>
      </Box>
    </RichTextStylesProvider>
  );
}

export default React.forwardRef<HTMLDivElement, RichTextProps>(RichText);

function isRangeInNodes(range: BaseRange, nodes: Descendant[]): boolean {
  return isPointInNodes(range.anchor, nodes) && isPointInNodes(range.focus, nodes);
}

function isPointInNodes(point: BasePoint, nodes: Descendant[]): boolean {
  if (point.path.length === 0 || nodes == null || nodes.length === 0) {
    return false;
  }

  if (point.path[0] > nodes.length) {
    return false;
  }

  const node = nodes[point.path[0]];
  if (node.type === 'text') {
    return node.text.length >= point.offset;
  }

  return isPointInNodes({ path: point.path.slice(1), offset: point.offset }, node.children);
}

function getInitialValue(options: RichTextOptions[]): Descendant[] {
  const blockOptions = options.filter((option) => ['HEADLINE', 'SUB_HEADLINE', 'PARAGRAPH'].includes(option));
  invariant(blockOptions.length > 0, 'block option needed');

  if (blockOptions.includes(RichTextOptions.PARAGRAPH)) {
    return [
      {
        type: 'paragraph',
        children: [{ text: '', type: 'text' }],
      },
    ];
  }

  if (blockOptions.includes(RichTextOptions.HEADLINE)) {
    return [
      {
        type: 'heading1',
        children: [{ text: '', type: 'text' }],
      },
    ];
  } else {
    return [
      {
        type: 'heading2',
        children: [{ text: '', type: 'text' }],
      },
    ];
  }
}
