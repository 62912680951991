/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BlockElementDto } from './BlockElementDto';
import {
    BlockElementDtoFromJSON,
    BlockElementDtoFromJSONTyped,
    BlockElementDtoToJSON,
} from './BlockElementDto';
import type { RichTextOptionsDto } from './RichTextOptionsDto';
import {
    RichTextOptionsDtoFromJSON,
    RichTextOptionsDtoFromJSONTyped,
    RichTextOptionsDtoToJSON,
} from './RichTextOptionsDto';

/**
 * 
 * @export
 * @interface LandingPageTextModuleContentDto
 */
export interface LandingPageTextModuleContentDto {
    /**
     * 
     * @type {string}
     * @memberof LandingPageTextModuleContentDto
     */
    moduleType: string;
    /**
     * 
     * @type {Array<RichTextOptionsDto>}
     * @memberof LandingPageTextModuleContentDto
     */
    options: Array<RichTextOptionsDto>;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof LandingPageTextModuleContentDto
     */
    content: Array<BlockElementDto>;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof LandingPageTextModuleContentDto
     */
    contentEnglish: Array<BlockElementDto>;
}

/**
 * Check if a given object implements the LandingPageTextModuleContentDto interface.
 */
export function instanceOfLandingPageTextModuleContentDto(value: object): boolean {
    if (!('moduleType' in value)) return false;
    if (!('options' in value)) return false;
    if (!('content' in value)) return false;
    if (!('contentEnglish' in value)) return false;
    return true;
}

export function LandingPageTextModuleContentDtoFromJSON(json: any): LandingPageTextModuleContentDto {
    return LandingPageTextModuleContentDtoFromJSONTyped(json, false);
}

export function LandingPageTextModuleContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageTextModuleContentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'moduleType': json['moduleType'],
        'options': ((json['options'] as Array<any>).map(RichTextOptionsDtoFromJSON)),
        'content': ((json['content'] as Array<any>).map(BlockElementDtoFromJSON)),
        'contentEnglish': ((json['contentEnglish'] as Array<any>).map(BlockElementDtoFromJSON)),
    };
}

export function LandingPageTextModuleContentDtoToJSON(value?: LandingPageTextModuleContentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'moduleType': value['moduleType'],
        'options': ((value['options'] as Array<any>).map(RichTextOptionsDtoToJSON)),
        'content': ((value['content'] as Array<any>).map(BlockElementDtoToJSON)),
        'contentEnglish': ((value['contentEnglish'] as Array<any>).map(BlockElementDtoToJSON)),
    };
}

