import React from 'react';
import { Descendant, Editor } from 'slate';
import { RenderElementProps } from 'slate-react';
import { BlockFormatOption, InlineFormatOption, MarkFormatOption } from './slate-types';

export type Render<TType extends 'mark' | 'block' | 'inline' = 'mark' | 'block' | 'inline'> = {
  type: TType extends 'mark' ? MarkFormatOption : BlockFormatOption | InlineFormatOption;
  render: TType extends 'mark'
    ? (children: Descendant[]) => React.ReactElement
    : (props: RenderElementProps) => React.ReactElement;
};

export function getRenderer<TType extends 'mark' | 'block' | 'inline'>(
  type: TType extends 'mark' ? MarkFormatOption : BlockFormatOption | InlineFormatOption,
  editor: Editor,
) {
  return (editor.renderers.find((action) => action.type === type) as Render<TType>)?.render;
}

export function getMarkRenderers(types: string[], editor: Editor) {
  return editor.renderers
    .filter((action): action is Render<'mark'> => types.includes(action.type))
    .map((style) => style.render);
}
