import { useRef, useMemo, DependencyList } from 'react';

/**
 * Returns stable callback reference by wrapping passed callback with useMemo.
 */
export default function useCallbackRef<T extends ((...args: any[]) => any) | undefined>(
  callback: T,
  deps: DependencyList = [],
): T {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const callbackDefined = callbackRef.current != null;

  return useMemo(
    () => {
      if (!callbackDefined) {
        return callbackRef.current;
      }

      return ((...args) => callbackRef.current?.(...args)) as T;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callbackDefined, ...deps],
  );
}
