import { FetchLandingPageDesignHistoryRequest } from '../../api/apis/LandingPageDesignApi';
import landingPageDesignApi from '../../data-access/landing-page-design-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';
import { enhanceLandingPageDesignHistoryWithOrder } from './landing-page-design-history/use-landing-page-design-history-settings';

export const landingPageDesignFetcher = createFetcherScope(landingPageDesignApi);
export const fetchLandingPageDesign = landingPageDesignFetcher.createFetcher(
  landingPageDesignApi.fetchLandingPageDesign,
);
export const fetchLandingPageDesigns = landingPageDesignFetcher.createFetcher(
  landingPageDesignApi.searchLandingPageDesigns,
);
export const fetchLandingPageDesignHistory = landingPageDesignFetcher.createFetcher(
  async (request: FetchLandingPageDesignHistoryRequest) => {
    const historyPage = await landingPageDesignApi.fetchLandingPageDesignHistory(request);

    return enhanceLandingPageDesignHistoryWithOrder(historyPage);
  },
);

export const getLandingPageDesignStatusBlockers = landingPageDesignFetcher.createFetcher(
  landingPageDesignApi.getLandingPageDesignStatusBlockers,
);
