/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColumnDto } from './ColumnDto';
import {
    ColumnDtoFromJSON,
    ColumnDtoFromJSONTyped,
    ColumnDtoToJSON,
} from './ColumnDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * List of persons that are involved in preparing, carrying out and follow up actions of an event.
 * @export
 * @interface KeyPlayersListSettingsDto
 */
export interface KeyPlayersListSettingsDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof KeyPlayersListSettingsDto
     */
    id: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof KeyPlayersListSettingsDto
     */
    eventId: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof KeyPlayersListSettingsDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {Array<ColumnDto>}
     * @memberof KeyPlayersListSettingsDto
     */
    columns: Array<ColumnDto>;
}

/**
 * Check if a given object implements the KeyPlayersListSettingsDto interface.
 */
export function instanceOfKeyPlayersListSettingsDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('eventId' in value)) return false;
    if (!('columns' in value)) return false;
    return true;
}

export function KeyPlayersListSettingsDtoFromJSON(json: any): KeyPlayersListSettingsDto {
    return KeyPlayersListSettingsDtoFromJSONTyped(json, false);
}

export function KeyPlayersListSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyPlayersListSettingsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'eventId': json['eventId'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'columns': ((json['columns'] as Array<any>).map(ColumnDtoFromJSON)),
    };
}

export function KeyPlayersListSettingsDtoToJSON(value?: KeyPlayersListSettingsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'eventId': value['eventId'],
        'version': VersionDtoToJSON(value['version']),
        'columns': ((value['columns'] as Array<any>).map(ColumnDtoToJSON)),
    };
}

