import { IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactEditor, useSlate } from 'slate-react';
import { getActiveBlock, isBlockActive, toggleBlock } from '../block';
import { isMarkActive, toggleMark } from '../mark';
import { BlockFormatOption, MarkFormatOption } from '../slate-types';

export interface ToolbarButtonProps extends Omit<IconButtonProps, 'icon' | 'aria-label'> {
  label: string;
  icon: IconDefinition;
}

const ToolbarButton = React.forwardRef(
  ({ icon, label, ...props }: ToolbarButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    return (
      <Tooltip label={label}>
        <IconButton
          size="sm"
          variant="outline"
          icon={<FontAwesomeIcon icon={icon} />}
          aria-label={label}
          {...props}
          ref={ref}
        />
      </Tooltip>
    );
  },
);

export default ToolbarButton;

export const BlockToolbarButton = React.forwardRef(
  (
    {
      format,
      ...props
    }: Omit<ToolbarButtonProps, 'label' | 'isActive' | 'onClick'> & { format: Exclude<BlockFormatOption, 'listItem'> },
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const editor = useSlate();
    const { t } = useTranslation('common');

    if (editor.onlyOneBlockOption) {
      return null;
    }

    return (
      <ToolbarButton
        isActive={isBlockActive(editor, format)}
        onClick={(event) => {
          toggleBlock(editor, format);
          ReactEditor.focus(editor);
          event.preventDefault();
        }}
        label={t(`wysiwyg.aria_labels.${format}`)}
        {...props}
        ref={ref}
      />
    );
  },
);

export const MarkToolbarButton = React.forwardRef(
  (
    { format, ...props }: Omit<ToolbarButtonProps, 'label' | 'isActive' | 'onClick'> & { format: MarkFormatOption },
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const editor = useSlate();
    const { t } = useTranslation('common');
    const activeBlock = getActiveBlock(editor);

    return (
      <ToolbarButton
        isActive={isMarkActive(editor, format)}
        isDisabled={activeBlock != null && !editor.markOrInlineBlockAllowed(activeBlock)}
        onClick={(event) => {
          toggleMark(editor, format);
          ReactEditor.focus(editor);
          event.preventDefault();
        }}
        label={t(`wysiwyg.aria_labels.${format}`)}
        {...props}
        ref={ref}
      />
    );
  },
);
