/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Data missing in the visa to become valid
 * @export
 */
export const VisaMissingAttributeDto = {
    FIRST_NAME: 'FIRST_NAME',
    SURNAME: 'SURNAME',
    DATE_OF_BIRTH: 'DATE_OF_BIRTH',
    PLACE_OF_BIRTH: 'PLACE_OF_BIRTH',
    NATIONALITY: 'NATIONALITY',
    PASSPORT_NUMBER: 'PASSPORT_NUMBER',
    DATE_OF_ISSUE: 'DATE_OF_ISSUE',
    DATE_OF_EXPIRE: 'DATE_OF_EXPIRE',
    ISSUING_AUTHORITY: 'ISSUING_AUTHORITY',
    PARTICIPATION_PERIOD: 'PARTICIPATION_PERIOD',
    IFB_HOTEL_COST_COVERAGE: 'IFB_HOTEL_COST_COVERAGE',
    DATE_OF_ARRIVAL: 'DATE_OF_ARRIVAL',
    DATE_OF_DEPARTURE: 'DATE_OF_DEPARTURE',
    POSTAL_ADDRESS: 'POSTAL_ADDRESS'
} as const;
export type VisaMissingAttributeDto = typeof VisaMissingAttributeDto[keyof typeof VisaMissingAttributeDto];


export function VisaMissingAttributeDtoFromJSON(json: any): VisaMissingAttributeDto {
    return VisaMissingAttributeDtoFromJSONTyped(json, false);
}

export function VisaMissingAttributeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisaMissingAttributeDto {
    return json as VisaMissingAttributeDto;
}

export function VisaMissingAttributeDtoToJSON(value?: VisaMissingAttributeDto | null): any {
    return value as any;
}

