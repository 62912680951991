/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CostBearerLabelDto } from './CostBearerLabelDto';
import {
    CostBearerLabelDtoFromJSON,
    CostBearerLabelDtoFromJSONTyped,
    CostBearerLabelDtoToJSON,
} from './CostBearerLabelDto';
import type { GroupTemplateStatusDto } from './GroupTemplateStatusDto';
import {
    GroupTemplateStatusDtoFromJSON,
    GroupTemplateStatusDtoFromJSONTyped,
    GroupTemplateStatusDtoToJSON,
} from './GroupTemplateStatusDto';
import type { LandingPageReferenceDto } from './LandingPageReferenceDto';
import {
    LandingPageReferenceDtoFromJSON,
    LandingPageReferenceDtoFromJSONTyped,
    LandingPageReferenceDtoToJSON,
} from './LandingPageReferenceDto';
import type { ProductReferenceDto } from './ProductReferenceDto';
import {
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoFromJSONTyped,
    ProductReferenceDtoToJSON,
} from './ProductReferenceDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';

/**
 * 
 * @export
 * @interface GroupTemplateListItemDto
 */
export interface GroupTemplateListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupTemplateListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GroupTemplateListItemDto
     */
    name: string;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof GroupTemplateListItemDto
     */
    owners: Array<SectionReferenceDto>;
    /**
     * 
     * @type {number}
     * @memberof GroupTemplateListItemDto
     */
    capacity?: number;
    /**
     * 
     * @type {LandingPageReferenceDto}
     * @memberof GroupTemplateListItemDto
     */
    landingPage?: LandingPageReferenceDto;
    /**
     * 
     * @type {Array<ProductReferenceDto>}
     * @memberof GroupTemplateListItemDto
     */
    permittedProducts: Array<ProductReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof GroupTemplateListItemDto
     */
    costBearer?: string;
    /**
     * 
     * @type {CostBearerLabelDto}
     * @memberof GroupTemplateListItemDto
     */
    costBearerLabel?: CostBearerLabelDto;
    /**
     * 
     * @type {GroupTemplateStatusDto}
     * @memberof GroupTemplateListItemDto
     */
    status: GroupTemplateStatusDto;
}

/**
 * Check if a given object implements the GroupTemplateListItemDto interface.
 */
export function instanceOfGroupTemplateListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('owners' in value)) return false;
    if (!('permittedProducts' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function GroupTemplateListItemDtoFromJSON(json: any): GroupTemplateListItemDto {
    return GroupTemplateListItemDtoFromJSONTyped(json, false);
}

export function GroupTemplateListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupTemplateListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'owners': ((json['owners'] as Array<any>).map(SectionReferenceDtoFromJSON)),
        'capacity': json['capacity'] == null ? undefined : json['capacity'],
        'landingPage': json['landingPage'] == null ? undefined : LandingPageReferenceDtoFromJSON(json['landingPage']),
        'permittedProducts': ((json['permittedProducts'] as Array<any>).map(ProductReferenceDtoFromJSON)),
        'costBearer': json['costBearer'] == null ? undefined : json['costBearer'],
        'costBearerLabel': json['costBearerLabel'] == null ? undefined : CostBearerLabelDtoFromJSON(json['costBearerLabel']),
        'status': GroupTemplateStatusDtoFromJSON(json['status']),
    };
}

export function GroupTemplateListItemDtoToJSON(value?: GroupTemplateListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'owners': ((value['owners'] as Array<any>).map(SectionReferenceDtoToJSON)),
        'capacity': value['capacity'],
        'landingPage': LandingPageReferenceDtoToJSON(value['landingPage']),
        'permittedProducts': ((value['permittedProducts'] as Array<any>).map(ProductReferenceDtoToJSON)),
        'costBearer': value['costBearer'],
        'costBearerLabel': CostBearerLabelDtoToJSON(value['costBearerLabel']),
        'status': GroupTemplateStatusDtoToJSON(value['status']),
    };
}

