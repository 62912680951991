import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EditionDto, EditionTypeDto } from '../../../api';
import HistoryDisplaySettings from '../../../ui/history/history-display-settings';

export default function useEditionHistorySettings(): HistoryDisplaySettings<EditionDto> {
  const { t } = useTranslation(['common', 'edition']);

  return useMemo<HistoryDisplaySettings<EditionDto>>(() => {
    return {
      attributeLabels: {
        name: t('edition:name'),
        dateRange: t('edition:date_range'),
        festivalDateRange: t('edition:festival_range'),
        editionType: t('edition:edition_type'),
        visible: t('edition:visibility'),
      },
      valueFormatter: {
        dateRange: (value) => t('common:format.date_range', { dateRange: value }),
        festivalDateRange: (value) =>
          value?.start == null && value?.end == null ? undefined : t('common:format.date_range', { dateRange: value }),
        editionType: (value) =>
          value == null ? undefined : t(`edition:editionTypeOptions.${value as EditionTypeDto}`),
        visible: (value) => (value == null ? undefined : t(`edition:visibilityOptions.${value as boolean}`)),
      },
      diffEntireWord: {
        editionType: true,
        visible: true,
      },
      name: 'name',
    };
  }, [t]);
}
