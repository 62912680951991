import { Flex, Heading, Stack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContent } from '../../../ui/page';
import useParam from '../../../util/use-params/use-params';
import { FilmHelmet } from '../../film/film-helmet';
import FilmGroupDataTable from './film-group-data-table';

export const Component = FilmGroupRoute;

export function FilmGroupHelmet() {
  const { t } = useTranslation('group');

  return <FilmHelmet suffix={t('filmRelation.relatedGroups')} />;
}

export default function FilmGroupRoute() {
  const { t } = useTranslation(['group']);
  const filmId = useParam('filmId');

  return (
    <PageContent useFullWidth fixedHeight>
      <Stack spacing={6}>
        <Flex as="header">
          <Heading size="md" as="h3">
            {t('group:filmRelation.relatedGroups')}
          </Heading>
        </Flex>
        <FilmGroupDataTable
          fallbackSort={[{ property: 'group.name', direction: 'ASC' }]}
          forcedFilter={[{ property: 'film.id', operator: 'eq', value: filmId }]}
        />
      </Stack>
    </PageContent>
  );
}
