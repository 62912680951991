import { TFunction } from 'i18next';
import { produce } from 'immer';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BerlinaleLinkDto,
  HistoryPageDto,
  LandingPageDesignDto,
  LandingPageDesignSponsorLogoDto,
  LandingPageDesignSponsorRowDto,
} from '../../../api';
import HistoryDisplaySettings from '../../../ui/history/history-display-settings';

export default function useLandingPageDesignHistorySettings(): HistoryDisplaySettings<LandingPageDesignDto> {
  const { t } = useTranslation(['common', 'landing_page_design']);

  return useMemo<HistoryDisplaySettings<LandingPageDesignDto>>(() => {
    return {
      attributeLabels: {
        brand: t('landing_page_design:brand'),
        edition: t('landing_page_design:edition'),
        status: t('landing_page_design:status'),
        'header.backgroundColor': t('landing_page_design:header.backgroundColor'),
        'header.textSymbolColor': t('landing_page_design:header.textSymbolColor'),
        'header.logoMetadata': t('landing_page_design:header.logoMetadata'),
        'header.logoLinkDe': t('landing_page_design:header.logoLinkDe'),
        'header.logoLinkEn': t('landing_page_design:header.logoLinkEn'),
        'body.backgroundColor': t('landing_page_design:body.backgroundColor'),
        'body.textSymbolColor': t('landing_page_design:body.textSymbolColor'),
        'body.buttonColor': t('landing_page_design:body.buttonColor'),
        'body.buttonHoverColor': t('landing_page_design:body.buttonHoverColor'),
        'body.buttonActiveColor': t('landing_page_design:body.buttonActiveColor'),
        'body.buttonTextColor': t('landing_page_design:body.buttonTextColor'),
        'body.buttonHoverTextColor': t('landing_page_design:body.buttonHoverTextColor'),
        'body.buttonActiveTextColor': t('landing_page_design:body.buttonActiveTextColor'),
        'body.moduleColor': t('landing_page_design:body.moduleColor'),
        'sponsoring.row1.title': `${t('landing_page_design:sponsoring.row1')} ${t('landing_page_design:sponsoring.rowTitle')}`,
        'sponsoring.row1.titleEnglish': `${t('landing_page_design:sponsoring.row1')} ${t('landing_page_design:sponsoring.rowTitleEnglish')}`,
        'sponsoring.row1.backgroundColor': `${t('landing_page_design:sponsoring.row1')} ${t('landing_page_design:sponsoring.rowBackgroundColor')}`,
        'sponsoring.row1.sponsors': `${t('landing_page_design:sponsoring.row1')} ${t('landing_page_design:sponsoring.sponsors')}`,
        'sponsoring.row2.title': `${t('landing_page_design:sponsoring.row2')} ${t('landing_page_design:sponsoring.rowTitle')}`,
        'sponsoring.row2.titleEnglish': `${t('landing_page_design:sponsoring.row2')} ${t('landing_page_design:sponsoring.rowTitleEnglish')}`,
        'sponsoring.row2.backgroundColor': `${t('landing_page_design:sponsoring.row2')} ${t('landing_page_design:sponsoring.rowBackgroundColor')}`,
        'sponsoring.row2.sponsors': `${t('landing_page_design:sponsoring.row2')} ${t('landing_page_design:sponsoring.sponsors')}`,
        'sponsoring.row3.title': `${t('landing_page_design:sponsoring.row3')} ${t('landing_page_design:sponsoring.rowTitle')}`,
        'sponsoring.row3.titleEnglish': `${t('landing_page_design:sponsoring.row3')} ${t('landing_page_design:sponsoring.rowTitleEnglish')}`,
        'sponsoring.row3.backgroundColor': `${t('landing_page_design:sponsoring.row3')} ${t('landing_page_design:sponsoring.rowBackgroundColor')}`,
        'sponsoring.row3.sponsors': `${t('landing_page_design:sponsoring.row3')} ${t('landing_page_design:sponsoring.sponsors')}`,
        'footer.backgroundColor': t('landing_page_design:footer.backgroundColor'),
        'footer.textSymbolColor': t('landing_page_design:footer.textSymbolColor'),
        'footer.socialMediaLinks.tikTokLink': t('common:socialMediaLinks.tikTokLink'),
        'footer.socialMediaLinks.instagramLink': t('common:socialMediaLinks.instagramLink'),
        'footer.socialMediaLinks.facebookLink': t('common:socialMediaLinks.facebookLink'),
        'footer.socialMediaLinks.twitterLink': t('common:socialMediaLinks.twitterLink'),
        'footer.socialMediaLinks.linkedInLink': t('common:socialMediaLinks.linkedInLink'),
        'footer.socialMediaLinks.youtubeLink': t('common:socialMediaLinks.youtubeLink'),
        'footer.berlinaleLinks': t('landing_page_design:footer.berlinaleLinks'),
      },
      valueFormatter: {
        edition: (value) => value?.name,
        status: (value) => (value == null ? undefined : t(`landing_page_design:statusOptions.${value}`)),
        'header.logoMetadata': (value) => value?.originalFileName,
      },
      subElementSettings: {
        'footer.berlinaleLinks': getBerlinaleLinksSettings(t),
        'sponsoring.row1.sponsors': getBerlinaleSponsorSettings(t),
        'sponsoring.row2.sponsors': getBerlinaleSponsorSettings(t),
        'sponsoring.row3.sponsors': getBerlinaleSponsorSettings(t),
      },
      diffEntireWord: {
        status: true,
        edition: true,
      },
      name: 'brand',
    };
  }, [t]);
}

function getBerlinaleLinksSettings(t: TFunction<['common', 'landing_page_design']>) {
  return (): HistoryDisplaySettings<BerlinaleLinkDto> => ({
    attributeLabels: {
      textGerman: t('common:berlinaleLink.textGerman'),
      linkGerman: t('common:berlinaleLink.linkGerman'),
      textEnglish: t('common:berlinaleLink.textEnglish'),
      linkEnglish: t('common:berlinaleLink.linkEnglish'),
    },
    valueFormatter: {},
    name: 'textGerman',
  });
}

type LandingPageDesignSponsorWithOrder = LandingPageDesignSponsorLogoDto & { order: number };

function getBerlinaleSponsorSettings(t: TFunction<['common', 'landing_page_design']>) {
  return (): HistoryDisplaySettings<LandingPageDesignSponsorWithOrder> => ({
    attributeLabels: {
      order: t('landing_page_design:sponsoring.order'),
      'german.metadata': t('landing_page_design:sponsoring.sponsorLogo'),
      'german.link': t('landing_page_design:sponsoring.sponsorLogoLink'),
      'german.linkTarget': t('landing_page_design:sponsoring.sponsorLogoLinkTarget'),
      'german.title': t('landing_page_design:sponsoring.sponsorLogoTitle'),
      'german.alt': t('landing_page_design:sponsoring.sponsorLogoAlt'),
      'english.metadata': t('landing_page_design:sponsoring.sponsorLogoEnglish'),
      'english.link': t('landing_page_design:sponsoring.sponsorLogoLinkEnglish'),
      'english.linkTarget': t('landing_page_design:sponsoring.sponsorLogoLinkTargetEnglish'),
      'english.title': t('landing_page_design:sponsoring.sponsorLogoTitleEnglish'),
      'english.alt': t('landing_page_design:sponsoring.sponsorLogoAltEnglish'),
    },
    valueFormatter: {
      'german.metadata': (value) => value?.originalFileName,
      'english.metadata': (value) => value?.originalFileName,
      order: (order) => order?.toString(),
    },
    diffEntireWord: {
      order: true,
    },
    name: 'german.title',
  });
}

export function enhanceLandingPageDesignHistoryWithOrder(historyPage: HistoryPageDto) {
  return produce(historyPage, (draft) => {
    draft.content.forEach((content) => {
      const updateLandingPageSponsoringRow = (row: LandingPageDesignSponsorRowDto) => {
        row.sponsors.forEach((sponsor, index) => {
          (sponsor as LandingPageDesignSponsorWithOrder).order = index + 1;
        });
      };

      const updateLandingPageDesign = (landingPageDesign: LandingPageDesignDto | undefined) => {
        if (landingPageDesign?.sponsoring == null) {
          return;
        }

        updateLandingPageSponsoringRow(landingPageDesign.sponsoring.row1);
        updateLandingPageSponsoringRow(landingPageDesign.sponsoring.row2);
        updateLandingPageSponsoringRow(landingPageDesign.sponsoring.row3);
      };

      updateLandingPageDesign(content.before);
      updateLandingPageDesign(content.after);
    });
  });
}
