/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublishedLaneElementLaneDto
 */
export interface PublishedLaneElementLaneDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PublishedLaneElementLaneDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PublishedLaneElementLaneDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PublishedLaneElementLaneDto
     */
    englishTitle: string;
}

/**
 * Check if a given object implements the PublishedLaneElementLaneDto interface.
 */
export function instanceOfPublishedLaneElementLaneDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('englishTitle' in value)) return false;
    return true;
}

export function PublishedLaneElementLaneDtoFromJSON(json: any): PublishedLaneElementLaneDto {
    return PublishedLaneElementLaneDtoFromJSONTyped(json, false);
}

export function PublishedLaneElementLaneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishedLaneElementLaneDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'englishTitle': json['englishTitle'],
    };
}

export function PublishedLaneElementLaneDtoToJSON(value?: PublishedLaneElementLaneDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'englishTitle': value['englishTitle'],
    };
}

