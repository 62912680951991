import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { TagRelationTypeDto } from '../../../../api';
import { BreadcrumbRouteObject } from '../../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../../ui/helmet/helmet-outlet';
import { filmIcon } from '../../../../ui/icons/business-objects';
import useFetcher from '../../../../util/swr/use-fetcher';
import FilmPageRoute from '../../../film/film-page/film-page-route';
import { fetchFilm } from '../../../film/film-queries';
import { mostRelevantTitle } from '../../../film/film-utils';
import usePermission from '../../../permission/use-permission';

const FilmTagRelationContent = React.lazy(() => import('../tag-relation-page-content'));

const filmTagRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <FilmPageRoute />,
    children: [
      {
        path: 'tags',
        element: <FilmTagContent />,
        handle: {
          helmet: <FilmTagsHelmet />,
        },
      },
    ],
  },
];

export default filmTagRoutes;

function FilmTagContent() {
  const relationType = TagRelationTypeDto.FILM;
  const params = useParams<{ filmId: string }>();
  const filmId = params.filmId;
  invariant(filmId, 'Empty filmId');

  const film = useFetcher(fetchFilm, { id: filmId });
  const filmRef = {
    id: filmId,
    title: mostRelevantTitle(film),
  };
  const fromAnnotation = filmRef.title!;

  const { hasPermission } = usePermission();

  return (
    <FilmTagRelationContent
      relationRef={filmRef}
      relationType={relationType}
      fromIcon={filmIcon}
      fromAnnotation={fromAnnotation}
      canLink={hasPermission('FILM-TAG:CAN_LINK')}
      canUnlink={hasPermission('FILM-TAG:CAN_UNLINK')}
    />
  );
}

function FilmTagsHelmet() {
  const { t } = useTranslation('tag');
  const params = useParams<{ filmId: string }>();
  invariant(params.filmId, 'Empty filmId');
  const film = useFetcher(fetchFilm, { id: params.filmId });

  return (
    <Helmet
      title={t('relations.film.helmet', {
        name: mostRelevantTitle(film),
      })}
    />
  );
}
