import { isEmpty, trim } from 'lodash-es';
import React from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { validateEmailNotOnBlocklistFunction } from '../../../feature/common/validation/validate-email-not-on-blocklist';
import { EMAIL_PATTERN } from '../../../util/constants';
import useAsyncValidation from '../../../util/use-async-validation/use-async-validation';
import InputFormControl, { InputFormControlProps } from '../input-form-control';

interface EmailsControlProps<TFieldValues extends FieldValues>
  extends Omit<InputFormControlProps<TFieldValues>, 'isMultiline' | 'validate' | 'onChange'> {}

function EmailsControl<TFieldValues extends FieldValues>(
  { ...props }: EmailsControlProps<TFieldValues>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { t } = useTranslation(['common']);

  const validateEmailNotOnBlocklist = useAsyncValidation(validateEmailNotOnBlocklistFunction());

  const areMailsValid = async (emails: string | undefined) => {
    if (isEmpty(emails)) {
      return true;
    }
    const emailAddresses = splitAndTrimEmails(emails!);
    if (emailAddresses.some((address) => !EMAIL_PATTERN.test(address))) {
      return t('common:validation_error.emailPattern');
    }
    const notOnBlocklist = await validateEmailNotOnBlocklist(emailAddresses);
    if (!notOnBlocklist) {
      return t('common:validation_error.emailOnBlocklistMultiple');
    }
  };

  return (
    <InputFormControl<TFieldValues>
      isMultiline
      validate={areMailsValid}
      onChange={validateEmailNotOnBlocklist.reset}
      ref={ref}
      {...props}
    />
  );
}

export const splitAndTrimEmails = (emails: string) => {
  return emails
    .split(';')
    .map(trim)
    .filter((e) => !isEmpty(e));
};

export default React.forwardRef(EmailsControl);
