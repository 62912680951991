import { Badge, Heading, HStack, Spinner, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EditionHeaderDto } from '../../../api';
import EditionSelectButton from '../edition-select-button';
import useEditionHeader from '../use-edition-header/use-edition-header';
import { createFocusedEditionSearchParam } from '../use-focused-or-active-edition/use-focused-or-active-edition-id';

interface FocusedEditionHeaderProps {
  navigationTarget: string;
  editionId?: string;
  showEditionSelect?: boolean;
  isMobile?: boolean;
}

/**
 * Die Edition, in der ich mich als Nutzer:in befinde.
 */
const EditionHeader = React.memo(
  ({ navigationTarget, showEditionSelect, isMobile, editionId }: FocusedEditionHeaderProps) => {
    const dateRangeColor = useColorModeValue('blackAlpha.600', 'whiteAlpha.600');
    const editionHeader = useEditionHeader(editionId);
    const { t } = useTranslation(['edition', 'common']);

    const navigate = useNavigate();

    const routeWithEdition = (editionHeader: EditionHeaderDto) => {
      return `/${navigationTarget}?${createFocusedEditionSearchParam(editionHeader.id)}`;
    };

    const handleEditionChange = (editionHeader: EditionHeaderDto) => {
      navigate(routeWithEdition(editionHeader));
    };

    return (
      <HStack
        data-mobile={isMobile}
        sx={{
          bgColor: 'background.hero',
          px: 8,
          py: 0,
          h: 18,
          gap: 4,
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderBottomColor: 'blackAlpha.200',
          position: 'sticky',
          top: 0,
          zIndex: 'calc(var(--chakra-zIndices-docked) + 1)',
          '&[data-mobile="true"]': {
            px: 3,
            h: 10,
            fontSize: 'sm',
          },
        }}
      >
        {editionHeader != null ? (
          <>
            <HStack spacing={2} alignItems="baseline">
              <Heading as="h2" size={isMobile ? 'sm' : 'md'}>
                {editionHeader.name}
              </Heading>
              {editionHeader.active ? (
                <Badge colorScheme="teal" variant="solid" alignSelf="center">
                  {t('edition:active')}
                </Badge>
              ) : null}
              <Text as="span" color={dateRangeColor}>
                {t('common:format.date_range', { dateRange: editionHeader.dateRange })}
              </Text>
            </HStack>

            {showEditionSelect && (
              <EditionSelectButton
                edition={editionHeader}
                onChange={handleEditionChange}
                buttonLabel={t('edition:change')}
              />
            )}
          </>
        ) : (
          <Spinner size="sm" />
        )}
      </HStack>
    );
  },
);

export default EditionHeader;
