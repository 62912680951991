/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CancellationFeeDto = {
    CHARGE: 'CHARGE',
    FREE: 'FREE'
} as const;
export type CancellationFeeDto = typeof CancellationFeeDto[keyof typeof CancellationFeeDto];


export function CancellationFeeDtoFromJSON(json: any): CancellationFeeDto {
    return CancellationFeeDtoFromJSONTyped(json, false);
}

export function CancellationFeeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancellationFeeDto {
    return json as CancellationFeeDto;
}

export function CancellationFeeDtoToJSON(value?: CancellationFeeDto | null): any {
    return value as any;
}

