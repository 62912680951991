/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfferDto } from './OfferDto';
import {
    OfferDtoFromJSON,
    OfferDtoFromJSONTyped,
    OfferDtoToJSON,
} from './OfferDto';

/**
 * 
 * @export
 * @interface OfferSearchResultDto
 */
export interface OfferSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof OfferSearchResultDto
     */
    resultType: OfferSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OfferSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {OfferDto}
     * @memberof OfferSearchResultDto
     */
    result: OfferDto;
}


/**
 * @export
 */
export const OfferSearchResultDtoResultTypeEnum = {
    OFFER: 'OFFER'
} as const;
export type OfferSearchResultDtoResultTypeEnum = typeof OfferSearchResultDtoResultTypeEnum[keyof typeof OfferSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the OfferSearchResultDto interface.
 */
export function instanceOfOfferSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function OfferSearchResultDtoFromJSON(json: any): OfferSearchResultDto {
    return OfferSearchResultDtoFromJSONTyped(json, false);
}

export function OfferSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': OfferDtoFromJSON(json['result']),
    };
}

export function OfferSearchResultDtoToJSON(value?: OfferSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': OfferDtoToJSON(value['result']),
    };
}

