/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductReferenceDto } from './ProductReferenceDto';
import {
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoFromJSONTyped,
    ProductReferenceDtoToJSON,
} from './ProductReferenceDto';

/**
 * 
 * @export
 * @interface GroupPermittedProductDto
 */
export interface GroupPermittedProductDto {
    /**
     * 
     * @type {number}
     * @memberof GroupPermittedProductDto
     */
    numberOf: number;
    /**
     * 
     * @type {ProductReferenceDto}
     * @memberof GroupPermittedProductDto
     */
    product: ProductReferenceDto;
}

/**
 * Check if a given object implements the GroupPermittedProductDto interface.
 */
export function instanceOfGroupPermittedProductDto(value: object): boolean {
    if (!('numberOf' in value)) return false;
    if (!('product' in value)) return false;
    return true;
}

export function GroupPermittedProductDtoFromJSON(json: any): GroupPermittedProductDto {
    return GroupPermittedProductDtoFromJSONTyped(json, false);
}

export function GroupPermittedProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupPermittedProductDto {
    if (json == null) {
        return json;
    }
    return {
        
        'numberOf': json['numberOf'],
        'product': ProductReferenceDtoFromJSON(json['product']),
    };
}

export function GroupPermittedProductDtoToJSON(value?: GroupPermittedProductDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'numberOf': value['numberOf'],
        'product': ProductReferenceDtoToJSON(value['product']),
    };
}

