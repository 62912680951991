import { Flex, Grid, GridItem, Heading, HStack, HTMLChakraProps } from '@chakra-ui/react';
import { ReactElement, ReactNode, useId } from 'react';

/**
 * Properties for description group.
 */
export interface DescriptionsProps extends HTMLChakraProps<'div'> {
  children?: ReactNode;
  columnCount?: number;
  label?: string;
  action?: ReactNode;
  helperPopover?: ReactElement;
}

/**
 * Groups description items with additional caption with caption in viewers and editors.
 */
export default function DescriptionGroup({
  children,
  columnCount = 2,
  label,
  action,
  helperPopover,
  ...props
}: DescriptionsProps) {
  const id = useId();

  return (
    <Grid
      {...props}
      templateColumns={`repeat(${columnCount}, 1fr 1fr)`}
      sx={{
        '--gap': '1px',
        gap: 'var(--gap)',
        bgColor: 'layer.01',
        border: '1px solid',
        borderColor: 'border.01',
        borderRadius: 'base',
        overflow: 'hidden',
      }}
      aria-labelledby={id}
    >
      {label != null && (
        <GridItem
          as={Flex}
          sx={{
            px: 4,
            py: action ? 1 : 2,
            pr: action ? 1 : 4,
            backgroundColor: 'layer.02',
            alignItems: 'center',
            height: 10,
            justifyContent: 'space-between',
          }}
          colSpan={columnCount * 2}
        >
          <HStack spacing={0} alignItems="center">
            <Heading as="h3" size="sm" sx={{ mr: 0, fontWeight: 'semibold' }} id={id}>
              {label}
            </Heading>
            {helperPopover}
          </HStack>
          {action}
        </GridItem>
      )}
      {children}
    </Grid>
  );
}
