import { Alert, AlertIcon, chakra, HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '../../../ui/form/form-control';
import MultiValueSelectControl from '../../../ui/form/select-control/multi-value-select-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import { LayoutType } from '../../common/layout-type';
import { markers } from '../person-enum-constants';

/**
 * Properties for markers control.
 */
export interface MarkersControlProps {
  layout: LayoutType;
}

/**
 * Control to edit person's markers.
 */
export default function MarkersGroupControl({ layout }: MarkersControlProps) {
  const { t } = useTranslation('person');

  return (
    <Stack spacing={3}>
      <HStack spacing={6} alignItems="flex-start">
        <Alert status="info">
          <AlertIcon />
          {t('confidentialityInfo')}
        </Alert>
      </HStack>

      <Stack direction={layout} spacing={6} alignItems="flex-start">
        <chakra.div w={layout === LayoutType.NORMAL ? '20%' : '100%'}>
          <ConfidentialityControl />
        </chakra.div>
        <MarkersControl />
      </Stack>
    </Stack>
  );
}

export function ConfidentialityControl() {
  const { t } = useTranslation('person');

  return (
    <FormControl
      label={t('confidentiality')}
      name="confidentiality"
      helperPopover={<HelperPopover children={t('confidentialityPopover')} />}
      isRequired
    >
      <ValueSelectControl
        name="confidentiality"
        options={[true, false]}
        renderLabel={(value) => t(`confidentialityLabel.${value}`)}
        defaultValue={false}
        isClearable={false}
      />
    </FormControl>
  );
}

export function MarkersControl({ helperText }: { helperText?: string }) {
  const { t } = useTranslation('person');

  let helperTextToShow;
  if (helperText == null) {
    helperTextToShow = t('markersHint');
  } else {
    helperTextToShow = helperText;
  }

  return (
    <FormControl
      label={t('markers')}
      name="markers"
      helperText={helperTextToShow}
      helperPopover={<HelperPopover children={t('markersPopover')} />}
    >
      <MultiValueSelectControl
        name="markers"
        options={markers}
        renderLabel={(value) => t(`markersLabel.${value}`)}
        isSearchable={true}
      />
    </FormControl>
  );
}
