import { faQrcode } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { guestlistIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { EVENT_MOBILE_MENU_ITEM, EventMenuItem } from '../event/event-page/event-page-menu';

export const admissionMobileMenuItem: EventMenuItem[] = [
  {
    order: 7,
    label: <Translate ns="guest_list" i18nKey="admission" />,
    icon: guestlistIcon,
    path: (eventId) => `/mobile/events/${eventId}/guest-list`,
    isVisible: (ownerSectionIds, permissions) =>
      permissions.hasPermission('GUEST_LIST:CAN_SEE_DETAILS') ||
      ownerSectionIds.some((sectionId) =>
        permissions.hasPermissionFromSection(sectionId, 'GUEST_LIST:CAN_SEE_DETAILS_OWN'),
      ),
  },
  {
    order: 8,
    label: <Translate ns="live_ticketing" i18nKey="live_ticketing" />,
    icon: faQrcode,
    path: (eventId) => `/mobile/events/${eventId}/live-ticketing`,
    isVisible: (ownerSectionIds, permissions) =>
      permissions.hasPermission('LIVE_TICKETING:CAN_ADMIT') ||
      ownerSectionIds.some((sectionId) =>
        permissions.hasPermissionFromSection(sectionId, 'LIVE_TICKETING:CAN_ADMIT_OWN'),
      ),
  },
];

export default function AdmissionProvider(registry: PluginRegistry) {
  registry.register(EVENT_MOBILE_MENU_ITEM, admissionMobileMenuItem[0]);
  registry.register(EVENT_MOBILE_MENU_ITEM, admissionMobileMenuItem[1]);
}
