import { Popover as BasePopover, PopoverProps as BasePopoverProps, usePopoverContext } from '@chakra-ui/react';
import React from 'react';

interface PopoverProps extends Omit<BasePopoverProps, 'children'> {
  closeOnHorizontalScroll?: { unlessInsideRef: React.RefObject<HTMLDivElement> };
  children?: React.ReactNode;
}

export default function Popover({ children, closeOnHorizontalScroll, ...props }: PopoverProps) {
  return (
    <BasePopover {...props}>
      <PopoverCloseOnScroll closeOnHorizontalScroll={closeOnHorizontalScroll}>{children}</PopoverCloseOnScroll>
    </BasePopover>
  );
}

interface PopoverCloseOnScrollProps {
  children: PopoverProps['children'];
  closeOnHorizontalScroll?: { unlessInsideRef: React.RefObject<HTMLDivElement> };
}

function PopoverCloseOnScroll({ children, closeOnHorizontalScroll }: PopoverCloseOnScrollProps) {
  const { onClose, isOpen } = usePopoverContext();

  React.useEffect(() => {
    if (!isOpen || closeOnHorizontalScroll == null) {
      return;
    }

    const { unlessInsideRef } = closeOnHorizontalScroll;

    // Keep track of the previous scroll position to detect horizontal scrolling.
    const prevScrollXMap = new Map<Element, number>();

    const handleScroll = (event: Event) => {
      const target = event.target as Element;
      // if the scroll event's source is inside the popover itself…
      // (e.g. inputs do a horizontal scroll if the text is too long)
      if (unlessInsideRef.current?.contains(target)) {
        // …do not close it
        return;
      }

      const prevScrollX = prevScrollXMap.get(target);

      if (prevScrollX != null && prevScrollX !== target.scrollLeft) {
        onClose();
      }

      prevScrollXMap.set(target, target.scrollLeft);
    };

    document.addEventListener('scroll', handleScroll, true);

    return () => document.removeEventListener('scroll', handleScroll, true);
  }, [isOpen, closeOnHorizontalScroll, onClose]);

  return <>{children}</>;
}
