import { Menu, MenuDivider, MenuItem, MenuList, Portal, ResponsiveValue } from '@chakra-ui/react';
import { faCopy, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { EventDto, EventListItemDto, EventStatusCheckDto } from '../../../api';
import eventApi from '../../../data-access/event-api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import { DisableableRouterLink } from '../../../ui/disableable-button/disableable-router-link';
import { SwitchToMobileButton } from '../../../ui/toggle-mobile-button/toogle-mobile-button';
import usePermission from '../../permission/use-permission';
import EventDeleteButton from '../event-delete/event-delete-button';

interface EventMenuProps {
  event: EventDto | EventListItemDto;
  onDelete: () => void;
  hasSwitchToMobileButton?: boolean;
  size?: ResponsiveValue<'sm' | 'md'>;
}

export function EventMenuList({ event, onDelete, hasSwitchToMobileButton, size }: EventMenuProps) {
  const { t } = useTranslation(['event', 'common']);
  const { hasPermission, hasPermissionFromSection } = usePermission();

  const [eventStatusCheck, setEventStatusCheck] = React.useState<EventStatusCheckDto>({
    isDeletable: false,
    hasGuestList: false,
    hasPersonInRundown: false,
    connectedEvents: [],
    hasKeyPlayer: false,
    hasLiveTicketingAttendee: false,
    hasMailing: false,
  });

  async function checkStatus(event: EventDto | EventListItemDto) {
    invariant(event.id != null, 'Missing event id');
    const statusCheck = await eventApi.checkEventStatus({ eventId: event.id });
    setEventStatusCheck(statusCheck);
  }

  const actions: ReactNode[] = [];
  const sectionIds = 'owners' in event ? event.owners.map((owner) => owner.section.id) : event.ownerSectionIds;

  if (hasSwitchToMobileButton) {
    actions.push(<SwitchToMobileButton key="mobileSwitch" />);
  }

  if (hasPermission('EVENT:CAN_COPY') || hasPermission('EVENT:CAN_COPY_OWN')) {
    const canCopy =
      hasPermission('EVENT:CAN_COPY') ||
      sectionIds.some((sectionId) => hasPermissionFromSection(sectionId, 'EVENT:CAN_COPY_OWN'));

    actions.push(
      <MenuItem
        icon={<FontAwesomeIcon icon={faCopy} fixedWidth />}
        as={DisableableRouterLink}
        to={`/events/${event.id}/copy`}
        key="copyEvent"
        isDisabled={!canCopy}
        disableReason={t('common:validation_error.incomplete_permission')}
      >
        {t('event:action.copy')}
      </MenuItem>,
    );
  }

  if (hasPermission('EVENT:CAN_DELETE') || hasPermission('EVENT:CAN_DELETE_OWN')) {
    if (actions.length > 0) {
      actions.push(<MenuDivider key="divideEtImpera" data-testid="menu-divider" />);
    }

    const canDelete =
      hasPermission('EVENT:CAN_DELETE') ||
      sectionIds.some((sectionId) => hasPermissionFromSection(sectionId, 'EVENT:CAN_DELETE_OWN'));

    actions.push(
      <MenuItem
        as={EventDeleteButton}
        isDeletable={eventStatusCheck.isDeletable}
        connectedEvents={eventStatusCheck.connectedEvents}
        hasGuestList={eventStatusCheck.hasGuestList}
        hasNoPermissionToDelete={!canDelete}
        hasPersonInRundown={eventStatusCheck.hasPersonInRundown}
        hasKeyPlayer={eventStatusCheck.hasKeyPlayer}
        hasLiveTicketingAttendee={eventStatusCheck.hasLiveTicketingAttendee}
        hasMailing={eventStatusCheck.hasMailing}
        color="text.error"
        event={event}
        onSuccess={onDelete}
        icon={<FontAwesomeIcon icon={faTrashAlt} fixedWidth />}
        key="deleteEvent"
      >
        {t('event:action.delete')}
      </MenuItem>,
    );
  }

  return (
    actions.length > 0 && (
      <Menu onOpen={() => checkStatus(event)}>
        <BurgerMenuButton size={size} />
        <Portal>
          <MenuList>{actions}</MenuList>
        </Portal>
      </Menu>
    )
  );
}
