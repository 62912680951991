/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';
import type { FieldOfInterestDto } from './FieldOfInterestDto';
import {
    FieldOfInterestDtoFromJSON,
    FieldOfInterestDtoFromJSONTyped,
    FieldOfInterestDtoToJSON,
} from './FieldOfInterestDto';
import type { IndustryDto } from './IndustryDto';
import {
    IndustryDtoFromJSON,
    IndustryDtoFromJSONTyped,
    IndustryDtoToJSON,
} from './IndustryDto';

/**
 * Filmography of a company
 * @export
 * @interface FilmographyDto
 */
export interface FilmographyDto {
    /**
     * 
     * @type {Date}
     * @memberof FilmographyDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof FilmographyDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof FilmographyDto
     */
    director: string;
    /**
     * 
     * @type {Array<CountryReferenceDto>}
     * @memberof FilmographyDto
     */
    countries: Array<CountryReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof FilmographyDto
     */
    releaseYear: string;
    /**
     * 
     * @type {FieldOfInterestDto}
     * @memberof FilmographyDto
     */
    genre: FieldOfInterestDto;
    /**
     * 
     * @type {string}
     * @memberof FilmographyDto
     */
    festival: string;
    /**
     * 
     * @type {string}
     * @memberof FilmographyDto
     */
    filmMarketYear: string;
    /**
     * 
     * @type {IndustryDto}
     * @memberof FilmographyDto
     */
    role: IndustryDto;
}

/**
 * Check if a given object implements the FilmographyDto interface.
 */
export function instanceOfFilmographyDto(value: object): boolean {
    if (!('title' in value)) return false;
    if (!('director' in value)) return false;
    if (!('countries' in value)) return false;
    if (!('releaseYear' in value)) return false;
    if (!('genre' in value)) return false;
    if (!('festival' in value)) return false;
    if (!('filmMarketYear' in value)) return false;
    if (!('role' in value)) return false;
    return true;
}

export function FilmographyDtoFromJSON(json: any): FilmographyDto {
    return FilmographyDtoFromJSONTyped(json, false);
}

export function FilmographyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilmographyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'title': json['title'],
        'director': json['director'],
        'countries': ((json['countries'] as Array<any>).map(CountryReferenceDtoFromJSON)),
        'releaseYear': json['releaseYear'],
        'genre': FieldOfInterestDtoFromJSON(json['genre']),
        'festival': json['festival'],
        'filmMarketYear': json['filmMarketYear'],
        'role': IndustryDtoFromJSON(json['role']),
    };
}

export function FilmographyDtoToJSON(value?: FilmographyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'title': value['title'],
        'director': value['director'],
        'countries': ((value['countries'] as Array<any>).map(CountryReferenceDtoToJSON)),
        'releaseYear': value['releaseYear'],
        'genre': FieldOfInterestDtoToJSON(value['genre']),
        'festival': value['festival'],
        'filmMarketYear': value['filmMarketYear'],
        'role': IndustryDtoToJSON(value['role']),
    };
}

