/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StandCompanyRelationStatusDto = {
    CONFIRMED: 'CONFIRMED',
    PENDING: 'PENDING'
} as const;
export type StandCompanyRelationStatusDto = typeof StandCompanyRelationStatusDto[keyof typeof StandCompanyRelationStatusDto];


export function StandCompanyRelationStatusDtoFromJSON(json: any): StandCompanyRelationStatusDto {
    return StandCompanyRelationStatusDtoFromJSONTyped(json, false);
}

export function StandCompanyRelationStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandCompanyRelationStatusDto {
    return json as StandCompanyRelationStatusDto;
}

export function StandCompanyRelationStatusDtoToJSON(value?: StandCompanyRelationStatusDto | null): any {
    return value as any;
}

