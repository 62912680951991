import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditionReferenceDto, EventStatusDto } from '../../../api';
import Checklist from '../../../ui/checklist/checklist';
import ConfirmDialog, { ConfirmDialogProps } from '../../../ui/dialog/confirm-dialog';

interface ConfirmChangeDialogProps extends Omit<ConfirmDialogProps, 'confirmActionLabel' | 'status'> {
  status?: EventStatusDto;
  visible?: boolean;
  edition?: EditionReferenceDto;
  previousEdition?: EditionReferenceDto;
}

export default function ConfirmChangeDialog({
  status,
  visible,
  edition,
  previousEdition,
  onClose,
  ...props
}: ConfirmChangeDialogProps) {
  const { t } = useTranslation('event');
  const { t: tCommon } = useTranslation('common');
  const [isChecked, setChecked] = React.useState<boolean>(false);
  const initFocusRef = React.useRef<HTMLInputElement>(null);

  const onCloseWithReset = React.useCallback(
    (confirm: boolean) => {
      setChecked(false);
      onClose(confirm);
    },
    [onClose],
  );

  function confirmChecklist() {
    const checklist = [];
    if (status != null) {
      checklist.push(
        t('status.change_confirmation', {
          status: t(`statusOptions.${status}`),
        }),
      );
    }
    if (visible === false) {
      checklist.push(t('visibility.confirmation'));
    }
    if (edition != null && previousEdition && previousEdition.name !== edition.name) {
      checklist.push(
        t('edition.confirmation', {
          newEdition: edition.name,
          oldEdition: previousEdition?.name,
        }),
      );
    }
    return checklist;
  }

  return (
    <ConfirmDialog
      {...props}
      initialFocusRef={initFocusRef}
      confirmActionLabel={tCommon('action.save_object', { object: t('event') })}
      isConfirmActionDisabled={!isChecked}
      submitButtonVariant="primary"
      onClose={onCloseWithReset}
    >
      <Checklist initialFocusRef={initFocusRef} tasks={confirmChecklist()} onAllChecked={setChecked} />
    </ConfirmDialog>
  );
}
