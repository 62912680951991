import { chakra, useMergeRefs } from '@chakra-ui/react';
import React from 'react';

interface DataTableTruncatedTextProps {
  children?: React.ReactNode;
}

// lh: This component can be used to wrap text inside a data table cell to have ellipsis.
// We cannot use a simple <Text isTruncated /> here, because tables have a weird way of calculating
// the width of cells.
const DataTableTruncatedText = React.forwardRef(
  ({ children }: DataTableTruncatedTextProps, forwardedRef: React.ForwardedRef<HTMLDivElement>) => {
    const innerRef = React.useRef<HTMLSpanElement>(null);
    const ref = React.useRef<HTMLDivElement>(null);
    const mergedRef = useMergeRefs(ref, forwardedRef);

    React.useLayoutEffect(() => {
      const measure = () => {
        ref.current!.style.height = `${innerRef.current!.offsetHeight}px`;
      };

      const observer: IntersectionObserver | undefined =
        // lh: IntersectionObserver is not available when testing
        window.IntersectionObserver &&
        new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
              measure();
            }
          });
        });

      window.addEventListener('resize', measure);
      observer?.observe(innerRef.current!);
      measure();

      return () => {
        window.removeEventListener('resize', measure);
        observer?.disconnect();
      };
    }, []);

    return (
      <chakra.div ref={mergedRef} position="relative" w="full">
        <chakra.span
          ref={innerRef}
          position="absolute"
          whiteSpace="nowrap"
          w="full"
          overflow="hidden"
          textOverflow="ellipsis"
          // Use system tooltip to show unabbreviated text. Only possible with string children, not React Nodes
          title={typeof children === 'string' ? children : undefined}
        >
          {children}
        </chakra.span>
      </chakra.div>
    );
  },
);

export default DataTableTruncatedText;
