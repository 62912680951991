import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { groupTemplateIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function GroupTemplateSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'GROUP_TEMPLATE', `${result.resultType} is no instance of GroupTemplateSearchResult`);

  const groupTemplate = result.result;
  const { t } = useTranslation('group_template');

  return (
    <SearchResult
      icon={groupTemplateIcon}
      title={groupTemplate.name}
      link={`/group-templates/${groupTemplate.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="GROUP_TEMPLATE:CAN_SEE_DETAILS_OWN"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('capacity'),
            element: groupTemplate.capacity?.toString(),
          },
          {
            label: t('owners.label'),
            element: groupTemplate.owners.map((owner) => owner.section.name).join(', '),
          },
          {
            label: t('permittedProducts.label'),
            element: groupTemplate.permittedProducts.map((product) => product.product.title).join(', '),
          },
          {
            label: t('costBearer'),
            element: groupTemplate.costBearer,
          },
          {
            label: t('landingPage.label'),
            element: groupTemplate.landingPage?.name,
          },
          {
            label: t('status.label'),
            element: t(`statusOptions.${groupTemplate.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}
