/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BlockChildDto } from './BlockChildDto';
import {
    BlockChildDtoFromJSON,
    BlockChildDtoFromJSONTyped,
    BlockChildDtoToJSON,
} from './BlockChildDto';

/**
 * 
 * @export
 * @interface BlockElementDto
 */
export interface BlockElementDto {
    /**
     * 
     * @type {string}
     * @memberof BlockElementDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof BlockElementDto
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockElementDto
     */
    align?: string;
    /**
     * 
     * @type {Array<BlockChildDto>}
     * @memberof BlockElementDto
     */
    children?: Array<BlockChildDto>;
}

/**
 * Check if a given object implements the BlockElementDto interface.
 */
export function instanceOfBlockElementDto(value: object): boolean {
    if (!('type' in value)) return false;
    return true;
}

export function BlockElementDtoFromJSON(json: any): BlockElementDto {
    return BlockElementDtoFromJSONTyped(json, false);
}

export function BlockElementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockElementDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'author': json['author'] == null ? undefined : json['author'],
        'align': json['align'] == null ? undefined : json['align'],
        'children': json['children'] == null ? undefined : ((json['children'] as Array<any>).map(BlockChildDtoFromJSON)),
    };
}

export function BlockElementDtoToJSON(value?: BlockElementDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'author': value['author'],
        'align': value['align'],
        'children': value['children'] == null ? undefined : ((value['children'] as Array<any>).map(BlockChildDtoToJSON)),
    };
}

