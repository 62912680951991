import { Stack } from '@chakra-ui/react';
import { values } from 'lodash-es';
import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { LandingPageTextModuleSettingsDto, RichTextOptionsDto } from '../../../api';
import Label from '../../../ui/form/label';
import RichTextOptionsViewer, {
  RichTextOption,
} from '../../../ui/form/rich-text-options-control/rich-text-options-viewer';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import LandingPageTemplateModuleConfig, {
  LANDING_PAGE_TEMPLATE_MODULE_HOOK,
} from '../landing-page-template-module-config';
import TextModuleSettingsControl from './text-module-settings-control';

export default function LandingPageTemplateModuleProvider(registry: PluginRegistry) {
  registry.register(LANDING_PAGE_TEMPLATE_MODULE_HOOK, useLandingPageTextModule);
}

function useLandingPageTextModule(): LandingPageTemplateModuleConfig<LandingPageTextModuleSettingsDto> {
  const { t } = useTranslation(['landing_page_template', 'common']);
  const id = useId();

  return {
    type: 'text',
    label: t('landing_page_template:moduleSettings.text'),
    renderControl: ({ name }) => <TextModuleSettingsControl name={name} />,
    renderViewer: ({ module }) => (
      <Stack spacing={1}>
        <Label id={id}>{t('common:richTextOptions.label')}</Label>{' '}
        <RichTextOptionsViewer options={values(RichTextOptionsDto)} activeOptions={module.options} />
      </Stack>
    ),
    historyDisplaySettings: {
      attributeLabels: {
        options: t('common:richTextOptions.label'),
      },
      valueFormatter: {
        options: (options) =>
          options
            ?.map((option: RichTextOption) => t(`common:richTextOptions.options.${option}`))
            .sort()
            .join(', '),
      },
    },
  };
}
