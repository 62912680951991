/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountingRecordPermissionDto
 */
export interface AccountingRecordPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordPermissionDto
     */
    name: AccountingRecordPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordPermissionDto
     */
    action: AccountingRecordPermissionDtoActionEnum;
}


/**
 * @export
 */
export const AccountingRecordPermissionDtoNameEnum = {
    ACCOUNTING_RECORD: 'ACCOUNTING_RECORD'
} as const;
export type AccountingRecordPermissionDtoNameEnum = typeof AccountingRecordPermissionDtoNameEnum[keyof typeof AccountingRecordPermissionDtoNameEnum];

/**
 * @export
 */
export const AccountingRecordPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    EXPORT: 'CAN_EXPORT'
} as const;
export type AccountingRecordPermissionDtoActionEnum = typeof AccountingRecordPermissionDtoActionEnum[keyof typeof AccountingRecordPermissionDtoActionEnum];


/**
 * Check if a given object implements the AccountingRecordPermissionDto interface.
 */
export function instanceOfAccountingRecordPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function AccountingRecordPermissionDtoFromJSON(json: any): AccountingRecordPermissionDto {
    return AccountingRecordPermissionDtoFromJSONTyped(json, false);
}

export function AccountingRecordPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountingRecordPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function AccountingRecordPermissionDtoToJSON(value?: AccountingRecordPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

