/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupExternalApplicationStatusDto } from './GroupExternalApplicationStatusDto';
import {
    GroupExternalApplicationStatusDtoFromJSON,
    GroupExternalApplicationStatusDtoFromJSONTyped,
    GroupExternalApplicationStatusDtoToJSON,
} from './GroupExternalApplicationStatusDto';
import type { GroupExternalApplicationTypeDto } from './GroupExternalApplicationTypeDto';
import {
    GroupExternalApplicationTypeDtoFromJSON,
    GroupExternalApplicationTypeDtoFromJSONTyped,
    GroupExternalApplicationTypeDtoToJSON,
} from './GroupExternalApplicationTypeDto';

/**
 * 
 * @export
 * @interface GroupExternalApplicationListItemDto
 */
export interface GroupExternalApplicationListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupExternalApplicationListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GroupExternalApplicationListItemDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof GroupExternalApplicationListItemDto
     */
    surname: string;
    /**
     * 
     * @type {GroupExternalApplicationStatusDto}
     * @memberof GroupExternalApplicationListItemDto
     */
    status: GroupExternalApplicationStatusDto;
    /**
     * 
     * @type {GroupExternalApplicationTypeDto}
     * @memberof GroupExternalApplicationListItemDto
     */
    type: GroupExternalApplicationTypeDto;
}

/**
 * Check if a given object implements the GroupExternalApplicationListItemDto interface.
 */
export function instanceOfGroupExternalApplicationListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('status' in value)) return false;
    if (!('type' in value)) return false;
    return true;
}

export function GroupExternalApplicationListItemDtoFromJSON(json: any): GroupExternalApplicationListItemDto {
    return GroupExternalApplicationListItemDtoFromJSONTyped(json, false);
}

export function GroupExternalApplicationListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupExternalApplicationListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'status': GroupExternalApplicationStatusDtoFromJSON(json['status']),
        'type': GroupExternalApplicationTypeDtoFromJSON(json['type']),
    };
}

export function GroupExternalApplicationListItemDtoToJSON(value?: GroupExternalApplicationListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'status': GroupExternalApplicationStatusDtoToJSON(value['status']),
        'type': GroupExternalApplicationTypeDtoToJSON(value['type']),
    };
}

