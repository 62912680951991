import { values } from 'lodash-es';
import { LandingPageTemplateDto, RichTextOptionsDto } from '../../../api';
import RichTextOptionsControl from '../../../ui/form/rich-text-options-control/rich-text-options-control';

interface TextModuleSettingsControlProps {
  name: `moduleSettings.${number}`;
}

export default function TextModuleSettingsControl({ name }: TextModuleSettingsControlProps) {
  return (
    <RichTextOptionsControl<LandingPageTemplateDto> name={`${name}.options`} options={values(RichTextOptionsDto)} />
  );
}
