import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';
import useFetcher from '../../../util/swr/use-fetcher';
import { Component } from '../../order-confirmation/order-confirmation-page/order-confirmation-page-route';
import { fetchOrderConfirmation } from '../../order-confirmation/order-confirmation-queries';
import { OrderConfirmationPaymentPageContent } from './payment-lister/order-confirmation-payment-page-content';

const orderConfirmationPaymentRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <Component />,
    children: [
      {
        path: 'payments',
        element: <OrderConfirmationPaymentPageContent />,
        handle: {
          helmet: <OrderConfirmationPaymentsHelmet />,
        },
      },
    ],
  },
];

export default orderConfirmationPaymentRoutes;

function OrderConfirmationPaymentsHelmet() {
  const { t } = useTranslation('payment');
  const params = useParams<{ orderConfirmationId: string }>();
  invariant(params.orderConfirmationId, 'Empty orderConfirmationId');
  const orderConfirmation = useFetcher(fetchOrderConfirmation, { id: params.orderConfirmationId });

  return (
    <Helmet
      title={t('connections.orderConfirmationHelmet', {
        orderConfirmationNumber: orderConfirmation.orderConfirmationNumber,
      })}
    />
  );
}
