import React from 'react';
import { systemMailingIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useSystemMailingPermissionClassGroupExtension } from './system-mailing-permissions';
import SystemMailingSearchResult from './system-mailing-search-result';

export default function SystemMailingPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'SYSTEM_MAILING',
    Component: SystemMailingSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'SYSTEM_MAILING',
    icon: systemMailingIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.SYSTEM_MAILING" />,
    necessaryPermission: 'SYSTEM_MAILING:CAN_SEE_DETAILS',
  });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useSystemMailingPermissionClassGroupExtension);
}
