import { useMemo } from 'react';
import invariant from 'tiny-invariant';
import { PermissionDto } from '../../api';
import usePlugins, { PluginToken } from '../../util/plugin/use-plugins';

export type PermissionName = PermissionDto['name'];
type PermissionByName<TName extends PermissionName> = PermissionDto & { name: TName };

export interface PermissionClassGroupExtension<TName extends PermissionName = PermissionName> {
  name: TName;
  label: string;
  getActionLabel(action: PermissionByName<TName>['action']): string;
  getInfo(action: PermissionByName<TName>['action']): string | '';
  getAdditionalRequirement(action: PermissionByName<TName>['action']): string | '';
}

export const PERMISSION_CLASS_GROUP_EXTENSION = new PluginToken<() => PermissionClassGroupExtension<any>>(
  'PermissionClassGroupExtension',
);

export function usePermissionClassGroupExtensions() {
  const extensionHooks = usePlugins(PERMISSION_CLASS_GROUP_EXTENSION);
  // Hooks should never be called conditionally. So this is actually a no-go. But: the list of
  // plugins returned by usePlugins is static. Therefor the order in which the hooks are called is
  // always the same. Just not wrap it in useMemo and everything should work out fine.
  const extensions = extensionHooks.map((hook) => hook());

  return useMemo(
    () => ({
      extensions,
      getExtension(name: string) {
        const extension = extensions.find((extension) => name === extension.name);
        invariant(extension != null, `no extension for permission class group ${name}`);

        return extension;
      },
    }),
    [extensions],
  );
}
