import invariant from 'tiny-invariant';
import { GetLandingPageRequest } from '../../api';
import landingPageApi from '../../data-access/landing-page-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const landingPageFetcher = createFetcherScope(landingPageApi);
export const getLandingPage = landingPageFetcher.createFetcher(async (params: GetLandingPageRequest) => {
  const response = await landingPageApi.getLandingPageRaw(params);
  const previewUrl = response.raw.headers.get('X-PREVIEW-URL');
  invariant(previewUrl != null, 'Missing preview url header');

  return { landingPage: await response.value(), previewUrl };
});
export const listLandingPages = landingPageFetcher.createFetcher(landingPageApi.listLandingPages);
export const listLandingPagesForDesign = landingPageFetcher.createFetcher(landingPageApi.listLandingPagesForDesign);
export const getLandingPageHistory = landingPageFetcher.createFetcher(landingPageApi.getLandingPageHistory);
