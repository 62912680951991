/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { PhoneNumberListItemDto } from './PhoneNumberListItemDto';
import {
    PhoneNumberListItemDtoFromJSON,
    PhoneNumberListItemDtoFromJSONTyped,
    PhoneNumberListItemDtoToJSON,
} from './PhoneNumberListItemDto';

/**
 * 
 * @export
 * @interface GroupCompanyRelationListItemCompanyDto
 */
export interface GroupCompanyRelationListItemCompanyDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupCompanyRelationListItemCompanyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GroupCompanyRelationListItemCompanyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GroupCompanyRelationListItemCompanyDto
     */
    status: GroupCompanyRelationListItemCompanyDtoStatusEnum;
    /**
     * 
     * @type {Array<PersonReferenceDto>}
     * @memberof GroupCompanyRelationListItemCompanyDto
     */
    companyAdmins?: Array<PersonReferenceDto>;
    /**
     * 
     * @type {PhoneNumberListItemDto}
     * @memberof GroupCompanyRelationListItemCompanyDto
     */
    phoneNumber?: PhoneNumberListItemDto;
    /**
     * 
     * @type {string}
     * @memberof GroupCompanyRelationListItemCompanyDto
     */
    emailAddress?: string;
}


/**
 * @export
 */
export const GroupCompanyRelationListItemCompanyDtoStatusEnum = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    HISTORIC: 'HISTORIC',
    UNCONFIRMED: 'UNCONFIRMED',
    ARCHIVED: 'ARCHIVED'
} as const;
export type GroupCompanyRelationListItemCompanyDtoStatusEnum = typeof GroupCompanyRelationListItemCompanyDtoStatusEnum[keyof typeof GroupCompanyRelationListItemCompanyDtoStatusEnum];


/**
 * Check if a given object implements the GroupCompanyRelationListItemCompanyDto interface.
 */
export function instanceOfGroupCompanyRelationListItemCompanyDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function GroupCompanyRelationListItemCompanyDtoFromJSON(json: any): GroupCompanyRelationListItemCompanyDto {
    return GroupCompanyRelationListItemCompanyDtoFromJSONTyped(json, false);
}

export function GroupCompanyRelationListItemCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupCompanyRelationListItemCompanyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'companyAdmins': json['companyAdmins'] == null ? undefined : ((json['companyAdmins'] as Array<any>).map(PersonReferenceDtoFromJSON)),
        'phoneNumber': json['phoneNumber'] == null ? undefined : PhoneNumberListItemDtoFromJSON(json['phoneNumber']),
        'emailAddress': json['emailAddress'] == null ? undefined : json['emailAddress'],
    };
}

export function GroupCompanyRelationListItemCompanyDtoToJSON(value?: GroupCompanyRelationListItemCompanyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'status': value['status'],
        'companyAdmins': value['companyAdmins'] == null ? undefined : ((value['companyAdmins'] as Array<any>).map(PersonReferenceDtoToJSON)),
        'phoneNumber': PhoneNumberListItemDtoToJSON(value['phoneNumber']),
        'emailAddress': value['emailAddress'],
    };
}

