import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useTagPermissionClassGroupExtension(): PermissionClassGroupExtension<'TAG'> {
  const { t } = useTranslation('tag');

  return {
    name: 'TAG',
    label: t('tag'),
    getActionLabel: (action) => t(`permission.${action}`),
    getAdditionalRequirement: (action) => t(`permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`permissionInfo.${action}`, { defaultValue: '' }),
  };
}

export function useCompanyTagPermissionClassGroupExtension(): PermissionClassGroupExtension<'COMPANY-TAG'> {
  const { t } = useTranslation('tag');

  return {
    name: 'COMPANY-TAG',
    label: t('tag'),
    getActionLabel: (action) => t(`tagRelation.permission.COMPANY.permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`tagRelation.permission.COMPANY.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`tagRelation.permission.COMPANY.permissionInfo.${action}`, { defaultValue: '' }),
  };
}

export function useEventTagPermissionClassGroupExtension(): PermissionClassGroupExtension<'EVENT-TAG'> {
  const { t } = useTranslation('tag');

  return {
    name: 'EVENT-TAG',
    label: t('tag'),
    getActionLabel: (action) => t(`tagRelation.permission.EVENT.permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`tagRelation.permission.EVENT.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`tagRelation.permission.EVENT.permissionInfo.${action}`, { defaultValue: '' }),
  };
}

export function useFormatTagPermissionClassGroupExtension(): PermissionClassGroupExtension<'FORMAT-TAG'> {
  const { t } = useTranslation('tag');

  return {
    name: 'FORMAT-TAG',
    label: t('tag'),
    getActionLabel: (action) => t(`tagRelation.permission.FORMAT.permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`tagRelation.permission.FORMAT.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`tagRelation.permission.FORMAT.permissionInfo.${action}`, { defaultValue: '' }),
  };
}

export function useLocationTagPermissionClassGroupExtension(): PermissionClassGroupExtension<'LOCATION-TAG'> {
  const { t } = useTranslation('tag');

  return {
    name: 'LOCATION-TAG',
    label: t('tag'),
    getActionLabel: (action) => t(`tagRelation.permission.LOCATION.permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`tagRelation.permission.LOCATION.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`tagRelation.permission.LOCATION.permissionInfo.${action}`, { defaultValue: '' }),
  };
}

export function usePersonTagPermissionClassGroupExtension(): PermissionClassGroupExtension<'PERSON-TAG'> {
  const { t } = useTranslation('tag');

  return {
    name: 'PERSON-TAG',
    label: t('tag'),
    getActionLabel: (action) => t(`tagRelation.permission.PERSON.permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`tagRelation.permission.PERSON.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`tagRelation.permission.PERSON.permissionInfo.${action}`, { defaultValue: '' }),
  };
}

export function useProductTagPermissionClassGroupExtension(): PermissionClassGroupExtension<'PRODUCT-TAG'> {
  const { t } = useTranslation('tag');

  return {
    name: 'PRODUCT-TAG',
    label: t('tag'),
    getActionLabel: (action) => t(`tagRelation.permission.PRODUCT.permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`tagRelation.permission.PRODUCT.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`tagRelation.permission.PRODUCT.permissionInfo.${action}`, { defaultValue: '' }),
  };
}

export function useStandTagPermissionClassGroupExtension(): PermissionClassGroupExtension<'STAND-TAG'> {
  const { t } = useTranslation('tag');

  return {
    name: 'STAND-TAG',
    label: t('tag'),
    getActionLabel: (action) => t(`tagRelation.permission.STAND.permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`tagRelation.permission.STAND.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`tagRelation.permission.STAND.permissionInfo.${action}`, { defaultValue: '' }),
  };
}

export function useFilmTagPermissionClassGroupExtension(): PermissionClassGroupExtension<'FILM-TAG'> {
  const { t } = useTranslation('tag');

  return {
    name: 'FILM-TAG',
    label: t('tag'),
    getActionLabel: (action) => t(`tagRelation.permission.FILM.permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`tagRelation.permission.FILM.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`tagRelation.permission.FILM.permissionInfo.${action}`, { defaultValue: '' }),
  };
}
