/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OccupationDto } from './OccupationDto';
import {
    OccupationDtoFromJSON,
    OccupationDtoFromJSONTyped,
    OccupationDtoToJSON,
} from './OccupationDto';

/**
 * 
 * @export
 * @interface SimplePersonDto
 */
export interface SimplePersonDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SimplePersonDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SimplePersonDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SimplePersonDto
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof SimplePersonDto
     */
    stageName?: string;
    /**
     * The unique key for a person starting with "P".
     * @type {string}
     * @memberof SimplePersonDto
     */
    personKey: string;
    /**
     * List of the occupations
     * @type {Array<OccupationDto>}
     * @memberof SimplePersonDto
     */
    occupations?: Array<OccupationDto>;
}

/**
 * Check if a given object implements the SimplePersonDto interface.
 */
export function instanceOfSimplePersonDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('personKey' in value)) return false;
    return true;
}

export function SimplePersonDtoFromJSON(json: any): SimplePersonDto {
    return SimplePersonDtoFromJSONTyped(json, false);
}

export function SimplePersonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimplePersonDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'surname': json['surname'] == null ? undefined : json['surname'],
        'stageName': json['stageName'] == null ? undefined : json['stageName'],
        'personKey': json['personKey'],
        'occupations': json['occupations'] == null ? undefined : ((json['occupations'] as Array<any>).map(OccupationDtoFromJSON)),
    };
}

export function SimplePersonDtoToJSON(value?: SimplePersonDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'stageName': value['stageName'],
        'personKey': value['personKey'],
        'occupations': value['occupations'] == null ? undefined : ((value['occupations'] as Array<any>).map(OccupationDtoToJSON)),
    };
}

