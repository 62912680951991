import { useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditionDto, EditionTypeDto } from '../../../api';
import editionApi from '../../../data-access/edition-api';
import InputFormControl from '../../../ui/form/input-form-control';
import useAsyncValidation from '../../../util/use-async-validation/use-async-validation';

export default function EditionNameControl() {
  const { t } = useTranslation(['edition', 'common']);
  const editionType = useWatch<EditionDto, 'editionType'>({ name: 'editionType' });
  const editionId = useWatch<EditionDto, 'id'>({ name: 'id' });
  const { watch, setValue, resetField } = useFormContext<EditionDto>();
  const prevNameRef = useRef('');

  const validateUniqueName = useAsyncValidation(async (name: string, { signal }) => {
    if (editionType === EditionTypeDto.REGULAR) {
      return true;
    }

    name = name.trim();

    if (name.length === 0) {
      return t('common:validation_error.required', { field: t('edition:name') });
    }

    if (name.length > 40) {
      return t('common:validation_error.max_length', { field: t('edition:name'), count: 40 });
    }

    if (!/^(?!\d+\.\s*Berlinale\s*$).*$/i.test(name)) {
      return t('edition:editor.validation_error.name.pattern');
    }

    const page = await editionApi.searchEditions({ filter: [`name,in,${name.trim()}`] }, { signal });

    return !page.content.some((a) => a.id !== editionId) || t('editor.validation_error.name.unique');
  });

  useEffect(() => {
    const subscription = watch((edition, { type, name }) => {
      if (name === 'editionType' && type === 'change') {
        if (edition.editionType === EditionTypeDto.OUT_OF_BAND) {
          validateUniqueName.reset();
          setValue('name', prevNameRef.current);
        } else {
          validateUniqueName.reset();
          prevNameRef.current = edition.name ?? '';
          resetField('name');
        }
      }
    });

    return subscription.unsubscribe;
  }, [watch, setValue, resetField, validateUniqueName]);

  return (
    <InputFormControl
      name="name"
      label={t('name')}
      isDisabled={editionType === EditionTypeDto.REGULAR}
      isRequired={editionType === EditionTypeDto.OUT_OF_BAND}
      maxLength={editionType === EditionTypeDto.OUT_OF_BAND ? 40 : undefined}
      validate={validateUniqueName}
      onChange={validateUniqueName.reset}
    />
  );
}
