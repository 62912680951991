/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { ModeOfTransportDto } from './ModeOfTransportDto';
import {
    ModeOfTransportDtoFromJSON,
    ModeOfTransportDtoFromJSONTyped,
    ModeOfTransportDtoToJSON,
} from './ModeOfTransportDto';

/**
 * 
 * @export
 * @interface ParticipationTripsInnerDto
 */
export interface ParticipationTripsInnerDto {
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof ParticipationTripsInnerDto
     */
    duration: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {ModeOfTransportDto}
     * @memberof ParticipationTripsInnerDto
     */
    arrivalModeOfTransport?: ModeOfTransportDto;
    /**
     * 
     * @type {string}
     * @memberof ParticipationTripsInnerDto
     */
    transportNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationTripsInnerDto
     */
    startingPoint?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationTripsInnerDto
     */
    destination?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationTripsInnerDto
     */
    comment?: string;
    /**
     * 
     * @type {ModeOfTransportDto}
     * @memberof ParticipationTripsInnerDto
     */
    departureModeOfTransport?: ModeOfTransportDto;
    /**
     * 
     * @type {Date}
     * @memberof ParticipationTripsInnerDto
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the ParticipationTripsInnerDto interface.
 */
export function instanceOfParticipationTripsInnerDto(value: object): boolean {
    if (!('duration' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function ParticipationTripsInnerDtoFromJSON(json: any): ParticipationTripsInnerDto {
    return ParticipationTripsInnerDtoFromJSONTyped(json, false);
}

export function ParticipationTripsInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationTripsInnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'duration': ClosedDateTimeRangeDtoFromJSON(json['duration']),
        'arrivalModeOfTransport': json['arrivalModeOfTransport'] == null ? undefined : ModeOfTransportDtoFromJSON(json['arrivalModeOfTransport']),
        'transportNumber': json['transportNumber'] == null ? undefined : json['transportNumber'],
        'startingPoint': json['startingPoint'] == null ? undefined : json['startingPoint'],
        'destination': json['destination'] == null ? undefined : json['destination'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'departureModeOfTransport': json['departureModeOfTransport'] == null ? undefined : ModeOfTransportDtoFromJSON(json['departureModeOfTransport']),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function ParticipationTripsInnerDtoToJSON(value?: ParticipationTripsInnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'duration': ClosedDateTimeRangeDtoToJSON(value['duration']),
        'arrivalModeOfTransport': ModeOfTransportDtoToJSON(value['arrivalModeOfTransport']),
        'transportNumber': value['transportNumber'],
        'startingPoint': value['startingPoint'],
        'destination': value['destination'],
        'comment': value['comment'],
        'departureModeOfTransport': ModeOfTransportDtoToJSON(value['departureModeOfTransport']),
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

