import { chakra } from '@chakra-ui/react';
import { faH2 } from '@fortawesome/pro-regular-svg-icons';
import React, { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react';
import { Render } from '../render';
import { BlockFormatOption, WithFunction } from '../slate-types';
import { useRichTextStyles } from '../styles-context';
import { BlockToolbarButton } from '../toolbar/toolbar-button';
import { applyAlignment } from './with-alignment';

const HEADING_2: BlockFormatOption = 'heading2';

const withHeading2: WithFunction = (editor) => {
  const { markOrInlineBlockAllowed } = editor;

  const heading2: Render<'block'> = {
    type: HEADING_2,
    render: ({ children, attributes, element }) => (
      <Heading2 {...attributes} {...applyAlignment(element)}>
        {children}
      </Heading2>
    ),
  };
  editor.renderers = [...editor.renderers, heading2];

  editor.toolbarButtons.block = [...editor.toolbarButtons.block, <BlockToolbarButton format={HEADING_2} icon={faH2} />];

  editor.markOrInlineBlockAllowed = (element) => element.type !== HEADING_2 && markOrInlineBlockAllowed(element);

  return editor;
};

export default withHeading2;

const Heading2 = forwardRef(
  ({ children, ...props }: ComponentPropsWithoutRef<typeof chakra.h2>, ref: ForwardedRef<HTMLHeadingElement>) => {
    const styles = useRichTextStyles();

    return (
      <chakra.h2 __css={styles.heading2} {...props} ref={ref}>
        {children}
      </chakra.h2>
    );
  },
);
