import { Input, Stack, Text, useId } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEBOUNCE_TIME } from '../../../util/constants';
import useDebouncedState from '../../../util/debounce/use-debounced-state';
import MultiValueAsyncSelect from '../../select/multi-value-async-select';
import { useDataTableFilter } from '../data-table-context';
import { joinAndEscapeFilterString, splitAndUnescapeFilterString } from './filter-utils';
import RecordSelect from './record-select';

/**
 * String filter operations.
 */
const OperatorOptions = {
  CONTAIN: 'contain',
  NOT_CONTAIN: '!contain',
  START_WITH: 'start',
  NOT_START_WITH: '!start',
  END_WITH: 'end',
  NOT_END_WITH: '!end',
  NOT_EQUAL: '!eq',
} as const;

const OperatorOptionsIncludingIn = {
  ...OperatorOptions,
  IN: 'in',
} as const;

type Operator = keyof typeof OperatorOptionsIncludingIn;

export interface StringFilterProps {
  label: React.ReactNode;
  loadOptions?(value: string): Promise<string[]>;
  availableOperators?: Operator[];
}

/**
 * String filter, may be used for all string columns in data tables. The 'in' operator is only displayed when loadOptions are set.
 */
export default function StringFilter({ loadOptions, label, availableOperators }: StringFilterProps) {
  const { t } = useTranslation('common');
  const { property, getFilter, setFilter, removeFilters, initialFocusRef } = useDataTableFilter();
  const id = useId(undefined, 'string-filter');

  const { operator, value: filterValue } = getFilter(property) ?? {};

  const [operatorOption, setOperatorOption] = React.useState(operator ?? OperatorOptions.CONTAIN);

  const [value, setValue] = useDebouncedState(
    filterValue ?? '',
    (value) => {
      if (value === '') {
        removeFilters(property);
      } else {
        setFilter({ operator: operatorOption, value, property });
      }
    },
    DEBOUNCE_TIME,
  );

  React.useEffect(() => {
    setOperatorOption((operatorOption) => operator ?? operatorOption);
  }, [operator]);

  const handleOperatorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const operatorOption = event.target.value;

    setOperatorOption(operatorOption);

    if (operatorOption === OperatorOptionsIncludingIn.IN || operator === OperatorOptionsIncludingIn.IN) {
      removeFilters(property);
    } else if (value !== '') {
      setFilter({ operator: operatorOption, value, property });
    }
  };

  const handleSelectChange = (values: string[] | null) => {
    if (values == null || values.length === 0) {
      removeFilters(property);
    } else {
      setFilter({ operator: operatorOption, value: joinAndEscapeFilterString(values), property });
    }
  };

  const customOperators = availableOperators?.reduce<Partial<typeof OperatorOptions>>(
    (options, o) => ({ ...options, [o]: OperatorOptionsIncludingIn[o] }),
    {},
  );
  const operatorOptions = customOperators ?? (loadOptions ? OperatorOptionsIncludingIn : OperatorOptions);

  return (
    <Stack as="fieldset" spacing={2} aria-labelledby={`${id}-label`}>
      <Text as="div" fontSize="sm" fontWeight="medium" id={`${id}-label`}>
        {label}
      </Text>
      <RecordSelect<typeof operatorOptions>
        size="sm"
        value={operatorOption}
        onChange={handleOperatorChange}
        options={operatorOptions}
        aria-label={t(`data_table.string_filter.operator_label`)}
      >
        {(key) => t(`data_table.string_filter.operator_option.${key}`)}
      </RecordSelect>
      {operatorOption === OperatorOptionsIncludingIn.IN && loadOptions ? (
        <StringFilterSelect
          ref={initialFocusRef}
          values={value === '' ? [] : splitAndUnescapeFilterString(value)}
          loadOptions={loadOptions}
          onChange={handleSelectChange}
        />
      ) : (
        <Input
          ref={initialFocusRef}
          size="sm"
          aria-label={t('data_table.string_filter.filter_label')}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          placeholder={t('select.placeholder')}
          maxLength={50}
        />
      )}
    </Stack>
  );
}

export interface StringFilterSelectProps {
  values: string[];
  loadOptions(value: string, pageSizeLimit: number): Promise<string[]>;
  onChange(values: string[] | null): void;
}

export const StringFilterSelect = React.forwardRef(
  ({ values, loadOptions, onChange }: StringFilterSelectProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { t } = useTranslation('common');

    return (
      <MultiValueAsyncSelect<string>
        ref={ref}
        size="sm"
        values={values}
        aria-label={t('data_table.string_filter.filter_label')}
        loadOptions={loadOptions}
        onChange={onChange}
        renderLabel={(value) => value}
        optionIdentifier={(value) => value}
        defaultOptions
        isClearable
      />
    );
  },
);
