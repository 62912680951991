import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useEditionPermissionClassGroupExtension(): PermissionClassGroupExtension<'EDITION'> {
  const { t } = useTranslation(['edition']);

  return {
    name: 'EDITION',
    label: t('edition:permissionLabel'),
    getActionLabel: (action) => t(`edition:permission.${action}`),
    getAdditionalRequirement: (action) => t(`edition:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`edition:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
