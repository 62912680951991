/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LandingPageModuleSettingsDto } from './LandingPageModuleSettingsDto';
import {
    LandingPageModuleSettingsDtoFromJSON,
    LandingPageModuleSettingsDtoFromJSONTyped,
    LandingPageModuleSettingsDtoToJSON,
} from './LandingPageModuleSettingsDto';
import type { LandingPageTemplateStatusDto } from './LandingPageTemplateStatusDto';
import {
    LandingPageTemplateStatusDtoFromJSON,
    LandingPageTemplateStatusDtoFromJSONTyped,
    LandingPageTemplateStatusDtoToJSON,
} from './LandingPageTemplateStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface LandingPageTemplateDto
 */
export interface LandingPageTemplateDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LandingPageTemplateDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof LandingPageTemplateDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof LandingPageTemplateDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageTemplateDto
     */
    type: string;
    /**
     * 
     * @type {Array<LandingPageModuleSettingsDto>}
     * @memberof LandingPageTemplateDto
     */
    moduleSettings: Array<LandingPageModuleSettingsDto>;
    /**
     * 
     * @type {LandingPageTemplateStatusDto}
     * @memberof LandingPageTemplateDto
     */
    status: LandingPageTemplateStatusDto;
}

/**
 * Check if a given object implements the LandingPageTemplateDto interface.
 */
export function instanceOfLandingPageTemplateDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('type' in value)) return false;
    if (!('moduleSettings' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function LandingPageTemplateDtoFromJSON(json: any): LandingPageTemplateDto {
    return LandingPageTemplateDtoFromJSONTyped(json, false);
}

export function LandingPageTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'type': json['type'],
        'moduleSettings': ((json['moduleSettings'] as Array<any>).map(LandingPageModuleSettingsDtoFromJSON)),
        'status': LandingPageTemplateStatusDtoFromJSON(json['status']),
    };
}

export function LandingPageTemplateDtoToJSON(value?: LandingPageTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'type': value['type'],
        'moduleSettings': ((value['moduleSettings'] as Array<any>).map(LandingPageModuleSettingsDtoToJSON)),
        'status': LandingPageTemplateStatusDtoToJSON(value['status']),
    };
}

