import { FormControl, FormLabel } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditionDto, EditionTypeDto } from '../../../api';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import now from '../../../util/now';

const editionTypes = [EditionTypeDto.REGULAR, EditionTypeDto.OUT_OF_BAND];

export default function EditionTypeControl({
  initialFocusRef,
}: {
  initialFocusRef: React.RefObject<HTMLSelectElement>;
}) {
  const { t } = useTranslation('edition');
  const { watch } = useFormContext<EditionDto>();
  const start = watch('dateRange.start');

  return (
    <FormControl
      aria-label={t('edition_type')}
      isRequired
      label={t('edition_type')}
      isDisabled={start != null && start.valueOf() < now()}
    >
      <FormLabel>{t('edition_type')}</FormLabel>
      <ValueSelectControl
        defaultValue={EditionTypeDto.REGULAR}
        ref={initialFocusRef}
        isRequired
        label={t('edition_type')}
        options={editionTypes}
        renderLabel={(option) => t(`editionTypeOptions.${option}`)}
        name="editionType"
      />
    </FormControl>
  );
}
