/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PhoneNumberListItemDto } from './PhoneNumberListItemDto';
import {
    PhoneNumberListItemDtoFromJSON,
    PhoneNumberListItemDtoFromJSONTyped,
    PhoneNumberListItemDtoToJSON,
} from './PhoneNumberListItemDto';
import type { TeamStatusDto } from './TeamStatusDto';
import {
    TeamStatusDtoFromJSON,
    TeamStatusDtoFromJSONTyped,
    TeamStatusDtoToJSON,
} from './TeamStatusDto';

/**
 * Organizational associations of employees.
 * @export
 * @interface TeamListItemDto
 */
export interface TeamListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TeamListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TeamListItemDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TeamListItemDto
     */
    englishName: string;
    /**
     * An email address
     * @type {string}
     * @memberof TeamListItemDto
     */
    emailAddress?: string;
    /**
     * List of phone numbers
     * @type {Array<PhoneNumberListItemDto>}
     * @memberof TeamListItemDto
     */
    phoneNumbers: Array<PhoneNumberListItemDto>;
    /**
     * 
     * @type {TeamStatusDto}
     * @memberof TeamListItemDto
     */
    status: TeamStatusDto;
}

/**
 * Check if a given object implements the TeamListItemDto interface.
 */
export function instanceOfTeamListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('englishName' in value)) return false;
    if (!('phoneNumbers' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function TeamListItemDtoFromJSON(json: any): TeamListItemDto {
    return TeamListItemDtoFromJSONTyped(json, false);
}

export function TeamListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'englishName': json['englishName'],
        'emailAddress': json['emailAddress'] == null ? undefined : json['emailAddress'],
        'phoneNumbers': ((json['phoneNumbers'] as Array<any>).map(PhoneNumberListItemDtoFromJSON)),
        'status': TeamStatusDtoFromJSON(json['status']),
    };
}

export function TeamListItemDtoToJSON(value?: TeamListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'englishName': value['englishName'],
        'emailAddress': value['emailAddress'],
        'phoneNumbers': ((value['phoneNumbers'] as Array<any>).map(PhoneNumberListItemDtoToJSON)),
        'status': TeamStatusDtoToJSON(value['status']),
    };
}

