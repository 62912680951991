import { Grid, GridItem, Link, Wrap, WrapItem } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { StaffStatusDto, TeamDto, TeamStaffRelationDto, TeamStatusDto } from '../../../../api';
import sectionApi from '../../../../data-access/section-api';
import teamStaffApi from '../../../../data-access/team-staff-api';
import DataTable, {
  DataTableColumn,
  DataTableState,
  DataTableTruncatedText,
  useDataTableState,
} from '../../../../ui/data-table';
import EnumFilter from '../../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../../ui/data-table/use-request-params';
import Optional from '../../../../ui/optional/optional';
import linkPhoneNumber from '../../../../ui/phone-number/link-phone-number';
import renderPhoneNumber from '../../../../ui/phone-number/render-phone-number';
import fallbackMiddleware from '../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../util/swr/use-fetcher';
import Translate from '../../../../util/translate/translate';
import HasPermission from '../../../permission/has-permission';
import usePermission from '../../../permission/use-permission';
import SectionReference from '../../../section/section-reference/section-reference';
import DeleteRelationsButton from '../../common/delete-relations-button';
import { fetchTeamStaffRelations, teamStaffFetcher } from '../team-staff-queries';

function useTeamStaffRelations(state: DataTableState, teamId: string) {
  const requestParams = useRequestParams(
    state,
    [
      { property: 'staff.surname', direction: 'ASC' },
      { property: 'staff.firstName', direction: 'ASC' },
    ],
    [{ property: 'team.id', operator: 'eq', value: teamId }],
  );

  return useFetcher(fetchTeamStaffRelations, requestParams, { use: [fallbackMiddleware] });
}

export default function TeamStaffRelationDataTable({ team }: { team: TeamDto }) {
  const [state, setState] = useDataTableState({
    filter: [
      {
        property: 'staff.status',
        operator: 'in',
        value: 'ACTIVE',
      },
    ],
  });
  const { t } = useTranslation(['common', 'staff', 'relations']);
  const page = useTeamStaffRelations(state, team.id);
  const { hasPermission } = usePermission();
  const canLinkStaff = hasPermission('TEAM:CAN_LINK_STAFF_WITH_TEAM');

  const columns: DataTableColumn<TeamStaffRelationDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<TeamStaffRelationDto>[] = [
      {
        key: 'staff.surname',
        name: t('staff:surname'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        sticky: true,
        renderCell: (teamStaff) => (
          <HasPermission
            necessaryPermission={({ hasPermission, hasPermissionFromSection }) => {
              return (
                teamStaff.staff.belongsToSections.some((section) =>
                  hasPermissionFromSection(section.id, 'STAFF:CAN_SEE_DETAILS_OWN'),
                ) || hasPermission('STAFF:CAN_SEE_DETAILS')
              );
            }}
            fallback={teamStaff.staff.surname}
          >
            <DataTableTruncatedText>
              <Link as={RouterLink} to={`../../../staff/${teamStaff.staff.id!}`}>
                {teamStaff.staff.surname}
              </Link>
            </DataTableTruncatedText>
          </HasPermission>
        ),
        filter: (
          <StringFilter
            label={t('staff:surname')}
            loadOptions={async (value: string) => {
              const page = await teamStaffApi.searchTeamStaffRelations({
                pageable: { size: 10 },
                filter: [`team.id,eq,${team.id}`, `staff.surname,contain,${value}`],
              });

              return page.content.map((teamStaff) => teamStaff.staff.surname);
            }}
          />
        ),
      },
      {
        key: 'staff.firstName',
        name: t('staff:firstName'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        renderCell: (teamStaff) => (
          <HasPermission
            necessaryPermission={({ hasPermission, hasPermissionFromSection }) => {
              return (
                teamStaff.staff.belongsToSections.some((section) =>
                  hasPermissionFromSection(section.id, 'STAFF:CAN_SEE_DETAILS_OWN'),
                ) || hasPermission('STAFF:CAN_SEE_DETAILS')
              );
            }}
            fallback={teamStaff.staff.firstName}
          >
            <DataTableTruncatedText>
              <Link as={RouterLink} to={`../../../staff/${teamStaff.staff.id!}`}>
                {teamStaff.staff.firstName}
              </Link>
            </DataTableTruncatedText>
          </HasPermission>
        ),
        filter: (
          <StringFilter
            label={t('staff:firstName')}
            loadOptions={async (value: string) => {
              const page = await teamStaffApi.searchTeamStaffRelations({
                pageable: { size: 10 },
                filter: [`team.id,eq,${team.id}`, `staff.firstName,contain,${value}`],
              });

              return page.content.map((teamStaff) => teamStaff.staff.firstName);
            }}
          />
        ),
      },
      {
        key: 'staff.belongsToSections.section.name',
        name: <Translate ns="staff" i18nKey="sections" />,
        cellProps: {},
        renderCell: (relation) => (
          <Wrap spacingX={4} spacingY={2}>
            {relation.staff.belongsToSections
              .sort((a, b) => a.name!.localeCompare(b.name!))
              .map((section) => (
                <WrapItem key={section.id}>
                  <SectionReference sectionReference={section} size="md" />
                </WrapItem>
              ))}
          </Wrap>
        ),
        filter: (
          <StringFilter
            label={t('staff:sections')}
            availableOperators={['CONTAIN', 'START_WITH', 'END_WITH', 'IN']}
            loadOptions={async (value: string) => {
              const page = await sectionApi.searchSections({
                pageable: { size: 10 },
                filter: [`name,contain,${value}`],
              });

              return page.content.map((section) => section.name);
            }}
          />
        ),
      },
      {
        key: 'staff.phoneNumber',
        name: t('staff:phone_number.header'),
        cellProps: {},
        renderCell: (relation) => (
          <Optional isEmpty={isEmpty(relation.staff.phoneNumbers)}>
            <Wrap>
              {relation.staff.phoneNumbers?.map((number, index) => (
                <WrapItem key={index}>
                  <Link href={linkPhoneNumber(number)} isExternal>
                    {renderPhoneNumber(number)}
                  </Link>
                </WrapItem>
              ))}
            </Wrap>
          </Optional>
        ),
      },
      {
        key: 'staff.emailAddress',
        name: t('staff:email'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        renderCell: (relation) => <DataTableTruncatedText>{relation.staff.emailAddress?.email}</DataTableTruncatedText>,
        filter: <StringFilter label={t('staff:email')} />,
      },
      {
        key: 'staff.site',
        name: t('staff:site'),
        cellProps: {},
        isSortable: true,
        renderCell: (relation) => <Optional children={relation.staff.site} />,
        filter: <StringFilter label={t('staff:site')} />,
      },
      {
        key: 'staff.room',
        name: t('staff:room'),
        isSortable: true,
        cellProps: {},
        renderCell: (relation) => <Optional children={relation.staff.room} />,
        filter: <StringFilter label={t('staff:room')} />,
      },
      {
        key: 'staff.status',
        name: t('staff:status'),
        cellProps: {},
        isSortable: true,
        renderCell: (relation) => t(`staff:statusOptions.${relation.staff.status}`),
        filter: (
          <EnumFilter
            label={t('staff:status')}
            options={[StaffStatusDto.ACTIVE, StaffStatusDto.INACTIVE]}
            renderOptionLabel={(key) => t(`staff:statusOptions.${key}`)}
          />
        ),
      },
    ];

    return columns;
  }, [t, team.id]);

  const rowKey = React.useCallback((teamStaff: TeamStaffRelationDto) => {
    invariant(teamStaff.id != null, 'Missing team staff relation id');

    return teamStaff.id;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" gridRowGap={4} minH={0}>
      <DataTable
        page={page == null ? { content: [] } : page}
        state={state}
        columns={columns}
        rowKey={rowKey}
        selection={canLinkStaff ? { keySelector: rowKey } : undefined}
        onStateChange={setState}
        isPageable
        rowStyle={(data) => {
          return data.staff.status === StaffStatusDto.INACTIVE
            ? {
                transition: 'opacity var(--chakra-transition-duration-normal)',
                opacity: 0.7,
                _hover: {
                  opacity: 1,
                },
              }
            : {};
        }}
      />
      <GridItem display="flex" flexDir="row" justifyContent="end">
        {canLinkStaff && (
          <DeleteRelationsButton
            selection={state.selection}
            isDisabled={team.status === TeamStatusDto.ARCHIVED || isEmpty(state.selection)}
            deleteRelations={(ids) => teamStaffApi.deleteByIds({ ids: ids })}
            mutate={() => teamStaffFetcher.mutate()}
            resetSelection={() => {
              setState({ ...state, selection: [] });
            }}
          />
        )}
      </GridItem>
    </Grid>
  );
}
