import { ReactNode, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import invariant from 'tiny-invariant';
import HistoryDisplaySettings from '../../ui/history/history-display-settings';
import usePlugins, { PluginToken } from '../../util/plugin/use-plugins';

type LandingPageTemplateModuleDisplaySettings<T extends FieldValues> = Partial<
  Pick<HistoryDisplaySettings<T>, 'attributeLabels' | 'valueFormatter' | 'diffEntireWord'>
>;

export default interface LandingPageTemplateModuleConfig<TSettings extends FieldValues> {
  type: string;
  label: string;
  renderControl(props: { name: `moduleSettings.${number}` }): ReactNode;
  renderViewer({ module }: { module: TSettings }): ReactNode;
  historyDisplaySettings: LandingPageTemplateModuleDisplaySettings<TSettings>;
}

export interface LandingPageTemplateModuleConfigHook<TSettings extends FieldValues> {
  (): LandingPageTemplateModuleConfig<TSettings>;
}

export const LANDING_PAGE_TEMPLATE_MODULE_HOOK = new PluginToken<LandingPageTemplateModuleConfigHook<any>>(
  'LandingPageModuleHook',
);

export function useLandingPageTemplateModuleConfigs() {
  const moduleConfigHooks = usePlugins(LANDING_PAGE_TEMPLATE_MODULE_HOOK);
  // Hooks should never be called conditionally. So this is actually a no-go. But: the list of
  // plugins returned by usePlugins is static. Therefor the order in which the hooks are called is
  // always the same. Just not wrap it in useMemo and everything should work out fine.
  const moduleConfigs = moduleConfigHooks.map((hook) => hook());

  return useMemo(
    () => ({
      moduleConfigs,
      getModuleConfig: (type: string) => {
        const extension = moduleConfigs.find((extension) => type === extension.type);
        invariant(extension != null, `no type extension found for landing page module type ${type}`);
        return extension;
      },
    }),
    [moduleConfigs],
  );
}
