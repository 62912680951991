import React from 'react';
import { useTranslation } from 'react-i18next';
import FinalDeleteConfirmDialog, {
  createDefaultDeleteChecklistItem,
} from '../../../ui/dialog/final-delete-confirm-dialog';

export interface GroupDeleteDialogProps {
  groupName: string;
  isOpen: boolean;
  onClose(confirm: boolean): void;
}

export default function GroupDeleteDialog({ onClose, groupName, ...props }: GroupDeleteDialogProps) {
  const { t } = useTranslation('group');

  const confirmationMessageChecklistItems = [
    createDefaultDeleteChecklistItem({
      objectTypeWithArticleGenitive: t('group_with_article_genetive'),
      objectToDelete: groupName,
    }),
  ];

  return (
    <FinalDeleteConfirmDialog
      {...props}
      objectTypeToDelete={t('group')}
      confirmationCheckListItems={confirmationMessageChecklistItems}
      onClose={onClose}
    />
  );
}
