/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilmTagPermissionDto
 */
export interface FilmTagPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof FilmTagPermissionDto
     */
    name: FilmTagPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof FilmTagPermissionDto
     */
    action: FilmTagPermissionDtoActionEnum;
}


/**
 * @export
 */
export const FilmTagPermissionDtoNameEnum = {
    FILM_TAG: 'FILM-TAG'
} as const;
export type FilmTagPermissionDtoNameEnum = typeof FilmTagPermissionDtoNameEnum[keyof typeof FilmTagPermissionDtoNameEnum];

/**
 * @export
 */
export const FilmTagPermissionDtoActionEnum = {
    LINK: 'CAN_LINK',
    UNLINK: 'CAN_UNLINK'
} as const;
export type FilmTagPermissionDtoActionEnum = typeof FilmTagPermissionDtoActionEnum[keyof typeof FilmTagPermissionDtoActionEnum];


/**
 * Check if a given object implements the FilmTagPermissionDto interface.
 */
export function instanceOfFilmTagPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function FilmTagPermissionDtoFromJSON(json: any): FilmTagPermissionDto {
    return FilmTagPermissionDtoFromJSONTyped(json, false);
}

export function FilmTagPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilmTagPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function FilmTagPermissionDtoToJSON(value?: FilmTagPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

