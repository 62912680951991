/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SocialMediaLinksDto
 */
export interface SocialMediaLinksDto {
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof SocialMediaLinksDto
     */
    facebookLink?: string;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof SocialMediaLinksDto
     */
    instagramLink?: string;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof SocialMediaLinksDto
     */
    twitterLink?: string;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof SocialMediaLinksDto
     */
    youtubeLink?: string;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof SocialMediaLinksDto
     */
    linkedInLink?: string;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof SocialMediaLinksDto
     */
    tikTokLink?: string;
}

/**
 * Check if a given object implements the SocialMediaLinksDto interface.
 */
export function instanceOfSocialMediaLinksDto(value: object): boolean {
    return true;
}

export function SocialMediaLinksDtoFromJSON(json: any): SocialMediaLinksDto {
    return SocialMediaLinksDtoFromJSONTyped(json, false);
}

export function SocialMediaLinksDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialMediaLinksDto {
    if (json == null) {
        return json;
    }
    return {
        
        'facebookLink': json['facebookLink'] == null ? undefined : json['facebookLink'],
        'instagramLink': json['instagramLink'] == null ? undefined : json['instagramLink'],
        'twitterLink': json['twitterLink'] == null ? undefined : json['twitterLink'],
        'youtubeLink': json['youtubeLink'] == null ? undefined : json['youtubeLink'],
        'linkedInLink': json['linkedInLink'] == null ? undefined : json['linkedInLink'],
        'tikTokLink': json['tikTokLink'] == null ? undefined : json['tikTokLink'],
    };
}

export function SocialMediaLinksDtoToJSON(value?: SocialMediaLinksDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'facebookLink': value['facebookLink'],
        'instagramLink': value['instagramLink'],
        'twitterLink': value['twitterLink'],
        'youtubeLink': value['youtubeLink'],
        'linkedInLink': value['linkedInLink'],
        'tikTokLink': value['tikTokLink'],
    };
}

