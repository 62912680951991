import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useFilmPermissionClassGroupExtension(): PermissionClassGroupExtension<'FILM'> {
  const { t } = useTranslation(['film']);

  return {
    name: 'FILM',
    label: t('film:film'),
    getActionLabel: (action) => t(`film:permission.${action}`),
    getAdditionalRequirement: (action) => t(`film:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`film:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
