import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import registry from '../../registry';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import GroupBreadcrumb from './common/group-breadcrumb';
import externalGroupApplcationRoutes from './external-applications/external-application-routes';
import GROUP_CHILD_ROUTES from './group-child-routes';

const groupRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'groups',
    handle: {
      breadcrumb: <Translate ns="group" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./group-lister/group-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./group-editor/new-group-editor-route'),
      },
      {
        path: ':groupId',
        handle: {
          breadcrumb: <GroupBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./group-page/group-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./group-viewer/group-viewer-route'),
              },
              {
                path: 'personRelations',
                lazy: () => import('./group-relation/group-person-relation-viewer-route'),
              },
              {
                path: 'companyRelations',
                lazy: () => import('./group-relation/group-company-relation-viewer-route'),
              },
              {
                path: 'staffRelations',
                lazy: () => import('./group-relation/group-staff-relation-viewer-route'),
              },
              {
                path: 'external-applications',
                lazy: () =>
                  import(
                    './external-applications/group-external-application-lister/group-external-application-lister-route'
                  ),
              },
              {
                path: 'filmRelations',
                lazy: () => import('./group-film-relation/group-film-relation-page-content'),
              },
              {
                path: 'history',
                lazy: () => import('./group-history/group-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./group-editor/edit-group-editor-route'),
          },
          externalGroupApplcationRoutes,
          ...registry.getAll(GROUP_CHILD_ROUTES).flat(),
        ],
      },
    ],
  },
];

export default groupRoutes;
