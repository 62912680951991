/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  ConfirmationBodyDto,
  FailureDto,
  PageableDto,
  UnconfirmedCompaniesPageDto,
  UnconfirmedCompanyDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    ConfirmationBodyDtoFromJSON,
    ConfirmationBodyDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    UnconfirmedCompaniesPageDtoFromJSON,
    UnconfirmedCompaniesPageDtoToJSON,
    UnconfirmedCompanyDtoFromJSON,
    UnconfirmedCompanyDtoToJSON,
} from '../models/index';

export interface CompanyIsReferencedRequest {
    id: string;
}

export interface ConfirmCompanyRequest {
    id: string;
    confirmationBodyDto: ConfirmationBodyDto;
}

export interface FetchUnconfirmedCompanyRequest {
    id: string;
}

export interface SearchUnconfirmedCompaniesRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

/**
 * 
 */
export class UnconfirmedCompanyApi extends runtime.BaseAPI {

    /**
     */
    async companyIsReferencedRaw(requestParameters: CompanyIsReferencedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling companyIsReferenced().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/unconfirmed-companies/{id}/is-referenced`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async companyIsReferenced(requestParameters: CompanyIsReferencedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.companyIsReferencedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async confirmCompanyRaw(requestParameters: ConfirmCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling confirmCompany().'
            );
        }

        if (requestParameters['confirmationBodyDto'] == null) {
            throw new runtime.RequiredError(
                'confirmationBodyDto',
                'Required parameter "confirmationBodyDto" was null or undefined when calling confirmCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/unconfirmed-companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmationBodyDtoToJSON(requestParameters['confirmationBodyDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async confirmCompany(requestParameters: ConfirmCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.confirmCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fetchUnconfirmedCompanyRaw(requestParameters: FetchUnconfirmedCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnconfirmedCompanyDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchUnconfirmedCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/unconfirmed-companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnconfirmedCompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchUnconfirmedCompany(requestParameters: FetchUnconfirmedCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnconfirmedCompanyDto> {
        const response = await this.fetchUnconfirmedCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchUnconfirmedCompaniesRaw(requestParameters: SearchUnconfirmedCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnconfirmedCompaniesPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/unconfirmed-companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnconfirmedCompaniesPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async searchUnconfirmedCompanies(requestParameters: SearchUnconfirmedCompaniesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnconfirmedCompaniesPageDto> {
        const response = await this.searchUnconfirmedCompaniesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
