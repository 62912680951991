import reportingApi from '../../data-access/reporting-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const reportingFetcher = createFetcherScope(reportingApi);

export const fetchCollectionModel = reportingFetcher.createFetcher(reportingApi.getCollections);
export const fetchEdgeModel = reportingFetcher.createFetcher(reportingApi.getEdges);
export const fetchReports = reportingFetcher.createFetcher(reportingApi.searchReportListItems);
export const fetchReport = reportingFetcher.createFetcher(reportingApi.getReport);
export const queryReport = reportingFetcher.createFetcher(reportingApi.querySavedReport);
