import { HStack, StackItem } from '@chakra-ui/react';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { StandCompanyRelationDto, StandCompanyRelationStatusDto } from '../../../api';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import { standCompanyRelationStatusOptions } from '../stand-enum-constants';

export interface StandCompanyRelationStatusSelectControlProps {
  initialFocusRef?: RefObject<HTMLInputElement | HTMLElement>;
}

export function StandCompanyRelationStatusSelectControl({
  initialFocusRef,
}: StandCompanyRelationStatusSelectControlProps) {
  const { t } = useTranslation('stand');

  return (
    <ValueSelectFormControl<StandCompanyRelationStatusDto, StandCompanyRelationDto>
      label={t('companyRelation.relationStatus')}
      options={standCompanyRelationStatusOptions}
      renderLabel={(value) => <StandCompanyRelationStatusRenderLabel relationStatus={value} />}
      getStringValue={(value) => t(`companyRelation.relationStatusOptions.${value}`)}
      name="relationStatus"
      ref={initialFocusRef}
      isRequired
    />
  );
}

function StandCompanyRelationStatusRenderLabel({ relationStatus }: { relationStatus: StandCompanyRelationStatusDto }) {
  const { t } = useTranslation('stand');
  return (
    <HStack spacing={2} width="full" overflow="hidden">
      <StackItem noOfLines={1} flexShrink={1}>
        {t(`companyRelation.relationStatusOptions.${relationStatus}`)}
      </StackItem>
    </HStack>
  );
}
