import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CodeDtoPaymentModifierEnum, CodeEditorContentDto } from '../../../api';
import Form from '../../../ui/form/form';
import SubmitButton from '../../../ui/form/submit-button';
import AutomaticConnectionControl from '../code-form/automatic-connection-control';
import DiscountControl from '../code-form/discount-control';
import PaymentModifierControl from '../code-form/payment-modifier-control';
import ProductSelectionControl from '../code-form/product-selection-control';
import ValidityPeriodControl from '../code-form/validity-period-control';

interface CodeConfigMailingModuleControlDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (dto: CodeEditorContentDto) => void;
  code?: DefaultValues<CodeEditorContentDto>;
}

export default function CodeConfigMailingModuleControlDialog({
  isOpen,
  onClose,
  onChange,
  code,
}: CodeConfigMailingModuleControlDialogProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <Suspense>
          <CodeFormModal onClose={onClose} onChange={onChange} code={code} />
        </Suspense>
      </ModalContent>
    </Modal>
  );
}

interface CodeFormModalProps {
  onClose: () => void;
  onChange: (dto: CodeEditorContentDto) => void;
  code?: DefaultValues<CodeEditorContentDto>;
}

function CodeFormModal({ onClose, onChange, code }: CodeFormModalProps) {
  const { t } = useTranslation(['common', 'code']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const form = useForm<CodeEditorContentDto>({
    mode: 'all',
    defaultValues: code,
  });
  const handleValid = async (codeEditorContentDto: CodeEditorContentDto) => {
    onChange(codeEditorContentDto);
    onClose();
  };

  const formIsDirty = Object.keys(form.formState.dirtyFields).length > 0;

  const discount = form.watch('paymentModifier');
  const discountRequired = discount === CodeDtoPaymentModifierEnum.DISCOUNT;

  return (
    <FormProvider {...form}>
      <Form<CodeEditorContentDto> onValid={handleValid} initialFocusRef={initialFocusRef}>
        <ModalHeader>{t('code:action.new')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <ValidityPeriodControl />
            <ProductSelectionControl />
            <PaymentModifierControl />
            {discountRequired && <DiscountControl />}
            <AutomaticConnectionControl />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            {t('common:action.abort')}
          </Button>
          <SubmitButton variant="primary" isDisabled={!formIsDirty}>
            {t('common:action.apply')}
          </SubmitButton>
        </ModalFooter>
      </Form>
    </FormProvider>
  );
}
