/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  GroupCompanyListItemPageDto,
  GroupCompanyRelationDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    GroupCompanyListItemPageDtoFromJSON,
    GroupCompanyListItemPageDtoToJSON,
    GroupCompanyRelationDtoFromJSON,
    GroupCompanyRelationDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateGroupCompanyRelationRequest {
    groupCompanyRelationDto: GroupCompanyRelationDto;
}

export interface DeleteRelationByIdRequest {
    id: string;
}

export interface FetchGroupCompanyHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchGroupCompanyRelationListItemsRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

/**
 * 
 */
export class GroupCompanyApi extends runtime.BaseAPI {

    /**
     * Create a group company relation.
     */
    async createGroupCompanyRelationRaw(requestParameters: CreateGroupCompanyRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupCompanyRelationDto>> {
        if (requestParameters['groupCompanyRelationDto'] == null) {
            throw new runtime.RequiredError(
                'groupCompanyRelationDto',
                'Required parameter "groupCompanyRelationDto" was null or undefined when calling createGroupCompanyRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-company-relations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupCompanyRelationDtoToJSON(requestParameters['groupCompanyRelationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupCompanyRelationDtoFromJSON(jsonValue));
    }

    /**
     * Create a group company relation.
     */
    async createGroupCompanyRelation(requestParameters: CreateGroupCompanyRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupCompanyRelationDto> {
        const response = await this.createGroupCompanyRelationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteRelationByIdRaw(requestParameters: DeleteRelationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteRelationById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-company-relations/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteRelationById(requestParameters: DeleteRelationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRelationByIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fetchGroupCompanyHistoryRaw(requestParameters: FetchGroupCompanyHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchGroupCompanyHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-company-relations/versionsByGroup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchGroupCompanyHistory(requestParameters: FetchGroupCompanyHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchGroupCompanyHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for group company relations.
     */
    async searchGroupCompanyRelationListItemsRaw(requestParameters: SearchGroupCompanyRelationListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupCompanyListItemPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchGroupCompanyRelationListItems().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-company-relations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupCompanyListItemPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for group company relations.
     */
    async searchGroupCompanyRelationListItems(requestParameters: SearchGroupCompanyRelationListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupCompanyListItemPageDto> {
        const response = await this.searchGroupCompanyRelationListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
