import React from 'react';
import { Trans } from 'react-i18next';
import invariant from 'tiny-invariant';
import { CompanyFilmRelationListItemDto } from '../../../../api';
import HistoryPage from '../../../../ui/history/history-page';
import { PageState } from '../../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../../util/swr/use-fetcher';
import { fetchCompanyFilmHistory } from '../../company-queries';
import { CompanyHistoryExtension } from '../../company-relation/company-history-extension';
import useCompanyFilmHistorySettings from './use-company-film-history-settings';

export const companyFilmHistoryExtension: CompanyHistoryExtension = {
  key: 'film',
  buttonLabel: <Trans ns="company" i18nKey="relation.film.tabLabel" />,
  content: (companyId, state, onStateChanged) => (
    <CompanyFilmHistory companyId={companyId} state={state} onStateChanged={onStateChanged} />
  ),
};

interface CompanyFilmHistoryProps {
  companyId: string;
  state: PageState;
  onStateChanged: (state: PageState) => void;
}

export function CompanyFilmHistory({ companyId, state, onStateChanged }: CompanyFilmHistoryProps) {
  const companyFilmHistorySettings = useCompanyFilmHistorySettings();

  const companyFilmHistoryPage = useFetcher(fetchCompanyFilmHistory, {
    companyId,
    pageable: state,
  });

  invariant(companyFilmHistoryPage != null, 'Missing company film history page');

  return (
    <HistoryPage<CompanyFilmRelationListItemDto>
      page={companyFilmHistoryPage}
      state={state}
      onStateChange={onStateChanged}
      historyDisplaySettings={companyFilmHistorySettings}
      showLine={false}
    />
  );
}
