import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { GroupFilmListItemDto } from '../../../api';
import HistoryDisplaySettings from '../../../ui/history/history-display-settings';
import HistoryPage from '../../../ui/history/history-page';
import usePaginationState from '../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../util/swr/use-fetcher';
import Translate from '../../../util/translate/translate';
import { FilmHistoryExtension } from '../../film/film-history/film-history-extension';
import { fetchFilmGroupHistory } from '../group-queries';

export const filmHistoryGroupExtension: FilmHistoryExtension = {
  key: 'groups',
  buttonLabel: <Translate ns="group" i18nKey="filmRelation.relatedGroups" />,
  content: (props) => <FilmGroupHistory {...props} />,
};

function FilmGroupHistory({ filmId }: { filmId: string }) {
  const [state, setState] = usePaginationState();
  const filmGroupHistorySettings = useFilmGroupHistorySettings();

  const groupFilmHistoryPage = useFetcher(fetchFilmGroupHistory, {
    filmId,
    pageable: state,
  });

  invariant(groupFilmHistoryPage != null, 'Missing film history page');

  return (
    <HistoryPage<GroupFilmListItemDto>
      page={groupFilmHistoryPage}
      state={state}
      onStateChange={setState}
      historyDisplaySettings={filmGroupHistorySettings}
      showLine={false}
    />
  );
}

function useFilmGroupHistorySettings(): HistoryDisplaySettings<GroupFilmListItemDto> {
  const { t } = useTranslation(['group']);

  return useMemo<HistoryDisplaySettings<GroupFilmListItemDto>>(() => {
    return {
      attributeLabels: {
        'group.name': t('group:name'),
      },
      valueFormatter: {},
      name: 'group.name',
      showEditDescription: false,
    };
  }, [t]);
}
