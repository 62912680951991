import { Stack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ManualPaymentDto } from '../../../api';
import DateInputFormControl from '../../../ui/form/date-input-control/date-input-form-control';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';

interface RecordManualPaymentFormProps {
  initialFocusRef: React.RefObject<any>;
}

export default function RecordManualPaymentForm({ initialFocusRef }: RecordManualPaymentFormProps) {
  const { t } = useTranslation(['order_confirmation', 'common']);

  return (
    <Stack spacing={4}>
      <DateInputFormControl<ManualPaymentDto>
        ref={initialFocusRef}
        name="receiptOfPayment"
        label={t('paymentResult.receiptOfPayment')}
        isRequired
      />
      <InputFormControl<ManualPaymentDto> label={t('paymentResult.reason')} name="reason" isRequired />
      <InputFormControl<ManualPaymentDto> label={t('paymentResult.method')} name="method" isRequired />
      <FormControl<ManualPaymentDto> name="generateInvoice" label={t('paymentResult.generateInvoice')} isRequired>
        <ValueSelectControl<boolean>
          name="generateInvoice"
          label={t('paymentResult.generateInvoice')}
          options={[true, false]}
          isClearable={false}
          renderLabel={(option) => t(`common:boolean_viewer.${option}`)}
          // rule for required as the isRequired rule will say that false === undefined
          rules={{
            validate: (value) =>
              value == null
                ? t('common:validation_error.required', { field: t('paymentResult.generateInvoice') })
                : undefined,
          }}
        />
      </FormControl>
    </Stack>
  );
}
