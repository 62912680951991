import {
  Box,
  Flex,
  IconButton,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { faArrowDown, faArrowUp, faBroom, faFilter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { merge } from 'lodash-es';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useDefinedContext from '../../util/context/use-defined-context/use-defined-context';
import useDialog from '../../util/use-dialog/use-dialog';
import ConfirmDialog from '../dialog/confirm-dialog';
import Popover from '../popover/popover';
import DataTableColumn, {
  isChildDataTableColumn,
  isParentDataTableColumn,
  ParentDataTableColumn,
} from './data-table-column';
import {
  DataTableColumnProvider,
  DataTableStateContext,
  DataTableStylesContext,
  useDataTableFilter,
} from './data-table-context';
import { DataTableSort } from './data-table-state';

interface DataTableHeaderProps {
  columns: DataTableColumn<any, any>[];
}

function DataTableHeader({ columns }: DataTableHeaderProps) {
  return (
    <Thead>
      <Tr>{columns.map((column) => column && <DataTableColumnHeader key={column.key} column={column} />)}</Tr>
    </Thead>
  );
}

export default React.memo(DataTableHeader);

interface DataTableHeaderCellProps {
  column: DataTableColumn<any, any>;
}

function DataTableColumnHeader({ column }: DataTableHeaderCellProps) {
  const initialFilterFocusRef = React.useRef<HTMLElement>(null);
  const styles = useDefinedContext(DataTableStylesContext);
  const [isCloseable, setIsCloseable] = React.useState(true);

  if (!column) {
    return null;
  }

  const th = (
    <Th
      key={column.key}
      sx={merge(styles.th, column.cellProps, column.headerCellProps)}
      data-sortable={isParentDataTableColumn(column) && column.isSortable}
      data-sticky={column.sticky}
      isNumeric={column.isNumeric}
    >
      <Flex alignItems="center">
        {column.name}
        {isParentDataTableColumn(column) && (
          <>
            <Spacer />
            <Flex ml={column.name != null ? 2 : undefined}>
              <DataTableColumnSort column={column} />
              <DataTableColumnFilter
                initialFilterFocusRef={initialFilterFocusRef}
                column={column}
                isCloseable={isCloseable}
              />
              {column.hasTheBroom && <DataTableBroom />}
            </Flex>
          </>
        )}
      </Flex>
    </Th>
  );

  if (isChildDataTableColumn(column)) {
    return th;
  }

  return (
    <DataTableColumnProvider
      column={column}
      initialFilterFocusRef={initialFilterFocusRef}
      onCloseableChange={setIsCloseable}
    >
      {th}
    </DataTableColumnProvider>
  );
}

interface DataTableColumnSortProps {
  column: ParentDataTableColumn<any>;
}

function DataTableColumnSort({ column }: DataTableColumnSortProps) {
  const sortProperty = column.sortProperty ?? column.key;
  const { sort, sortingLabel, handleSortClick } = useDataTableSort(sortProperty);

  if (!column.isSortable) {
    return null;
  }

  return (
    <Tooltip label={sortingLabel(sort)} closeOnClick={false}>
      <IconButton
        variant={sort != null ? 'primary' : 'solid'}
        icon={<FontAwesomeIcon icon={sort == null || sort.direction === 'DESC' ? faArrowDown : faArrowUp} />}
        aria-label={sortingLabel(sort)}
        size="xs"
        onClick={handleSortClick}
      />
    </Tooltip>
  );
}

export function useDataTableSort(sortProperty: string) {
  const { state, dispatch } = useDefinedContext(DataTableStateContext);
  const { t } = useTranslation('common');

  const sort = state.sort?.find((sort) => sort.property === sortProperty);
  const sortingLabel = (sort: DataTableSort | undefined) => {
    if (sort == null) {
      return t('data_table.sort_descending');
    }

    if (sort.direction === 'DESC') {
      return t('data_table.sort_ascending');
    }

    return t('data_table.remove_sorting');
  };

  const handleSortClick = () => {
    dispatch({ type: 'SORT', payload: sortProperty });
  };

  return { sort, sortingLabel, handleSortClick };
}

interface DataTableColumnFilterProps {
  initialFilterFocusRef: React.RefObject<HTMLElement>;
  column: ParentDataTableColumn<any>;
  isCloseable: boolean;
}

function DataTableColumnFilter({ initialFilterFocusRef, column, isCloseable }: DataTableColumnFilterProps) {
  const { t } = useTranslation('common');
  const { property, getFilter } = useDataTableFilter();
  const hasFilter = getFilter(property) != null;
  const popoverWidth = column.filterWidth != null ? column.filterWidth : property.startsWith('dateTime') ? 'md' : 'xs';
  const { onToggle, isOpen, onClose } = useDisclosure();
  const popoverContentRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    if (isCloseable) {
      onClose();
    }
  };

  if (column.filter == null) {
    return null;
  }

  return (
    <Popover
      initialFocusRef={initialFilterFocusRef}
      closeOnHorizontalScroll={{ unlessInsideRef: popoverContentRef }}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Tooltip label={t('data_table.open_filter')}>
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton
              aria-label={t('data_table.open_filter')}
              icon={<FontAwesomeIcon icon={faFilter} />}
              variant={hasFilter ? 'primary' : 'solid'}
              size="xs"
              ml={1}
              onClick={(event) => {
                event.stopPropagation();
                onToggle();
              }}
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <Portal>
        <PopoverContent onClick={(event) => event.stopPropagation()} width={popoverWidth} ref={popoverContentRef}>
          <PopoverArrow />
          <PopoverBody px={3} pb={3} pt={2}>
            {column.filter}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

function DataTableBroom() {
  const { dispatch } = useDefinedContext(DataTableStateContext);
  const { t } = useTranslation('common');

  const [confirmBroomDialogIsOpen, onConfirmBroomDialogClosed, openConfirmBroomDialog] = useDialog();

  const handleBroomClick = async () => {
    const confirm = await openConfirmBroomDialog();
    if (confirm) {
      dispatch({ type: 'BROOM' });
    }
  };

  return (
    <>
      <Tooltip placement="bottom-end" label={t('data_table.broom_filter_and_sorting')}>
        <IconButton
          variant="solid"
          icon={<FontAwesomeIcon icon={faBroom} />}
          aria-label={t('data_table.broom_filter_and_sorting')}
          size="xs"
          ml={1}
          onClick={handleBroomClick}
        />
      </Tooltip>
      <ConfirmDialog
        isOpen={confirmBroomDialogIsOpen}
        onClose={onConfirmBroomDialogClosed}
        confirmActionLabel={t('data_table.broom_filter_and_sorting_confirm_button')}
        returnFocusOnClose={false}
      >
        <p>{t('data_table.broom_filter_and_sorting_confirm')}</p>
      </ConfirmDialog>
    </>
  );
}
