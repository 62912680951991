import { Flex, Heading, Stack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContent } from '../../../ui/page';
import useParam from '../../../util/use-params/use-params';
import { FilmHelmet } from '../../film/film-helmet';
import FilmCompanyDataTable from './film-company-data-table';

export const Component = FilmCompaniesRoute;

export function FilmCompaniesHelmet() {
  const { t } = useTranslation('company');

  return <FilmHelmet suffix={t('relation.company.relatedCompanies')} />;
}

export default function FilmCompaniesRoute() {
  const { t } = useTranslation(['company']);

  return (
    <PageContent useFullWidth fixedHeight>
      <Stack spacing={6}>
        <Flex as="header">
          <Heading size="md" as="h3">
            {t('company:relation.film.relatedCompanies')}
          </Heading>
        </Flex>
        <FilmCompanyDataTable filmId={useParam('filmId')} />
      </Stack>
    </PageContent>
  );
}
