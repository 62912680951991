import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Stack } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, now, toPairs } from 'lodash-es';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MailingTemplateDto, MailingTypeDto, ModuleTypeDto } from '../../../../api';
import useFetcher from '../../../../util/swr/use-fetcher';
import { fetchModuleConstraints } from '../../mailing-template-queries';
import { ModuleSettingsModuleControl } from './module-settings-module-control';
import useNumberOfMailingModules from './use-number-of-mailing-modules';

interface FlexModuleSettingsControlProps {
  name: string;
  mailingType: MailingTypeDto;
}

export default function FlexModuleSettingsControl({ name, mailingType }: FlexModuleSettingsControlProps) {
  const { t } = useTranslation('mailing_template');
  const { fields, append, remove } = useFieldArray<MailingTemplateDto, `moduleSettings.${number}.moduleOptions`>({
    name: `${name}.moduleOptions` as `moduleSettings.${number}.moduleOptions`,
  });

  const constraints = useFetcher(fetchModuleConstraints, { mailingType }, { immutable: true });
  const numberOfModules = useNumberOfMailingModules((mailingTemplate) => mailingTemplate.moduleSettings);
  const menuItems = toPairs(constraints)
    .filter(
      ([moduleType, constraint]) =>
        moduleType !== 'FLEX' &&
        (constraint.maxAmount == null || constraint.maxAmount > (numberOfModules[moduleType] ?? 0)) &&
        !fields.some((option) => option.moduleType === moduleType),
    )
    .map(([moduleType, constraint]) => (
      <MenuItem
        key={moduleType}
        onClick={() =>
          append({
            ...constraint.defaultSettings,
            timestamp: new Date(now()),
          })
        }
      >
        {t(`moduleSettings.moduleTypeOptions.${moduleType as ModuleTypeDto}`)}
      </MenuItem>
    ));

  return (
    <Stack
      m={0}
      p={0}
      alignItems="stretch"
      gap={0}
      border="1px"
      borderColor="border.01"
      borderRadius="base"
      bg="layer.01"
    >
      {fields.map((moduleSettings, innerIndex) => (
        <Box key={moduleSettings.id} sx={{ borderBottom: '1px solid', borderColor: 'border.01' }}>
          <ModuleSettingsModuleControl
            name={`${name}.moduleOptions.${innerIndex}` as `moduleSettings.${number}.moduleOptions.${number}`}
            isDeletable
            onDelete={() => {
              remove(innerIndex);
            }}
            mailingType={mailingType}
          />
        </Box>
      ))}

      <Menu placement="bottom">
        <MenuButton as={Button} borderTopRadius={isEmpty(fields) ? '4px' : '0'} isDisabled={menuItems.length === 0}>
          <FontAwesomeIcon icon={faPlus} /> {t('moduleSettings.addModule')}
        </MenuButton>
        <MenuList>{menuItems}</MenuList>
      </Menu>
    </Stack>
  );
}
