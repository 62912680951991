import { HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PersonStatusDto } from '../../../api';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import { LayoutType } from '../../common/layout-type';
import InitialCommentControl from './initial-comment-control';
import PhotoControl from './photo-control/photo-control';

const statusOptions = [PersonStatusDto.ACTIVE, PersonStatusDto.INACTIVE, PersonStatusDto.HISTORIC];

/**
 * Properties for additional information control.
 */
export interface AdditionalInformationControlProps {
  layout: LayoutType;
  showInitialComment?: boolean;
  disableStatus?: boolean;
}

/**
 * Control to edit additional information for person.
 */
export default function AdditionalInformationControl({
  layout,
  showInitialComment,
  disableStatus,
}: AdditionalInformationControlProps) {
  return (
    <Stack spacing={3}>
      <HStack spacing={6} alignItems="flex-start">
        <PhotoControl layout={layout} />
      </HStack>
      {showInitialComment && <InitialCommentControl />}
      <HStack spacing={6} alignItems="flex-start">
        <StatusControl disableStatus={disableStatus} />
      </HStack>
    </Stack>
  );
}

export function StatusControl({ disableStatus }: { disableStatus?: boolean }) {
  const { t } = useTranslation('person');

  return (
    <ValueSelectFormControl<PersonStatusDto>
      name="status"
      label={t('status')}
      helperText={
        !disableStatus && (
          <Stack shouldWrapChildren spacing={0}>
            <Trans ns="person" i18nKey="statusHintActive" />
            <Trans ns="person" i18nKey="statusHintInactive" />
            <Trans ns="person" i18nKey="statusHintHistoric" />
          </Stack>
        )
      }
      options={statusOptions}
      renderLabel={(value) => t(`statusLabel.${value}`)}
      isDisabled={disableStatus ?? false}
      defaultValue={PersonStatusDto.ACTIVE}
      isRequired
    />
  );
}
