import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useLandingPagePermissionClassGroupExtension(): PermissionClassGroupExtension<'LANDING_PAGE'> {
  const { t } = useTranslation(['landing_page']);

  return {
    name: 'LANDING_PAGE',
    label: t('landing_page:landingPage'),
    getActionLabel: (action) => t(`landing_page:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`landing_page:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`landing_page:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
