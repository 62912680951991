/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LandingPageDesignSponsorRowDto } from './LandingPageDesignSponsorRowDto';
import {
    LandingPageDesignSponsorRowDtoFromJSON,
    LandingPageDesignSponsorRowDtoFromJSONTyped,
    LandingPageDesignSponsorRowDtoToJSON,
} from './LandingPageDesignSponsorRowDto';

/**
 * 
 * @export
 * @interface LandingPageDesignSponsoringDto
 */
export interface LandingPageDesignSponsoringDto {
    /**
     * 
     * @type {LandingPageDesignSponsorRowDto}
     * @memberof LandingPageDesignSponsoringDto
     */
    row1: LandingPageDesignSponsorRowDto;
    /**
     * 
     * @type {LandingPageDesignSponsorRowDto}
     * @memberof LandingPageDesignSponsoringDto
     */
    row2: LandingPageDesignSponsorRowDto;
    /**
     * 
     * @type {LandingPageDesignSponsorRowDto}
     * @memberof LandingPageDesignSponsoringDto
     */
    row3: LandingPageDesignSponsorRowDto;
}

/**
 * Check if a given object implements the LandingPageDesignSponsoringDto interface.
 */
export function instanceOfLandingPageDesignSponsoringDto(value: object): boolean {
    if (!('row1' in value)) return false;
    if (!('row2' in value)) return false;
    if (!('row3' in value)) return false;
    return true;
}

export function LandingPageDesignSponsoringDtoFromJSON(json: any): LandingPageDesignSponsoringDto {
    return LandingPageDesignSponsoringDtoFromJSONTyped(json, false);
}

export function LandingPageDesignSponsoringDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageDesignSponsoringDto {
    if (json == null) {
        return json;
    }
    return {
        
        'row1': LandingPageDesignSponsorRowDtoFromJSON(json['row1']),
        'row2': LandingPageDesignSponsorRowDtoFromJSON(json['row2']),
        'row3': LandingPageDesignSponsorRowDtoFromJSON(json['row3']),
    };
}

export function LandingPageDesignSponsoringDtoToJSON(value?: LandingPageDesignSponsoringDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'row1': LandingPageDesignSponsorRowDtoToJSON(value['row1']),
        'row2': LandingPageDesignSponsorRowDtoToJSON(value['row2']),
        'row3': LandingPageDesignSponsorRowDtoToJSON(value['row3']),
    };
}

