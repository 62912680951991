import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useLiveTicketingPermissionClassGroupExtension(): PermissionClassGroupExtension<'LIVE_TICKETING'> {
  const { t } = useTranslation(['live_ticketing']);

  return {
    name: 'LIVE_TICKETING',
    label: t('live_ticketing:live_ticketing'),
    getActionLabel: (action) => t(`live_ticketing:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`live_ticketing:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`live_ticketing:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
