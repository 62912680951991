import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { countryIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function CountrySearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'COUNTRY', `${result.resultType} is no instance of CountrySearchResult`);

  const country = result.result;
  const { t } = useTranslation('country');

  return (
    <SearchResult
      icon={countryIcon}
      title={country.name}
      link="/countries"
      score={result.score}
      onClick={onClick}
      necessaryPermission="COUNTRY:CAN_SEE_DETAILS"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('englishName'),
            element: country.englishName,
          },
          {
            label: t('countryCode'),
            element: country.countryCode,
          },
          {
            label: t('status'),
            element: t(`statusLabel.${country.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}
