import { LandingPageReferenceDto } from '../../../api';
import { landingPageDesignIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

interface LandingPageReferenceProps extends Omit<ReferenceProps, 'children' | 'icon' | 'to'> {
  reference: LandingPageReferenceDto;
}

export default function LandingPageReference({ reference, ...props }: LandingPageReferenceProps) {
  return (
    <Reference
      necessaryPermission="LANDING_PAGE:CAN_SEE_DETAILS"
      icon={landingPageDesignIcon}
      to={`/landing-pages/${reference.id}`}
      {...props}
    >
      {reference.name}
    </Reference>
  );
}
