/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilmReferenceDto } from './FilmReferenceDto';
import {
    FilmReferenceDtoFromJSON,
    FilmReferenceDtoFromJSONTyped,
    FilmReferenceDtoToJSON,
} from './FilmReferenceDto';
import type { GroupReferenceDto } from './GroupReferenceDto';
import {
    GroupReferenceDtoFromJSON,
    GroupReferenceDtoFromJSONTyped,
    GroupReferenceDtoToJSON,
} from './GroupReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * The list item for a relation between a group and a film.
 * @export
 * @interface GroupFilmListItemDto
 */
export interface GroupFilmListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupFilmListItemDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof GroupFilmListItemDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {GroupReferenceDto}
     * @memberof GroupFilmListItemDto
     */
    group: GroupReferenceDto;
    /**
     * 
     * @type {FilmReferenceDto}
     * @memberof GroupFilmListItemDto
     */
    film: FilmReferenceDto;
}

/**
 * Check if a given object implements the GroupFilmListItemDto interface.
 */
export function instanceOfGroupFilmListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('group' in value)) return false;
    if (!('film' in value)) return false;
    return true;
}

export function GroupFilmListItemDtoFromJSON(json: any): GroupFilmListItemDto {
    return GroupFilmListItemDtoFromJSONTyped(json, false);
}

export function GroupFilmListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupFilmListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'group': GroupReferenceDtoFromJSON(json['group']),
        'film': FilmReferenceDtoFromJSON(json['film']),
    };
}

export function GroupFilmListItemDtoToJSON(value?: GroupFilmListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'group': GroupReferenceDtoToJSON(value['group']),
        'film': FilmReferenceDtoToJSON(value['film']),
    };
}

