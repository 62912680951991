/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AttendanceBlockerDto,
  AttendeeDto,
  AttendeePageDto,
  FailureDto,
  LiveTicketingSettingsDto,
  PageableDto,
} from '../models/index';
import {
    AttendanceBlockerDtoFromJSON,
    AttendanceBlockerDtoToJSON,
    AttendeeDtoFromJSON,
    AttendeeDtoToJSON,
    AttendeePageDtoFromJSON,
    AttendeePageDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    LiveTicketingSettingsDtoFromJSON,
    LiveTicketingSettingsDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface AddAttendeeRequest {
    eventId: string;
    attendeeDto: AttendeeDto;
}

export interface AddAttendeesRequest {
    eventId: string;
    attendeeDto: Array<AttendeeDto>;
}

export interface CreateOrUpdateSettingsRequest {
    eventId: string;
    liveTicketingSettingsDto: LiveTicketingSettingsDto;
}

export interface FindSettingsRequest {
    eventId: string;
}

export interface GetAttendeesRequest {
    eventId: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface OverbookRoomRequest {
    eventId: string;
}

export interface StartLiveTicketingRequest {
    eventId: string;
}

export interface StopLiveTicketingRequest {
    eventId: string;
}

/**
 * 
 */
export class LiveTicketingApi extends runtime.BaseAPI {

    /**
     * Add Attendee to Event.
     */
    async addAttendeeRaw(requestParameters: AddAttendeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling addAttendee().'
            );
        }

        if (requestParameters['attendeeDto'] == null) {
            throw new runtime.RequiredError(
                'attendeeDto',
                'Required parameter "attendeeDto" was null or undefined when calling addAttendee().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/live-ticketing/event/{eventId}/attendee`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttendeeDtoToJSON(requestParameters['attendeeDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add Attendee to Event.
     */
    async addAttendee(requestParameters: AddAttendeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addAttendeeRaw(requestParameters, initOverrides);
    }

    /**
     * Add Attendees to Event.
     */
    async addAttendeesRaw(requestParameters: AddAttendeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling addAttendees().'
            );
        }

        if (requestParameters['attendeeDto'] == null) {
            throw new runtime.RequiredError(
                'attendeeDto',
                'Required parameter "attendeeDto" was null or undefined when calling addAttendees().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/live-ticketing/event/{eventId}/attendees`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['attendeeDto']!.map(AttendeeDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add Attendees to Event.
     */
    async addAttendees(requestParameters: AddAttendeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addAttendeesRaw(requestParameters, initOverrides);
    }

    /**
     * Create or Update Liveticketing Settings
     */
    async createOrUpdateSettingsRaw(requestParameters: CreateOrUpdateSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling createOrUpdateSettings().'
            );
        }

        if (requestParameters['liveTicketingSettingsDto'] == null) {
            throw new runtime.RequiredError(
                'liveTicketingSettingsDto',
                'Required parameter "liveTicketingSettingsDto" was null or undefined when calling createOrUpdateSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/live-ticketing/event/{eventId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LiveTicketingSettingsDtoToJSON(requestParameters['liveTicketingSettingsDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create or Update Liveticketing Settings
     */
    async createOrUpdateSettings(requestParameters: CreateOrUpdateSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createOrUpdateSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * Find Liveticketing settings
     */
    async findSettingsRaw(requestParameters: FindSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LiveTicketingSettingsDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling findSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/live-ticketing/event/{eventId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LiveTicketingSettingsDtoFromJSON(jsonValue));
    }

    /**
     * Find Liveticketing settings
     */
    async findSettings(requestParameters: FindSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LiveTicketingSettingsDto> {
        const response = await this.findSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get list of people that were registered for the event
     */
    async getAttendeesRaw(requestParameters: GetAttendeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttendeePageDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getAttendees().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/live-ticketing/event/{eventId}/attendees`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttendeePageDtoFromJSON(jsonValue));
    }

    /**
     * get list of people that were registered for the event
     */
    async getAttendees(requestParameters: GetAttendeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttendeePageDto> {
        const response = await this.getAttendeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Overbook room with exceeded capacity
     */
    async overbookRoomRaw(requestParameters: OverbookRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling overbookRoom().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/live-ticketing/event/{eventId}/overbook`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Overbook room with exceeded capacity
     */
    async overbookRoom(requestParameters: OverbookRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.overbookRoomRaw(requestParameters, initOverrides);
    }

    /**
     * Start the Live Ticketing
     */
    async startLiveTicketingRaw(requestParameters: StartLiveTicketingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling startLiveTicketing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/live-ticketing/event/{eventId}/start`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Start the Live Ticketing
     */
    async startLiveTicketing(requestParameters: StartLiveTicketingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.startLiveTicketingRaw(requestParameters, initOverrides);
    }

    /**
     * Stop the Live Ticketing
     */
    async stopLiveTicketingRaw(requestParameters: StopLiveTicketingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling stopLiveTicketing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/live-ticketing/event/{eventId}/stop`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Stop the Live Ticketing
     */
    async stopLiveTicketing(requestParameters: StopLiveTicketingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.stopLiveTicketingRaw(requestParameters, initOverrides);
    }

}
