import { offerIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import OfferSearchResult from './offer-search-result';

export default function OfferPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'OFFER',
    Component: OfferSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'OFFER',
    icon: offerIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.OFFER" />,
  });
}
