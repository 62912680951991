import { AlertDialogProps, Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogStatus } from './dialog';

export interface ConfirmDialogProps {
  isOpen: boolean;
  onClose(confirm: boolean): void;
  cancelActionLabel?: string;
  confirmActionLabel: React.ReactNode;
  isConfirmActionDisabled?: boolean;
  canCancel?: boolean;
  children?: React.ReactNode;
  submitButtonColorScheme?: ButtonProps['colorScheme'];
  submitButtonVariant?: ButtonProps['variant'];
  submitButtonProps?: ButtonProps;
  title?: string;
  scrollBehavior?: AlertDialogProps['scrollBehavior'];
  returnFocusOnClose?: boolean;
  initialFocusRef?: React.RefObject<any>;
  status?: DialogStatus;
}

export default function ConfirmDialog({
  isOpen,
  onClose,
  cancelActionLabel,
  canCancel = true,
  confirmActionLabel,
  isConfirmActionDisabled = false,
  children,
  submitButtonVariant,
  submitButtonColorScheme,
  submitButtonProps,
  title,
  scrollBehavior,
  returnFocusOnClose = true,
  initialFocusRef,
  status = 'warning',
}: ConfirmDialogProps) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('common');

  return (
    <Dialog
      leastDestructiveRef={initialFocusRef ?? cancelRef}
      isOpen={isOpen}
      onClose={() => onClose(false)}
      size="lg"
      status={status}
      title={title ?? t('misc.warning')}
      scrollBehavior={scrollBehavior}
      closeOnOverlayClick={false}
      returnFocusOnClose={returnFocusOnClose}
      footer={
        <>
          {canCancel && (
            <Button ref={cancelRef} onClick={() => onClose(false)}>
              {cancelActionLabel ?? t('action.abort')}
            </Button>
          )}
          <Button
            {...submitButtonProps}
            colorScheme={submitButtonColorScheme}
            variant={submitButtonVariant}
            onClick={() => onClose(true)}
            isDisabled={isConfirmActionDisabled}
            ml={3}
          >
            {confirmActionLabel}
          </Button>
        </>
      }
    >
      {children}
    </Dialog>
  );
}
