import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useLandingPageDesignPermissionClassGroupExtension(): PermissionClassGroupExtension<'LANDING_PAGE_DESIGN'> {
  const { t } = useTranslation(['landing_page_design']);

  return {
    name: 'LANDING_PAGE_DESIGN',
    label: t('landing_page_design:landingPageDesign'),
    getActionLabel: (action) => t(`landing_page_design:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`landing_page_design:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`landing_page_design:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
