/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyCompanyRelationTypeDto } from './CompanyCompanyRelationTypeDto';
import {
    CompanyCompanyRelationTypeDtoFromJSON,
    CompanyCompanyRelationTypeDtoFromJSONTyped,
    CompanyCompanyRelationTypeDtoToJSON,
} from './CompanyCompanyRelationTypeDto';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Relation between two companies
 * @export
 * @interface CompanyCompanyRelationDto
 */
export interface CompanyCompanyRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CompanyCompanyRelationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof CompanyCompanyRelationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof CompanyCompanyRelationDto
     */
    from: CompanyReferenceDto;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof CompanyCompanyRelationDto
     */
    to: CompanyReferenceDto;
    /**
     * 
     * @type {CompanyCompanyRelationTypeDto}
     * @memberof CompanyCompanyRelationDto
     */
    relationType?: CompanyCompanyRelationTypeDto;
}

/**
 * Check if a given object implements the CompanyCompanyRelationDto interface.
 */
export function instanceOfCompanyCompanyRelationDto(value: object): boolean {
    if (!('from' in value)) return false;
    if (!('to' in value)) return false;
    return true;
}

export function CompanyCompanyRelationDtoFromJSON(json: any): CompanyCompanyRelationDto {
    return CompanyCompanyRelationDtoFromJSONTyped(json, false);
}

export function CompanyCompanyRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyCompanyRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'from': CompanyReferenceDtoFromJSON(json['from']),
        'to': CompanyReferenceDtoFromJSON(json['to']),
        'relationType': json['relationType'] == null ? undefined : CompanyCompanyRelationTypeDtoFromJSON(json['relationType']),
    };
}

export function CompanyCompanyRelationDtoToJSON(value?: CompanyCompanyRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'from': CompanyReferenceDtoToJSON(value['from']),
        'to': CompanyReferenceDtoToJSON(value['to']),
        'relationType': CompanyCompanyRelationTypeDtoToJSON(value['relationType']),
    };
}

