import { HStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MailingTextModuleSettingsDto } from '../../../../api';
import RichTextFormControl from '../../../../ui/form/rich-text-control/rich-text-form-control';
import HelperPopover from '../../../../ui/helper-buttons/helper-popover';
import { RichTextOptions } from '../../../../ui/rich-text/rich-text-options';

export interface TextMailingModuleControlProps {
  module: MailingTextModuleSettingsDto;
  name: string;
}

export default function TextMailingModuleControl({ module, name }: TextMailingModuleControlProps) {
  const { t } = useTranslation('mailing');

  return (
    <HStack spacing={6} alignItems="top">
      <RichTextFormControl
        name={`${name}.content`}
        label={t('text_module.label')}
        helperText={t('text_module.helper_text')}
        helperPopover={<HelperPopover children={t('text_module.helper_popover')} />}
        options={module.richTextOptions as RichTextOptions[]}
        variant="mailing"
      />
      <RichTextFormControl
        name={`${name}.contentEnglish`}
        label={t(`text_module.label`) + ' ' + t(`text_module.english`)}
        helperPopover={<HelperPopover children={t('text_module.helper_popover')} />}
        options={module.richTextOptions as RichTextOptions[]}
        variant="mailing"
      />
    </HStack>
  );
}
