import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { landingPageTemplateIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';
import { useLandingPageTypes } from './landing-page-type-config';

export default function LandingPageTemplateSearchResult({ result, onClick }: SearchResultProps) {
  invariant(
    result.resultType === 'LANDING_PAGE_TEMPLATE',
    `${result.resultType} is no instance of LandingPageTemplateSearchResult`,
  );

  const landingPageTemplate = result.result;
  const { t } = useTranslation(['landing_page_template', 'common']);
  const { getLandingPageTypeConfig } = useLandingPageTypes();

  return (
    <SearchResult
      icon={landingPageTemplateIcon}
      title={landingPageTemplate.name}
      link={`/landing-page-templates/${landingPageTemplate.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="LANDING_PAGE_TEMPLATE:CAN_SEE_DETAILS"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('landing_page_template:status'),
            element: t(`landing_page_template:statusOptions.${landingPageTemplate.status}`),
          },
          {
            label: t('landing_page_template:type'),
            element: getLandingPageTypeConfig(landingPageTemplate.type).label,
          },
        ]}
      />
    </SearchResult>
  );
}
