import { Alert, AlertIcon, HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PersonDto, PersonDtoSalutationEnum } from '../../../api';
import InputFormControl from '../../../ui/form/input-form-control';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import useWatchChange from '../../../ui/form/use-watch-change/use-watch-change';
import { LayoutType } from '../../common/layout-type';
import { postNominalOptions, salutationOptions, titleOptions } from '../person-enum-constants';

export interface SalutationControlProps {
  layout: LayoutType;
}

export default function SalutationControl({ layout }: SalutationControlProps) {
  const { t } = useTranslation(['common', 'person']);

  return (
    <>
      <Stack spacing={4}>
        <PartialSalutationControl layout={layout} />

        <Stack direction={layout} spacing={6} alignItems="flex-start">
          <ValueSelectFormControl
            name="title"
            label={t('person:title')}
            helperText={t('person:titleExplanation')}
            options={titleOptions}
            renderLabel={(option) => t(`person:titleLabels.${option}`)}
          />

          <ValueSelectFormControl
            name="postNominal"
            label={t('person:postNominal')}
            options={postNominalOptions}
            renderLabel={(option) => t(`person:postNominalLabels.${option}`)}
          />
        </Stack>
      </Stack>
    </>
  );
}

export function PartialSalutationControl({ layout }: { layout: LayoutType }) {
  const { t } = useTranslation(['common', 'person']);

  const salutation = useWatch({ name: 'salutation' });
  const { resetField } = useFormContext();

  useWatchChange<PersonDto>(['salutation'], (data) => {
    if (data.salutation !== PersonDtoSalutationEnum.INDIVIDUAL) {
      resetField('salutationIndividual', { defaultValue: null });
    }
  });

  return (
    <>
      <Stack direction={layout} spacing={6}>
        <ValueSelectFormControl
          name="salutation"
          label={t('person:salutation')}
          isRequired
          options={salutationOptions}
          renderLabel={(option) => t(`person:salutationLabels.${option}`)}
          defaultValue={PersonDtoSalutationEnum.DEFAULT}
        />

        <InputFormControl<PersonDto>
          name="salutationIndividual"
          label={t('person:salutation_individual')}
          maxLength={100}
          minLength={3}
          isRequired={salutation === PersonDtoSalutationEnum.INDIVIDUAL}
          isDisabled={salutation !== PersonDtoSalutationEnum.INDIVIDUAL}
        />
      </Stack>

      {salutation === PersonDtoSalutationEnum.INDIVIDUAL && (
        <HStack spacing={6} alignItems="flex-start">
          <Alert status="info">
            <AlertIcon />
            {t('person:salutation_individual_alert')}
          </Alert>
        </HStack>
      )}
    </>
  );
}
