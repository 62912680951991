import { ReactNode } from 'react';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface FilmHistoryExtension {
  key: string;
  buttonLabel: ReactNode;
  content: ({ filmId }: { filmId: string }) => ReactNode;
}

export const FILM_HISTORY_EXTENSION = new PluginToken<FilmHistoryExtension>('FilmHistoryExtension');
