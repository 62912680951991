/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlockElementDto } from './BlockElementDto';
import {
    instanceOfBlockElementDto,
    BlockElementDtoFromJSON,
    BlockElementDtoFromJSONTyped,
    BlockElementDtoToJSON,
} from './BlockElementDto';
import type { InlineElementDto } from './InlineElementDto';
import {
    instanceOfInlineElementDto,
    InlineElementDtoFromJSON,
    InlineElementDtoFromJSONTyped,
    InlineElementDtoToJSON,
} from './InlineElementDto';
import type { MarkElementDto } from './MarkElementDto';
import {
    instanceOfMarkElementDto,
    MarkElementDtoFromJSON,
    MarkElementDtoFromJSONTyped,
    MarkElementDtoToJSON,
} from './MarkElementDto';

/**
 * @type BlockChildDto
 * 
 * @export
 */
export type BlockChildDto = { type: 'citation' } & BlockElementDto | { type: 'heading1' } & BlockElementDto | { type: 'heading2' } & BlockElementDto | { type: 'link' } & InlineElementDto | { type: 'linkButton' } & InlineElementDto | { type: 'listItem' } & BlockElementDto | { type: 'orderedList' } & BlockElementDto | { type: 'paragraph' } & BlockElementDto | { type: 'text' } & MarkElementDto | { type: 'unorderedList' } & BlockElementDto;

export function BlockChildDtoFromJSON(json: any): BlockChildDto {
    return BlockChildDtoFromJSONTyped(json, false);
}

export function BlockChildDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockChildDto {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'citation':
            return {...BlockElementDtoFromJSONTyped(json, true), type: 'citation'};
        case 'heading1':
            return {...BlockElementDtoFromJSONTyped(json, true), type: 'heading1'};
        case 'heading2':
            return {...BlockElementDtoFromJSONTyped(json, true), type: 'heading2'};
        case 'link':
            return {...InlineElementDtoFromJSONTyped(json, true), type: 'link'};
        case 'linkButton':
            return {...InlineElementDtoFromJSONTyped(json, true), type: 'linkButton'};
        case 'listItem':
            return {...BlockElementDtoFromJSONTyped(json, true), type: 'listItem'};
        case 'orderedList':
            return {...BlockElementDtoFromJSONTyped(json, true), type: 'orderedList'};
        case 'paragraph':
            return {...BlockElementDtoFromJSONTyped(json, true), type: 'paragraph'};
        case 'text':
            return {...MarkElementDtoFromJSONTyped(json, true), type: 'text'};
        case 'unorderedList':
            return {...BlockElementDtoFromJSONTyped(json, true), type: 'unorderedList'};
        default:
            throw new Error(`No variant of BlockChildDto exists with 'type=${json['type']}'`);
    }
}

export function BlockChildDtoToJSON(value?: BlockChildDto | null): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'citation':
            return BlockElementDtoToJSON(value);
        case 'heading1':
            return BlockElementDtoToJSON(value);
        case 'heading2':
            return BlockElementDtoToJSON(value);
        case 'link':
            return InlineElementDtoToJSON(value);
        case 'linkButton':
            return InlineElementDtoToJSON(value);
        case 'listItem':
            return BlockElementDtoToJSON(value);
        case 'orderedList':
            return BlockElementDtoToJSON(value);
        case 'paragraph':
            return BlockElementDtoToJSON(value);
        case 'text':
            return MarkElementDtoToJSON(value);
        case 'unorderedList':
            return BlockElementDtoToJSON(value);
        default:
            throw new Error(`No variant of BlockChildDto exists with 'type=${value['type']}'`);
    }

}

