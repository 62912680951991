import { chakra, forwardRef } from '@chakra-ui/react';
import React, { ComponentPropsWithoutRef, ForwardedRef } from 'react';
import { Render } from '../render';
import { BlockFormatOption, WithFunction } from '../slate-types';
import { useRichTextStyles } from '../styles-context';
import { applyAlignment } from './with-alignment';

const PARAGRAPH: BlockFormatOption = 'paragraph';

const withParagraph: WithFunction = (editor) => {
  const paragraphStyle: Render<'block'> = {
    type: PARAGRAPH,
    render: ({ children, attributes, element }) => (
      <Paragraph {...attributes} {...applyAlignment(element)}>
        {children}
      </Paragraph>
    ),
  };

  editor.renderers = [...editor.renderers, paragraphStyle];

  return editor;
};

export default withParagraph;

const Paragraph = forwardRef(
  ({ children, ...props }: ComponentPropsWithoutRef<typeof chakra.p>, ref: ForwardedRef<HTMLButtonElement>) => {
    const styles = useRichTextStyles();

    return (
      <chakra.p __css={styles.paragraph} {...props} ref={ref}>
        {children}
      </chakra.p>
    );
  },
);
