import { HStack, IconButton, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { faQrcode, faQuestionCircle, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { KeyboardEvent, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import DebouncedInput from '../../util/debounce/debounced-input';
import { useGlobalSearchContext } from './use-global-search-state';

export function SearchInput({ initialFocusRef }: { initialFocusRef?: RefObject<HTMLInputElement> }) {
  const { t } = useTranslation('app');
  const {
    highlightIndex,
    setPhrase,
    phrase,
    highlightNext,
    highlightPrev,
    clickHighlighted,
    setScannerIsOpen,
    setHelperTextIsOpen,
  } = useGlobalSearchContext();

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const actions: Record<string, () => void> = {
      ArrowDown: highlightNext,
      ArrowUp: highlightPrev,
      Enter: clickHighlighted,
    };

    const action = actions[event.key];

    if (action != null) {
      event.preventDefault();
      event.stopPropagation();

      action();
    }
  };

  return (
    <HStack>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <FontAwesomeIcon icon={faSearch} />
        </InputLeftElement>
        <Input
          as={DebouncedInput}
          placeholder={t('search.label')}
          aria-label={t('search.label')}
          defaultValue={phrase ?? ''}
          onDebouncedChange={setPhrase}
          onKeyDown={handleKeyDown}
          onBlur={() => highlightIndex(null)}
          ref={initialFocusRef}
        />
      </InputGroup>
      <IconButton
        aria-label={t('search.qrSearch.scan')}
        onClick={() => {
          setScannerIsOpen(true);
        }}
        icon={<FontAwesomeIcon icon={faQrcode} />}
      />
      <IconButton
        aria-label={t('search.help')}
        onClick={() => {
          setHelperTextIsOpen(true);
        }}
        icon={<FontAwesomeIcon icon={faQuestionCircle} />}
      />
    </HStack>
  );
}
