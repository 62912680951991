/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LandingPageTemplatePermissionDto
 */
export interface LandingPageTemplatePermissionDto {
    /**
     * 
     * @type {string}
     * @memberof LandingPageTemplatePermissionDto
     */
    name: LandingPageTemplatePermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof LandingPageTemplatePermissionDto
     */
    action: LandingPageTemplatePermissionDtoActionEnum;
}


/**
 * @export
 */
export const LandingPageTemplatePermissionDtoNameEnum = {
    LANDING_PAGE_TEMPLATE: 'LANDING_PAGE_TEMPLATE'
} as const;
export type LandingPageTemplatePermissionDtoNameEnum = typeof LandingPageTemplatePermissionDtoNameEnum[keyof typeof LandingPageTemplatePermissionDtoNameEnum];

/**
 * @export
 */
export const LandingPageTemplatePermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    COPY: 'CAN_COPY',
    DELETE: 'CAN_DELETE'
} as const;
export type LandingPageTemplatePermissionDtoActionEnum = typeof LandingPageTemplatePermissionDtoActionEnum[keyof typeof LandingPageTemplatePermissionDtoActionEnum];


/**
 * Check if a given object implements the LandingPageTemplatePermissionDto interface.
 */
export function instanceOfLandingPageTemplatePermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function LandingPageTemplatePermissionDtoFromJSON(json: any): LandingPageTemplatePermissionDto {
    return LandingPageTemplatePermissionDtoFromJSONTyped(json, false);
}

export function LandingPageTemplatePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageTemplatePermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function LandingPageTemplatePermissionDtoToJSON(value?: LandingPageTemplatePermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

