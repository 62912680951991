import {
  Box,
  Button,
  chakra,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { faArrowUpRight, faLink, faLinkSimple } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentPropsWithoutRef, ForwardedRef, forwardRef, RefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseSelection, NodeEntry, Path, Point, Text, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import invariant from 'tiny-invariant';
import Translate from '../../../util/translate/translate';
import { getActiveBlock, getActiveBlockNode } from '../block';
import { addInline, isInlineBlockActive, normalizeChildLength, removeInline, updateInline } from '../inline';
import { Render } from '../render';
import { InlineFormatOption, LinkButtonElement, LinkElement, WithFunction } from '../slate-types';
import { useRichTextStyles } from '../styles-context';
import ToolbarButton from '../toolbar/toolbar-button';

const LINK: InlineFormatOption = 'link';

const withLink: WithFunction = (editor) => {
  const renderLink: Render<'inline'> = {
    type: LINK,
    render: ({ children, attributes, element }) => {
      invariant(element.type === 'link');

      return (
        <Link href={element.url} {...attributes}>
          {children}
        </Link>
      );
    },
  };
  editor.renderers = [...editor.renderers, renderLink];

  editor.toolbarButtons.link = [...editor.toolbarButtons.link, <LinkToolbarButton format={LINK} />];

  const { isInline } = editor;
  editor.isInline = (element) => LINK === element.type || isInline(element);

  normalizeChildLength(editor, 50, LINK);

  return editor;
};

export default withLink;

const Link = forwardRef(
  ({ children, ...props }: ComponentPropsWithoutRef<typeof chakra.a>, ref: ForwardedRef<HTMLAnchorElement>) => {
    const styles = useRichTextStyles();

    return (
      <chakra.a __css={styles.link} {...props} ref={ref}>
        <Box as={FontAwesomeIcon} __css={styles.linkIcon} icon={faArrowUpRight} />
        {children}
      </chakra.a>
    );
  },
);

const LINK_BUTTON: InlineFormatOption = 'linkButton';

export const withLinkButton: WithFunction = (editor) => {
  const renderLinkButton: Render<'block'> = {
    type: LINK_BUTTON,
    render: ({ children, attributes, element }) => {
      invariant(element.type === 'linkButton');

      return (
        <LinkButton href={element.url} {...attributes}>
          {children}
        </LinkButton>
      );
    },
  };
  editor.renderers = [...editor.renderers, renderLinkButton];

  editor.toolbarButtons.link = [...editor.toolbarButtons.link, <LinkToolbarButton format={LINK_BUTTON} />];

  const { isInline } = editor;
  editor.isInline = (element) => LINK_BUTTON === element.type || isInline(element);

  normalizeChildLength(editor, 26, LINK_BUTTON);

  return editor;
};

const LinkButton = forwardRef(
  ({ children, ...props }: ComponentPropsWithoutRef<typeof chakra.a>, ref: ForwardedRef<HTMLLinkElement>) => {
    const styles = useRichTextStyles();

    return (
      <chakra.a sx={styles.linkButton} {...props} ref={ref}>
        {children}
      </chakra.a>
    );
  },
);

const LinkToolbarButton = forwardRef(
  ({ format }: { format: InlineFormatOption }, ref: ForwardedRef<HTMLButtonElement>) => {
    const editor = useSlate();
    const initialFocusRef = useRef(null);
    const activeBlock = getActiveBlock(editor);
    const activeLinkEntry = getActiveBlockNode(editor, format);
    const { selection } = editor;
    const { t } = useTranslation('common');

    const moveSelectionToNextSibling = (activeLocation: Path) => {
      activeLocation[activeLocation.length - 1] += 1;
      Transforms.select(editor, activeLocation);
      ReactEditor.focus(editor);
    };

    return (
      <Popover initialFocusRef={initialFocusRef} isLazy>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <ToolbarButton
                isActive={isInlineBlockActive(editor, format)}
                isDisabled={activeBlock != null && !editor.markOrInlineBlockAllowed(activeBlock)}
                onClick={(event) => {
                  if (isCursorPointAtLastIndex(selection, activeLinkEntry) && activeLinkEntry?.[1] != null) {
                    moveSelectionToNextSibling(activeLinkEntry?.[1]);
                    event.preventDefault();
                  }
                }}
                label={t(`wysiwyg.aria_labels.${format}`)}
                icon={format === 'link' ? faLink : faLinkSimple}
                ref={ref}
              />
            </PopoverTrigger>
            <Portal>
              <PopoverContent width="xl">
                <PopoverArrow />
                <LinkPopoverContent
                  onClose={onClose}
                  initialFocusRef={initialFocusRef}
                  format={format}
                  activeLink={activeLinkEntry}
                />
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
    );
  },
);

function LinkPopoverContent({
  onClose,
  format,
  activeLink,
  initialFocusRef,
}: {
  onClose: () => void;
  format: InlineFormatOption;
  activeLink: NodeEntry | undefined;
  initialFocusRef: RefObject<HTMLInputElement>;
}) {
  const editor = useSlate();
  const initialLink = (activeLink?.[0] as LinkElement | LinkButtonElement | undefined)?.url;
  const [link, setLink] = useState(initialLink != null ? initialLink : '');
  const { t } = useTranslation('common');

  return (
    <PopoverBody>
      <HStack>
        <FormControl>
          <HStack>
            <FormLabel size="sm" mb={0} mr={1}>
              {t('wysiwyg.link_label')}:
            </FormLabel>
            <Input
              ref={initialFocusRef}
              size="sm"
              value={link}
              onChange={(newValue) => setLink(newValue.target.value)}
            />
          </HStack>
        </FormControl>
        <Button
          size="sm"
          onClick={(event) => {
            event.preventDefault();
            if (activeLink != null) {
              updateInline(editor, activeLink[1], link);
            } else {
              addInline(editor, format, link);
            }
            setLink('');
            ReactEditor.focus(editor);
            onClose();
          }}
        >
          <Translate ns="common" i18nKey="action.apply" />
        </Button>
        {isInlineBlockActive(editor, format) && (
          <Button
            size="sm"
            onClick={(event) => {
              event.preventDefault();
              removeInline(editor, format);
              ReactEditor.focus(editor);
              onClose();
            }}
          >
            <Translate ns="common" i18nKey="action.delete" />
          </Button>
        )}
      </HStack>
    </PopoverBody>
  );
}

function isCursorPointAtLastIndex(
  selection: BaseSelection,
  activeLink: NodeEntry<LinkElement | LinkButtonElement> | undefined,
): boolean {
  const activeText = (activeLink?.[0]?.children[0] as Text | undefined)?.text;
  const isPointSelection =
    selection?.focus != null && selection.anchor != null && Point.equals(selection.focus, selection.anchor);
  const isCursorPointAtLastIndex =
    isPointSelection && activeText != null && activeText.length === selection.focus.offset;

  return isCursorPointAtLastIndex && activeLink?.[1] != null;
}
